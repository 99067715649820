import styled from 'styled-components'


interface DashboardNavigationContainerProps {

}


export const StyledDashboardNavigationContainer = styled.div<DashboardNavigationContainerProps>`
    border-top: solid 1px lightgrey;
    padding: 12px 0px 0px 0px;
    overflow-x: scroll;

    @media (min-width: 768px) {
        padding: 24px 0px 12px 0px;
    }
    
    @media (min-width: 1600px) {
    }
`

interface DashboardNavigationProps {

}


export const StyledDashboardNavigation = styled.div<DashboardNavigationProps>`
    width: 100%;
    display: inline-flex;
    gap: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 768px) {
    }
    
    @media (min-width: 1600px) {
    }
`
