import styled from 'styled-components'

interface LoadingModalContainerProps {

}


export const StyledLoadingModalContainer  = styled.div<LoadingModalContainerProps>`
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
`

interface LoadingModalProps {

}

export const StyledLoadingModal = styled.div<LoadingModalProps>`
    background: #fff;
    padding: 120px;
`

