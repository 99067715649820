import { useNavigate } from "react-router-dom"
import { Button } from "../Button"
import { Text } from "../Text"
import { View } from "../View"
import { StyledAuction, StyledAuctionContainer, StyledAuctionItems, StyledAuctionItemsContainer } from "./styled"
import { usePostStore, useProductDetailPageStore } from "../../stores/web.store"
import { Post } from "../Post"
import { toAuctionState, toDateDiff } from "../../utils/app.util"
import { useEffect, useState } from "react"


const Auction = ({data}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const navigate = useNavigate()

    // const [timeValue, setTimeValue] = useState<any>(toDateDiff(data.metadata.end_datetime, true))
    // const [auctionState, setAuctionState] = useState<any>('')

    // useEffect(() => {
    //     toAuctionState(data, setTimeValue, setAuctionState)
    // }, [])

    // const onClickViewAll = () => {
    //     setPost(data)
    //     setPdp('auction')
    // }


    // const countdown = () => {
    //     setInterval(function () {
    //         setTimeValue(toDateDiff(data.metadata.end_datetime, true))
    //     }, 1000);
    // }

    const onClick = () => {
        setPost(data)
        setPdp('auction')
    }

    return (
        <StyledAuctionContainer>
            <StyledAuction>
                <Post data={data} type={data.type} setPost={setPost} onClick={onClick} borderRadius="10px" noIcon/>
                {/* <StyledAuctionItemsContainer>
                    <StyledAuctionItems>
                        {data.posts.map((post: any) => {
                            return (
                                <Post data={post} type={post.type} setPost={setPost} onClick={() => onClickPost({...post, parent_post: data})}/>
                            )
                        })}
                        
                    </StyledAuctionItems>
                </StyledAuctionItemsContainer>   */}
            </StyledAuction>
        </StyledAuctionContainer>
    )
}

export default Auction