import { QuickFilter } from "../QuickFilter"


type ShowProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const Show = ({selectedFilter, setSelectedFilter}: ShowProps) => {
    const onClick = () => {
        setSelectedFilter('show')
    }

    return (
        <QuickFilter title="Shows" onClick={onClick} isSelected={selectedFilter === 'show'} />
    )
}

export default Show