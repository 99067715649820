import { Actions } from "./Actions"
import { Title } from "./Title"
import { StyledInitialState, StyledInitialStateContainer } from "./styled"


const InitialState = () => {
    return (
        <StyledInitialStateContainer>
            <StyledInitialState>
                <Title />
                <Actions />
            </StyledInitialState>
        </StyledInitialStateContainer>
    )
}

export default InitialState