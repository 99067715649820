import styled from 'styled-components'


interface HighAuctionRecordContainerProps {

}


export const StyledHighAuctionRecordContainer = styled.div<HighAuctionRecordContainerProps>`
    border-bottom: solid 1px #000;
    padding: 12px 0px;
    width: 100%;
`

interface HighAuctionRecordProps {

}


export const StyledHighAuctionRecord = styled.div<HighAuctionRecordProps>`
    display: inline-flex;
    width: 100%;
    padding: 4px 0px;
`

interface HighAuctionRecordContentContainerProps {
    isExpanded?: boolean;
}

export const StyledHighAuctionRecordContentContainer = styled.div<HighAuctionRecordContentContainerProps>`
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    ${props => props.isExpanded && `
        height: fit-content;
        max-height: 200px;
    `}
`

interface HighAuctionRecordContentProps {

}

export const StyledHighAuctionRecordContent = styled.div<HighAuctionRecordContentProps>`

`











