import styled from "styled-components";

interface AboutSectionContainerProps {

}

export const StyledAboutSectionContainer = styled.div<AboutSectionContainerProps>`
`

interface AboutSectionProps {

}

export const StyledAboutSection = styled.div<AboutSectionProps>`
    display: grid;
    grid-template-columns: 50% 50%;
    width: calc(100% - 24px);
    grid-gap: 24px;
`