import { QuickFilter } from "../QuickFilter"

type AuctionProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const Auction = ({selectedFilter, setSelectedFilter}: AuctionProps) => {
    const onClick = () => {
        setSelectedFilter('auction')
    }

    return (
        <QuickFilter title="Auctions" onClick={onClick} isSelected={selectedFilter === 'auction'}/>
    )
}

export default Auction