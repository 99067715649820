import styled from "styled-components";

interface SlideMenuContainerProps {
    isActive?: any;
    isExpanded?: boolean;
    isExtender?: boolean;
}

export const StyledSlideMenuContainer = styled.div<SlideMenuContainerProps>`
    position: fixed;
    top: 0;
    height: 100vh;
    background: #fff;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 5;
    width: ${props => props.isExpanded ? '100%' : '0'};
    left: 0;
    
    @media (min-width: 768px) {
        width: ${props => props.isActive ? (props.isExpanded ? 'calc(70% - 201px)' : 'calc(70% - 68px)'): '0'};
        left: ${props => props.isExpanded ? (props.isExtender ? 'calc(30% + 226px)' : 'calc(30% + 226px)'): (props.isExtender ? 'calc(30% + 68px)' : '90px')};

    }

    @media (min-width: 1024px) {

    }
`

interface SlideMenuProps {
    isActive?: boolean;
}

export const StyledSlideMenu = styled.div<SlideMenuProps>`
    position: relative;
`

interface ButtonContainerProps {

}

export const StyledButtonContainer = styled.div<ButtonContainerProps>`
    position: absolute;
    top: 12px;
    right: 24px;
`

interface MessageContainerProps {

}

export const StyledMessageContainer = styled.div<MessageContainerProps>`
    position: absolute;
    bottom: 24px;
    left: 24px;
    width: calc(100% - 48px);
    margin: auto;
`