import styled from 'styled-components'


interface StateContainerProps {

}


export const StyledStateContainer = styled.div<StateContainerProps>`
    width: 95%;
    padding: 0px 0px 60px 0px;
    margin: auto;

    @media (min-width: 768px) {
        width: 600px;
    }

    @media (min-width: 1024px) {

    }
`

interface StateProps {

}


export const StyledState = styled.div<StateProps>`
    background: rgba(0, 0, 0, 0.65);
    border-radius: 12px;
    padding: 32px 24px;
`









