
import { useProductDetailPageStore, useProfileStore } from "../../../stores/web.store"
import Button from "../Button"



const Username = ({account, onMouseEnter, onMouseLeave, fontSize, onClick, fontWeight}: any) => {
    const setProfile = useProfileStore((state: any) => state.setProfile) 
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const onClickButton = async () => {
        setProfile(account)
        setPdp('profile')
    }

    return (
        <Button 
            noStyle
            fontWeight={fontWeight || '600'}
            fontSize={fontSize || '13px'}
            onClick={onClick || onClickButton}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            width="max-content"
        >{account.first_name} {account.last_name}</Button>
    )
}

export default Username