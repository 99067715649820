import { Button } from "../../Button"
import { Image } from "../../Image"
import { StyledGalleryTemplate, StyledGalleryTemplateContainer } from "./styled"


const GalleryTemplate = ({template, selectedTemplate, setSelectedTemplate}: any) => {

    const onClick = () => {
        if (template === selectedTemplate) {
            setSelectedTemplate('')
        } else {
            setSelectedTemplate(template)
        }
        
    }
    return (
        <StyledGalleryTemplateContainer>
            <Button noStyle onClick={onClick} width="100%" display="block">
                <StyledGalleryTemplate isActive={selectedTemplate === template}>
                    <Image src={`/img/templates/gallery/${template}`} width="100%" height="120px"/>
                </StyledGalleryTemplate>
            </Button>
        </StyledGalleryTemplateContainer>
    )
}

export default GalleryTemplate