import { Image } from "../Image"
import { ReactComponent as MultiMediaIcon } from '../../img/multiMedia.svg';
import { ReactComponent as ExhibitIcon } from '../../img/exhibitIcon.svg';
import { ReactComponent as AuctionIcon } from '../../img/auctionIcon.svg';
import { ReactComponent as VideoIcon } from '../../img/video.svg';
import { ReactComponent as ArtworkIcon } from '../../img/artworkPost.svg';
import { StyledPost, StyledPostContainer, StyledPostTypeIcon } from "./styled"
import { Button } from "../Button";
import { Text } from "../Text";
import { ImageGallery } from "../ImageGallery";
import { useModalStore, useProductDetailPageStore } from "../../stores/web.store";
import { toCurrency, toDate } from "../../utils/app.util";
import { ProfilePicture } from "../ProfilePicture";
import { View } from "../View";
import ViewInRoom from "../Button/ViewInRoom/ViewInRoom";
import { ReactNode, useEffect } from "react";


type PostProps = {
    data?: any;
    type?: string;
    setPost?: any;
    height?: string;
    width?: string;
    isThumbnail?: boolean;
    onClick?: any
    borderRadius?: string;
    noIcon?: boolean;
    children?: ReactNode;
}


const Post = ({data, type, height, width, setPost, onClick, isThumbnail, noIcon, borderRadius, children}: PostProps) => {
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    const onClickToPdp = () => {
        setPost(data)

        if (data.type === 'media') {
            setModalKey('post')
        } else {
            setPdp(data.type)
        }
       
        // setModalKey(null)
    }

    return (
        <StyledPostContainer>
            <StyledPost>
                {(!isThumbnail && !noIcon) && <StyledPostTypeIcon>
                    <View>
                        {data.type === 'media' && data.media.length > 1 && <MultiMediaIcon width={20} height={20}/>}
                        {/* {data.type === 'video' && <VideoIcon width="28px" height="28px"/>} */}
                        {data.type === 'show' && <ExhibitIcon width={20} height={20} />}
                        {data.type === 'artwork' && <ArtworkIcon width={20} height={20}/>}
                        {data.type === 'auction' && <AuctionIcon width={20} height={20} stroke="#fff"/>}
                    </View>
                </StyledPostTypeIcon>}
                <ImageGallery data={data} setPost={setPost} height={height} width={width} isThumbnail={isThumbnail} onClick={onClick || onClickToPdp} borderRadius={borderRadius}/>
                
                {children ? children : 
                
                    <View>
                        {!isThumbnail && ['artwork', 'auction'].includes(type || '') && <View padding="12px 0px">
                            <Button noStyle display="block" onClick={onClick || onClickToPdp}>
                                {(type || '') === 'artwork' && <Text fontWeight="600" padding="0px 0px 4px 0px">
                                    <ProfilePicture account={data.owner} username fontSize="14px"/>
                                </Text>}
                                <Text padding="0px 0px 4px 0px" textAlign="left">{data.title}</Text>
                                {(type || '') === 'auction' && <Text color="#808080" padding="0px 0px 4px 0px" textAlign="left">{data.description || data.caption}</Text>}
                                {(type || '') === 'auction' && <Text color="#808080" padding="0px 0px 4px 0px" textAlign="left">{`${toDate(data.metadata.start_datetime)} - ${toDate(data.metadata.end_datetime)}`}</Text>}
                                {(type || '') === 'artwork' && <Text fontWeight="600" padding="0px 0px 4px 0px" textAlign="left">
                                    {toCurrency((data.state || '') === 'auction' ? data.metadata.starting_bid : data.metadata.price)} {(data.state || '')=== 'auction' && `(${data.bids || 0} bids)`}
                                </Text>}
                            </Button>
                            
                        </View>}

                        {!isThumbnail && ['show'].includes(type || '') && <View padding="12px 0px">
                            <Button noStyle display="block" onClick={onClick || onClickToPdp}>
                                <Text fontWeight="600" padding="0px 0px 4px 0px"><ProfilePicture account={data.owner} username fontSize="14px"/></Text>
                                <Text color="#808080" padding="0px 0px 4px 0px">{data.title}</Text>
                                <Text color="#808080">{toDate(`${data.metadata.start_datetime}`)} to {toDate(`${data.metadata.end_datetime}`)}</Text>
                            </Button>
                        </View>}
                    </View>}
            </StyledPost>
        </StyledPostContainer>
    )
}

export default Post