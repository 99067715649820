import styled from "styled-components";


export const StyledHeroContainer = styled.div`
    background-image: url("/img/landing/Headquarters.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 405px;
    width: 100%;

`

export const StyledCards = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 48px 32px;
`

export const StyledCard = styled.div`
    -webkit-box-shadow: 0px 0px 10px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px -8px rgba(0,0,0,0.75);
    padding: 24px;
    border-radius: 10px;
`