import styled from 'styled-components'

interface FeaturedContainterProps {

}


export const StyledFeatureContainer = styled.div<FeaturedContainterProps>`

`


interface FeaturedProps {

}


export const StyledFeatured = styled.div<FeaturedProps>`

`
