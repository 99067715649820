import styled from "styled-components";

interface GalleryFiltersContainerProps {

}


export const StyledGalleryFiltersContainer = styled.div<GalleryFiltersContainerProps>`
    padding: 12px 0px 24px 0px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 0;
`

interface SearchAndSortProps {

}


export const StyledSearchAndSort = styled.div<SearchAndSortProps>`
    display: inline-flex;
    width: 100%;
    gap: 0;
`