import { Text } from "../../components/Text"
import { View } from "../../components/View"
import { StyledTrendingNow } from "./styled"


type TrendingNowItemProps = {
    post?: any;

}

const TrendingNowItem = ({post}: TrendingNowItemProps) => {
    return (
        <View>
            <View display="flex" flexDirection="column" gap="12px">
                <View height="224px" background="#F4F5F6" borderRadius="5px">

                </View>
                <View>
                    <View display="flex" flexDirection="column" gap="2px">
                        <Text fontWeight="500">{'Kishio Suga'}</Text>
                        <Text>{'Enclosed Formation of...'}</Text>
                        <Text>{'BLUM'}</Text>
                        <Text fontWeight="500">{'Sold'}</Text>
                    </View> 
                </View>
            </View>
        </View>
    )
}

const TrendingNow = () => {
    return <View display="none" />
    return (
        <View>
            <View display="inline-flex" flexDirection="column" gap="36px" width="100%">
                <View>
                    <Text fontSize="20px" fontWeight="500" textAlign="center" width="100%">{'Trending Now'}</Text>
                </View>
                <View>
                    <StyledTrendingNow>
                        <TrendingNowItem />
                        <TrendingNowItem />
                        <TrendingNowItem />
                        <TrendingNowItem />
                        <TrendingNowItem />
                        <TrendingNowItem />
                    </StyledTrendingNow>
                </View>
            </View>
        </View>
    )
}

export default TrendingNow