import { QuickFilter } from "../QuickFilter"


type PatronsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const Patrons = ({selectedFilter, setSelectedFilter}: PatronsProps) => {
    const onClick = () => {
        setSelectedFilter('art_patron')
    }

    return (
        <QuickFilter title="Patrons" onClick={onClick} isSelected={selectedFilter === 'art_patron'}/>
    )
}

export default Patrons