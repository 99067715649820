import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"


const Show = () => {
    return (
        <DashboardLayout>
            <div />
        </DashboardLayout>
    )
}

export default Show