import Skeleton from "react-loading-skeleton"
import { PhotoOverlap } from "../PhotoOverlap"
import { Text } from "../Text"
import { StyledFollowedBy, StyledFollowedByContainer } from "./styled"
import { toUsername } from "../../utils/app.util"


const FollowedBy = ({profile}: any) => {
    if (!profile.followers) return <Skeleton />

    return (
        <StyledFollowedByContainer>
            {profile?.followers.length > 0 && <StyledFollowedBy>
                <PhotoOverlap data={profile.followers}/>
                <Text 
                    fontSize="14px" 
                    color="grey"
                >
                    {`Followed by ${toUsername(profile.followers[0].owner)}${(profile.followers.length - 1) > 0 ? ' and' + (profile.followers.length - 1).toString() + 'others' : ''}`}
                </Text>
            </StyledFollowedBy>}
        </StyledFollowedByContainer>
    )
}

export default FollowedBy