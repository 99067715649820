import styled from 'styled-components'


interface SwitchContainerProps {

}


export const StyledSwitchContainer = styled.div<SwitchContainerProps>`

`


interface SwitchProps {

}


export const StyledSwitch = styled.input<SwitchProps>`

`


interface SliderProps {

}


export const StyledSlider = styled.input<SliderProps>`


`

interface LabelProps {

}


export const StyledLabel = styled.label<LabelProps>`

`

interface SpanProps {
    isChecked?: boolean;
}


export const StyledSpan = styled.div<SpanProps>`
    display: inline-block;
    padding: 0px 16px;
    vertical-align: top;
    font-weight: 300;

    ${props => props.isChecked && `
        font-weight: 500;
    `}

`