import styled, { keyframes } from "styled-components"
import { animated } from 'react-spring';


interface FeedPostContainerProps {

}

export const StyledFeedPostContainer = styled.div<FeedPostContainerProps>`
    padding: 12px 0px;
    
`

interface FeedPostProps {

}

export const StyledFeedPost = styled.div<FeedPostProps>`

`
 interface MobileCommentsContainerProps {
    isActive: boolean;
 }

export const StyledMobileCommentsContainer = styled.div<MobileCommentsContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => props.isActive ? '100%' : '0'};
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
    overflow: hidden;
`;

export const StyledMobileCommentHeader = styled(animated.div)`

`

interface MobileCommentsProps {
    isActive: boolean;
}

export const StyledMobileComments = styled(animated.div)<MobileCommentsProps>`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: ${props => props.isActive ? '80%' : '0'};
    background: rgba(255, 255, 255, 0.95);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 5;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
`
