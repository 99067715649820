import { Button } from "../Button"
import { Text } from "../Text"
import { ProfilePicture } from "../ProfilePicture"
import { StyledGalleryInfo, StyledGalleryInfoContainer } from "./styled"


type GalleryInfoProps = {
    includeLocation?: boolean;
    data?: any;
}


const GalleryInfo = ({data, includeLocation}: GalleryInfoProps) => {
    return (
        <StyledGalleryInfoContainer>
            <ProfilePicture height="32px" width="32px" textAlign="left" account={data} username/>
            <StyledGalleryInfo>
                {includeLocation && <Button noStyle>Add a Location</Button>}
            </StyledGalleryInfo>
        </StyledGalleryInfoContainer>
    )
}

export default GalleryInfo