import styled from 'styled-components'


interface ShowDetailsContainerProps {

}

export const StyledShowDetailsContainer = styled.div<ShowDetailsContainerProps>`

`

interface ShowDetailsProps {

}

export const StyledShowDetails = styled.div<ShowDetailsProps>`

`

interface ShowInfoContainerProps {

}

export const StyledShowInfoContainer = styled.div<ShowInfoContainerProps>`

`

interface ShowInputContainerProps {

}

export const StyledShowInputContainer = styled.div<ShowInputContainerProps>`

`

interface ShowTitleInputProps {

}

export const StyledShowTitleInput = styled.div<ShowTitleInputProps>`
    padding: 24px 0px 0px 0px;
`

interface ShowDatePickerContainerProps {

}

export const StyledShowDatePickerContainer = styled.div<ShowDatePickerContainerProps>`
    padding: 24px 0px;
`

interface ShowDatePickerProps {

}

export const StyledShowDatePicker = styled.div<ShowDatePickerProps>`
    & .react-datetimerange-picker__wrapper {
        display: inline-flex;
        width: max-content;
        align-items: center;
        gap: 0;
        font-family: Roboto;
        border-radius: 10px;
        font-size: 13px;
        text-indent: 12px;
    }

    & .react-datetimerange-picker__range-divider {
        padding: 0px 6px;
    }

    & .react-datetimerange-picker__inputGroup__input:placeholder {
        color: #6b6b6b;
    }

    & .react-datetimerange-picker__inputGroup__input {
        padding: 0;
        min-width: unset;
        width: fit-content;
    }

    & .react-datetime-picker__inputGroup__divider {
        display: none;
    }
`