import styled from 'styled-components'

interface PreviewTextProps {
   
}


export const StyledPreviewText  = styled.div<PreviewTextProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    line-height: 16px;
    font-weight: 500;
    font-size: 13px;
`

interface MultiUsersProps {
   
}


export const StyledMultiUsers  = styled.div<MultiUsersProps>`
    position: relative;

    & :nth-child(2) {
        display: none;
    }



    &:hover > div {
        display: block;
    }
`