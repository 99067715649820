import styled from "styled-components";


interface EditProfileContainerProps {

}

export const StyledEditProfileContainer = styled.div<EditProfileContainerProps>`
    width: 100%;
    background: #fff;
    border-radius: 12px;
    position: relative;
    overflow-y: scroll;
    height: calc(100vh - 120px); 
    
`

interface EditProfileProps {
    
}

export const StyledEditProfile = styled.div<EditProfileProps>`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0;
`