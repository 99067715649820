
import Button from "../Button";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { View } from "../../View";
import { uploadProfilePicture} from "../../../hooks/api.hook"
import { useAccountStore } from "../../../stores/web.store";
import { Text } from "../../Text";


const ChangePhoto = () => {
    const account = useAccountStore((state: any) => state.account)
    const setAccount = useAccountStore((state: any) => state.setAccount)

    const onDrop = useCallback( async (acceptedFiles: any) => {
        const mediaData = [acceptedFiles.map((file: any) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {}
            reader.readAsArrayBuffer(file)
            return {url: URL.createObjectURL(file), file: file}
        })]

        const resp = await uploadProfilePicture({media: mediaData[0][0]})

        setAccount({...account, profile_picture: resp.data.url})
    }, [])
    
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <View>
            <Button
                border="solid 1px #BFCBFB"
                background="#DDE3FD"
                color="#0838F0"
                padding="4px 8px"
                borderRadius="20px"
                fontWeight="600"
                fontSize="13px"
                {...getRootProps()}
                
            >
                <Text>
                    Change Photo
                </Text>
                <input {...getInputProps()} style={{ display: "none" }} />
            </Button>
        </View>

    )
}

export default ChangePhoto