import styled from "styled-components";


interface DividerContainerProps {

}

export const StyledDividerContainer = styled.div<DividerContainerProps>`

`

interface DividerProps {
    margin?: string;
}

export const StyledDivider = styled.hr<DividerProps>`
    border: none;
    border-bottom: solid 1px #e0e0e0;
    ${props => props.margin && `
        margin: ${props.margin};
    `}
`