import styled from 'styled-components'


interface CreateAuctionPostContainerProps {

}


export const StyledCreateAuctionPostContainer  = styled.div<CreateAuctionPostContainerProps>`
    padding: 4px;

    @media (min-width: 768px) {
        padding: 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface CreateAuctionPostProps {

}

export const StyledCreateAuctionPost = styled.div<CreateAuctionPostProps>`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;

    @media (min-width: 768px) {
        grid-template-columns: 30% 70%;
        grid-gap: 20px;
    }

    @media (min-width: 1024px) {

    }
`

