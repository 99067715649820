import { ReactNode } from "react"
import { View } from "../../View"
import { StyledContainer } from "./styled"
import { useMeetingStore, useProductDetailPageStore } from "../../../stores/web.store";
import { Button } from "../../Button";


type ContainerProps = {
    children: ReactNode;
    isExpanded?: boolean;
    padding?: string;
}


const Container = ({children, isExpanded, padding}: ContainerProps) => {
    const pdp = useProductDetailPageStore((state: any) => state.pdp)
    const meeting = useMeetingStore((state: any) => state.meeting)
    return (
        <StyledContainer isActive={(pdp !== null) || (meeting !== null && isExpanded)} isExpanded={isExpanded}>
            <View padding={padding || "24px"}>
                {children}
            </View>
        </StyledContainer>
    )
}

export default Container