import { StyledDivider, StyledDividerContainer } from "./styled"


type DividerProps = {
    margin?: string;
}


const Divider = ({margin}: DividerProps) => {
    return (
        <StyledDividerContainer>
            <StyledDivider margin={margin}/>
        </StyledDividerContainer>
    )
}

export default Divider