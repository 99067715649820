import { ReactNode, useEffect, useRef } from "react"
import { StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { useComponentVisible } from "../../utils/app.util"
import { useModalStore } from "../../stores/web.store";
import { MediaModal } from "./MediaModal";
import { PostModal } from "./PostModal";
import { CreateModal } from "./CreateModal";


type ModalProps = {
    children: ReactNode;
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
    isOverlay?: boolean;
}

const useOutsideAlerter = (ref: any, setModal: any, setModalKey: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
            // setModalKey('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const Modal = (props: ModalProps) => {
    const modalKey = useModalStore((state: any) => state.modalKey)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    const { ref } = useComponentVisible(props.setModal);
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, props.setModal, setModalKey);
  
    if (!props.isOverlay && modalKey === 'create') return <CreateModal {...props} />
    if (!props.isOverlay && modalKey === 'media') return <MediaModal {...props} />
    if (!props.isOverlay && modalKey === 'post') return <PostModal {...props} />
    return (
        <StyledModalContainer position={props.position} top={props.top} left={props.left} background={props.background}>
            <StyledModal width={props.width} height={props.modalHeight} margin={props.margin}>
                <StyledModalContentContainer>
                    <StyledModalContent height={props.height} background={props.background} padding={props.padding} borderRadius={props.borderRadius}>
                        <div ref={wrapperRef}>
                            {props.children}
                        </div>
                    </StyledModalContent>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default Modal