import styled from "styled-components";

interface PhotoReelContainerProps {

}


export const StyledPhotoReelContainer = styled.div<PhotoReelContainerProps>`
    width: 100%;
`


interface PhotoReelProps {
    columns: number;
}


export const StyledPhotoReel = styled.div<PhotoReelProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.columns || 'auto-fill'}, minmax(250px, 1fr));
    grid-gap: 12px;
    width: 100%;
    overflow-x: scroll;
`