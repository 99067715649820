import styled from "styled-components";

interface ArtistsContainerProps {

}

export const StyledArtistsContainer = styled.div<ArtistsContainerProps>`

`

interface ArtistsProps {

}


export const StyledArtists = styled.div<ArtistsProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 16px;
`
