import { useEffect, useState } from "react"
import { Button } from "../../../components/Button"
import { StyledActions, StyledActionsContainer, StyledActionsLeftContainer, StyledActionsRightContainer } from "./styled"
import { useDashboardMenuExpandedStore, usePostPayloadStore } from "../../../stores/web.store"
import { Draft } from "../../../components/Button/Draft"
import { Post } from "../../../components/Button/Post"
import { Cancel } from "../../../components/Button/Cancel"


const PostActions = ({onClickSaveDraft, onClickSharePost, onClickCancel, onClickAddPostToShow, onClickAddPostToAuction, postType}: any) => {
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        if (['show', 'auction'].includes(postType)) {
            setIsDisabled((postPayload.posts || []).length === 0)
        }
    }, [postPayload])

    return (
        <StyledActionsContainer isExpanded={isExpanded}>
            <StyledActions>
                <StyledActionsLeftContainer>
                    <Cancel  onClick={onClickCancel} />
                    {onClickAddPostToShow && <Button noStyle fontWeight="600" onClick={onClickAddPostToShow} color="#0938F0">Add Artwork</Button>}
                    {onClickAddPostToAuction && <Button noStyle fontWeight="600" onClick={onClickAddPostToAuction} color="#0938F0">Add Artwork</Button>}
                </StyledActionsLeftContainer>
                <StyledActionsRightContainer>
                    <Draft isDisabled={isDisabled} onClick={onClickSaveDraft} />
                    <Post isDisabled={isDisabled} onClick={onClickSharePost} />
                </StyledActionsRightContainer>
            </StyledActions>
        </StyledActionsContainer>
    )
}

export default PostActions