import styled from 'styled-components'


interface ImageGalleryContainerProps {

}

export const StyledImageGalleryContainer = styled.div<ImageGalleryContainerProps>`
    
`

interface ImageGalleryProps {

}

export const StyledImageGallery = styled.div<ImageGalleryProps>`
    position: relative;    
`

interface GalleryImageProps {
    height?: string
    borderRadius?: string;
}

export const StyledGalleryImage = styled.div<GalleryImageProps>`
    position: relative;
    width: auto;
    ${props => props.height && `
        height: ${props.height};
    `}

    ${props => props.borderRadius && `
        border-radius: ${props.borderRadius};
    `}
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
//    -webkit-overflow-scrolling: touch;
    
`

interface BackButtonProps {

}

export const StyledBackButton = styled.div<BackButtonProps>`
    position: absolute;
    top: 25%;
    left: 0;
    z-index: 2;
`

interface NextButtonProps {

}

export const StyledNextButton = styled.div<NextButtonProps>`
    position: absolute;
    top: 25%;
    right: 0;
    z-index: 2;

`

interface ZoomSliderContainerProps {

}

export const StyledZoomSliderContainer = styled.div<ZoomSliderContainerProps>`
    position: fixed;
    bottom: 2vh;
    left: 0;
    width: 100%;
    text-align: center;
`

interface ZoomSliderProps {

}

export const StyledZoomSlider = styled.div<ZoomSliderProps>`
    width: 200px;
    margin: auto;
    & .range-slider {
        height: 6px;
    }

    & .range-slider .range-slider__thumb{
        background: #0938F0; 
        height: 13px;
        width: 13px;
        background: #000;
    }

    & .range-slider .range-slider__range {
        background: rgba(0, 0, 0, 0.59); 
    }
`

interface BackNavigatorProps {
    isHover?: boolean;
}

export const StyledBackNavigator = styled.div<BackNavigatorProps>`
    position: absolute;
    left: 8px;
    top: 50%;
    z-index: 2;
    opacity: .9;

    ${props => props.isHover && `
        opacity: .9;

        &:hover {
            opacity: 1;
        }
    
    `}

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`

interface NextNavigatorProps {
    isHover?: boolean;
}

export const StyledNextNavigator = styled.div<NextNavigatorProps>`
    position: absolute;
    right: 8px;
    top: 50%;
    z-index: 2;
    opacity: .9;

    ${props => props.isHover && `
        opacity: .9;

        &:hover {
            opacity: 1;
        }
    
    `}
    

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`