import {create} from 'zustand'
import { LightTheme } from '../themes/web.theme'

export const useAccountStore = create((set) => ({
    account: null,
    setAccount: (data: any) => set(() => ({ account: data }))
}))

export const useAccessStore = create((set) => ({
    access: null,
    setAccess: (data: any) => set(() => ({ access: data }))
}))

export const useAdminAccessStore = create((set) => ({
    isAdmin: false,
    setIsAdmin: (data: any) => set(() => ({ isAdmin: data }))
}))

export const useSuperAdminAccessStore = create((set) => ({
    isSuperAdmin: false,
    setIsSuperAdmin: (data: any) => set(() => ({ isSuperAdmin: data }))
}))

export const useThemeStore = create((set) => ({
    theme: LightTheme(),
    setTheme: (data: any) => set(() => ({ theme: data }))
}))

export const useResponsiveStore = create((set) => ({
    mode: 'desktop',
    height: 1200,
    width: 1440,
    setMode: (data: any) => set(() => ({ mode: data })),
    setHeight: (data: any) => set(() => ({ height: data })),
    setWidth: (data: any) => set(() => ({ width: data }))
}))

export const useModalStore = create((set) => ({
    isModal: false,
    setIsModal: (data: any) => set(() => ({ isModal: data })),
    modalKey: '',
    setModalKey: (data: any) => set(() => ({ modalKey: data }))
}))

const SLIDE_MENU_DEFAULT = {
    isActive: false,
    location: 'left',
    key: null
}

export const useSlideMenuStore = create((set) => ({
    slideMenu: SLIDE_MENU_DEFAULT,
    setSlideMenu: (data: any) => set(() => ({ slideMenu: data })),
    resetSlideMenu: () => set(() => ({ slideMenu: SLIDE_MENU_DEFAULT })),
    openMenu: () => set((state: any) => ({ slideMenu: {...state, isActive: true} })),
    closeMenu: () => set((state: any) => ({ slideMenu: {...state, isActive: false} }))
}))

export const useUserStore = create((set) => ({
    user: null,
    setUser: (data: any) => set(() => ({ user: data }))
}))

export const useMessageStore = create((set) => ({
    message: null,
    setMessage: (data: any) => set(() => ({ message: data }))
}))

export const useThreadStore = create((set) => ({
    thread: null,
    setThread: (data: any) => set(() => ({ thread: data }))
}))

export const useThreadsStore = create((set) => ({
    threads: [],
    setThreads: (data: any) => set(() => ({ threads: data }))
}))

export const useMeetingStore = create((set) => ({
    meeting: null,
    setMeeting: (data: any) => set(() => ({ meeting: data }))
}))

export const useLexicalContextStore = create((set) => ({
    lexicalContext: {},
    setLexicalContext: (data: any) => set(() => ({ lexicalContext: data }))
}))


export const useUploadStore = create((set) => ({
    isUploading: false,
    setIsUploading: (data: any) => set(() => ({ isUploading: data })),
    uploadedResp: {},
    setUploadedResp: (data: any) => set(() => ({ uploadedResp: data })),
}))


const DEFAULT_SIGNUP_STORE = {email: null, password: null, name: null, role: null, invalidEmail: false, invalidPassword: false, invalidName: false}

export const useSignupStore = create((set) => ({
    signupStore: DEFAULT_SIGNUP_STORE,
    setSignupStore: (data: any) => set(() => ({ signupStore: data })),
    resetSignupStore: () => set(() => ({ signupStore: DEFAULT_SIGNUP_STORE}))
}))

export const useTokenStore = create((set) => ({
    token: null,
    setToken: (data: any) => set(() => ({ token: data }))
}))

export const useSignupCompleteStore = create((set) => ({
    signupComplete: false,
    setSignupComplete: (data: any) => set(() => ({ signupComplete: data })),
}))

export const useButterBarStore = create((set) => ({
    isButterBar: false,
    setIsButterBar: (data: any) => set(() => ({ isButterBar: data })),
}))

export const useDashboardNavigationStore = create((set) => ({
    dashboardNavigation: 'all',
    setDashboardNavigation: (data: any) => set(() => ({ dashboardNavigation: data })),
}))

export const useDashboardCreateModeStore = create((set) => ({
    isCreateMode: false,
    setIsCreateMode: (data: any) => set(() => ({ isCreateMode: data })),
}))

export const useSelectedPostStore = create((set) => ({
    selectedPost: null,
    setSelectedPost: (data: any) => set(() => ({ selectedPost: data })),
}))

export const useLandingPageStateStore = create((set) => ({
    activeState: 'initial',
    setActiveState: (data: any) => set(() => ({ activeState: data })),
}))

export const usePostPayloadStore = create((set) => ({
    postPayload: {privacy_settings: {audience: 'public'}, posts: []},
    setPostPayload: (data: any) => set(() => ({ postPayload: data })),
    resetPostPayload: () => set(() => ({ postPayload: {privacy_settings: {audience: 'public'}, posts: []}})),
}))

export const usePostMapPayload = create((set) => ({
    postMapPayload: {},
    setPostMapPayload: (data: any) => set(() => ({ postMapPayload: data })),
    resetPostMapPayload: () => set(() => ({ postMapPayload: {}})),
}))

export const useExplorePostsStore = create((set) => ({
    explorePosts: [],
    setExplorePosts: (data: any) => set(() => ({ explorePosts: data })),
    filteredData: [],
    setFilteredData: (data: any) => set(() => ({ filteredData: data })),
}))

export const useFollowingPostsStore = create((set) => ({
    followingPosts: [],
    setFollowingPosts: (data: any) => set(() => ({ followingPosts: data })),
}))

export const useForYouPostsStore = create((set) => ({
    forYouPosts: [],
    setForYouPosts: (data: any) => set(() => ({ forYouPosts: data })),
}))

export const useAllMyPostsStore = create((set) => ({
    allMyPosts: [],
    setAllMyPosts: (data: any) => set(() => ({ allMyPosts: data })),
}))

export const useMyArtworksStore = create((set) => ({
    myArtworks: [],
    setMyArtworks: (data: any) => set(() => ({ myArtworks: data })),
}))

export const useMyShowsStore = create((set) => ({
    myShows: [],
    setMyShows: (data: any) => set(() => ({ myShows: data })),
}))

export const useMyMediaPostsStore = create((set) => ({
    myMediaPosts: [],
    setMyMediaPosts: (data: any) => set(() => ({ myMediaPosts: data })),
}))

export const useMyAuctionsStore = create((set) => ({
    myAuctions: [],
    setMyAuctions: (data: any) => set(() => ({ myAuctions: data })),
}))

export const useMySubscribersStore = create((set) => ({
    mySubscribers: [],
    setMySubscribers: (data: any) => set(() => ({ mySubscribers: data })),
}))

export const useActiveFeedStore = create((set) => ({
    activeFeed: 'forYou',
    setActiveFeed: (data: any) => set(() => ({ activeFeed: data })),
}))

export const useDashboardMenuExpandedStore = create((set) => ({
    isExpanded: false,
    setIsExpanded: (data: any) => set(() => ({ isExpanded: data })),
}))

export const useViewNavigatorStore = create((set) => ({
    activeView: null,
    setActiveView: (data: any) => set(() => ({ activeView: data })),
}))

export const usePostStore = create((set) => ({
    post: null,
    setPost: (data: any) => set(() => ({ post: data })),
}))

export const usePostsStore = create((set) => ({
    posts: [],
    setPosts: (data: any) => set(() => ({ post: data })),
}))

export const useProfileStore = create((set) => ({
    profile: null,
    setProfile: (data: any) => set(() => ({ profile: data })),
}))

export const useProductDetailPageStore = create((set) => ({
    pdp: null,
    setPdp: (data: any) => set(() => ({ pdp: data })),
    resetPdp: () => set(() => ({ pdp: null })),
}))

export const useFiltersStore = create((set) => ({
    filterStore: {loaded: false, query: {}, filters: {}},
    setFilterStore: (data: any) => set(() => ({ filterStore: data })),
    resetFilterStore: () => set(() => ({ filterStore: {loaded: false, query: {}, filters: {}}})),
}))



  
  
