import styled from "styled-components";

interface AuctionContainerProps {

}

export const StyledAuctionContainer = styled.div<AuctionContainerProps>`

`

interface AuctionProps {

}

export const StyledAuction = styled.div<AuctionProps>`

`

interface AuctionItemsContainerProps {

}

export const StyledAuctionItemsContainer = styled.div<AuctionItemsContainerProps>`

`

interface AuctionItemsProps {

}

export const StyledAuctionItems = styled.div<AuctionItemsProps>`

`