import styled from 'styled-components'


interface ModalContainerProps {
    position?: string;
    top?: string;
    left?: string;
}


export const StyledModalContainer = styled.div<ModalContainerProps>`
    background: ${'rgba(0, 0, 0, .4)'};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5;
`


interface ModalProps {
    width?: any;
    height?: any;
    margin?: any;
}


export const StyledModal = styled.div<ModalProps>`
    width: ${props => props.width || '100%'};
    display: flex;
    flex-direction: column;
    height: ${props => props.height || '100vh'};
    margin: ${props => props.margin || 'auto'};
    align-items: center;
    border-radius: 20px;
    
    

    @media (min-width: 768px) {
        width: ${props => props.width || 'fit-content'};
        margin: ${props => props.margin || 'auto'};
        
    }
    
    @media (min-width: 1600px) {
    }
`

interface CreatePostContainerProps {

}


export const StyledCreatePostContainer = styled.div<CreatePostContainerProps>`
    border-bottom: solid 1px #9E9E9E;
    position: fixed;
    width: 100%;
    padding: 12px 0px;
    background: #fff;
    top: 0;
    

    @media (min-width: 768px) {
        position: relative;
    }
    
    @media (min-width: 1600px) {
    }
`

interface ModalContentContainerProps {

}


export const StyledModalContentContainer = styled.div<ModalContentContainerProps>`
    height: max-content;
    width: 100%;
    margin: auto;
    position: relative;
    padding: 96px 0px 24px 0px;
    
    background: #fff;
    overflow: hidden;
    overflow-y: scroll;
    min-width: 30vw;
    
    @media (min-width: 768px) {
        width: 60%;
        overflow-y: hidden;
        border-radius: 20px;
        padding: 0;
    }

    @media (min-width: 1024px) {

    }
`

interface ModalContentProps {
    height?: string;
    background?: string;
}


export const StyledModalContent = styled.div<ModalContentProps>`

    
    border-radius: 20px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    border-radius: 20px;
    padding: 24px;
    display: block;
    
    
    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
    }
`

interface ModalContentProps {
    height?: string;
    background?: string;
}


export const StyledCreateCard = styled.button<ModalContentProps>`
    cursor: pointer;

    height: max-content;
    border-radius; 20px;
    border: none;
    background: #fff;
    -webkit-box-shadow: 2px 2px 10px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 10px -9px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 10px -9px rgba(0,0,0,0.75);
    margin: auto;
    padding: 48px;

    @media (min-width: 768px) {
        
        
        

    }

    @media (min-width: 1024px) {
    }
    
    
    &:hover {
        background: #ECECEC;
    }

    &:active:hover {
        opacity: .5;
    }
`


