import {  useEffect, useRef } from "react"
import { StyledCreateCard, StyledCreatePostContainer, StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { useComponentVisible } from "../../../utils/app.util"
import { useDashboardCreateModeStore, useModalStore, usePostStore, useResponsiveStore, useSelectedPostStore } from "../../../stores/web.store";
import { Text } from "../../Text";
import { Input } from "../../Input";
import { View } from "../../View";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'
import {ReactComponent as MediaIcon} from '../../../img/media.svg'
import {ReactComponent as ShowIcon} from '../../../img/show.svg'
import {ReactComponent as ArtworkIcon} from '../../../img/artwork.svg'
import {ReactComponent as AuctionIcon} from '../../../img/auctionIcon.svg'
import { useNavigate } from "react-router-dom";


type CreateModalProps = {
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
}

const useOutsideAlerter = (ref: any, setModal: any, setModalKey: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
            setModalKey('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

type CreateCardProps = {
    icon: string;
    title: string;
    description: string;
    onClick: any;
}


const CreateCard = ({icon, title, description, onClick}: CreateCardProps) => {
    const height = useResponsiveStore((state: any) => state.height)
    return (
        <StyledCreateCard onClick={onClick}>
            <View display="inline" flexDirection="column" gap="12px" justifyContent="center">
                <View textAlign="center" width="100%">
                    {icon === 'media' && <MediaIcon height={60} width={60}/>}
                    {icon === 'artwork' && <ArtworkIcon height={60} width={60}/>}
                    {icon === 'auction' && <AuctionIcon stroke="#000" height={60} width={60} />}
                    {icon === 'show' && <ShowIcon height={60} width={60}/>}
                </View>
                <View width="100%">
                    <Text textAlign="center" width="100%" fontSize="18px" color="#000">{title}</Text>
                </View>
                <View width="100%">
                    <Text textAlign="center" width="100%" fontSize="11px" color="#000">{description}</Text>
                </View>
            </View>
        </StyledCreateCard>
    )
}


const CreateMedia = () => {
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const navigate = useNavigate()
    const onClick = () => {
        setIsCreateMode(true)
        navigate('/profile')
        setModalKey(null)
        setSelectedPost('media')

    }
    return (
        <CreateCard
            title={'Media'}
            description={'Share your images and videos easily. Showcase your visual content to engage the community.'}
            icon={'media'}
            onClick={onClick}
        />
    )
}

const CreateAuction = () => {
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const navigate = useNavigate()
    const onClick = () => {
        setIsCreateMode(true)
        navigate('/profile')
        setModalKey(null)
        setSelectedPost('auction')

    }
    return (
        <CreateCard
            title={'Auction'}
            description={'Hold auctions for your artwork and connect with potential buyers in the community.'}
            icon={'auction'}
            onClick={onClick}
        />
    )
}

const CreateArtwork = () => {
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const navigate = useNavigate()
    const onClick = () => {
        setIsCreateMode(true)
        navigate('/profile')
        setModalKey(null)
        setSelectedPost('artwork')

    }
    return (
        <CreateCard
            title={'Artwork'}
            description={'Display your original artwork to inspire and connect with fellow artists and collectors.'}
            icon={'artwork'}
            onClick={onClick}
        />
    )
}

const CreateShow = () => {
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const navigate = useNavigate()
    const onClick = () => {
        setIsCreateMode(true)
        navigate('/profile')
        setModalKey(null)
        setSelectedPost('show')

    }
    return (
        <CreateCard
            title={'Show'}
            description={'Share your art exhibitions and invite the community to experience your creative showcases.'}
            icon={'show'}
            onClick={onClick}
        />
    )
}

const CreateModal = ({setModal, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius}: CreateModalProps) => {
    const post = usePostStore((state: any) => state.post)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const respWidth = useResponsiveStore((state: any) => state.width)
    const { ref } = useComponentVisible(setModal);
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, setModal, setModalKey);

    const onClickClose = () => {
        setModal(false)
        setModalKey('')
    }
    return (
        <StyledModalContainer position={position} top={top} left={left}  ref={ref}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer>
                    <StyledCreatePostContainer>
                        <View>
                            <Text textAlign="center" width="100%" fontWeight="600" fontSize="24px">{'Create new post'}</Text>
                        </View>
                    </StyledCreatePostContainer> 
                    <View>
                        <StyledModalContent height={height} background={background} ref={wrapperRef}>
                            <CreateMedia />
                            <CreateArtwork />
                            <CreateShow />
                            <CreateAuction />
                        </StyledModalContent>
                    </View>
                    <View position={respWidth > 768 ? "absolute" : "fixed"} top="24px" right="24px">
                        <Button noStyle onClick={onClickClose}><XIcon height={28} width={28}/></Button>
                    </View>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default CreateModal