import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { Card } from "../Card"
import { StyledHeroContainer } from "./styled"


type CardProps = {
    isReverse?: boolean;
}

const Collectors = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Let collectors visit your gallery from around the globe"}
            description={"Make it possible for collectors, curators, critics and art patrons to visit your exhibitions as if being there in person, anytime, anywhere."}
            image={"/img/landing/forGalleries/Collectors.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Mount = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Mount exhibitions in virtual reality"}
            description={"Mount beautiful, photo realistic, exhibitions in virtual reality across our web, mobile app, and on the Apple Vision Pro. Whether it’s a solo, thematic group show, or individual secondary market offerings."}
            image={"/img/landing/forGalleries/Mount.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Immersive = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Immersive 3D walkthrough on the Apple Vision Pro"}
            description={"Scan your physical exhibitions with a partner such as Matterport, and bring them to life with spatial technology, so anyone, anywhere, around the world can view your show as if they were there."}
            image={"/img/landing/forGalleries/Immersive.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Showcase = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Showcase your artists and inventory - all in one place"}
            description={"Your gallery profile page on Primary Yellow is a dedicated space to tell the story of your gallery and its artists to a global community of art patrons. Join the conversation and stay connected to everyone around the world."}
            image={"/img/landing/forGalleries/Showcase.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Seamless = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"A seamless checkout experience"}
            description={"Primary Yellow makes it easy for collectors to buy art. Instead of multiple back and forth emails and PDFs, simply send a link with a checkout process that takes 1-click."}
            image={"/img/landing/forGalleries/Seamless.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const ForGalleries = () => {
    return (
        <DashboardLayout>
            <StyledHeroContainer>
                <View display="flex" alignItems="center" height="678px">
                    <View width="100%">
                        <Text padding="0px 0px 0px 64px" fontSize="4vw" color="#fff" fontWeight="500" width="70%">
                            {"Let collectors visit your gallery from anywhere in the world."}
                        </Text>
                        <View width="100%" padding="24px 0px 0px 64px">
                            <Button 
                                height="48px" 
                                width="160px" 
                                borderRadius="24px" 
                                background="#fff" 
                                border="solid 1px #000"
                                fontSize="20px"
                                fontWeight="500"
                                noStyle
                            >Get Started</Button>
                        </View>
                    </View>
                </View>
            </StyledHeroContainer>
            <View padding="64px 48px">
                <View display="flex" flexDirection="column" gap="120px">
                    <Collectors />
                    <Mount isReverse/>
                    <Immersive />
                    <Showcase isReverse />
                    <Seamless />
                    <View>
                        <FooterAd image={'/img/landing/forGalleries/LaunchingSoon.svg'} />
                    </View>
                </View>
            </View>
        </DashboardLayout>
    )
}

export default ForGalleries