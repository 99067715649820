import { useState } from "react"
import { Input } from "../../../components/Input"
import { Select } from "../../../components/Select"
import { Text } from "../../../components/Text"
import { usePostPayloadStore } from "../../../stores/web.store"
import { StyledInlineContainer } from "../../../styled"
import { StyledSwitchContainer } from "../PostPrivacySettings/styled"
import { PostAvailabilityItem } from "./PostAvailabilityItem"
import { StyledPostAvailability, StyledPostAvailabilityContainer } from "./styled"
import { Switch } from "../../../components/Switch"
import { View } from "../../../components/View"

const EstimatedValue = ({payload, setPayload}: any) => {
    const options = [
        {label: 'USD$', value: 'USD'}
    ]

    const onChangeSelect = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), estimated_value_currency: e.value}})
    }

    const onBlur = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), estimated_value: parseFloat(e)}})
    }
    return (
        <PostAvailabilityItem title={'Estimated Value'}>
            <StyledInlineContainer>
                <Select 
                    border="none" 
                    placeholder="USD$" 
                    textIndent={'0'} 
                    options={options} 
                    padding="0"
                    boxShadow={'none !important'}
                    onChange={onChangeSelect}
                />
                <Input type="number" border="none" placeholder="0" min="0" onBlur={onBlur} noPlaceholderPrefix value={payload.metadata?.estimated_value}/>
            </StyledInlineContainer>
        </PostAvailabilityItem>
    )
}

const ReservePrice = ({payload, setPayload}: any) => {
    const options = [
        {label: 'USD$', value: 'USD'}
    ]

    const onChangeSelect = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), reserve_price_currency: e.value}})
    }

    const onBlur = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), reserve_price: parseFloat(e)}})
    }
    return (
        <PostAvailabilityItem title={'Reserve Price'}>
            <StyledInlineContainer>
                <Select 
                    border="none" 
                    placeholder="USD$" 
                    textIndent={'0'} 
                    options={options} 
                    padding="0"
                    boxShadow={'none !important'}
                    onChange={onChangeSelect}
                />
                <Input type="number" border="none" placeholder="0" min="0" onBlur={onBlur} noPlaceholderPrefix value={payload.metadata?.reserve_price}/>
            </StyledInlineContainer>
        </PostAvailabilityItem>
    )
}

const StartingBid = ({payload, setPayload}: any) => {
    const options = [
        {label: 'USD$', value: 'USD'}
    ]

    const onChangeSelect = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), starting_bid_currency: e.value}})
    }

    const onBlur = (e: any) => {
        console.log(e)
        setPayload({...payload, metadata: {...(payload.metadata || {}), starting_bid: parseFloat(e)}})
    }

    const onChange = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), starting_bid: parseFloat(e.target.value)}})
    }
    return (
        <PostAvailabilityItem title={'Starting Bid'}>
            <StyledInlineContainer>
                <Select 
                    border="none" 
                    placeholder="USD$" 
                    textIndent={'0'} 
                    options={options} 
                    padding="0"
                    boxShadow={'none !important'}
                    onChange={onChangeSelect}
                />
                <Input type="number" border="none" placeholder="0" min="0" onBlur={onBlur} onChange={onChange} noPlaceholderPrefix value={payload.metadata?.starting_bid}/>
            </StyledInlineContainer>
        </PostAvailabilityItem>
    )
}

const BuyNowPrice = ({payload, setPayload}: any) => {
    const options = [
        {label: 'USD$', value: 'USD'}
    ]

    const onChangeSelect = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), currency: e.value}})
    }

    const onBlur = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), price: parseFloat(e)}})
    }
    return (
        <PostAvailabilityItem title={'Buy Now Price'}>
            <StyledInlineContainer>
                <Select 
                    border="none" 
                    placeholder="USD$" 
                    textIndent={'0'} 
                    options={options} 
                    padding="0"
                    boxShadow={'none !important'}
                    onChange={onChangeSelect}
                />
                <Input type="number" border="none" min="0" placeholder="0" onBlur={onBlur} noPlaceholderPrefix value={payload.metadata?.price}/>
            </StyledInlineContainer>
        </PostAvailabilityItem>
    )
}

const MakeOffer = ({payload, setPayload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const options = [
        {label: 'USD$', value: 'USD'}
    ]

    const onChangeSwitch = (e: any) => {
        setIsChecked(e)
        setPayload({...payload, metadata: {...(payload.metadata || {}), can_make_offer: e}})
    }

    const onChangeSelect = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), reserve_price_currency: e.value}})
    }

    const onBlur = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), reserve_price: parseFloat(e)}})
    }

    return (
        <PostAvailabilityItem title={'Make Offer'}>
            <StyledInlineContainer>
                <div style={{padding: '12px'}}>
                    <Switch onChange={onChangeSwitch} isChecked={isChecked}/>
                </div>
                {isChecked && <Select 
                    border="none" 
                    placeholder="USD$" 
                    textIndent={'0'} 
                    options={options} 
                    padding="0"
                    boxShadow={'none !important'}
                    onChange={onChangeSelect}
                />}
                {isChecked && <Input type="number" placeholder="0" min="0" border="none" onBlur={onBlur} noPlaceholderPrefix/>}
            </StyledInlineContainer>
        </PostAvailabilityItem>
    )
}


const Inquire = ({payload, setPayload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = (e: any) => {
        setIsChecked(e)
        setPayload({...payload, metadata: {...(payload.metadata || {}), can_inquire: e}})
    }

    return (
        <PostAvailabilityItem title={'Inquire'}>
            <StyledSwitchContainer>
                <Switch onChange={onChange} isChecked={isChecked}/>
            </StyledSwitchContainer>
        </PostAvailabilityItem>
    )
}

const ContactGallery = ({payload, setPayload}: any) => {
    const options = [
        {label: 'Gallery XYZ', id: '123-123123-12312-121'}
    ]

    const onBlur = (e: any) => {
        // setPayload({...payload, metadata: {...(payload.metadata || {}), gallery_email: e}})
    }

    return (
        <PostAvailabilityItem title={'Contact Gallery'} label={'Gallery Email'}>
            <StyledInlineContainer>
                <Input border="none" onBlur={onBlur} placeholder="Gallery Email" textIndent="0" noPlaceholderPrefix/>
                {/* <Select 
                    border="none" 
                    placeholder="Search for gallery" 
                    padding="0" 
                    textIndent={'0'} 
                    options={options} 
                    boxShadow={'none !important'}
                    isHiddenIndicator
                    isSearchable
                /> */}
            </StyledInlineContainer>
        </PostAvailabilityItem>
    )
}

const NotForSale = ({payload, setPayload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = (e: any) => {
        setIsChecked(e)
        setPayload({...payload, metadata: {...(payload.metadata || {}), is_for_sale: !e}})
    }
    return (
        <PostAvailabilityItem title={'Not for Sale'}>
            <StyledSwitchContainer>
                <Switch onChange={onChange} isChecked={isChecked}/>
            </StyledSwitchContainer>
        </PostAvailabilityItem>
    )
}

const Sold = ({payload, setPayload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = (e: any) => {
        setIsChecked(e)
        setPayload({...payload, metadata: {...(payload.metadata || {}), is_sold: e}})
    }
    return (
        <PostAvailabilityItem title={'Sold'}>
            <StyledSwitchContainer>
                <Switch onChange={onChange} isChecked={isChecked}/>
            </StyledSwitchContainer>
        </PostAvailabilityItem>
    )
}

const PermanantCollection = ({payload, setPayload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = (e: any) => {
        setIsChecked(e)
        setPayload({...payload, metadata: {...(payload.metadata || {}), is_permanent_collection: e}})
    }
    return (
        <PostAvailabilityItem title={'Permanent Collection'}>
            <StyledSwitchContainer>
                <Switch onChange={onChange} isChecked={isChecked}/>
            </StyledSwitchContainer>
        </PostAvailabilityItem>
    )
}

const PostAvailability = ({payload, setPayload}: any) => {
    return (
        <StyledPostAvailabilityContainer>
            <Text fontWeight="500" fontSize="16px">Availability</Text>
            <Text padding="4px 0px 0px 0px">Select at least one option</Text>
            <StyledPostAvailability>
                {payload.state === 'auction' && <View>
                    <StartingBid payload={payload} setPayload={setPayload}/>
                    <ReservePrice payload={payload} setPayload={setPayload} />
                </View>}
                <BuyNowPrice payload={payload} setPayload={setPayload}/>
                <EstimatedValue payload={payload} setPayload={setPayload}/>
                <MakeOffer payload={payload} setPayload={setPayload}/>
                <Inquire payload={payload} setPayload={setPayload}/>
                <ContactGallery payload={payload} setPayload={setPayload}/>
                <NotForSale payload={payload} setPayload={setPayload}/>
                <Sold payload={payload} setPayload={setPayload}/>
                <PermanantCollection payload={payload} setPayload={setPayload}/>
            </StyledPostAvailability>
        </StyledPostAvailabilityContainer>
    )
}

export default PostAvailability