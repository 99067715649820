import styled from 'styled-components'


interface ProfilePictureWrapperProps {
    flexDirection?: string;
    gap?: string;
}

export const StyledProfilePictureWrapper = styled.div<ProfilePictureWrapperProps>`
    display: ${props => props.flexDirection === 'column' ? 'flex' : 'inline-flex'};
    flex-direction: ${props => props.flexDirection || 'row'};
    align-items: center;
    gap: ${props => props.gap || '4px'};
    position: relative;
`


interface ProfilePictureContainerProps {
    textAlign?: string;
    height?: string;
    width?: string;
}


export const StyledProfilePictureContainer = styled.div<ProfilePictureContainerProps>`
    text-align: ${props => props.textAlign || 'center'};
    display: flex;
    clip-path: circle();
    background: lightgrey;
    align-items: center;
    justify-content: center;
    border: solid 1px lightgrey;
`

interface ProfilePictureProps {
    height?: string;
    width?: string;
}


export const StyledProfilePicture = styled.img<ProfilePictureProps>`
    border-radius: 50%;
    width: ${props => props.width || '200px'};
    height: ${props => props.height || '200px'};
    object-fit: cover;
`

interface HoverInfoContainerProps {

}

export const StyledHoverInfoContainer = styled.div<HoverInfoContainerProps>`
    position: absolute;
    background: #fff;
    -webkit-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);
    border-radius: 4px;
    z-index: 2;
    width: max-content;
    bottom: -80px;
`

interface HoverInfoProps {

}

export const StyledHoverInfo = styled.div<HoverInfoProps>`
    padding: 24px;
`

interface PhotoContainerProps {

}

export const StyledPhotoContainer = styled.div<PhotoContainerProps>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
`

interface FollowingContainerProps {

}

export const StyledFollowingContainer = styled.div<FollowingContainerProps>`

`

interface FollowingProps {

}

export const StyledFollowing = styled.div<FollowingProps>`
    display: inline-flex;
    align-items: center;
    width: 100%;
    gap: 12px;
`










