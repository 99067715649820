import { StyledBioSection, StyledFeaturedRepresentationSection, StyledPhotoSection, StyledProfile, StyledProfileContainer } from "./styled"
import { FeaturedRepresentation } from "./FeaturedRepresentation";
import { HighAuctionRecord } from "./HighAuctionRecord";
import { ProfilePicture } from "../../components/ProfilePicture";
import { Bio } from "./Bio";
import { ActiveSecondaryMarket } from "./ActiveSecondaryMarket";
import { CriticallyAcclaimed } from "./CriticallyAcclaimed";
import { Button } from "../../components/Button";
import { useAccountStore, useModalStore, useProfileStore, useResponsiveStore } from "../../stores/web.store";
import { Modal } from "../../components/Modal";
import { AccountFollowers } from "../../components/Profile/AccountFollowers";
import { AccountFollowings } from "../../components/Profile/AccountFollowings";
import { View } from "../../components/View";
import { Text } from "../../components/Text";

import { CommissionModal } from "../../components/Modal/CommissionModal";
import { useEffect, useState } from "react";
import api from "../../services/api.service";
import { RippleLoader } from "../../components/Loader/Loaders/RippleLoader";
import { useLocation } from "react-router-dom";

const AccountFollowersModal = ({profile}: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    return (
        <Modal 
                setModal={setIsModal} 
                width={'100%'} 
                height={'100vh'}
                background="rgba(0, 0, 0, .5)"
            >
            <AccountFollowers profile={profile}/>
        </Modal>
    )
}

const AccountFollowingModal = ({profile}: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    return (
        <Modal 
                setModal={setIsModal} 
                width={'100%'} 
                height={'100vh'}
                background="rgba(0, 0, 0, .5)"
            >
            <AccountFollowings profile={profile}/>
        </Modal>
    )
}

const AddBioButton = () => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    const onClick = () => {
        setModalKey('accountActions')
        setIsModal(true)
    }
    return (
        <Button
            border={`solid 1px ${'#BFCBFB'}`}
            background={'#DDE3FD'}
            color={'#0938F0'}
            padding="8px 20px"
            borderRadius="23px"
            fontSize="16px"
            width="max-content"
            onClick={onClick}
            noStyle
        >Add Bio</Button>
    )
}

const Profile = ({profile}: any) => {
    const location = useLocation()
    const account = useAccountStore((state: any) => state.account)
    const isModal = useModalStore((state: any) => state.isModal)
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const modalKey = useModalStore((state: any) => state.modalKey)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setProfile = useProfileStore((state: any) => state.setProfile)
    const width = useResponsiveStore((state: any) => state.width)

    const [userProfile, setUserProfile] = useState<any>(null)

    

    useEffect(() => { 
        const fetch = async () => {
            const resp = await api.get(`/accounts/${profile.id}`)
            setUserProfile(resp.data)
        }
        if (!profile.id || profile.followers === undefined) {
            fetch()
        } else {
            setUserProfile(profile)
        }
        
    }, [profile])

    const onClickProfilePhoto = () => {
        setModalKey('accountActions')
        setIsModal(true)
    }

    if (userProfile === null) return <RippleLoader/>

    return (
        <StyledProfileContainer>
            <StyledProfile>
                <View width={width > 768 ? userProfile.role === 'artist' ? "60%" : "100%" : "100%"}>
                    <View display="flex" flexDirection="column" gap="24px">
                        <View>
                            <View display="inline-flex" width="100%" gap="24px">
                                <StyledPhotoSection>
                                    <ProfilePicture 
                                        account={userProfile} 
                                        width={width > 768 ? "200px" : "80px"}
                                        height={width > 768 ? "200px" : "80px"} 
                                        photo 
                                        noHover 
                                        onClick={onClickProfilePhoto}
                                    />
                                    {/* {account.id === userProfile.id && <View position="absolute" bottom="12px" right="12px">
                                        <ProfilePictureButton />
                                    </View>} */}
                                </StyledPhotoSection>
                                <StyledBioSection>
                                    <Bio profile={userProfile}/>
                                </StyledBioSection>
                            </View>
                        </View>
                        <View>
                            <Text 
                                textAlign={userProfile.profile ? 'left' : 'center'} 
                                width={userProfile.profile ? '100%' : '200px'}
                                fontSize="14px"
                            >
                                {userProfile.profile ? userProfile.profile.bio : account.id === profile.id && <AddBioButton />}
                            </Text>
                        </View>
                    </View>
                </View>
                {width > 768 && userProfile.role === 'artist' && <View width="40%">
                    <StyledFeaturedRepresentationSection>
                        <FeaturedRepresentation />
                        <HighAuctionRecord />
                        <ActiveSecondaryMarket />
                        <CriticallyAcclaimed />
                    </StyledFeaturedRepresentationSection>
                </View>}
                
            </StyledProfile>
            
            {isModal && modalKey === 'accountFollowers' && <AccountFollowersModal profile={userProfile} />}
            {isModal && modalKey === 'accountFollowing' && <AccountFollowingModal profile={userProfile} />}
            {isModal && modalKey === 'commission' && <CommissionModal setModal={setIsModal} profile={userProfile}/>}
        </StyledProfileContainer>
    )
}

export default Profile