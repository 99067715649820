// import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
// import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Input } from "../../../components/Input"
import { Text } from "../../../components/Text"
import { StyledShowDatePicker, StyledShowDatePickerContainer, StyledShowDetails, StyledShowDetailsContainer, StyledShowInfoContainer, StyledShowInputContainer, StyledShowTitleInput } from "./styled"
import { useState } from 'react';
import { usePostPayloadStore } from '../../../stores/web.store';
import { View } from '../../../components/View';
import { DateTimePicker } from '../../../components/DateTimePicker';


const ShowTitleInput = ({payload, setPayload}: any) => {
    const onChange = (e: any) => {
        setPayload({...payload, title: e.target.value})
    }
    return (
        <StyledShowTitleInput>
            <Input 
                placeholder={'Show title'} 
                fontSize='20px'
                onChange={onChange}
                width='100%'
            />
        </StyledShowTitleInput>
    )
}


const ShowDetails = () => {
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    return (
        <StyledShowDetailsContainer>
            <StyledShowDetails>
                <StyledShowInputContainer>
                    <ShowTitleInput payload={postPayload} setPayload={setPostPayload}/>
                </StyledShowInputContainer>
                <StyledShowDatePickerContainer>
                    <DateTimePicker payload={postPayload} setPayload={setPostPayload}/>
                </StyledShowDatePickerContainer>
            </StyledShowDetails>
        </StyledShowDetailsContainer>
    )
}

export default ShowDetails