import { Image } from "../Image";
import { ProfilePicture } from "../ProfilePicture";
import { StyledPhotoOverlap, StyledPhotoOverlapContainer, StyledPhotoOverlapItem } from "./styled"

type PhotoOverlapProps = {
    offset?: number;
    data?: any;
}


const PhotoOverlap = ({offset, data}: PhotoOverlapProps) => {
    return (
        <StyledPhotoOverlapContainer width={`${(data.length > 3 ? 3 : data.length) * 16}px`}>
            <StyledPhotoOverlap>
                {data.slice(0, 3).map((obj: any, index: number) => {
                    return (
                        <StyledPhotoOverlapItem index={index} total={data.length}>
                            <ProfilePicture photo height="24px" width="24px" account={obj.owner}/>
                            {/* <Image src={obj.profile_picture} borderRadius="24px" height="24px" width="24px" objectFit="cover" /> */}
                        </StyledPhotoOverlapItem>
                    )
                })}
            </StyledPhotoOverlap>
        </StyledPhotoOverlapContainer>
    )
}

export default PhotoOverlap