import styled from "styled-components"


interface FeedNavigationContainerProps {
    isActive?: boolean;
}

export const StyledFeedNavigationContainer = styled.div<FeedNavigationContainerProps>`
    display: ${props => props.isActive ? 'block' : 'none'};
    padding: 24px;

    @media (min-width: 768px) {
        padding: 0px;
        display: block;
    }

    @media (min-width: 1024px) {

    }
`

interface FeedNavigationProps {

}

export const StyledFeedNavigation = styled.div<FeedNavigationProps>`
    display: inline-flex;
    gap: 80px;
    width: 100%;
`