import { useEffect, useState } from "react"
import { Image } from "../Image"
import { StyledPhotoReel, StyledPhotoReelContainer } from "./styled"
import Skeleton from "react-loading-skeleton"
import { View } from "../View"
import { Post } from "../Post"
import { useModalStore, usePostStore } from "../../stores/web.store"


const PhotoReel = ({api, data}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const [posts, setPosts] = useState<any>(data || [{media: []}, {media: []}, {media: []}, {media: []}, {media: []}])

    useEffect(() => { 
        if (api.is_loaded) {
            setPosts(api.data)
        }
    }, [api])

    const onClick = (post: any) => {
        setPost(post)
    }


    return (
        <StyledPhotoReelContainer>
            <StyledPhotoReel columns={posts.length > 4 ? posts.length : 4}>
                {posts.map((post: any) => {
                    if (!api.is_loaded) return <View height="200px" width="100%"><Skeleton /></View>
                    if (post.media.length === 0) return <View display="none"/>
                    if (post.type === 'media') return <View display="none"/>
                    return (
                        <Post data={post} onClick={() => onClick(post)}/>
                    )
                })}
            </StyledPhotoReel>
        </StyledPhotoReelContainer>
    )
}

export default PhotoReel