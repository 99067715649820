import styled from 'styled-components'


interface UserInfoContainerProps {

}

export const StyledUserInfoContainer = styled.div<UserInfoContainerProps>`
    display: inline-flex;
    gap: 4px;
    align-items: center;
    position: relative;
`

interface UserInfoProps {

}

export const StyledUserInfo = styled.div<UserInfoProps>`
    display: inline-flex;
    gap: 12px;
    align-items: center;
    position: relative;
`
