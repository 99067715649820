import { Button } from "../../components/Button";
import { Text } from "../../components/Text";
import { View } from "../../components/View";
import { useThemeStore, useViewNavigatorStore } from "../../stores/web.store";
import { StyledViewNavigator, StyledViewNavigatorContainer } from "./styled"



const ViewNavigator = ({data}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const activeView = useViewNavigatorStore((state: any) => state.activeView)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    return (
        <StyledViewNavigatorContainer>
            <StyledViewNavigator columns={data.length}>
            {data.map((obj: any) => {
                return <Button noStyle onClick={() => setActiveView(obj.id)}>
                    <View borderBottom={activeView === obj.id ? `solid 2px ${theme.button.background}` : 'none'}>
                        <Text 
                            textAlign="center" 
                            padding="20px 0px" 
                            fontSize="16px"
                            fontWeight="500"
                            
                            color={activeView === obj.id ? theme.button.background : theme.text.color}
                        >{obj.title}</Text>
                    </View>
                </Button>
            })}
            </StyledViewNavigator>
        </StyledViewNavigatorContainer>
    )
}

export default ViewNavigator