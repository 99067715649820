import styled from "styled-components";


interface SlideMenuContainerProps {
    isActive: boolean;
}

export const StyledSlideMenuContainer = styled.div<SlideMenuContainerProps>`
    position: fixed;
    left: 0;
    top: 0;
    width: 66px;
    height: 100vh;
    background: #fff;
    z-index: 3;
   
    

    ${props => props.isActive && `

        
    `}
`

interface SlideMenuProps {}

export const StyledSlideMenu = styled.div<SlideMenuProps>`
  display: inline-flex;
  background: #fff;
  box-shadow: 5px 0 10px -3px rgba(0, 0, 0, 0.1), 5px 0 4px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

interface SlideMenuTriggerProps {}

export const StyledSlideMenuTrigger = styled.div<SlideMenuTriggerProps>`
  width: 66px;
  border-left: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ContentContainerProps {
  isActive: boolean;
}

export const StyledContentContainer = styled.div<ContentContainerProps>`
  width: 0px;
  flex-grow: 1;
  overflow: hidden;
  padding: 0px;
  transition: width 0.3s ease-in-out;

  ${props => props.isActive && `
    width: calc(400px - 66px);
    padding: 24px;
  `}
`;

export const StyledRotatedText = styled.div`
  transform: rotate(90deg);
  transform-origin: left top;
  width: 66px; /* Ensure the rotated text fits within the 66px width */
  text-align: center;
`;