import { useEffect, useRef, useState } from "react"
import Comment from "../Comment/Comment"
import { StyledComments, StyledCommentsContainer } from "./styled"
import api from "../../services/api.service"
import { usePostStore, useResponsiveStore } from "../../stores/web.store"



const Comments = ({post}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const [offset, setOffset] = useState(1)
    const height = useResponsiveStore((state: any) => state.height)
    const messageRef = useRef<any>();

    const onScrollLoad = async () => {
        const resp = await api.get(`/posts/${post.id}/comments`)
        setPost({...post, comments: [...post.comments, resp.data]})
        setOffset(offset + 1)
    }

    const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [post.comments]);

    if (!post) return <div />

    return (
        <StyledCommentsContainer>
            <StyledComments height={`${height * .06}vh`}>
                <Comment post={post} data={{...post, comment: post.caption}}/>
                {post.comments.map((comment: any) => {
                    return (
                        <Comment post={post} data={comment}/>
                    )
                })}
                <div ref={messageRef} />
            </StyledComments>
            
        </StyledCommentsContainer>
    )
}

export default Comments