import styled from 'styled-components'


interface ActiveSecondaryMarketContainerProps {

}


export const StyledActiveSecondaryMarketContainer = styled.div<ActiveSecondaryMarketContainerProps>`
    border-bottom: solid 1px #000;
    padding: 12px 0px;
`

interface ActiveSecondaryMarketProps {

}


export const StyledActiveSecondaryMarketRecord = styled.div<ActiveSecondaryMarketProps>`
    display: inline-flex;
    width: 100%;
    padding: 4px 0px;
`

interface ActiveSecondaryMarketContentContainerProps {
    isExpanded?: boolean;
}

export const StyledActiveSecondaryMarketRecordContentContainer = styled.div<ActiveSecondaryMarketContentContainerProps>`
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    ${props => props.isExpanded && `
        height: fit-content;
        max-height: 200px;
    `}
`

interface ActiveSecondaryMarketContentProps {

}

export const StyledActiveSecondaryMarketRecordContent = styled.div<ActiveSecondaryMarketContentProps>`

`









