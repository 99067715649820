import { StyledFeaturedMuseums, StyledFeaturedMuseum, StyledFeaturedMuseumContainer } from "./styled"
import { View } from "../../../components/View"
import { Image } from "../../../components/Image"
import { Text } from "../../../components/Text"
import { Follow } from "../../../components/Button/Follow"


const FeaturedMuseums = ({data}: any) => {
    

    return (
        <StyledFeaturedMuseums>
            <StyledFeaturedMuseumContainer columns={data.length} width={`${data.length * 100}%`}>
                {data.map((museum: any) => {
                    return (
                        <StyledFeaturedMuseum>
                            <View>
                                <Image src={museum.media[0].url} width="100%" height="400px"/>
                            </View>
                            <View display="flex" alignItems="center" justifyContent="center">
                                <View textAlign="center">
                                    <Text textAlign="center" width="100%" fontWeight="500" fontSize="20px">Museum Name</Text>
                                    <Text textAlign="center" width="100%">Show Title</Text>
                                    <View padding="24px 0px 4px 0px">
                                        <Text 
                                            textAlign="center" 
                                            width="100%" 
                                            fontWeight="500" 
                                            fontSize="20px"
                                        >{`February 1`} - {`February 10`}, {`2024`}</Text>
                                    </View>
                                    <View textAlign="center">
                                        <Follow />
                                    </View>
                                </View>
                            </View>
                        </StyledFeaturedMuseum>
                    )
                })}
            </StyledFeaturedMuseumContainer>
        </StyledFeaturedMuseums>
    )
}

export default FeaturedMuseums