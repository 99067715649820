import { useRef } from "react"
import { Image } from "../../Image"
import { Text } from "../../Text"
import { View } from "../../View"
import { ScaledImage } from "../../ScaledImage"
import { useResponsiveStore } from "../../../stores/web.store"


const QASection = ({section}: any) => {
    return (
        <View padding="12px 0px">
            <View display="flex" flexDirection="column" gap="20px">
                <Text fontSize="1.25rem" fontStyle="italic" fontWeight="500">
                    {section.question}
                </Text>
                <View>
                    <View display="flex" flexDirection="column" gap="20px">
                        {section.answer.map((answer: any) => {
                            return <Text fontSize="1.25rem" lineHeight="2rem">{answer}</Text>
                        })}
                    </View>
                </View>
            </View>
        </View>
    )
}

const ImageSection = ({section}: any) => {
    const imageRef = useRef();
    return (
        <View>
            <ScaledImage imageRef={imageRef}>
                <Image src={`${process.env.REACT_APP_PRIMARY_YELLOW_API}${section.url}`} width="100%" aspectRatio="unset"/>
            </ScaledImage>
            <View padding="12px 0px">
                <Text color="grey">{section.caption}</Text>
            </View>
        </View>
    )
}


const QAEditorial = ({editorial}: any) => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <View>
            <View display="flex" flexDirection="column" gap={width > 768 ? "32px" : "12px"}>
                {editorial.sections.map((section: any) => {
                    return (
                        <View>
                            {section.type === 'q&a' && <QASection section={section} />}
                            {section.type === 'image' && <ImageSection section={section} />}
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

export default QAEditorial