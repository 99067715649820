import { useEffect, useState } from "react"
import { Feed } from "../../components/Feed"
import { useForYouPosts } from "../../hooks/api.hook"
import { useForYouPostsStore } from "../../stores/web.store"
import { StyledForYou, StyledForYouContainer } from "./styled"
import api from "../../services/api.service"
import InfiniteScroll from "react-infinite-scroll-component"


const ForYou = () => {
    const forYouPosts = useForYouPostsStore((state: any) => state.forYouPosts) 
    const setForYouPosts = useForYouPostsStore((state: any) => state.setForYouPosts) 

    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const {data} = useForYouPosts()

    useEffect(() => {
        if (data && data.is_loaded) {
            setForYouPosts(data.data)
        }
    }, [data])

    const fetchMoreData = async () => {
        const newOffset = offset + 1
        const resp = await api.post(`/search/for-you`, {query: {}, offset: forYouPosts.length})

        setForYouPosts([...forYouPosts, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
    };
    
    if (data && !data.is_loaded) return <div />
    return (
        <StyledForYouContainer >
            
                <StyledForYou>
                    <InfiniteScroll
                        dataLength={forYouPosts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="FeedContainer"
                    >
                        <Feed posts={forYouPosts} />
                    </InfiniteScroll>
                </StyledForYou>
            
        </StyledForYouContainer>
    )
}

export default ForYou