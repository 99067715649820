
import { StyledLanding } from "./styled"
import { useButterBarStore } from "../../stores/web.store"
import { ButterBar } from "../../components/ButterBar"
import { MainContent } from "../../sections/Landing/MainContent"
import { Footer } from "../../sections/Layout/Footer"



const Landing = () => {
    const isButterBar = useButterBarStore((state: any) => state.isButterBar)
    return (
        <StyledLanding>
            <MainContent />
            <Footer />
            {isButterBar && <ButterBar background="rgba(0, 0, 0, 0.65)" color={'#fff'}>{isButterBar}</ButterBar>}
        </StyledLanding>
    )
}

export default Landing