import styled from 'styled-components'


interface InitialStateContainerProps {

}


export const StyledInitialStateContainer = styled.div<InitialStateContainerProps>`
    padding: 60px 0px 0px 0px;

    @media (min-width: 768px) {
        padding: 120px 0px 0px 0px;
    }

    @media (min-width: 1024px) {

    }
`

interface InitialStateProps {

}


export const StyledInitialState = styled.div<InitialStateProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - 160px);
`









