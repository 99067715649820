import { Button } from "../../components/Button"
import { FeaturedGroup } from "../../components/Featured/FeaturedGroup"
import { Text } from "../../components/Text"
import { View } from "../../components/View"
import { toDateDiff } from "../../utils/app.util"
import { StyledSectionTitle } from "../styled"
import { StyledFeatureContainer, StyledFeatured } from "./styled"


const Featured = ({data, type, columns, height, width, noTitle, onClick}: any) => {
    return (
        <StyledFeatureContainer>
            <StyledFeatured>
                <FeaturedGroup 
                    data={data} 
                    columns={columns} 
                    width={width}
                    height={height}
                    onClick={onClick}
                />
            </StyledFeatured>
        </StyledFeatureContainer>
    )
}

export default Featured