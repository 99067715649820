import { useAccountStore, useDashboardMenuExpandedStore, useMeetingStore, useMessageStore, useProductDetailPageStore, useThreadStore } from "../../stores/web.store"
import { ProfilePicture } from "../ProfilePicture"
import { Text } from "../Text"
import { View } from "../View"
import { StyledContact, StyledContactContainer, StyledMessge, StyledMessgeContainer, StyledThread, StyledThreadContainer, StyledTyping, StyledTypingSpan } from "./styled"
import {ReactComponent as VideoCameraIcon} from '../../img/videoCamera.svg'
import {ReactComponent as PhoneIcon} from '../../img/phone.svg'
import {ReactComponent as BackIcon} from '../../img/chevronLeft.svg'
import { Button } from "../Button"
import { useEffect, useState } from "react"
import { MeetingModal } from "../Modal/MeetingModal"
import api from "../../services/api.service"


const ThreadMessage = ({data, setMeetingState}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const lexical_message = JSON.parse(data?.lexical_message || '{}')
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const meeting = useMeetingStore((state: any) => state.meeting)
    const onClickMeeting = async () => {
        setPdp('meeting')
        setMeetingState(null)
    }
    return (
        <StyledThreadContainer isSender={account.id === data.sender?.id}>
            <View>
                <ProfilePicture photo account={data.sender}/>
            </View>
            <StyledThread 
                isTyping={data.isTyping} 
                isButton={lexical_message.root?.type === 'button'}
                isSender={account.id === data.sender?.id}
            >
                {data.isTyping && <StyledTyping>
                    <StyledTypingSpan></StyledTypingSpan>
                    <StyledTypingSpan></StyledTypingSpan>
                    <StyledTypingSpan></StyledTypingSpan>
                </StyledTyping>}
                {!data.isTyping && 
                    lexical_message.root?.type === 'button' 
                        ? <Button
                            border={`solid 1px ${'#BFCBFB'}`}
                            background={'#DDE3FD'}
                            color={'#0938F0'}
                            padding="8px 20px"
                            borderRadius="23px"
                            fontSize="16px"
                            width="100%"
                            onClick={onClickMeeting}
                            noStyle
                        >{meeting?.owner_id === account.id ? 'Start Meeting' : 'Join Meeting'}</Button>
                        : <Text fontSize="14px" lineHeight="20px">{data.message}</Text> 
                }
                
            </StyledThread>
        </StyledThreadContainer>
    )
}


const Message = ({isTyping, threadMessages, setThreadMessages, setIsNewMessage, setIsActive}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const thread = useThreadStore((state: any) => state.thread)
    const meeting = useMeetingStore((state: any) => state.meeting)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setMeeting = useMeetingStore((state: any) => state.setMeeting)

    const setThread = useThreadStore((state: any) => state.setThread)

    const [payload, setPayload] = useState({})
    const [meetingState, setMeetingState] = useState<any>(null)

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get(`/threads/${thread.id}/meeting`)
            setMeeting(resp.data)
        }

        if (thread?.id) {
            fetch()
        }
    }, [thread])


    useEffect(() => {
        const tempMessage = {message: '', sender: account, isTyping: isTyping}
        if (isTyping) {
            setThreadMessages([...threadMessages, tempMessage])
        } else {
            if (threadMessages.length > 0) {
                setThreadMessages(threadMessages.filter((msg: any) => !msg.isTyping))
            }
                
        }
        
    }, [isTyping])

    const onClickBack = (data: any) => {
        setThread(null)
        setIsActive(false)
    }


    const onClickMeeting = async (type: string) => {
        if (meeting?.id && meeting?.type === type) {
            setPdp('meeting')
            setMeetingState(null)
        } else {
            setPayload({...payload, type})
            setMeetingState('init')
        }
    }

    if (!thread) return <div />
    
    return (
        <StyledMessgeContainer>
            <StyledMessge>
                <StyledContactContainer>
                    <StyledContact>
                        <Button noStyle padding="0px 0px 0px 4px" onClick={onClickBack}>
                            <BackIcon />
                        </Button>
                        <View width="60%">
                            <View 
                                display="inline-flex" 
                                alignItems="cener" 
                                width="100%"
                                gap="12px"
                            >
                                {thread.recipients.map((recipient: any) => {
                                    return (
                                        <ProfilePicture 
                                            account={recipient} 
                                            username 
                                            photo 
                                            fontSize="20px"
                                            width="32px"
                                            height="32px"
                                        />
                                    )
                                })}
                            </View>
                            
                        </View>
                        <View width="40%">
                            <View display="inline-flex" alignItems="center" width="100%" justifyContent="end" gap="12px">
                                <Button noStyle onClick={() => onClickMeeting('Video')}><VideoCameraIcon width={28} height={28}/></Button>
                                <Button noStyle onClick={() => onClickMeeting('Audio')}><PhoneIcon width={28} height={28}/></Button>
                            </View>
                        </View>
                    </StyledContact>
                    <View padding="12px" overflowY="scroll" height="80vh">
                        {threadMessages.map((threadMessage: any) => {
                            return (
                                <ThreadMessage data={threadMessage} setMeetingState={setMeetingState}/>
                            )
                        })}
                    </View>
                    
                </StyledContactContainer>
            </StyledMessge>
            {meetingState === 'init' && <MeetingModal setModal={setMeetingState} payload={payload} setPayload={setPayload} thread={thread}  />}
        </StyledMessgeContainer>
    )
}

export default Message