import styled from 'styled-components'

interface TagContainerProps {
    padding?: string;
}


export const StyledTagContainer  = styled.div<TagContainerProps>`
    padding: ${props => props.padding || '4px'};
`

interface TagProps {
    border?: string;
    borderRadius?: string;
}

export const StyledTag = styled.div<TagProps>`
    display: inline-flex;
    width: 100%;
    padding: 12px 12px 12px 0px;
    border: ${props => props.border || 'solid 1px #000'}
    border-radius: ${props => props.borderRadius || '#000'}
`


interface TagImageContainerProps {

}

export const StyledTagImageContainer = styled.div<TagImageContainerProps>`

`


interface TagTextContainerProps {

}

export const StyledTagTextContainer = styled.div<TagTextContainerProps>`
    padding: 0px 8px;
`

interface TagRemoveContainerProps {

}

export const StyledTagRemoveContainer = styled.div<TagRemoveContainerProps>`

`

