import { useState } from "react";
import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { ReactComponent as ChevronDownIcon } from '../../../img/chevronDown.svg';
import { StyledActiveSecondaryMarketContainer, StyledActiveSecondaryMarketRecord, StyledActiveSecondaryMarketRecordContent, StyledActiveSecondaryMarketRecordContentContainer } from "./styled"


const ActiveSecondaryMarket = () => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <StyledActiveSecondaryMarketContainer>
            <StyledActiveSecondaryMarketRecord>
                <Button onClick={() => setIsExpanded(!isExpanded)} noStyle width="100%">
                    <Text fontWeight="500" width="90%" fontSize="13px" lineHeight="20px" textAlign="left">Active Secondary Market</Text>
                    <Text textAlign="right" width="100%"><ChevronDownIcon height={'20px'} width={'20px'}/></Text>
                </Button>
            </StyledActiveSecondaryMarketRecord>
            <StyledActiveSecondaryMarketRecordContentContainer isExpanded={isExpanded}>
                <StyledActiveSecondaryMarketRecordContent>
                    Coming soon
                </StyledActiveSecondaryMarketRecordContent>
            </StyledActiveSecondaryMarketRecordContentContainer>
        </StyledActiveSecondaryMarketContainer>
    )
}

export default ActiveSecondaryMarket