import styled from 'styled-components'

interface MediumContainerProps {

}


export const StyledMediumContainer  = styled.div<MediumContainerProps>`
    
`

interface MediumProps {

}


export const StyledMedium  = styled.div<MediumProps>`
    
`

