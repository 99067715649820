import { QuickFilter } from "../QuickFilter"


type Props = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const OneOnOnes = ({selectedFilter, setSelectedFilter}: Props) => {
    const onClick = () => {
        setSelectedFilter('one_on_one')
    }

    return (
        <QuickFilter title="1:1" onClick={onClick} isSelected={selectedFilter === 'one_on_one'}/>
    )
}

export default OneOnOnes