import { ReactNode } from "react";
import { StyledView } from "./styled"
import { useThemeStore } from "../../stores/web.store";

type ViewProps = {
    children?: ReactNode;
    display?: string;
    justifyContent?: string;
    alignItems?: string;
    padding?: string;
    margin?: string;
    width?: string;
    height?: string;
    flexGrow?: string;
    minHeight?: string;
    textAlign?: string;
    background?: string;
    borderRadius?: string;
    flexDirection?: string;
    flex?: string;
    flexWrap?: string;
    gridTemplateColumns?: string;
    gridGap?: string;
    overflow?: string;
    overflowX?: string;
    overflowY?: string;
    border?: string;
    borderBottom?: string;
    borderTop?: string;
    gap?: string;
    color?: string;
    position?: string;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    zIndex?: string;
    transform?: string;
    id?: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
    ref?: any;
    onChange?: any;
    float?: string;
    maxHeight?: string;
    className?: string;
}


const View = (props: ViewProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    return (
        <StyledView 
            id={props.id}
            theme={theme}
            className={props.className}
            display={props.display}
            textAlign={props.textAlign}
            alignItems={props.alignItems}
            justifyContent={props.justifyContent}
            padding={props.padding}
            margin={props.margin}
            width={props.width}
            height={props.height}
            maxHeight={props.maxHeight}
            minHeight={props.minHeight}
            flexGrow={props.flexGrow}
            flexDirection={props.flexDirection}
            flex={props.flex}
            flexWrap={props.flexWrap}
            gridTemplateColumns={props.gridTemplateColumns}
            gridGap={props.gridGap}
            overflow={props.overflow}
            overflowY={props.overflowY}
            overflowX={props.overflowX}
            border={props.border}
            transform={props.transform}
            background={props.background}
            borderRadius={props.borderRadius}
            borderBottom={props.borderBottom}
            borderTop={props.borderTop} 
            gap={props.gap}
            color={props.color}
            float={props.float}
            position={props.position}
            top={props.top}
            bottom={props.bottom}
            left={props.left}
            right={props.right}
            zIndex={props.zIndex}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            ref={props.ref}
            onChange={props.onChange}
        >
            {props.children}
        </StyledView>
    )
}

export default View