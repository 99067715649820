import { SearchBar } from "../../SearchBar"
import { Sort } from "../../Sort"
import Filters from "../Filters"
import { StyledMuseumFiltersContainer, StyledSearchAndSort } from "./styled"


const MuseumFilters = () => {
    return (
        <StyledMuseumFiltersContainer>
            <Filters rarity priceRange medium allFilters/>
            <StyledSearchAndSort>
                <SearchBar />
                <Sort />
            </StyledSearchAndSort>
        </StyledMuseumFiltersContainer>
    )
}

export default MuseumFilters