import {AddressElement} from '@stripe/react-stripe-js';
import { StyledAddressForm } from './styled';
import { useAccountStore } from '../../../stores/web.store';
import api from '../../../services/api.service';
import { isVisible } from '@testing-library/user-event/dist/utils';

const Form = ({shipping, setShipping}: any) => {
    const account = useAccountStore((state: any) => state.account)
      
    const onChange = async (event: any) => {
        if (event.complete) {
            const address = event.value.address;

            const payload = {
                from_address: {
                    "name": "Dr. Steve Brule",
                    "street1": "179 N Harbor Dr",
                    "city": "Redondo Beach",
                    "state": "CA",
                    "zip": "90277",
                    "country": "US",
                    "phone": "4153334444",
                    "email": "dr_steve_brule@gmail.com",
                },
                to_address: {
                    name: event.value.name,
                    street1: address.line1,
                    street2: address.line2,
                    city: address.city,
                    state: address.state,
                    zip: address.postal_code,
                    country: address.country

                }
            }

            const resp = await api.post('/service/shipping/rates', payload)
            setShipping({
                address: payload,
                rates: resp,
                isHiddenForm: true
            })


            /*
                {
                    "line1": "23 N Aberdeen St",
                    "line2": "Unit 2S",
                    "city": "Chicago",
                    "country": "US",
                    "postal_code": "60607",
                    "state": "IL"
                }

                "to_address": {
                    "name": "Dr. Steve Brule",
                    "street1": "179 N Harbor Dr",
                    "city": "Redondo Beach",
                    "state": "CA",
                    "zip": "90277",
                    "country": "US",
                    "phone": "4153334444",
                    "email": "dr_steve_brule@gmail.com",
                },
            */
        }
    }
    return (
        <StyledAddressForm>
            <AddressElement 
                options={{
                    mode: 'shipping',
                    defaultValues: {
                        name: `${account.first_name} ${account.last_name}`
                    },
                    autocomplete: {
                        mode: "google_maps_api",
                        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
                    }
                }} 
                onChange={onChange} 
            />
        </StyledAddressForm>
    );
};

export default Form;
