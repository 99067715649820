import {  useEffect, useRef } from "react"
import { StyledCreatePostContainer, StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { useComponentVisible } from "../../../utils/app.util"
import { useModalStore, usePostStore, useResponsiveStore } from "../../../stores/web.store";
import { Text } from "../../Text";

import { View } from "../../View";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { Commission } from "../../Commission";



type CommissionModalProps = {
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
    profile: string;
}

const useOutsideAlerter = (ref: any, setModal: any, setModalKey: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
            setModalKey('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}



const CreateModal = ({profile, setModal, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius}: CommissionModalProps) => {
    const post = usePostStore((state: any) => state.post)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const respWidth = useResponsiveStore((state: any) => state.width)
    const { ref } = useComponentVisible(setModal);
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, setModal, setModalKey);

    const onClickClose = () => {
        setModal(false)
        setModalKey('')
    }
    return (
        <StyledModalContainer position={position} top={top} left={left}  ref={ref}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer ref={wrapperRef}>
                    <StyledCreatePostContainer>
                        <View>
                            <Text textAlign="center" width="100%" fontWeight="600" fontSize="20px">{'Commission'}</Text>
                        </View>
                    </StyledCreatePostContainer> 
                    <StyledModalContent height={height} background={background}>
                        <Commission />
                    </StyledModalContent>
                    <View position={respWidth > 768 ? "absolute" : "fixed"} top="12px" right="12px">
                        <Button noStyle onClick={onClickClose}><XIcon height={28} width={28}/></Button>
                    </View>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default CreateModal