import styled from "styled-components";



interface FeaturedGalleriesProps {

}

export const StyledFeaturedGalleries = styled.div<FeaturedGalleriesProps>`
    width: 100%;
    overflow-x: scroll;
`

interface FeaturedGalleryContainerProps {
    columns: number;
    width: string;
}

export const StyledFeaturedGalleryContainer = styled.div<FeaturedGalleryContainerProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-gap: 0;
    width: ${props => props.width}; 
    
`

interface FeaturedGalleriesProps {

}

export const StyledFeaturedGallery = styled.div<FeaturedGalleriesProps>`
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 0;
`