import { useEffect, useState } from "react"
import { DiscoverPeoplePost } from "../../components/DiscoverPeoplePost"
import { Text } from "../../components/Text"
import { StyledDiscoverPeople, StyledDiscoverPeopleContainer } from "./styled"
import api from "../../services/api.service"
import { PageLoader } from "../../components/Loader/PageLoader"
import { RippleLoader } from "../../components/Loader/Loaders/RippleLoader"
import { useAccountStore } from "../../stores/web.store"


const DiscoverPeople = () => {
    const account = useAccountStore((state: any) => state.account)
    const [data, setData] = useState<any>([])
    const [apiResp, setApiResp] = useState<any>([])

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get('/discover')
            setData(resp.data)
            setApiResp(resp)
        }

        fetch()
    }, [])

    if (!apiResp.is_loaded) return <RippleLoader />

    return (
        <StyledDiscoverPeopleContainer>
            <Text fontSize="24px" fontWeight="600" padding="24px 0px">Discover People</Text>
            <StyledDiscoverPeople>
                {data.map((user: any) => {
                    if (user.id === account.id) return <div/>
                    return (
                        <DiscoverPeoplePost data={user}/>
                    )
                })}
            </StyledDiscoverPeople>
        </StyledDiscoverPeopleContainer>
    )
}

export default DiscoverPeople