import { ReactNode } from "react"
import { StyledSetting, StyledSettingContainer } from "./styled"
import { View } from "../../../components/View";
import { Text } from "../../../components/Text";


type SettingProps = {
    title: string;
    children: ReactNode;
}


const Setting = ({title, children}: SettingProps) => {
    return (
        <StyledSettingContainer>
            <StyledSettingContainer>
                <View padding="12px 0px 48px 0px">
                    <Text fontSize="24px" fontWeight="600">{title}</Text>
                </View>
                {children}
            </StyledSettingContainer>
        </StyledSettingContainer>
    )
}

export default Setting