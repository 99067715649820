import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { useResponsiveStore } from "../../../stores/web.store"
import { Card } from "../Card"
import { StyledHeroContainer } from "./styled"

type CardProps = {
    isReverse?: boolean;
}

const ArtistsCard = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"A platform built for artists"}
            description={"Built for the unique viewing experience surrounding art. Easily create solo shows or invite friends to do a group show so your work can be seen in context. Share images that will be available in AR/VR, release editions, manage a catalogue raisonné, and so much more. Have ideas? let us know."}
            image={"/img/landing/forArtists/Platform.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const MonetizationCard = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Monetization options"}
            description={"Primary Yellow is committed to growing your monetization options. Streamline your commission process for all kinds of art and design work; use our paid video chat feature to consult or talk to fans; build a subscriber base; grow your edition and merch shop; charge patrons to view your shows, and stay up to date as we continually develop new features."}
            image={"/img/landing/forArtists/Monetization.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const ARVRCard = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Mount shows and share images in AR/VR"}
            description={"Primary Yellow believes the future of art discovery and viewing is in AR and VR. We’re building the best viewing experience so you can share your work in life size, high definition AR/VR with everyone around the globe. Available on visionOS this summer."}
            image={"/img/landing/forArtists/ARVR.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Collective = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Collective impact"}
            description={"We’re built to work in collaboration with your gallery and studio managers. Direct users to your gallery for upcoming shows and assign a representative to co-manage your account."}
            image={"/img/landing/forArtists/Collective.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const Goodbye = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Goodbye to PDFs"}
            description={"Upload your shows and artworks to Primary Yellow and simply share a link with interested parties for the ultimate viewing (and checkout) experience. With controlled privacy settings, you’re in charge of who see’s what."}
            image={"/img/landing/forArtists/Goodbye.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const ForArtists = () => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <DashboardLayout>
            <StyledHeroContainer>
                <View display="flex" alignItems="center" height={width > 768 ? "678px" : "400px"}>
                    <View width="100%">
                        <Text padding={width > 768 ? "0px 0px 0px 64px" : "0px 0px 0px 24px"} fontSize={width > 768 ? "42px" : "28px"} color="#fff" fontWeight="500" width={width > 768 ? "70%" : "80%"}>
                            {"A comprehensive platform to share your artwork and connect with art patrons."}
                        </Text>
                        <View width="100%" padding={width > 768 ? "24px 0px 0px 64px" : "24px 0px 0px 24px"}>
                            <Button 
                                height="48px" 
                                width="160px" 
                                borderRadius="24px" 
                                background="#fff" 
                                border="solid 1px #000"
                                fontSize="20px"
                                fontWeight="500"
                                noStyle
                            >Get Started</Button>
                        </View>
                    </View>
                </View>
            </StyledHeroContainer>
            <View padding={width > 768 ? "64px 48px": "24px 12px"}>
                <View display="flex" flexDirection="column" gap={width > 768 ? "120px" : "64px"}>
                    <ArtistsCard />
                    <MonetizationCard isReverse/>
                    <ARVRCard />
                    <Collective isReverse />
                    <Goodbye />
                    <View>
                        <FooterAd image={'/img/landing/forArtists/LaunchingSoon.svg'} />
                    </View>
                </View>
            </View>
        </DashboardLayout>
    )
}

export default ForArtists