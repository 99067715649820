import { useAccountStore, usePostPayloadStore, useThemeStore } from "../../../stores/web.store"
import { key2Value, toCurrency } from "../../../utils/app.util"
import { Button } from "../../Button"
import { ImageGallery } from "../../ImageGallery"
import { Text } from "../../Text"
import { UserInfo } from "../../UserInfo"
import { StyledLeftSectionContainer, StyledPreview, StyledPreviewContainer, StyledRightSection, StyledRightSectionContainer } from "./styled"

/*
location = Column(JSONB)
    price = Column(Float)
    reserve_price = Column(Float)
    reserve_price_currency = Column(String(16))
    currency = Column(String(16))
    shipping_fee = Column(Float)
    medium = Column(String(256))
    rarity = Column(String(256))
    has_signature = Column(Boolean)
    has_coa = Column(Boolean)
    has_frame = Column(Boolean)

    dimensions = Column(JSONB)
    can_make_offer = Column(Boolean)
    can_inquire = Column(Boolean)
    gallery_id = Column(UUID)
    template = Column(String(64))

    is_for_sale = Column(Boolean)
    is_sold = Column(Boolean)
    is_permanent_collection = Column(Boolean)

    start_datetime = Column(DateTime, nullable=False)
    end_datetime = Column(DateTime, nullable=False)
*/


const Preview = () => {
    const account = useAccountStore((state: any) => state.account)
    const theme = useThemeStore((state: any) => state.theme)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    return (
        <StyledPreviewContainer>
            <Text fontWeight="500">Preview</Text>
            <StyledPreview>
                <StyledLeftSectionContainer>
                    <ImageGallery data={postPayload} setPost={setPostPayload} height="400px"/>
                </StyledLeftSectionContainer>
                <StyledRightSectionContainer>
                    <StyledRightSection>
                        <UserInfo account={account}/> 
                        
                        <Text fontSize="24px" fontWeight="500" padding="12px 0px">{postPayload.title}</Text>
                        <Text fontSize="18px" color={theme.text.secondaryColor} textTransform="capitalize">{key2Value(postPayload.metadata?.medium)}</Text>
                        <Text fontSize="18px" color={theme.text.secondaryColor}>{postPayload.metadata?.dimensions}</Text>
                        <Text fontSize="18px" color={theme.text.secondaryColor}>{postPayload.metadata?.has_frame ? 'Frame Included' : ''}</Text>
                        <Text fontSize="18px" color={theme.text.secondaryColor} textTransform="capitalize">{key2Value(postPayload.metadata?.rarity)}</Text>
                        {postPayload.metadata?.price && <Text fontWeight="500" fontSize="24px" padding="12px 0px">{toCurrency(postPayload.metadata?.price)}</Text>}
                        <div style={{padding: '12px 0px 0px 0px'}}>
                            <Button 
                                width="90%" 
                                height="48px" 
                                borderRadius="48px" 
                                fontWeight="500"
                                fontSize="16px"
                                color={theme.button.secondaryColor}
                            >Purchase</Button>
                        </div>
                        <div style={{padding: '12px 0px'}}>
                            <Button 
                                width="90%" 
                                height="48px" 
                                borderRadius="48px" 
                                border={`solid 1px #000`}
                                fontSize="16px"
                                fontWeight="500"
                                noStyle
                            >Make Offer</Button>
                        </div>
                    </StyledRightSection>
                </StyledRightSectionContainer>
            </StyledPreview>
        </StyledPreviewContainer>
    )
}

export default Preview