import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { StyledDashboardContainer, StyledDashboardLayout } from "./styled";
// import { TopNavMenu } from "../../TopNav/TopNavMenu";
import { DashboardMenu } from "../DashboardMenu";
import { Text } from "../../../components/Text";
import { ButterBar } from "../../../components/ButterBar";
import { useAccessStore, useAccountStore, useDashboardMenuExpandedStore, useFiltersStore, useMeetingStore, useModalStore, usePostStore, useProductDetailPageStore, useProfileStore, useSelectedPostStore, useSlideMenuStore, useViewNavigatorStore } from "../../../stores/web.store";
import { Footer } from "../Footer";
import SlideMenu from "../../SlideMenu/SlideMenu";
import { ProductDetailContainer } from "../../../components/ProductDetail/Container";
import Artwork from "../../../components/ProductDetail/Artwork/Artwork";
import Show from "../../../components/ProductDetail/Show/Show";
import { Auction } from "../../../components/ProductDetail/Auction";
import { Gallery } from "../../../components/ProductDetail/Gallery";
import { UserProfile } from "../../../pages/UserDashboard/UserDashboard";
import { useLocation } from "react-router-dom";
import { PostDetails } from "../../../components/PostDetails";
import { Modal } from "../../../components/Modal";
import { Lot } from "../../../components/ProductDetail/Lot";
import { Meeting } from "../../../components/Meeting";
import { Checkout } from "../../../components/Services/Stripe/Checkout";
import { Onboarding } from "../../../components/Services/Stripe/Onboarding";
import { PostModal } from "../../../components/Modal/PostModal";
import { CreateModal } from "../../../components/Modal/CreateModal";
import { ViewingRoom } from "../../../components/ViewingRoom";


type DashboardLayoutProps = {
    children: ReactNode;
    includeBanner?: boolean;
    overflow?: string;
}

const DashboardLayout = ({children, includeBanner, overflow}: DashboardLayoutProps) => {
    const location = useLocation()
    const isModal = useModalStore((state: any) => state.isModal)
    const resetFilterStore = useFiltersStore((state: any) => state.resetFilterStore)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const access = useAccessStore((state: any) => state.access)
    const pdp = useProductDetailPageStore((state: any) => state.pdp)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setProfile = useProfileStore((state: any) => state.setProfile) 
    const setPost = usePostStore((state: any) => state.setPost)
    const account = useAccountStore((state: any) => state.account)
    const post = usePostStore((state: any) => state.post)
    const meeting = useMeetingStore((state: any) => state.meeting)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const modalKey = useModalStore((state: any) => state.modalKey)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)

    const memoizedSetPdp = useCallback(() => setPdp(null), [setPdp]);
    const memoizedResetSlideMenu = useCallback(() => resetSlideMenu(), [resetSlideMenu]);
    const memoizedResetFilterStore = useCallback(() => resetFilterStore(), [resetFilterStore]);
    const memoizedSetProfile = useCallback(() => setProfile(account), [setProfile, account]);

    useEffect(() => {
        window.scrollTo(0, 0);
        memoizedSetPdp();
        memoizedResetSlideMenu();
        memoizedResetFilterStore();

        if (location.pathname === '/profile') {
            memoizedSetProfile();
        }
    }, [location, memoizedSetPdp, memoizedResetSlideMenu, memoizedResetFilterStore, memoizedSetProfile]);


    const memoizedFooter = useMemo(() => (
        <Footer width={'calc(100% - 42px)'} isExpanded={isExpanded} />
    ), [isExpanded]);

    const memoizedButterBar = useMemo(() => (
        includeBanner && (
            <ButterBar background='rgba(9, 55, 241, 0.95)' color='#fff' width={'calc(100% - 42px)'} left="42px">
                <Text width='100%' textAlign='center' fontSize='24px'>Primary Yellow Beta</Text>
            </ButterBar>
        )
    ), [includeBanner]);

    const [modalDimensions, setModalDimensions] = useState({
        modalWidth: 0,
        modalHeight: 0,
        objectFit: 'contain',
        ratio: 0
    });

    return (
        <StyledDashboardLayout isModal={isModal} id="DashboardLayout">
            {access && !selectedPost && <DashboardMenu />}
            <StyledDashboardContainer isModal={isModal} isExpanded={isExpanded} isCreate={selectedPost !== null} overflow={overflow}>
                {children}
                <SlideMenu />
                {memoizedFooter}
            </StyledDashboardContainer>
            {memoizedButterBar}
            <ProductDetailContainer isExpanded={isExpanded && pdp} padding={pdp === 'viewingRoom' ? '0px' : '24px'}>
                {post && pdp === 'artwork' && <Artwork />}
                {post && pdp === 'show' && <Show />}
                {post && pdp === 'auctionItem' && <Auction />}
                {post && pdp === 'auction' && <Lot />}
                {post && pdp === 'gallery' && <Gallery />}
                {post && pdp === 'viewingRoom' && <ViewingRoom />}
                {meeting && pdp === 'meeting' && <Meeting />}
                {/* {post && pdp === 'checkout' && <Checkout />} */}
                {pdp === 'profile' && <UserProfile />}
            </ProductDetailContainer>
            {(post && modalKey && modalKey === 'post') &&
                <PostModal setModal={setPost} borderRadius="0" padding="0" modalDimensions={modalDimensions}>
                    <PostDetails data={post} isModal setModalDimensions={setModalDimensions} modalDimensions={modalDimensions}/>
                </PostModal>}
            {(modalKey === 'create') &&
                <CreateModal setModal={setPost} borderRadius="0" padding="0" />}
        </StyledDashboardLayout>
    )
}

export default React.memo(DashboardLayout);