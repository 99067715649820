import { useEffect, useState } from 'react'
import {  StyledCameraRoll, StyledCameraRollContainer, StyledCameraRollWrapper, StyledPreview, StyledPreviewContainer } from './styled'
import { Image } from '../Image';
import { useThemeStore } from '../../stores/web.store';
import { Video } from '../Video';
import { Post } from '../Post';

export const Preview = ({post, height, width}: any) => {
    const onClick = () => {
        console.log(post)
    }

    console.log(post)

    return (
        <StyledPreviewContainer height={height} width={width}>
            <StyledPreview>
                <Post data={post} noIcon height={height} width={width} borderRadius='10px'/>
            </StyledPreview>
        </StyledPreviewContainer>
    )
}



const CameraRoll = ({data, height}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    return (
        <StyledCameraRollWrapper >
            <StyledCameraRollContainer>
                <StyledCameraRoll total={data.length}>
                    {data.map((post: any, idx: number) => {
                        return (
                            <Preview key={`post-${idx}`} post={post} height="80px" />
                        )
                    })}
                </StyledCameraRoll>
            </StyledCameraRollContainer>
        </StyledCameraRollWrapper>
    )
}

export default CameraRoll