import styled from "styled-components";


interface UploadingPostContainerProps {

}

export const StyledUploadingPostContainer= styled.div<UploadingPostContainerProps>`
    width: 100%;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 999;
`


interface UploadingBarContainerProps {

}

export const StyledUploadingBarContainer= styled.div<UploadingBarContainerProps>`
    background: #CED7FC;
    border-radius: 8px;

    -webkit-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
    box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
`

interface UploadingBarProps {

}

export const StyledUploadingBar = styled.div<UploadingBarProps>`

    display: inline-flex;
    align-items: center;
    height: 48px;
    width: 100%;
    gap: 8px;
`

interface UploadingProgressContainerProps {

}

export const StyledUploadingProgressContainer= styled.div<UploadingProgressContainerProps>`
    flex-grow: 1;
    height: 12px;
    background: #9A9EB1;
    position: relative;
    border-radius: 12px;
    position: relative;
    margin-right: 12px;
`

interface UploadingProgressProps {
    percentage?: number;
}

export const StyledUploadingProgress= styled.div<UploadingProgressProps>`
    position: absolute;
    border-radius: 12px;
    background: #526BD1;
    height: 12px;
    width: ${props => `${props.percentage || 0}%`};
    top: 0;
    left: 0;

`