import styled from 'styled-components'


interface ImageProps {
    maxHeight?: string;
    height?: string;
    width?: string;
    border?: string;
    objectFit?: string;
    borderRadius?: string;
    zoom?: number;
    aspectRatio?: string;
    isNoEffect?: boolean;
}


export const StyledImage = styled.img<ImageProps>`
    height: ${props => props.height || '420px'};
    width: ${props => props.width || 'auto'};
    border: ${props => props.border || 'none'};
    
    transform-origin: top left;
    border-radius: ${props => props.borderRadius || 'unset'};
    display: block;
    aspect-ratio: ${props => props.aspectRatio || 1};
    object-fit: ${props => props.objectFit || 'contain'};
    transition: 0s transform;

    ${props => props.maxHeight && `
        max-height: ${props.maxHeight};
    `}

    ${props => !props.isNoEffect && `
        // &:hover {
        //     transform: scale(3,3);  
        //     transition-delay: .75s;
        // }`
    }

    @media (min-width: 768px) {
        height: ${props => props.height || 'auto'};
        width: ${props => props.width || 'auto'};
        transform: scale(${props => props.zoom || 1});
    }

    @media (min-width: 1024px) {

    }
`