import { View } from "../../View"
import { StyledAccountFollowersContainer, StyledAccountFollowers, StyledCloseModalContainer, StyledAccountFollowerContainer } from "./styled"
import { Button } from "../../Button"
import { useAccountStore, useModalStore, useProfileStore, useThemeStore } from "../../../stores/web.store"
import { SearchBar } from "../../SearchBar"
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { UserInfo } from "../../UserInfo"
import { Text } from "../../Text"
import { useState } from "react"
import { ProfilePicture } from "../../ProfilePicture"

const AccountFollower = ({account}: any) => {
    return (
        <View display="inline-flex" alignItems="center" padding="0px 0px 12px 0px" width="100%">
            <View flexGrow="1" textAlign="left">
                <ProfilePicture account={account} photo username role/>
            </View>
            {/* <View textAlign="right" width="fit-content"><Button noStyle color={theme.button.background} fontWeight="500">{`Follow`}</Button></View> */}
        </View>
    )
}

const AccountFollowers = ({profile}: any)  => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const [followers, setFollowers] = useState<any>(profile.followers)

    const onSearch = (value: string) => {
        const lowercasedValue = value.toLowerCase();
        const hydratedFollowers = profile.followers.map((item: any) => {return {...item, owner: {...item.owner, full_name: item.owner.first_name + ' ' + item.owner.last_name}}});
        const filteredFollowers = hydratedFollowers.filter((item: any) => {
            return Object.values(item.owner).some(val =>
                String(val).toLowerCase().includes(lowercasedValue)
            );
        });
        setFollowers(filteredFollowers);
    }

    return (
        <StyledAccountFollowersContainer>
            <StyledAccountFollowers>
                <Text width="100%" fontWeight="500">{`Followers (${profile.total_followers})`}</Text>
                <View padding="20px 0px">
                    <SearchBar 
                        setData={setFollowers} 
                        data={followers} 
                        resetData={profile.followers}
                        fetch={onSearch}
                        
                    />
                </View>
                <StyledAccountFollowerContainer>
                    {followers.map((follower: any) => {
                        return (
                            <AccountFollower account={follower.owner}/>
                        )
                    })}
                </StyledAccountFollowerContainer>
            </StyledAccountFollowers>
            <StyledCloseModalContainer>
                <Button noStyle onClick={() => setIsModal(false)}><XIcon strokeWidth={2} height={24} width={24}/></Button>
            </StyledCloseModalContainer>
        </StyledAccountFollowersContainer>
    )
}

export default AccountFollowers