import { useRef, useState } from "react";
import { MediaUpload } from "../../../components/MediaUpload"
import { CreateMediaPostContent } from "./CreateMediaPostContent"
import { StyledCreateMediaPost, StyledCreateMediaPostContainer } from "./styled"
import { PostActions } from "../PostActions";
import { uploadPost } from '../../../hooks/api.hook';
import { useAllMyPostsStore, useDashboardCreateModeStore, usePostPayloadStore, useResponsiveStore, useSelectedPostStore } from "../../../stores/web.store";
import { $getRoot } from "lexical";
import { LoadingModal } from "../../../components/Modal/LoadingModal";
import { Text } from "../../../components/Text";
import { Button } from "../../../components/Button";
import {ReactComponent as LoaderIcon} from '../../../img/loader.svg'
import { StyledPostTitleContainer } from "../styled";


const CreateMediaPost = () => {
    const [isSubmittingPost, setIsSubmittingPost] = useState(false)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const allMyPosts = useAllMyPostsStore((state: any) => state.allMyPosts) 
    const setAllMyPosts = useAllMyPostsStore((state: any) => state.setAllMyPosts)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)
    const width = useResponsiveStore((state: any) => state.width)
    const editorStateRef = useRef();

    const onClickCancel = () => {
        setIsCreateMode(false)
        setSelectedPost(null)
    }

    const onClickSaveDraft = async () => {
        setIsSubmittingPost(true)
        const resp = await uploadPost({
            ...postPayload, 
            metadata: JSON.stringify(postPayload.metadata || {}),
            status: 'draft',
            type: 'media'
        })
        setIsSubmittingPost(false)
        setAllMyPosts([resp.data, ...allMyPosts])
        setIsCreateMode(false)
        setSelectedPost(null)
    }

    const onClickSharePost = async () => {
        setIsSubmittingPost(true)
        const payload = {
            ...postPayload, 
            metadata: JSON.stringify(postPayload.metadata || {}),
            status: 'active',
            type: 'media'
        }

        const resp = await uploadPost(payload)
        setIsSubmittingPost(false)
        setAllMyPosts([resp.data, ...allMyPosts])
        setIsCreateMode(false)
        setSelectedPost(null)
    }

    return (
        <StyledCreateMediaPostContainer>
            <StyledPostTitleContainer>
                <Text fontSize={width > 768 ? "24px" : '20px'} fontWeight="500" padding="0px 0px 6px 0px">Post Details</Text>
                <Text color="#6b6b6b" fontSize="16px">Enter content and details for your post</Text>
            </StyledPostTitleContainer>
            <StyledCreateMediaPost>
                <MediaUpload setPayload={setPostPayload} payload={postPayload}/>
                <CreateMediaPostContent editorStateRef={editorStateRef}/>
            </StyledCreateMediaPost>
            {isSubmittingPost && <LoadingModal setModal={setIsSubmittingPost}>
                <Text fontSize="60px">Uploading Post</Text>
                <Text textAlign="center" width="100%" padding="32px 0px 60px 0px"><LoaderIcon /></Text>
                <Text textAlign="center" width="100%"><Button noStyle fontSize="32px" onClick={() => setIsSubmittingPost(false)}>Cancel</Button></Text>
            </LoadingModal>}
        </StyledCreateMediaPostContainer>
    )
}

export default CreateMediaPost