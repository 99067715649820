import { useFiltersStore } from "../../../stores/web.store"
import { SearchBar } from "../../SearchBar"
import { Sort } from "../../Sort"
import Filters from "../Filters"
import { StyledArtworksFiltersContainer, StyledSearchAndSort } from "./styled"



const ArtworkFilters = ({setData, resetData}: any) => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 

    const fetch = (value: string) => {
        setFilterStore({...filterStore, query: value, loaded: true})
    }

    return (
        <StyledArtworksFiltersContainer>
            <Filters rarity priceRange medium allFilters/>
            <StyledSearchAndSort>
                <SearchBar setData={setData} fetch={fetch} resetData={resetData} />
                <Sort />
            </StyledSearchAndSort>
        </StyledArtworksFiltersContainer>
    )
}

export default ArtworkFilters