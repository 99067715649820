import { useEffect, useState } from "react"
import { EmptyPosts } from "../../components/EmptyPosts"
import { Post } from "../../components/Post"
import { Text } from "../../components/Text"
import { LI, OL, UL } from "../../styled"
import { CurrentShow } from "./CurrentShow"
import { StyledShowsContainer, StyledShows } from "./styled"
import { useAccountStore, useModalStore, useMyMediaPostsStore, useMyShowsStore, usePostStore, useProductDetailPageStore, useProfileStore } from "../../stores/web.store"
import { useMyShows } from "../../hooks/api.hook"
import { StyledAllPosts, StyledAllPostsContainer } from "../AllPosts/styled"
import { Modal } from "../../components/Modal"
import { PostDetails } from "../../components/PostDetails"


const Media = ({data}: any) => {
    const account = useAccountStore((state: any) => state.account) 
    const profile = useProfileStore((state: any) => state.profile) 
    const myMediaPosts = useMyMediaPostsStore((state: any) => state.myMediaPosts) 
    const setMyMediaPosts = useMyMediaPostsStore((state: any) => state.setMyMediaPosts) 

    const setPost = usePostStore((state: any) => state.setPost)
    const post = usePostStore((state: any) => state.post)
    const isModal = useModalStore((state: any) => state.isModal)

    useEffect(() => {
        setMyMediaPosts(data.filter((dataPost: any) => dataPost.type === 'media'))
    }, [data])

    if (myMediaPosts.length === 0 && account.id === profile.id) return (
        <EmptyPosts type="show" title="No shows yet">
            <Text />
        </EmptyPosts>
    )

    return (
        <StyledAllPostsContainer>
            <StyledAllPosts>
                {myMediaPosts.map((obj: any, index: number) => {
                    return (
                        <Post data={{...obj, index}} setPost={setPost} />
                    )
                })}
            </StyledAllPosts>
            {/* {isModal && post &&
                <Modal 
                    setModal={setPost} 
                    borderRadius="0"
                    padding="0"
                >
                <PostDetails data={post} isModal posts={data}/>
            </Modal>} */}
        </StyledAllPostsContainer>
    )
}

export default Media