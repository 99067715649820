import styled from 'styled-components'

interface CreatePostItemContainerProps {

}


export const StyledCreatePostItemContainer  = styled.div<CreatePostItemContainerProps>`
    
`

interface CreatePostItemProps {

}

export const StyledCreatePostItem = styled.div<CreatePostItemProps>`
    text-align: center;
    width: 100%;
`

interface IconContainerProps {

}

export const StyledIconContainer = styled.div<IconContainerProps>`
    padding: 0px 0px 8px 0px;
`
