import { useRef } from "react"
import { usePostStore, useProductDetailPageStore, useProfileStore } from "../../stores/web.store"
import { LikeButton } from "../Button/Like"
import { ProfilePicture } from "../ProfilePicture"
import { Text } from "../Text"
import { View } from "../View"
import { StyledCaption, StyledCaptionContainer } from "./styled"
import { ReadOnlyMarkdown } from "../MarkDown/ReadOnlyMarkdown"

const PostCaption = ({data}: any) => {
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setPost = usePostStore((state: any) => state.setPost)
    const setProfile = useProfileStore((state: any) => state.setProfile)
    const editorStateRef = useRef<any>();

    const onClickProfile = () => {
        setProfile(data.owner)
        setPdp('profile')
        setPost(null)
        
    }

    if (!data || !(data.caption || data.description)) return <div />
    return (
        <StyledCaptionContainer>
            <StyledCaption>
                {/* <ProfilePicture account={data.owner} username fontSize="16px"/> */}
                {/* <ReadOnlyMarkdown data={data.lexical_caption} /> */}
                <Text 
                    lineHeight="18px" 
                    fontSize="14px" 
                    width="100%" 
                    textAlign="left"
                    isReadMore={(data.caption || data.description).length > 200}
                >
                    <b 
                        onClick={onClickProfile} 
                        style={{cursor: "pointer", paddingRight: "4px"}}
                    >
                        {data.owner.first_name} {data.owner.last_name}
                    </b> 
                    <span style={{marginRight: 24}}>{data.caption || data.description}</span>
                </Text>
            </StyledCaption>
        </StyledCaptionContainer>
    )
}

export default PostCaption