import styled from 'styled-components'


interface LayoutProps {

}


export const StyledLayout = styled.div<LayoutProps>`
    height: 100vh;
    width: 100%;
    padding: 0px 0px 40px 0px;
    background: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    // font-family:"ll-unica77","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
`