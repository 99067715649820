import { useState } from "react"
import { Text } from "../../components/Text"
import { View } from "../../components/View"
import { usePostStore } from "../../stores/web.store"
import { formatValue, key2Value } from "../../utils/app.util"
import { StyledAboutTheWork, StyledAboutTheWorkContainer } from "./styled"


type AboutTheWorkItemProps = {
    title?: string;
    value?: any;
}

const AboutTheWorkItem = ({title, value}: AboutTheWorkItemProps) => {
    return (
        <View display="inline-flex" alignItems="center" width="100%">
            <Text textTransform="capitalize" width="50%" fontWeight="500">{key2Value(title!)}</Text>
            <Text textTransform="capitalize" width="50%" color="grey">{formatValue(value)}</Text>
        </View>
    )
}


const AboutTheWork = () => {
    const IGNORE_KEYS = ['price']

    const post = usePostStore((state: any) => state.post)
    return (
        <StyledAboutTheWorkContainer>
            <View>
                <Text 
                    fontSize="20px" 
                    fontWeight="500"
                >{`About the Work`}</Text>
            </View>
            <StyledAboutTheWork>
                {Object.keys(post.metadata).filter((key: any) => !IGNORE_KEYS.includes(key)).map((key: string) => {
                    return (
                        <AboutTheWorkItem title={key} value={post.metadata[key]} />
                    )
                    
                })}
            </StyledAboutTheWork>
        </StyledAboutTheWorkContainer>
    )
}

export default AboutTheWork