import styled from 'styled-components'

interface ArtistContainerProps {

}


export const StyledArtistContainer  = styled.div<ArtistContainerProps>`

`

interface ArtistProps {

}

export const StyledArtist = styled.div<ArtistProps>`

`


