import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Button"
import { Text } from "../../../../components/Text"
import { StyledFooterContent, StyledFooterContentContainer, StyledFooterLinkContainer, StyledFooterLinks, StyledFooterLinksContainer } from "./styled"


type LinkButtonProps = {
    title: string;
    link: string;
}

const LinkButton = ({title, link}: LinkButtonProps) => {
    const navigate = useNavigate()

    return (
        <StyledFooterLinkContainer>
            <Button noStyle color="#000" fontSize="20px" textAlign='left' onClick={() => navigate(link)} lineHeight="20px">{title}</Button>
        </StyledFooterLinkContainer>
    )
}


const PrimaryYellowFooterLinks = () => {
    const LINKS = [
        {title: 'About', link: '/about-us'},
        {title: 'Careers', link: '/careers'},
        {title: 'Press', link: '/press'},
        {title: 'Contact', link: '/contact-us'},
        {title: 'Editorial', link: '/editorial'}
    ]

    return (
        <StyledFooterLinksContainer>
            <StyledFooterLinks>
                <Text padding="8px 0px" fontSize="20px" fontWeight="600">Primary Yellow</Text>
                {LINKS.map((link: LinkButtonProps) => {
                    return (
                        <LinkButton {...link} />
                    )
                })}
            </StyledFooterLinks>
        </StyledFooterLinksContainer>
    )
}

const ResourcesFooterLinks = () => {

    const LINKS = [
        {title: 'Buy', link: '/buy'},
        {title: 'Sell', link: '/sell'},
        {title: 'Artmap', link: '/artmap'},
        {title: 'AI Art Advisor', link: '/art-advisor'},
        {title: 'AI Interior Design', link: '/interior-design'},
    ]
    
    return (
        <StyledFooterLinksContainer>
            <StyledFooterLinks>
                <Text padding="8px 0px" fontSize="20px" fontWeight="600">Resources</Text>
                {LINKS.map((link: LinkButtonProps) => {
                    return (
                        <LinkButton {...link} />
                    )
                })}
            </StyledFooterLinks>
        </StyledFooterLinksContainer>
    )
}


const PartnersFooterLinks = () => {
    const LINKS = [
        {title: 'Primary Yellow for Artists', link: '/for-artists'},
        {title: 'Primary Yellow for Collectors', link: '/for-collectors'},
        {title: 'Primary Yellow for Galleries', link: '/for-galleries'},
        {title: 'Primary Yellow for Museums', link: '/for-museums'},
        // {title: 'Primary Yellow for Auctions', link: '/auctions'}
    ]

    return (
        <StyledFooterLinksContainer>
            <StyledFooterLinks>
                <Text padding="8px 0px" fontSize="20px" fontWeight="600">Partners</Text>
                {LINKS.map((link: LinkButtonProps) => {
                    return (
                        <LinkButton {...link} />
                    )
                })}
            </StyledFooterLinks>
        </StyledFooterLinksContainer>
    )
}

const SupportFooterLinks = () => {
    
    return (
        <StyledFooterLinksContainer>
            <StyledFooterLinks>
                <Text padding="8px 0px" fontSize="20px" fontWeight="600">Support</Text>
                <LinkButton title={'User Verification'} link={'/verify'} />
            </StyledFooterLinks>
            <StyledFooterLinks padding="12px 0px 0px 0px">
                <Text padding="8px 0px" fontSize="16px" fontWeight="600">Get the App</Text>
                <LinkButton title={'iOS App'} link={'/ios'} />
            </StyledFooterLinks>
        </StyledFooterLinksContainer>
    )
}



const FooterContent = () => {
    return (
        <StyledFooterContentContainer>
            <StyledFooterContent>
                <PrimaryYellowFooterLinks />
                <ResourcesFooterLinks />
                <PartnersFooterLinks />
                <SupportFooterLinks />
            </StyledFooterContent>
        </StyledFooterContentContainer>
    )
}

export default FooterContent