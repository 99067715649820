import { QuickFilter } from "../QuickFilter"


type GalleriesProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const Galleries = ({selectedFilter, setSelectedFilter}: GalleriesProps) => {
    const onClick = () => {
        setSelectedFilter('gallery')
    }

    return (
        <QuickFilter title="Galleries" onClick={onClick} isSelected={selectedFilter === 'gallery'}/>
    )
}

export default Galleries