import { AllFilters } from "./AllFilters";
import { Medium } from "./Medium";
import { PriceRange } from "./PriceRange";
import { Rarity } from "./Rarity";
import { StyledFilters, StyledFiltersContainer } from "./styled"

type FiltersProps = {
    allFilters?: boolean;
    medium?: boolean;
    priceRange?: boolean;
    rarity?: boolean;
}

const Filters = (props: FiltersProps) => {
    return (
        <StyledFiltersContainer>
            <StyledFilters>
                {props.allFilters && <AllFilters />}
                {props.rarity && <Rarity />}
                {props.medium && <Medium />}
                {props.priceRange && <PriceRange />}
            </StyledFilters>
        </StyledFiltersContainer>
    )
}

export default Filters