import { ReactComponent as LogoIcon } from '../../../img/logo.svg';
import { ReactComponent as HomeIcon } from '../../../img/menuHome.svg';
import { ReactComponent as SearchIcon } from '../../../img/menuSearch.svg';
import { ReactComponent as BookmarkIcon } from '../../../img/menuSaved.svg';
import { ReactComponent as MailIcon } from '../../../img/menuMessage.svg';
import { ReactComponent as BellIcon } from '../../../img/menuBell.svg';
import { ReactComponent as CreateIcon } from '../../../img/menuCreate.svg';
import { ReactComponent as ProfileIcon } from '../../../img/menuProfile.svg';
import { ReactComponent as MenuIcon } from '../../../img/menuMenu.svg';
import { ReactComponent as ExploreIcon } from '../../../img/menuExplore.svg';
import { ReactComponent as ArtIcon } from '../../../img/menuArtwork.svg';
import { ReactComponent as ShowsIcon } from '../../../img/menuShow.svg';
import { ReactComponent as AuctionsIcon } from '../../../img/menuAuction.svg';
import { ReactComponent as LogoutIcon } from '../../../img/logout.svg';
import { ReactComponent as SettingsIcon } from '../../../img/settings.svg';
import { StyledDashabordMenu, StyledDashabordMenuContainer, StyledDashboardMenuItem, StyledDashboardMenuItemContainer, StyledDashboardMenuSection, StyledExpandMenu, StyledExpandMenuContainer, StyledMenuIcon, StyledMenuItemHover, StyledMenuItemHoverContainer, StyledMenuText } from "./styled"
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { useEffect, useRef, useState } from 'react';
import { useAccountStore, useDashboardCreateModeStore, useDashboardMenuExpandedStore, useDashboardNavigationStore, useModalStore, usePostStore, useProductDetailPageStore, useResponsiveStore, useSlideMenuStore } from '../../../stores/web.store';
import { useLocation, useNavigate } from 'react-router-dom';
import { signout } from '../../../services/auth.service';
import { View } from '../../../components/View';
import { useComponentVisible } from '../../../utils/app.util';
import { StyledProfilePicture } from '../../../components/ProfilePicture/styled';
import { Image } from '../../../components/Image';


type MenuItemHoverProps = {
    title?: string;
    isHover?: boolean;
    onClick?: any;
}

const MenuItem = ({ setIsHover, isHover, isActive, padding, margin, isExpanded, children}: any) => {
    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <StyledDashboardMenuItemContainer 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave} 
            padding={padding} 
            isExpanded={isExpanded} 
            isHover={isHover} 
            isActive={isActive}
            margin={margin}
        >
            <StyledDashboardMenuItem>
                {children}
            </StyledDashboardMenuItem>
        </StyledDashboardMenuItemContainer>
    )
}

const SearchItem = () => {
    const navigate = useNavigate()
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(slideMenu.key === 'search' && slideMenu.isActive)
    }, [slideMenu])

    const onClick = () => {
        if (slideMenu.key === 'search' && slideMenu.isActive) {
            resetSlideMenu()
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'search'})
        }
    }
    

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive} id="MenuSearchButton">
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <SearchIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            strokeWidth={isActive ? '4' : '2'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Search</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const ExploreItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/explore' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/explore')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <ExploreIcon 
                            height={width > 768 ? (isHover ? 38 : isActive ? 38 : 36) : (isHover ? 30 : isActive ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : isActive ? 38 : 36) : (isHover ? 30 : isActive ? 30 : 28)} 
                            fill={isActive ? '#000' : '#fff'}
                            stroke={isActive ? '#fff' : '#000'}
                            strokeWidth={'2'}
                        />
                    
                        {isExpanded && <StyledMenuText isActive={isActive}>Explore</StyledMenuText>}
                    </StyledMenuIcon>
                </Button>
            </div>
        </MenuItem>
    )
}

const ArtItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/artworks' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/artworks')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>  
                        <ArtIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke-width={isActive ? '4' : '2'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Artworks</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const ShowsItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/shows' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/shows')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <ShowsIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke={isActive ? '#fff' : '#000'}
                            fill={isActive ? '#000' : '#fff'}
                            strokeWidth={"1.5"}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Shows</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}


const AuctionsItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/auctions' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/auctions')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <AuctionsIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke-width={isActive ? '4' : '2'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Auctions</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const SavedItem = () => {
    const location = useLocation()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/saved' && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {

    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <BookmarkIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke-width={isHover ? '2' : '1.5'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Saved</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const MessagesItem = () => {
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(slideMenu.key === 'inbox')
    }, [slideMenu])

    const onClick = () => {
        if (slideMenu.key === 'inbox' && slideMenu.isActive) {
            resetSlideMenu()
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'inbox'})
        }
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <MailIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke-width={'2'}
                            stroke={isActive ? '#fff' : '#000'}
                            fill={isActive ? '#000' : '#fff'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Messages</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const NotificationsItem = () => {
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(slideMenu.key === 'notifications')
    }, [slideMenu])
    

    const onClick = () => {
        
        if (slideMenu.key === 'notifications' && slideMenu.isActive) {
            resetSlideMenu()
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'notifications'})
        }
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <View position='relative'>
                            <BellIcon 
                                height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                                width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                                stroke-width={'2'}
                                fill={isActive ? '#000' : '#fff'}
                            />
                            <View position='absolute' right='10px' top='4px' height='10px' width='10px' borderRadius='50%' background='#FFDC39'/>
                        </View>
                        {isExpanded && <StyledMenuText isActive={isActive}>Notifications</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const CreateItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/profile' && !slideMenu.isActive && isCreateMode)
    }, [location, slideMenu, isCreateMode])

    const onClick = () => {
        // setIsCreateMode(true)
        // navigate('/profile')
        setModalKey('create')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <CreateIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            stroke-width={'2'}
                            stroke={isActive ? '#fff' : '#000'}
                            fill={isActive ? '#000' : '#fff'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Create</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const ProfileItem = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const account = useAccountStore((state: any) => state.account)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(location.pathname === '/profile' && !slideMenu.isActive && !isCreateMode)
    }, [location, slideMenu, isCreateMode])

    const onClick = () => {
        setIsCreateMode(false)
        navigate('/profile')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        {account.profile_picture ?
                            <View>
                                <View padding='8px 0px 12px 6px'>
                                    <Image
                                        src={account.profile_picture} 
                                        height={'28px'} 
                                        width={'28px'}
                                        borderRadius='50%'
                                        objectFit='cover'
                                    />
                                </View>
                            </View> :
                            <ProfileIcon 
                                height={width > 768 ? (isHover ? 40 :  isActive ? 40 : 36) : (isHover ? 34 :  isActive ? 34 : 28)} 
                                width={width > 768 ? (isHover ? 40 : isActive ? 40 : 36) : (isHover ? 34 : isActive ? 34 : 28)} 
                                strokeWidth={2}
                                stroke={isActive ? '#fff' : '#000'}
                                fill={isActive ? '#000' : '#fff'}
                            />}
                        {isExpanded && <StyledMenuText isActive={isActive} padding={'0px 0px 0px 4px'}>Profile</StyledMenuText>}
                    </StyledMenuIcon>
                </Button>
            </div>
        </MenuItem>
    )
}

const LogoItem = () => {
    const navigate = useNavigate()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        navigate('/')
    }

    return (
        <MenuItem setIsHover={setIsHover} isExpanded={isExpanded}>
            <View padding='6px'>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <LogoIcon height={30} width={28} stroke-width={'1.5'}/>
                        {isExpanded && <StyledMenuText>Primary Yellow</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </View>
        </MenuItem>
    )
}

const HomeItem = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive((location.pathname === '/' || location.pathname === '') && !slideMenu.isActive)
    }, [location, slideMenu])

    const onClick = () => {
        navigate('/')
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} isActive={isActive}>
            <div>
                <Button noStyle onClick={onClick} width='100%' height='42px'>
                    <StyledMenuIcon isExpanded={isExpanded}>
                        <HomeIcon 
                            height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                            fill={isActive ? '#000' : '#fff'}
                            strokeWidth={'2'}
                        />
                        {isExpanded && <StyledMenuText isActive={isActive}>Home</StyledMenuText>}
                    </StyledMenuIcon>
                    
                </Button>
            </div>
        </MenuItem>
    )
}

const SignoutItem = () => {
    const navigate = useNavigate()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        setIsCreateMode(false)
        navigate('/profile')
    }

    const onClickLogout = async () => {
        await signout()
    }

    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} margin="0">
            <Button noStyle onClick={onClickLogout} width='100%' height='42px' padding='0px 20px 0px 4px'>
                <StyledMenuIcon isExpanded>
                    <LogoutIcon 
                        height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        stroke-width={isHover ? '2' : '1.5'}
                    />
                    <StyledMenuText>Signout</StyledMenuText>
                </StyledMenuIcon>
                
            </Button>
        </MenuItem>
    )
}

const SettingsItem = () => {
    const navigate = useNavigate()
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        setIsCreateMode(false)
        navigate('/settings')
    }


    return (
        <MenuItem setIsHover={setIsHover} isHover={isHover} margin="0">
            <Button noStyle onClick={onClick} width='100%' height='42px' padding='0px 20px 0px 4px'>
                <StyledMenuIcon isExpanded>
                    <SettingsIcon 
                        height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        stroke-width={isHover ? '2' : '1.5'}
                    />
                    <StyledMenuText>Settings</StyledMenuText>
                </StyledMenuIcon>
                
            </Button>
        </MenuItem>
    )
}

const useOutsideAlerter = (ref: any, setIsExpandedMenu: any) => {

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsExpandedMenu(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const ExpandMenu = ({setIsExpandedMenu, isExpandedMenu}: any) => {
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, setIsExpandedMenu);
    
    return (
        <StyledExpandMenuContainer isActive={isExpandedMenu} ref={wrapperRef}>
            <StyledExpandMenu>
                <View background='#fff' display='flex' flexDirection='column'>
                    <SettingsItem />
                </View>
                <View background='#fff' display='flex' flexDirection='column'>
                    <SignoutItem />
                </View>
            </StyledExpandMenu>
        </StyledExpandMenuContainer>
    )
}

const ExpandItem = ({setIsExpandedMenu, isExpandedMenu}: any) => {
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isHover, setIsHover] = useState(false)

    const onClick = () => {
        setIsExpandedMenu(!isExpandedMenu)
        // setIsExpanded(!isExpanded)
    }

    return (
        <MenuItem setIsHover={setIsHover} isExpanded={isExpanded} isHover={isHover}>
            <Button noStyle onClick={onClick} width='100%' height='42px'>
                <StyledMenuIcon isExpanded={isExpanded}>
                    <MenuIcon 
                        height={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        width={width > 768 ? (isHover ? 38 : 36) : (isHover ? 30 : 28)} 
                        stroke-width={isHover ? 2 : 1.5}
                    />
                    {isExpanded && <StyledMenuText>Menu</StyledMenuText>}
                </StyledMenuIcon>
                
            </Button>
        </MenuItem>
    )
}

const DashboardMenu = () => {
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isExpandedMenu, setIsExpandedMenu] = useState(false)
    
    const onMouseEnter = () => {
        setIsExpanded(true)
    }

    const onMouseLeave = () => {
        setIsExpanded(false)
    }

    return (
        <StyledDashabordMenuContainer isExpanded={isExpanded} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <StyledDashabordMenu>
                {width > 768 && <StyledDashboardMenuSection padding='0px 0px 8px 0px'>
                    <LogoItem />
                </StyledDashboardMenuSection>}
                <StyledDashboardMenuSection 
                    height={width > 768  ? 'calc(100vh - 126px)' : 'fit-content'} 
                    overflowY={width > 768 ? "scroll": "hidden"} 
                    isExpanded={isExpanded} 
                    top={width > 768 ? '12px' : '0'}
                >
                    <HomeItem />
                    {width > 768  && <SearchItem />}
                    <ExploreItem />
                    {width > 768 && <ArtItem />}
                    {width > 768 && <ShowsItem />}
                    {width > 768 && <AuctionsItem />}
                    {/* <SavedItem /> */}
                    {width > 768 ? <MessagesItem /> : <CreateItem /> }
                    {width > 768 && <NotificationsItem />}
                    {width > 768 ? <CreateItem /> : <MessagesItem />}
                    <ProfileItem />
                </StyledDashboardMenuSection>
                {width > 768 && <StyledDashboardMenuSection position='absolute' bottom="20px">
                    <ExpandItem setIsExpandedMenu={setIsExpandedMenu} isExpandedMenu={isExpandedMenu}/>
                </StyledDashboardMenuSection>}
                <ExpandMenu isExpandedMenu={isExpandedMenu} setIsExpandedMenu={setIsExpandedMenu}/>
            </StyledDashabordMenu>
        </StyledDashabordMenuContainer>
    )
}

export default DashboardMenu