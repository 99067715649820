import styled from 'styled-components'


interface GalleryTemplateContainerProps {

}


export const StyledGalleryTemplateContainer = styled.div<GalleryTemplateContainerProps>`

`

interface GalleryTemplateProps {
    isActive?: boolean
}


export const StyledGalleryTemplate = styled.div<GalleryTemplateProps>`
    & img:hover {
        -webkit-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
        -moz-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
        box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    }

    ${props => props.isActive && `
        & img {
            -webkit-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
            -moz-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
            box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
        }
    `}
`
