import styled from 'styled-components'


interface FooterContentContainerProps {

}


export const StyledFooterContentContainer = styled.div<FooterContentContainerProps>`
    @media (min-width: 768px) {
        padding: 12px 24px 80px 24px;
        
    }

    @media (min-width: 1024px) {

    }
`

interface FooterContentProps {

}


export const StyledFooterContent = styled.div<FooterContentProps>`
    width: 100%;
    margin: auto;

    @media (min-width: 768px) {
        width: 100%;
        display: inline-flex;
        padding: 0px 24px;
        
        // grid-template-columns: repeat(4, 1fr);
        grid-gap: 0;
        
    }

    @media (min-width: 1024px) {

    }
`

interface FooterLinksContainerProps {

}


export const StyledFooterLinksContainer = styled.div<FooterLinksContainerProps>`
    display: flex;
    flex-direction: column;
    padding: 24px;
`

interface FooterLinksProps {
    padding?: string;
}


export const StyledFooterLinks = styled.div<FooterLinksProps>`
    padding: ${props => props.padding || '0'};
`

interface FooterLinkContainerProps {

}


export const StyledFooterLinkContainer = styled.div<FooterLinkContainerProps>`
    padding: 8px 0px;
`
