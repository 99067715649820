import styled from 'styled-components'

interface RarityContainerProps {

}


export const StyledRarityContainer  = styled.div<RarityContainerProps>`
    
`

interface RarityProps {

}


export const StyledRarity  = styled.div<RarityProps>`
    
`

