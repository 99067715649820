import styled from 'styled-components'


interface AllFiltersContainerProps {

}


export const StyledAllFiltersContainer  = styled.div<AllFiltersContainerProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

interface AllFiltersProps {

}


export const StyledAllFilters  = styled.div<AllFiltersProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

