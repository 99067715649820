import { QuickFilter } from "../QuickFilter"

type TrendingLotsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const TrendingLots = ({selectedFilter, setSelectedFilter}: TrendingLotsProps) => {
    const onClick = () => {
        setSelectedFilter('trending_lots')
    }

    return (
        <QuickFilter title="Trending Lots" onClick={onClick} isSelected={selectedFilter === 'trending_lots'}/>
    )
}

export default TrendingLots