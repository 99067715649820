import styled from 'styled-components'


interface InlineContainerProps {

}


export const StyledInlineContainer = styled.div<InlineContainerProps>`
    display: inline-flex;
    gap: 4px;
    width: 100%;
    align-items: center;
`

interface BoldProps {

}


export const Bold = styled.b<BoldProps>`
    
`

interface OLProps {

}


export const OL = styled.ol<OLProps>`
`

interface ULProps {
    color?: string;
    fontSize?: string;
}


export const UL = styled.ul<ULProps>`
    font-size: ${props => props.fontSize || '13px'};
    color: ${props => props.color || '#000'};
`

interface LIProps {
    padding?: string;
}


export const LI = styled.li<LIProps>`
    text-align: left;
    padding: ${props => props.padding || '12px 0px'};
`