import styled from 'styled-components'


interface LandingrProps {

}


export const StyledLanding = styled.div<LandingrProps>`
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
`








