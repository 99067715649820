import { useEffect, useState } from "react"
import { EmptyPosts } from "../../components/EmptyPosts"
import { Text } from "../../components/Text"
import { StyledSavedContainer } from "./styled"
import api from "../../services/api.service"
import { PageLoader } from "../../components/Loader/PageLoader"
import { Button } from "../../components/Button"
import { View } from "../../components/View"
import {ReactComponent as PYSettingIcon} from '../../img/PYSetting.svg'
import { Boards } from "../../components/Boards"


const Saved = () => {
    const [bucket, setBucket] = useState<any>({posts: []})

    const [data, setData] = useState<any>([])
    const [apiResp, setApiResp] = useState<any>({})

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get('/saved-buckets')
            setData(resp.data)
            setApiResp(resp)
        }

        fetch()
    }, [])


    const onClickBack = () => {
        setData(apiResp.data)
        setBucket({posts: []})
    }

    if (data.length === 0) return (
        <EmptyPosts type="subscribers" title="No subscriber posts yet" override>
            <View padding="0px 0px 24px 0px">
                <View display="flex" flexDirection="column" gap="12px" textAlign="center" width="100%">
                    <View width="100%">
                        <PYSettingIcon />
                    </View>
                    <View>
                        <Text color="#808080" width="100%" textAlign="center" fontSize="24px">{"No posts"}</Text>
                    </View>
                    <View>
                        <Text color="#808080" width="100%" textAlign="center">{"You don’t have any saved posts."}</Text>
                    </View>
                </View>
            </View>
        </EmptyPosts>
    )

    if (!apiResp.is_loaded) return <PageLoader />

    return (
        <StyledSavedContainer>
            {bucket.posts.length > 1 && <View padding="0px 0px 24px 0px">
                <Button noStyle fontSize="16px" onClick={onClickBack}>Back</Button>
            </View>}
            <Boards api={apiResp} />
        </StyledSavedContainer>
    )
}

export default Saved