import { StyledAlert, StyledAlertContainer } from "./styled"
import {ReactComponent as AlertIcon} from '../../../img/bell.svg'
import { Text } from "../../Text";
import Button from "../Button";

type AlertProps = {
    data?: any;
}


const Alert = ({data}: AlertProps) => {
    const onClick = () => {
        
    }
    
    return (
        <StyledAlertContainer>
            <StyledAlert>
                <Button onClick={onClick} noStyle>
                    <AlertIcon height={15} width={15}/>
                    <Text>{`Create Alert`}</Text>
                </Button>
            </StyledAlert>
        </StyledAlertContainer>
    )
}

export default Alert