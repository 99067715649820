import { QuickFilter } from "../QuickFilter"


type Props = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const Commissions = ({selectedFilter, setSelectedFilter}: Props) => {
    const onClick = () => {
        setSelectedFilter('commission')
    }

    return (
        <QuickFilter title="Commissions" onClick={onClick} isSelected={selectedFilter === 'commission'}/>
    )
}

export default Commissions