import styled from "styled-components";

interface ShowSectionContainerProps {

}

export const StyledShowSectionContainer = styled.div<ShowSectionContainerProps>`
`

interface ShowSectionProps {

}

export const StyledShowSection = styled.div<ShowSectionProps>`
    display: grid;
    grid-template-columns: 50% 50%;
    width: calc(100% - 24px);
    grid-gap: 24px;
`