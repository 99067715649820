import styled from 'styled-components'


interface SignInContainerProps {

}


export const StyledSignInContainer = styled.div<SignInContainerProps>`
    
`


interface SignInProps {

}


export const StyledSignIn = styled.div<SignInProps>`

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
    }
`

interface RememberMeCheckboxContainerProps {
    
}


export const StyledRememberMeCheckboxContainer = styled.div<RememberMeCheckboxContainerProps>`
    padding: 0px 0px 32px 0px;
    display: inline-flex;
    width: 90%;
    gap: 12px;
    align-items: center;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
`

