import { StyledCreateDashboardContainer, StyledDashboardContent, StyledMainDashboardContainer } from "./styled";

import { Profile } from "../../sections/Profile";
import { GalleryView } from "../../components/GalleryView";
import { ArtPiece } from "../../components/Artwork/Painting";
import { HangArtPiece } from "../../components/HangArtPiece";
import { DashboardNavigation } from "../../sections/DashboardNavigation";
import { AllPosts } from "../../sections/AllPosts";
import { useAccountStore, useAllMyPostsStore, useDashboardCreateModeStore, useDashboardNavigationStore, useProfileStore } from "../../stores/web.store";
import { Shows } from "../../sections/Shows";
import { Artworks } from "../../sections/Artworks";
import { Auctions } from "../../sections/Auctions";
import { Subscribers } from "../../sections/Subscribers";
import { Insights } from "../../sections/Insights";
import { Saved } from "../../sections/Saved";
import { CreatePost } from "../../sections/CreatePost";
import { useAllMyPosts } from "../../hooks/api.hook";
import { useEffect } from "react";
import { DashboardLayout } from "../../sections/Layout/DashboardLayout";
import { StyledDashboard } from "../AdminDashboard/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Media } from "../../sections/Media";
import api from "../../services/api.service";
import { useEffectOnce } from "../../utils/app.util";


const MainDashboard = ({profile}: any) => {
    const {username} = useParams()
    const location = useLocation()
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    const dashboardNavigation = useDashboardNavigationStore((state: any) => state.dashboardNavigation)

    const account = useAccountStore((state: any) => state.account) 
    // const profile = useProfileStore((state: any) => state.profile) 
    // const setProfile = useProfileStore((state: any) => state.setProfile) 
    const allMyPosts = useAllMyPostsStore((state: any) => state.allMyPosts) 
    const setAllMyPosts = useAllMyPostsStore((state: any) => state.setAllMyPosts) 

    // const {data} = useAllMyPosts({query: location.pathname === '/profile' ? {} : {owner_id: id || profile?.id}})


    useEffect(() => {
        const fetchAllMyPosts = async (ownerId: string) => {
            const myPosts = await api.post(`/search/my-posts`, {query: {owner_id: ownerId}})
            setAllMyPosts(myPosts.data)
        }

        fetchAllMyPosts(profile.id)
    }, [profile])

    return (
        <StyledMainDashboardContainer isCreateMode={isCreateMode}>
            <Profile profile={profile}/>
            <DashboardNavigation />
            <div style={{position: 'relative'}}>
                <StyledDashboardContent>
                    {dashboardNavigation === 'all' && <AllPosts data={allMyPosts}/>}
                    {dashboardNavigation === 'posts' && <Media data={allMyPosts}/>}
                    {dashboardNavigation === 'shows' && <Shows data={allMyPosts}/>}
                    {dashboardNavigation === 'artworks' && <Artworks data={allMyPosts}/>}
                    {dashboardNavigation === 'auctions' && <Auctions data={allMyPosts}/>}
                    {account.id === profile.id && dashboardNavigation === 'subscribers' && <Subscribers data={allMyPosts}/>}
                    {account.id === profile.id && dashboardNavigation === 'saved' && <Saved />}
                    {/* {dashboardNavigation === 'insights' && <Insights />} */}
                </StyledDashboardContent>
            </div>
        </StyledMainDashboardContainer>
    )
}

const CreateDashboard = () => {
    const isCreateMode = useDashboardCreateModeStore((state: any) => state.isCreateMode)
    return (
        <StyledCreateDashboardContainer isCreateMode={isCreateMode}>
             <CreatePost />
        </StyledCreateDashboardContainer>
       
    )
}

export const UserProfile = ({profile}: any) => {
    return (
        <StyledDashboard>
            <MainDashboard profile={profile}/>
            <CreateDashboard />
        </StyledDashboard>
    )
}

const UserDashboard = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const account = useAccountStore((state: any) => state.account) 
    const profile = useProfileStore((state: any) => state.profile)
    const setProfile = useProfileStore((state: any) => state.setProfile)
    const {username} = useParams()

    useEffectOnce(() => {
        const fetch = async () => {
            const resp = await api.get(`/accounts/${username}?key=username`)
            if (resp.data.id) {
                setProfile(resp.data)
            } else {
                const resp = await api.get(`/accounts/${username}`)
                setProfile(resp.data)
            }
        }

        if (username) {
            fetch()
        } else {
            setProfile(account)
        }
    })


    if (!profile || (profile && !profile?.id)) return <div />

    
    return (
        <DashboardLayout>
            <UserProfile profile={profile}/>
        </DashboardLayout>
    )
}

export default UserDashboard