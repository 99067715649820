import { Text } from "../../Text";
import Button from "../Button";
import { StyledViewInRoom, StyledViewInRoomContainer } from "./styled"
import {ReactComponent as EyeIcon} from '../../../img/eye.svg'
import { View } from "../../View";
import { GalleryTemplates } from "../../GalleryTemplates";
import { useState } from "react";
import {ReactComponent as XICon} from '../../../img/x.svg'


type ViewInRoomProps = {
    data?: any;
    noTitle?: boolean;
}

const ViewInRoom = ({data, noTitle}: ViewInRoomProps) => {
    const [isViewing, setIsViewing] = useState(false)
    const onClick = () => {
        setIsViewing(true)
    }
    return (
        <StyledViewInRoomContainer>
            <StyledViewInRoom>
                <Button onClick={() => setIsViewing(true)} noStyle>
                    <EyeIcon />
                    {!noTitle && <Text>{`View in Room`}</Text>}
                </Button>
                {isViewing && <View 
                    position="fixed" 
                    height="100vh" 
                    width="100vw" 
                    top="0" 
                    left="0" 
                    background="#fff" 
                    zIndex="3"
                >
                    <View position="relative">
                        <View position="absolute" right="24px" top="24px" zIndex="4">
                            <Button noStyle onClick={() => setIsViewing(false)}>
                                <XICon />
                            </Button>
                        </View>
                        <View>
                            <GalleryTemplates payload={data} height="100vh" photoHeight="auto" photoTop={'-10%'}/>
                        </View>
                    </View>
                </View>}
            </StyledViewInRoom>
        </StyledViewInRoomContainer>
    )
}

export default ViewInRoom