import { useEffect, useState } from "react"
import Board from "../Board/Board"
import { StyledBoards } from "./styled"
import { View } from "../View"
import { Button } from "../Button"
import {ReactComponent as BackIcon} from '../../img/chevronLeft.svg'


const Boards = ({api}: any) => {
    const [board, setBoard] = useState<any>(null)
    const [filteredData, setFilteredData] = useState<any>(api.data)
    const [aspectRatios, setAspectRatios] = useState<any>({});
    

    const _getMedia = (data: any) => {
        if ((data?.media || []).length > 0) {
            return data.media[0].url
        } else if ((data?.posts || []).length > 0) {
            if (data.posts[0].media.length > 0) {
                return data.posts[0].media[0].url
            }
            
        }
        return null
    }

    useEffect(() => {
        // Calculate aspect ratios for each image dynamically
        const newAspectRatios: any = {};
    
        filteredData.forEach((tile: any) => {
          const img = new Image();
          
          img.src = board ? _getMedia(tile) || '' : _getMedia(tile.posts[0]) || '';
    
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            newAspectRatios[tile.id] = 250 / aspectRatio;
            setAspectRatios({ ...newAspectRatios });
          };
        });
    }, [filteredData]);

    const onClickBack = () => {
        setBoard(null)
        setFilteredData(api.data)
    }

    return (
        <View>
            <View>
                {board && <View padding="0px 0px 12px 0px">
                    <Button noStyle fontSize="13px" onClick={onClickBack}><BackIcon height={20} width={20} /> Back</Button>
                </View>}
            </View>
            <StyledBoards>
                {filteredData.filter((tile: any) => _getMedia(board ? tile : tile.posts[0])).map((tile: any) => {
                    const height = aspectRatios[tile.id];
                    return <Board data={tile} board={board} setBoard={setBoard} height={height} setFilteredData={setFilteredData} />
                })}
            </StyledBoards>
        </View>
    )
}

export default Boards