import { useState } from "react";
import GalleryTemplate from "./GalleryTemplate/GalleryTemplate";
import { StyledAvailableTemplates, StyledAvailableTemplatesContainer, StyledGalleryTemplates, StyledGalleryTemplatesContainer, StyledSelectedTemplate, StyledSelectedTemplateContainer } from "./styled"
import { Image } from "../Image";
import { ImageGallery } from "../ImageGallery";
import { HangArtPiece } from "../HangArtPiece";
import { View } from "../View";
import {ReactComponent as XIcon} from '../../img/x.svg'
import {ReactComponent as GearIcon} from '../../img/settings.svg'
import { Button } from "../Button";


export type TemplateProps = {
    template: string;
}


const GalleryTemplates = ({payload, setPayload, height, photoHeight, photoTop}: any) => {
    
    const TEMPLATES = [
        {
            template: 'benchWall.jpeg' 
        },
        {
            template: 'bwWood.jpeg' 
        },
        {
            template: 'chevronPattern.jpeg' 
        },
        {
            template: 'darkHardwood.jpeg' 
        },
        {
            template: 'lightHardwood.jpeg' 
        },
        {
            template: 'overheadLights.jpeg' 
        },
        {
            template: 'shadowWall.jpeg' 
        }
    ]

    const [selectedTemplate, setSelectedTemplate] = useState(TEMPLATES[0].template)
    const [showTemplates, setShowTemplates] = useState(false)

    return (
        <StyledGalleryTemplatesContainer>
            <StyledGalleryTemplates height={height}>
                <StyledSelectedTemplateContainer>
                    <StyledSelectedTemplate backgroundImage={selectedTemplate ? `/img/templates/gallery/${selectedTemplate}` : ''} height={height}>
                        <HangArtPiece top={photoTop}>
                            <ImageGallery data={payload} setPost={setPayload} height={photoHeight} objectFit="contain" isNoEffect onClick={() => {}} />
                        </HangArtPiece>
                    </StyledSelectedTemplate>
                </StyledSelectedTemplateContainer>
                <StyledAvailableTemplatesContainer showTemplates={showTemplates}>
                    <StyledAvailableTemplates total={TEMPLATES.length}>
                        {TEMPLATES.map((template: TemplateProps) => {
                            return (
                                <GalleryTemplate 
                                    {...template} 
                                    key={`template-${template}`} 
                                    setSelectedTemplate={setSelectedTemplate}
                                    selectedTemplate={selectedTemplate}
                                />
                            )
                        })}
                    </StyledAvailableTemplates>
                    <View position="fixed" bottom="124px" right="12px" display={showTemplates ? 'block': 'none'}>
                        <Button noStyle onClick={() => setShowTemplates(false)}><XIcon stroke="#fff" width={24} height={24}/></Button>
                    </View>
                </StyledAvailableTemplatesContainer>
            </StyledGalleryTemplates>
            <View position="absolute" bottom="16px" right="24px" display={!showTemplates ? 'block': 'none'}>
                <Button noStyle onClick={() => setShowTemplates(true)}><GearIcon height={24} width={24} /></Button>
            </View>
        </StyledGalleryTemplatesContainer>
    )
}

export default GalleryTemplates