import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { TrendingNow } from "../../../sections/TrendingNow"
import { StyledCard, StyledCards, StyledCareersContainer, StyledHeroContainer } from "./styled"
import {ReactComponent as PinIcon} from '../../../img/pin.svg'
import {ReactComponent as BuildingIcon} from '../../../img/building.svg'
import { useResponsiveStore } from "../../../stores/web.store"



type CardProps = {
    title: string;
    location: any;
    type: string;
    onClick: any;
}


const Card = ({title, location, type, onClick}: CardProps) => {
    return (
        <StyledCard>
            <View display="flex" flexDirection="column" gap="12px">
                <View>
                    <Text fontSize="16px" fontWeight="500">{title}</Text>
                </View>
                <View>
                    <View display="inline-flex" gap="24px" alignItems="center">
                        <View>
                            <View display="inline-flex" gap="6px" alignItems="center">
                                <PinIcon />
                                <Text>{`${location.city}, ${location.state}`}</Text>
                            </View>
                        </View>
                        <View>
                            <View display="inline-flex" gap="6px" alignItems="center">
                                <BuildingIcon />
                                <Text>{type}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View>
                    <Button
                        border="solid 1px #000"
                        background="#fff"
                        padding="6px 20px"
                        fontSize="13px"
                        borderRadius="24px"
                        onClick={onClick}
                    >Apply</Button>
                </View>
            </View>
        </StyledCard>
    )
}


const Careers = () => {
    const width = useResponsiveStore((state: any) => state.width)

    return (
        <DashboardLayout>
            <StyledHeroContainer>
                <View display="flex" alignItems="center" height={width > 768 ? "405px": '264px'}>
                    {/* <Text padding="0px 0px 0px 64px" fontSize="4vw" color="#fff" fontWeight="600">The Future of Art Collecting</Text> */}
                </View>
            </StyledHeroContainer>
            <StyledCareersContainer>
                <View padding={width > 768 ? '0px' : '12px 24px 0px 24px'}>
                    <View display="flex" flexDirection="column" gap="8px">
                        <View>
                            <Text width="100%" fontSize={width > 768 ? "32px" : "20px"} fontWeight="500">{"Join Us"}</Text>
                        </View>
                        <View>
                            <Text width="100%" fontSize={width > 768 ? "20px" : "16px"}>{"At Primary Yellow, we’re building the future of the art world, today. We’re re-imagining the way both new and experienced art collectors interact with artists, galleries, and each other. Our mission is to increase the amount of beauty in the world."}</Text>
                        </View>
                    </View>
                </View>
                <View padding={width > 768 ? '0px' : '0px 24px'}>
                    <View display="flex" flexDirection="column" gap="12px">
                        <View>
                            <Text width="100%" fontSize={width > 768 ? "28px" : "20px"} fontWeight="500" textAlign="center">{"Our Openings"}</Text>
                        </View>
                        <View>
                            <Text width="100%" fontSize={width > 768 ? "20px" : "16px"}>{"We’re always looking for exceptional talent, send us an email while we power up."}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <StyledCards>
                        <Card
                            title="Senior Specialist, Contemporary Art"
                            location={{city: 'New York', state: 'NY'}}
                            type={'Full-Time'}
                            onClick={() => {}}
                        />
                        <Card
                            title="Senior Specialist, Contemporary Art"
                            location={{city: 'New York', state: 'NY'}}
                            type={'Full-Time'}
                            onClick={() => {}}
                        />
                    </StyledCards>
                    
                </View>
                <View>
                    <FooterAd image={'/img/landing/about/LaunchingSoon.svg'} />
                </View>
            </StyledCareersContainer>
           
            
        </DashboardLayout>
    )
}

export default Careers