import { useEffect, useState } from "react"
import { ViewNavigator } from "../../sections/ViewNavigator"
import { SearchBar } from "../SearchBar"
import { Text } from "../Text"
import { View } from "../View"
import { StyledSearch, StyledSearchContainer, StyledSearchResult } from "./styled"
import { useSlideMenuStore, useViewNavigatorStore } from "../../stores/web.store"
import {ReactComponent as XIcon} from '../../img/x.svg'
import { Button } from "../Button"
import { ProfilePicture } from "../ProfilePicture"
import { RippleLoader } from "../Loader/Loaders/RippleLoader"
import { useMyRecentSearches } from "../../hooks/api.hook"
import api from "../../services/api.service"
import { useLocation } from "react-router-dom"
import Container from "../ProductDetail/Container/Container"


const VIEW_NAVIGATION_DATA = [
    {
        id: 'artist',
        title: 'Artists',
        image: ''
    },
    {
        id: 'gallery',
        title: 'Galleries',
        image: ''
    },
    {
        id: 'art_patron',
        title: 'Patrons',
        image: ''
    },
    {
        id: 'museum',
        title: 'Museums',
        image: ''
    }
]


const ArtistResult = ({data, query, isSearched}: any) => {
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const location = useLocation()
    const callback = async () => {
        if (isSearched) {
            api.post('/searches', {query, page_location: location.pathname, data, source: 'account', source_id: data.id, filter: {"role": 'artist'}})
        } else {
            resetSlideMenu()
        }
            
    }

    return (
        <ProfilePicture height="32px" width="32px" textAlign="left" account={data} callback={callback} photo username role noHover />
    )
}

const ArtworkResult = ({data}: any) => {
    return (
        <Button noStyle>
            <View>
                <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                    <ProfilePicture height="32px" width="32px" textAlign="left" account={data} username role/>
                </View>
            </View>
        </Button>
    )
}

const GalleryResult = ({data, query, isSearched}: any) => {
    const location = useLocation()
    const onClick = async () => {
        if (isSearched)
            api.post('/searches', {query, page_location: location.pathname, data, source: 'account', source_id: data.id, filter: {"role": 'gallery'}})
    }
    return (
        <Button noStyle onClick={onClick}>
            <View>
                <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                    <ProfilePicture height="32px" width="32px" textAlign="left" account={data} photo username role/>
                </View>
            </View>
        </Button>
    )
}

const PatronResult = ({data, query, isSearched}: any) => {
    const location = useLocation()
    const onClick = async () => {
        if (isSearched)
            api.post('/searches', {query, page_location: location.pathname, data, source: 'account', source_id: data.id, filter: {"role": 'art_patron'}})
    }
    return (
        <Button noStyle onClick={onClick}>
            <View>
                <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                    <ProfilePicture height="32px" width="32px" textAlign="left" account={data} photo username role/>
                </View>
            </View>
        </Button>
    )
}

const MuseumResult = ({data, query, isSearched}: any) => {
    const location = useLocation()
    const onClick = async () => {
        if (isSearched) 
            api.post('/searches', {query, page_location: location.pathname, data, source: 'account', source_id: data.id, filter: {"role": 'museum'}})
    }
    return (
        <Button noStyle onClick={onClick}>
            <View>
                <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                    <ProfilePicture height="32px" width="32px" textAlign="left" account={data} photo/>
                    <View display="flex" flexDirection="column" gap="4px">
                        <Text fontWeight="600" lineHeight="13px">{data.first_name} {data.last_name}</Text>
                        <Text textTransform="capitalize">{data.role}</Text>
                    </View>
                </View>
            </View>
        </Button>
    )
}

const SearchResult = ({data, isSearched, searchResults, setSearchResults, query}: any) => {
    const activeView = useViewNavigatorStore((state: any) => state.activeView)

    const onClick = () => {
        api.put(`/searches/${data.id}`, {
            state: 'dismissed'
        })
        setSearchResults(searchResults.filter((result: any) => result.id !== data.id))
    }
    return (
        <Button noStyle width="100%" isNoEffect>
            <StyledSearchResult>
                    <View display="inline-flex" width="100%">
                        <View width="80%" textAlign="left">
                            {activeView === 'artist' && <ArtistResult data={data.data || data} query={query} isSearched={isSearched}/>}
                            {activeView === 'gallery' && <GalleryResult data={data.data || data} query={query} isSearched={isSearched}/>}
                            {activeView === 'art_patron' && <PatronResult data={data.data || data} query={query} isSearched={isSearched}/>}
                            {activeView === 'museum' && <MuseumResult data={data.data || data} query={query} isSearched={isSearched}/>}
                        </View>
                        {!isSearched && <View flexGrow="1" textAlign="right">
                            <Button noStyle onClick={onClick}><XIcon height={20} width={20}/></Button>
                        </View>}
                    </View>
            </StyledSearchResult> 
       </Button>
    )
}

const Search = () => {
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const activeView = useViewNavigatorStore((state: any) => state.activeView)
    const [isSearched, setIsSearched] = useState<boolean>(false)

    const {data} = useMyRecentSearches()
    const [apiResp, setApiResp] = useState<any>({})
    const [searchResults, setSearchResults] = useState<any>([])
    const [query, setQuery] = useState('')
    const [searchFilter, setSearchFilter] = useState<any>({"role": 'artist'})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
            setSearchResults(data.data)
        }
    }, [data])

    useEffect(() => {
        const search = async () => {
            const resp = await api.post('/search/accounts', {query: {query: query, ...{...searchFilter.query, role: activeView}}})
            setSearchResults(resp.data)
        }

        setSearchFilter({"role": activeView})
        if (query.length > 0) {
            search()
        }
        
    }, [activeView])

    useEffect(() => {
        setActiveView('artist')
        setIsSearched(false)
    }, [])

    const onClickClearAll = () => {
        api.get(`/searches/clear-recent`)
        setSearchResults([])
    }

    if (!apiResp.is_loaded) return <RippleLoader />


    return (
        <StyledSearchContainer>
            <StyledSearch>
                <View padding="12px 24px 12px 12px">
                    <SearchBar 
                        placeholder="Search" 
                        setData={setSearchResults} 
                        data={searchResults} 
                        filter={searchFilter} 
                        setQuery={setQuery} 
                        setIsSearched={setIsSearched} 
                        resetData={apiResp.data}
                        background="#EFEFEF"
                        border="solid 1px #EFEFEF"
                    />
                </View>
                <View>
                    <ViewNavigator data={VIEW_NAVIGATION_DATA}/>
                </View>
                <View>
                    {!isSearched && <View padding="12px">
                        <View display="inline-flex" alignItems="center" width="100%">
                            <View width="50%">
                                <Text fontSize="18px" fontWeight="600">{`Recent`}</Text>
                            </View>
                            <View width="50%" textAlign="right">
                                {searchResults.length > 0 && <Button noStyle onClick={onClickClearAll} color="#0938F0">Clear all</Button>}
                            </View>
                        </View>
                    </View>}
                    <View overflowY="scroll" height="400px" width="100%" display="flex" flexDirection="column" gap="0px" padding={!isSearched ? "0px" : "12px 0px"}>
                        {searchResults.map((result: any) => {
                            if (result.data?.role || result.role === activeView) {
                                return (
                                    <SearchResult data={result} isSearched={isSearched} setSearchResults={setSearchResults} searchResults={searchResults} query={query}/>
                                )
                            }
                            return <View display="none" />
                            
                        })}
                    </View>
                </View>
            </StyledSearch>
        </StyledSearchContainer>
    )
}

export default Search