import styled from 'styled-components'


interface SignupCompleteContainerProps {

}


export const StyledSignupCompleteContainer = styled.div<SignupCompleteContainerProps>`
    
`

interface SignupCompleteProps {

}


export const StyledSignupComplete = styled.div<SignupCompleteProps>`
    padding: 12px;
`

interface WelcomeProps {

}


export const StyledWelcome = styled.div<WelcomeProps>`
    text-align: left;
    width: 100%;
`


interface ThankYouProps {

}


export const StyledThankYou = styled.div<ThankYouProps>`
    padding: 24px 0px;
`

