import { Follow } from "../../../components/Button/Follow"
import { Image } from "../../../components/Image"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { usePostStore } from "../../../stores/web.store"
import { StyledShowSection, StyledShowSectionContainer } from "./styled"


const ShowSection = () => {
    const post = usePostStore((state: any) => state.post)
    return (
        <StyledShowSectionContainer>
            <StyledShowSection>
                <View display="flex" alignItems="start" justifyContent="start">
                    <View>
                        <Text width="100%">Currently</Text>
                        <Text width="100%" fontWeight="500" fontSize="20px">Show Title</Text>
                        <View>
                            <Text 
                                width="100%" 
                                fontWeight="500" 
                                fontSize="20px"
                            >{`February 1`} - {`February 10`}, {`2024`}</Text>
                        </View>
                        <Text width="100%">{`City/Location`}</Text>
                        <Text width="100%">
                            {'Lorem ipsum dolor sit amet consectetur. Enim magna luctus morbi eu faucibus. Consectetur elit vitae varius montes purus. Condimentum viverra et odio malesuada. Amet sed congue dictumst tortor eros posuere lacinia.'}
                        </Text>
                        <View>
                            <Follow />
                        </View>
                    </View>
                </View>
                <View>
                    <Image src={post.media[0].url} width="100%" height="400px"/>
                </View>
            </StyledShowSection>
        </StyledShowSectionContainer>
    )
}

export default ShowSection