import { usePostStore } from "../../../stores/web.store"
import { toDate, toDateDiff } from "../../../utils/app.util"
import { ImageGallery } from "../../ImageGallery"
import { Post } from "../../Post"
import { ProfilePicture } from "../../ProfilePicture"
import { Text } from "../../Text"
import { View } from "../../View"
import { StyledFeatureItemContainer, StyledFeaturedItem } from "./styled"

type FeaturedItemProps = {
    data: any
    width?: string;
    height: string;
    onClick?: any;
}


const FeaturedItem = ({data, width, height, onClick}: FeaturedItemProps) => {
    const setPost = usePostStore((state: any) => state.setPost)
    return (
        <View>
            <View display="inline-flex" alignItems="start" width="100%" gap="30px">
                <View width="50%">
                    <ImageGallery data={data} height={420} setPost={setPost} onClick={() => onClick(data)} borderRadius={"10px"}/>
                </View>
                <View width="50%" position="relative" height="420px">
                    <View padding="0px 0px 32px 0px">
                        <Text fontSize="20px">Featured Exhibition</Text>
                    </View>
                    <View padding="0px 0px 32px 0px">
                        <Text fontSize="32px" fontWeight="500">{data.title}</Text>
                    </View> 
                    <View>
                        <Text color="#808080">{data.caption}</Text>
                    </View>
                    <View position="absolute" bottom="0" left="0">
                        <Text fontSize="20px">{toDate(data.metadata.start_datetime)}</Text>
                    </View>
                </View>
            </View>
        </View>
        // <StyledFeatureItemContainer>
        //     <View padding="0px 0px 20px 0px">
        //         <View display="inline-flex" alignItems="center" width="100%">
        //             <View width="90%">
        //                 <Text fontSize="20px" fontWeight="500" padding="0px 0px 4px 0px">{data.title}</Text>
        //                 <Text>{toDate(data.metadata.start_datetime)} to {toDate(data.metadata.end_datetime)}</Text>
        //             </View>
        //         </View>
        //     </View>
        //     <StyledFeaturedItem>
        //         <Post data={data} height={height} width={width} setPost={setPost} type={data.type} onClick={() => onClick(data)}/>
        //     </StyledFeaturedItem>
        // </StyledFeatureItemContainer>
    )
}

export default FeaturedItem