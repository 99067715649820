import styled from "styled-components";


interface ContainerProps {
    isActive?: boolean;
    isExpanded?: boolean;
}

export const StyledContainer = styled.div<ContainerProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background: #fff;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    

    @media (min-width: 768px) {
        left: 67px;
        width: calc(100% - 67px);
    }

    @media (min-width: 1600px) {
    }

    

    ${props => props.isActive && `
        height: 100%;
        overflow-y: scroll;
    `}

`