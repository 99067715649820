import styled from 'styled-components'


interface TopNavContainerProps {

}


export const StyledTopNavContainer = styled.div<TopNavContainerProps>`
    width: 100%;
    position: fixed;
    background: #fff;
`

interface TopNavWrapperProps {

}


export const StyledTopNavWrapper = styled.div<TopNavWrapperProps>`
    padding: 0px 32px;
`

interface TopNavProps {

}


export const StyledTopNav = styled.div<TopNavProps>`
    height: 84px;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-gap: 0;
`

interface TopNavLeftSectionProps {

}


export const StyledTopNavLeftSection = styled.div<TopNavLeftSectionProps>`
    display: table;
    width: 100%;
`

interface TopNavMiddleSectionProps {

}


export const StyledTopNavMiddleSection = styled.div<TopNavMiddleSectionProps>`
    text-align: center;
    display: table;
    width: 100%;
`

interface TopNavRightSectionProps {

}


export const StyledTopNavRightSection = styled.div<TopNavRightSectionProps>`
    text-align: right;
    display: table;
    width: 100%;
    position: relative;
`


interface TopNavVerticalAlignMiddleProps {

}


export const StyledTopNavVerticalAlign = styled.div<TopNavVerticalAlignMiddleProps>`
    display: table-cell;
    height: 64px;
    vertical-align: middle;
    width: 100%;
    position: relative;
`

interface MenuItemProps {
    textAlign?: string;
    padding?: string;
}


export const StyledMenuItem = styled.div<MenuItemProps>`
    padding: ${props => props.padding || '0px 12px'};
    text-align: ${props => props.textAlign || 'left'};
    margin: auto;
`

interface AccountMenuContainerProps {

}


export const StyledAccountMenuContainer = styled.div<AccountMenuContainerProps>`
    position: absolute;
    bottom: -48px;
    right: -12px;
    height: fit-content;
    width: fit-content;
    background: none;
    z-index: 2;
    text-align: left;

`

interface AccountMenuProps {

}


export const StyledAccountMenu = styled.div<AccountMenuProps>`
    padding: 12px;
`

