import styled from 'styled-components'


interface DashboardMenuContainerProps {
    width?: string;
    isExpanded?: boolean;
}


export const StyledDashabordMenuContainer = styled.div<DashboardMenuContainerProps>`
    width: 100%;;
    border-right: solid 1px #D7D7D7;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: #fff;
    transition: all 0.3s ease-in-out;

    @media (min-width: 768px) {
        width: ${props => props.width || props.isExpanded ? '200px' : '42px'};
        min-height: 100vh;
        top: 0;
        left: 0;
        padding: 12px;
    }

    @media (min-width: 1024px) {

    }
    

`

interface DashboardMenuProps {
   
}


export const StyledDashabordMenu = styled.div<DashboardMenuProps>`
    text-align: center;
    position: relative;
    width: 100%;
    overflow-x: scroll;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        width: unset;
        overflow-x: unset;
    }

    @media (min-width: 1024px) {

    }
`

interface DashboardMenuSectionProps {
    position?: string;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    padding?: string;
    height?: string;
    overflowY?: string;
    isExpanded?: boolean;
}


export const StyledDashboardMenuSection = styled.div<DashboardMenuSectionProps>`
    
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        position: ${props => props.position || 'relative'};
        top: ${props => props.top || 'unset'};
        bottom: ${props => props.bottom || 'unset'};
        left: ${props => props.left || 'unset'};
        right: ${props => props.right || 'unset'};
        padding: ${props => props.padding || '0'};
        height: ${props => props.height || 'auto'};
        overflow-y: ${props => props.overflowY || 'hidden'};
        overflow-x: hidden;
        width: 100%;
        text-align: ${props => props.isExpanded ? 'left': 'center'};
        gap: 8px;
        flex-direction: column;
        
    }

    @media (min-width: 1024px) {

    }

    
`

interface DashboardMenuItemContainerProps {
    padding?: string;
    isExpanded?: boolean;
    isActive?: boolean;
    isHover?: boolean;
    margin?: string;
}


export const StyledDashboardMenuItemContainer = styled.div<DashboardMenuItemContainerProps>`

    position: relative;
    background: #fff;
    border-radius: 4px;

    ${props => props.isHover && `
        background: #EFEFEF;
    `}

    ${props => props.isActive && `
        // background: #EFEFEF;
    `}

    @media (min-width: 768px) {
        
        width: 100%;
        
        ${props => props.isExpanded && `
            text-align: left;
            width: 100%;
        `}
        
    }

    @media (min-width: 1024px) {

    }

    
`

interface DashboardMenuItemProps {
    padding?: string;
    isExpanded?: boolean;
    isHover?: boolean;
    margin?: string;
}


export const StyledDashboardMenuItem = styled.div<DashboardMenuItemProps>`
    height: fit-content;
    padding: 8px 0px 2px 0px;

    @media (min-width: 768px) {
        // height: 64px;
        padding: 0px;
        
    }

    @media (min-width: 1024px) {

    }
    
`

interface MenuItemHoverContainerProps {
    isHover?: boolean;
}


export const StyledMenuItemHoverContainer = styled.div<MenuItemHoverContainerProps>`
    position: absolute;
    right: -42px;
    top: 14px;
    overflow: hidden;
    width: 0px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    ${props => props.isHover && `
        width: fit-content;
        max-width: 200px;
    `};
`

interface MenuItemHoverProps {

}


export const StyledMenuItemHover = styled.div<MenuItemHoverProps>`
    background: #fff;
    padding: 8px 20px;
`

interface MenuIconProps {
    isExpanded?: boolean;
}


export const StyledMenuIcon = styled.div<MenuIconProps>`
    @media (min-width: 768px) {
        display: ${props => props.isExpanded ? 'inline-flex' : 'block'};
        align-items: center;
        width: 100%;
        gap: 12px;
        // position: absolute;
        // left: 12px;
        // top: 12px;
        
    }

    @media (min-width: 1024px) {

    }
`

interface MenuTextProps {
    isActive?: boolean;
    padding? : string;
}


export const StyledMenuText = styled.div<MenuTextProps>`
    display: none;

    ${props => props.isActive && `
        font-weight: 600;
    `}

    @media (min-width: 768px) {
        display: block;
        // position: absolute;
        // left: 64px;
        // top: 24px;
        font-size: 18px;
        padding: ${props => props.padding || '0px'};
        
    }

    @media (min-width: 1024px) {

    }
`

interface ExpandMenuContainerProps {
    isActive: boolean;
}


export const StyledExpandMenuContainer = styled.div<ExpandMenuContainerProps>`
    position: absolute;
    left: 0px;
    bottom: 82px;
    font-size: 18px;
    border-radius: 8px;
    height: 0px;
    width: fit-content;
    margin: auto;
    background: rgba(199, 199, 199, 0.75);
    -webkit-box-shadow: 0px 2px 16px 0px rgba(199,199,199,1);
    -moz-box-shadow: 0px 2px 16px 0px rgba(199,199,199,1);
    box-shadow: 0px 2px 16px 0px rgba(199,199,199,1);
    overflow: hidden;

    ${props => props.isActive && `
        height: fit-content;
    `}
`

interface ExpandMenuProps {

}


export const StyledExpandMenu = styled.div<ExpandMenuProps>`
    background: #fff;
`
