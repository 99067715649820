import styled from "styled-components";


interface ShareContainerProps {

}

export const StyledShareContainer = styled.div<ShareContainerProps>`
    position: relative;
`

interface ShareProps {

}

export const StyledShare= styled.div<ShareProps>`

`