import { Children, cloneElement, useEffect, useState } from "react";
import { useResponsiveStore } from "../../stores/web.store";


type ImageProps = {
    children: any;
    imageRef?: any;
    width?: string;
    height?: string;
}

const ScaledImage = ({children, imageRef, width, height}: ImageProps) => {
    const responsiveWidth = useResponsiveStore((state: any) => state.width)
    const [imageStyles, setImageStyles] = useState<{ width: string; height: string }>({
        width: 'auto',
        height: 'auto'
    });

    useEffect(() => {
        const adjustImageProportions = () => {
            const image = imageRef.current;
            if (image) {
                const { naturalWidth, naturalHeight } = image;

                // Check if the image is taller than it is wide
                if (naturalHeight > naturalWidth) {
                    setImageStyles({
                        width: responsiveWidth > 768 ? 'auto' : '100%',  // Width adjusts proportionally
                        height: '100%'   // Height takes 100% of container
                    });
                } else {
                    // Image is wider than it is tall
                    setImageStyles({
                        width: '100%',   // Width takes 100% of container
                        height: 'auto'   // Height adjusts proportionally
                    });
                }
            }
        };

        // Call adjustImageProportions when image loads or on resize
        const handleResize = () => adjustImageProportions();
        window.addEventListener('resize', handleResize);

        if (imageRef.current?.complete) {
            adjustImageProportions();
        } else {
            imageRef.current?.addEventListener('load', adjustImageProportions);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            imageRef.current?.removeEventListener('load', adjustImageProportions);
        };
    }, []);

    const childrenWithProps = Children.map(children, (child: any) =>
        cloneElement(child, { imageRef, ...imageStyles })
    );
    

    return (
        <div>
            {childrenWithProps}
        </div>
    )
}

export default ScaledImage