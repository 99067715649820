import styled from 'styled-components'



interface EmptyPostsContainerProps {

}


export const StyledEmptyPostsContainer  = styled.div<EmptyPostsContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
`

interface EmptyPostsProps {

}

export const StyledEmptyPosts = styled.div<EmptyPostsProps>`
    text-align: center;
`

interface EmptyPostsIconContainerProps {

}

export const StyledEmptyPostsIconContainer = styled.div<EmptyPostsIconContainerProps>`
    padding: 24px 0px;
`

interface EmptyPostsIconProps {

}

export const StyledEmptyPostsIcon = styled.div<EmptyPostsIconProps>`
    padding: 40px;
    border: solid 4px #808080;
    border-radius: 120px;
    width: 120px;
    margin: auto;
`
