import { QuickFilter } from "../QuickFilter"


type PastShowsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const PastShows = ({selectedFilter, setSelectedFilter}: PastShowsProps) => {
    const onClick = () => {
        setSelectedFilter('past_show')
    }

    return (
        <QuickFilter title="Past Shows" onClick={onClick} isSelected={selectedFilter === 'past_show'}/>
    )
}

export default PastShows