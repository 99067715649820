import styled from 'styled-components'


interface GalleryTemplatesContainerProps {

}


export const StyledGalleryTemplatesContainer = styled.div<GalleryTemplatesContainerProps>`

`

interface GalleryTemplatesProps {
    height?: string;
}


export const StyledGalleryTemplates = styled.div<GalleryTemplatesProps>`
    height: ${props => props.height || '600px'};
    overflow-y: scroll;
`

interface SelectedTemplateContainerProps {

}


export const StyledSelectedTemplateContainer = styled.div<SelectedTemplateContainerProps>`
    position: relative;
    // -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px 0px inset;
    // -moz-box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px 0px inset;
    // box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px 0px inset;
    // padding: 48px;
`

interface SelectedTemplateProps {
    backgroundImage?: string;
    height?: string;
}


export const StyledSelectedTemplate = styled.div<SelectedTemplateProps>`
    width: 100%;
    background: #F1F1F1;
    height: ${props => props.height || '600px'};

    ${props => props.backgroundImage && `
        background-image: url("${props.backgroundImage}");
        // min-height: 1000px;
        
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        // -webkit-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
        // -moz-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
        // box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    `}
`

interface AvailableTemplatesContainerProps {
    showTemplates?: boolean;
}


export const StyledAvailableTemplatesContainer = styled.div<AvailableTemplatesContainerProps>`
    padding: 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.25);
    width: 100%;
    height: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    ${props => props.showTemplates && `
        height: fit-content;
        max-height: 200px;
        overflow-x: scroll;
        padding: 24px 0px;
    `}
`

interface AvailableTemplatesProps {
    total?: number
}


export const StyledAvailableTemplates = styled.div<AvailableTemplatesProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.total}, 1fr);
    grid-gap: 0px;
    width: ${props => (props.total || 4) / 4 * 100}%;
`