import styled from "styled-components";


interface ShareContainerProps {
    left: number;
}

export const StyledShareContainer = styled.div<ShareContainerProps>`

`

interface ShareProps {

}

export const StyledShare = styled.div<ShareProps>`

`