import styled from 'styled-components'


interface FeedContainerProps {

}

export const StyledFeedContainer = styled.div<FeedContainerProps>`
    position: relative;
`

interface FeedProps {

}

export const StyledFeed = styled.div<FeedProps>`
    height: calc(100vh - 48px);
    overflow-y: scroll;
`


