import { useEffect, useState } from "react"
import { Checkbox } from "../../components/Checkbox"
import { SearchBar } from "../../components/SearchBar"
import { Text } from "../../components/Text"
import { View } from "../../components/View"
import { FilterItem } from "./FilterItem"
import { Button } from "../../components/Button"

import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { Input } from "../../components/Input"
import { StyledPriceRange } from "../../components/Filters/PriceRange/styled"
import { useAccountStore, useFiltersStore, useSlideMenuStore } from "../../stores/web.store"
import { useFilters } from "../../hooks/api.hook"
import { toDBValue } from "../../utils/app.util"

import {ReactComponent as XIcon} from '../../img/x.svg'

const CheckboxItem = ({data, onChange}: any) => {
    return (
        <View padding="0px 0px 12px 0px">
            <View display="inline-flex" alignItems="center" gap="12px">
                <Checkbox 
                    onChange={(e: any) => onChange(e, data)} 
                    checked={data.isChecked}
                />
                <Text fontSize="16px">{data.name}</Text>
            </View>
        </View>
    )
}

const ListItems = ({data, onChange}: any) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [displayData, setDisplayData] = useState(data)

    useEffect(() => {
        setDisplayData(data)
    }, [data])

    useEffect(() => {
        if (isExpanded) {
            setDisplayData(data)
        } else {
            setDisplayData(data.slice(0, 5))
        }
        
    }, [isExpanded])

    return (
        <View>
            {displayData.map((item: any) => {
                return (
                    <CheckboxItem data={item} onChange={(e: any) => onChange(e, item)}/>
                )
            })}
            {data.length > 5 && <Button noStyle color={`#0938F0`} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? `Show Less`: `Show More`}</Button>}
        </View>
    )
}




const ArtistsFilter = ({data}: any) => {
    const filterKey = 'owner_id'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [isExpanded, setIsExpanded] = useState(false)
    const [displayData, setDisplayData] = useState(
        data.map((obj: any) => {
            if (filterStore.filters[filterKey]?.includes(obj.id)) {
                return {...obj, isChecked: true}
            }
            return obj
        })
    )

    useEffect(() => {
        if (isExpanded) {
            setDisplayData(data)
        } else {
            setDisplayData(data.slice(0, 5))
        }
        
    }, [isExpanded])

    const onChange = (e: any, item: any) => {
        
        const filterValue = item.id

        let newFilter = [] as any
        if (!item.isChecked) {
            if (!(filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = [...(filterStore.filters[filterKey] || []), filterValue]
                setDisplayData(displayData.map((obj: any) => {
                    if (obj.id === item.id) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            }
        } else {
            if ((filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = (filterStore.filters[filterKey] || []).filter((val: any) => val !== filterValue)
            }
            setDisplayData(displayData.map((obj: any) => {
                if (obj.id === item.id) {
                    return {...obj, isChecked: false}
                }
                return obj
            }))
        }
        

        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: newFilter
        }})
    }

    return (
        <FilterItem name={'Artists'}>
            <View padding="12px 0px 0px 0px">
                <View>
                    {displayData.map((item: any) => {
                        return (
                            <View padding="0px 0px 12px 0px">
                                <View display="inline-flex" alignItems="center" gap="12px">
                                    <Checkbox onChange={(e: any) => onChange(e, item)} checked={item.isChecked}/>
                                    <Text fontSize="16px">{item.first_name} {item.last_name}</Text>
                                </View>
                            </View>
                        )
                    })}
                    {data.length > 5 && <Button noStyle color={`#0938F0`} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? `Show Less`: `Show More`}</Button>}
                </View>
            </View>
        </FilterItem>
    )
}

const RarityFilter = () => {
    const filterKey = 'metadata.rarity'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 

    const [data, setData] = useState<any>([
        {name: 'Unique', isChecked: filterStore.filters[filterKey]?.includes('unique')},
        {name: 'Limited Edition', isChecked: filterStore.filters[filterKey]?.includes('limited_edition')},
        {name: 'Open Edition', isChecked: filterStore.filters[filterKey]?.includes('open_edition')},
        {name: 'Unknown Edition', isChecked: filterStore.filters[filterKey]?.includes('unknown_edition')},
    ])

    const onChange = (e: any, item: any) => {
        const filterValue = item.name.toLowerCase().replace(/ /g, '_')

        let newFilter = [] as any
        if (!item.isChecked) {
            if (!(filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = [...(filterStore.filters[filterKey] || []), filterValue]
                setData(data.map((obj: any) => {
                    if (obj.name === item.name) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            }
        } else {
            if ((filterStore.filters[filterKey] || []).includes(filterValue)) {
                if (typeof filterStore.filters[filterKey] === 'string' || filterStore.filters[filterKey] instanceof String) {
                    newFilter = []
                } else {
                    newFilter = (filterStore.filters[filterKey] || []).filter((val: any) => val !== filterValue)
                }
                
            }
            setData(data.map((obj: any) => {
                if (obj.name === item.name) {
                    return {...obj, isChecked: false}
                }
                return obj
            }))
        }
        
        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: newFilter
        }})
    }

    return (
        <FilterItem name={'Rarity'}>
            <View padding="12px 0px 0px 0px">
                <ListItems data={data} onChange={onChange}/>
            </View>
        </FilterItem>
    )
}

const MediumFilter = () => {
    const filterKey = 'metadata.medium'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 

    const Painting = 'Painting'
    const Photography = 'Photography'
    const Sculpture = 'Sculpture'
    const Prints = 'Prints'
    const WorkOnPaper = 'Work on Paper'
    const NFT = 'NFT'
    const Design = 'Design'
    const Drawing = 'Drawing'
    const Jewelry = 'Jewelry'
    const Installation = 'Installation'
    const PerformanceArt = 'Performance Art'
    const Reproduction = 'Reproduction'
    


    const [data, setData] = useState<any>([
        {name: Painting, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Painting))},
        {name: Photography, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Photography))},
        {name: Sculpture, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Sculpture))},
        {name: Prints, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Prints))},
        {name: WorkOnPaper, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(WorkOnPaper))},
        {name: NFT, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(NFT))},
        {name: Design, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Design))},
        {name: Drawing, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Drawing))},
        {name: Installation, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Installation))},
        {name: 'Film/Video', value: 'video', isChecked: filterStore.filters[filterKey]?.includes('video')},
        {name: Jewelry, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Jewelry))},
        {name: PerformanceArt, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(PerformanceArt))},
        {name: Reproduction, isChecked: filterStore.filters[filterKey]?.includes(toDBValue(Reproduction))},
    ])

    const onChange = (e: any, item: any) => {
        
        const filterValue = toDBValue(item.name)

        let newFilter = [] as any
        if (!item.isChecked) {
            if (!(filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = [...(filterStore.filters[filterKey] || []), filterValue]
                setData(data.map((obj: any) => {
                    if (obj.name === item.name) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            }
        } else {
            

            if ((filterStore.filters[filterKey] || []).includes(filterValue)) {
                if (typeof filterStore.filters[filterKey] === 'string' || filterStore.filters[filterKey] instanceof String) {
                    newFilter = []
                } else {
                    newFilter = (filterStore.filters[filterKey] || []).filter((val: any) => val !== filterValue)
                }
            }
            setData(data.map((obj: any) => {
                if (obj.name === item.name) {
                    return {...obj, isChecked: false}
                }
                return obj
            }))
        }
        

        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: newFilter
        }})
    }

    return (
        <FilterItem name={'Medium'}>
            <View padding="12px 0px 0px 0px">
                <ListItems data={data} onChange={onChange}/>
            </View>
        </FilterItem>
    )
}

const PriceFilter = () => {
    const filterKey = 'metadata.price'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [value, setValue] = useState<any>(null);

    useEffect(() => {
        setValue(filterStore.filters[filterKey]?.value || [0, 100000])
    }, [])
    
    const updateFilterStore = async (value: any) => {
        
        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: {condition: "range", value}
        }})
    }

    const onSlide = async (e: any) => {
        setValue(e)
        updateFilterStore(e)
    }

    const onChangeMin = (e: any) => {
        setValue([parseInt(e.target.value), value[1]])
        updateFilterStore([parseInt(e.target.value), value[1]])
    }

    const onChangeMax = (e: any) => {
        setValue([value[0], parseInt(e.target.value)])
        updateFilterStore([value[0], parseInt(e.target.value)])
    }

    return (
        <FilterItem name={'Price'}>
            {value !== null && <View padding="12px 0px 0px 0px">
                <StyledPriceRange>
                    <RangeSlider min={0} max={100000} step={100} value={value} onInput={onSlide} />
                </StyledPriceRange>
                <View padding="12px 0px">
                    <View display="inline-flex" alignItems="center" width="calc(100% - 4px)" gap="24px">
                        <View width="50%" padding="0px 0px 0px 4px">
                            <Input type="number" value={value[0]} onChange={onChangeMin} width="100%" placeholder="Min"/>
                        </View>
                        <View width="50%">
                            <Input type="number" value={value[1]} onChange={onChangeMax} width="100%" placeholder="Max"/>
                        </View>
                    </View>
                </View>
            </View>}
        </FilterItem>
    )
}

const SizeRadio = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 

    const CMDATA = [
        {name: 'Small (under 40cm)'},
        {name: 'Medium (40 to 100cm)'},
        {name: 'Large (over 100cm)'}
    ]

    const INDATA = [
        {name: 'Small (under 16in)'},
        {name: 'Medium (16in to 40in)'},
        {name: 'Large (over 40in)'}
    ]

    const [value, setValue] = useState('cm')
    const [data, setData] = useState(CMDATA)
    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => {
        if (value === 'in') {
            setData(INDATA)
        } else {
            setData(CMDATA)
        }
    }, [value])

    const onChange = (v: string) => {
        setValue(v)
    }

    const onChangeSize = (e: any, data: any) => {
        // const filterKey = 'metadata.dimensions'
        // const filterValue = data.name.toLowerCase().replace(/ /g, '_')

        // let newFilter = [] as any
        // if (e.target.checked) {
        //     if (!(filterStore.filters[filterKey] || []).includes(filterValue)) {
        //         newFilter = [...(filterStore.filters[filterKey] || []), filterValue]
        //     }
        // } else {
        //     if ((filterStore.filters[filterKey] || []).includes(filterValue)) {
        //         newFilter = (filterStore.filters[filterKey] || []).filter((val: any) => val !== filterValue)
        //     }
        // }
        

        // setFilterStore({...filterStore, loaded: true, filters: {
        //     ...filterStore.filters,
        //     [filterKey]: newFilter
        // }})
    }

    return (
        <View padding="12px 0px 0px 0px">
            <View display="inline-flex" alignItems="center" width="100%">
                <View width="50%">
                    <View display="inline-flex" alignItems="center">
                        <Input type="radio" value="cm" name="metric" onChange={() => onChange('cm')} checked={value === 'cm'} />
                        <Text fontSize="16px">{`cm`}</Text>
                    </View>
                </View>
                <View width="50%">
                    <View display="inline-flex" alignItems="center">
                        <Input type="radio" value="in" name="metric" onChange={() => onChange('in')} checked={value === 'in'} />
                        <Text fontSize="16px">{`in`}</Text>
                    </View>
                </View>
            </View>
            <View padding="12px 0px 0px 0px">
                <ListItems data={data} onChange={onChangeSize}/>
            </View>
            <View padding="12px 0px">
                <Button 
                    noStyle onClick={() => setIsExpanded(!isExpanded)}
                >{isExpanded ? `Hide custom size` : `Show custom size`}</Button>
            </View>
            {isExpanded && <View>
                <View>
                    <View>
                        <Text padding="0px 0px 4px 4px">{`Width`}</Text>
                        <View display="inline-flex" alignItems="center" width="calc(100% - 4px)" gap="24px">
                            <View width="50%" padding="0px 0px 0px 4px">
                                <Input width="100%"/>
                            </View>
                            <View width="50%">
                                <Input width="100%"/>
                            </View>
                        </View>
                    </View>
                    <View padding="12px 0px">
                        <Text padding="0px 0px 4px 4px">{`Height`}</Text>
                        <View display="inline-flex" alignItems="center" width="calc(100% - 4px)" gap="24px">
                            <View width="50%" padding="0px 0px 0px 4px">
                                <Input width="100%"/>
                            </View>
                            <View width="50%">
                                <Input width="100%"/>
                            </View>
                        </View>
                    </View>
                </View>
                <View padding="12px 0px 0px 0px">
                    <Button color="#fff" width="100%" borderRadius="60px" height="60px" fontSize="16px">Set Size</Button>
                </View>
            </View>}
        </View>
    )
}

const SizeFilter = () => {
    return (
        <FilterItem name={'Size'}>
            <View>
                <Text>{`This is based on the artworks average dimension.`}</Text>
            </View>
            <View>
                <SizeRadio />
            </View>
        </FilterItem>
    )
}

const PurchaseOptionFilter = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 

    const Purchase = 'Purchase'
    const MakeOffer = 'Make Offer'
    const Bid = 'Bid'
    const ContactGallery = 'Contact Gallery'

    const isForSaleFilterKey = 'metadata.is_for_sale'
    const canMakeOfferFilterKey = 'metadata.can_make_offer'
    const bidFilterKey = 'state'
    const contactGalleryFilterKey = 'metadata.can_inquire'

    const [data, setData] = useState<any>([
        {name: Purchase, isChecked: filterStore.filters[isForSaleFilterKey] || false},
        {name: MakeOffer, isChecked: filterStore.filters[canMakeOfferFilterKey] || false},
        {name: Bid, isChecked: filterStore.filters[bidFilterKey]?.includes('auction')},
        {name: ContactGallery, isChecked: filterStore.filters[contactGalleryFilterKey] || false},
    ])

    const onChange = (e: any, item: any) => {
        let newFilter = {}
        if (item.name === Purchase) {
            if (!(isForSaleFilterKey in (filterStore.filters || {}))) {
                newFilter = {...filterStore.filters, [isForSaleFilterKey]: true}
                setFilterStore({...filterStore, loaded: true, filters: {
                    ...newFilter
                }})
                setData(data.map((obj: any) => {
                    if (obj.name === item.name) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            } else {
                if (isForSaleFilterKey in (filterStore.filters || {})) {
                    delete filterStore.filters[isForSaleFilterKey]
                    setFilterStore({...filterStore, loaded: true, filters: {
                        ...filterStore.filters
                    }})

                    setData(data.map((obj: any) => {
                        if (obj.name === item.name) {
                            return {...obj, isChecked: false}
                        }
                        return obj
                    }))
                }
            }
        }

        if (item.name === MakeOffer) {
            if (!(canMakeOfferFilterKey in (filterStore.filters || {}))) {
                newFilter = {...filterStore.filters, [canMakeOfferFilterKey]: true}
                setFilterStore({...filterStore, loaded: true, filters: {
                    ...newFilter
                }})
                setData(data.map((obj: any) => {
                    if (obj.name === item.name) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            } else {
                if (canMakeOfferFilterKey in (filterStore.filters || {})) {
                    delete filterStore.filters[canMakeOfferFilterKey]
                    setFilterStore({...filterStore, loaded: true, filters: {
                        ...filterStore.filters
                    }})

                    setData(data.map((obj: any) => {
                        if (obj.name === item.name) {
                            return {...obj, isChecked: false}
                        }
                        return obj
                    }))
                }
                
            }

        }

        if (item.name === Bid) {
            const filterValue = 'auction'

            let newFilter = [] as any
            if (!item.isChecked) {
                if (!(filterStore.filters[bidFilterKey] || []).includes(filterValue)) {
                    newFilter = [...(filterStore.filters[bidFilterKey] || []), filterValue]
                    setData(data.map((obj: any) => {
                        if (obj.name === item.name) {
                            return {...obj, isChecked: true}
                        }
                        return obj
                    }))
                }
            } else {
                if ((filterStore.filters[bidFilterKey] || []).includes(filterValue)) {
                    newFilter = (filterStore.filters[bidFilterKey] || []).filter((val: any) => val !== filterValue)
                    setData(data.map((obj: any) => {
                        if (obj.name === item.name) {
                            return {...obj, isChecked: false}
                        }
                        return obj
                    }))
                }
            }
            setFilterStore({...filterStore, loaded: true, filters: {
                ...filterStore.filters,
                [bidFilterKey]: newFilter
            }})
        }

        if (item.name === ContactGallery) {
            if (!(contactGalleryFilterKey in (filterStore.filters || {}))) {
                newFilter = {...filterStore.filters, [contactGalleryFilterKey]: true}
                setFilterStore({...filterStore, loaded: true, filters: {
                    ... newFilter
                }})
                setData(data.map((obj: any) => {
                    if (obj.name === item.name) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            } else {
                if (contactGalleryFilterKey in (filterStore.filters || {})) {
                    newFilter = delete filterStore.filters[contactGalleryFilterKey]
                    setFilterStore({...filterStore, loaded: true, filters: {
                        ...filterStore.filters
                    }})
                    setData(data.map((obj: any) => {
                        if (obj.name === item.name) {
                            return {...obj, isChecked: false}
                        }
                        return obj
                    }))
                }
            }
            
        }
        
    }

    console.log(filterStore)

    return (
        <FilterItem name={'Purchase Options'}>
            <View padding="12px 0px 0px 0px">
                <ListItems data={data} onChange={onChange}/>
            </View>
        </FilterItem>
    )
}

const MaterialFilter = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const DATA = [
        {name: 'Acrylic'},
        {name: 'Aluminum'},
        {name: 'Aquatint'},
        {name: 'Arches paper'},
        {name: 'Acrylic'},
        {name: 'Aluminum'},
        {name: 'Aquatint'},
        {name: 'Arches paper'},
    ]
    return (
        <FilterItem name={'Material'}>
            <View>
                <SearchBar />
            </View>
            <View padding="12px 0px 0px 0px">
                <ListItems data={DATA} />
            </View>
        </FilterItem>
    )
}

const ArtistEthnicityFilter = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const DATA = [
        {name: 'Acrylic'},
        {name: 'Aluminum'},
        {name: 'Aquatint'},
        {name: 'Arches paper'},
        {name: 'Acrylic'},
        {name: 'Aluminum'},
        {name: 'Aquatint'},
        {name: 'Arches paper'},
    ]
    return (
        <FilterItem name={'Artist Ethnicity'}>
            <View>
                <SearchBar />
            </View>
            <View padding="12px 0px 0px 0px">
                <ListItems data={DATA} />
            </View>
        </FilterItem>
    )
}

const ArtworkLocationFilter = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const DATA = [
        {name: 'Acrylic'},
        {name: 'Aluminum'},
        {name: 'Aquatint'},
        {name: 'Arches paper'},
        {name: 'Acrylic'},
        {name: 'Aluminum'},
        {name: 'Aquatint'},
        {name: 'Arches paper'},
    ]
    return (
        <FilterItem name={'Artwork Location'}>
            <View>
                <SearchBar placeholder="Artwork Location"/>
            </View>
            <View padding="12px 0px 0px 0px">
                <ListItems data={DATA} />
            </View>
        </FilterItem>
    )
}

const TimePeriodFilter = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const DATA = [
        {name: '2020s'},
        {name: '2010s'},
        {name: '2000s'},
        {name: '1990s'},
        {name: '1980s'},
        {name: '1970s'},
        {name: '1960s'},
        {name: '1950s'},
    ]
    return (
        <FilterItem name={'Time Period'}>
            <View padding="12px 0px 0px 0px">
                <ListItems data={DATA} />
            </View>
        </FilterItem>
    )
}

const ColorFilter = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const DATA = [
        {name: 'Red'},
        {name: 'Orange'},
        {name: 'Purple'},
        {name: 'Yellow'},
        {name: 'Green'},
        {name: 'Blue'}
    ]
    return (
        <FilterItem name={'Color'}>
            <View padding="12px 0px 0px 0px">
                <ListItems data={DATA} />
            </View>
        </FilterItem>
    )
}

const MuseumFilter = ({data}: any) => {
    const filterKey = 'owner_id'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [isExpanded, setIsExpanded] = useState(false)
    
    const [displayData, setDisplayData] = useState(
        data.map((obj: any) => {
            if (filterStore.filters[filterKey]?.includes(obj.id)) {
                return {...obj, isChecked: true}
            }
            return obj
        })
    )

    useEffect(() => {
        if (isExpanded) {
            setDisplayData(data)
        } else {
            setDisplayData(data.slice(0, 5))
        }
        
    }, [isExpanded])

    const onChange = (e: any, item: any) => {
        
        const filterValue = item.id

        let newFilter = [] as any
        if (!item.isChecked) {
            if (!(filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = [...(filterStore.filters[filterKey] || []), filterValue]
                setDisplayData(displayData.map((obj: any) => {
                    if (obj.id === item.id) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            }
        } else {
            if ((filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = (filterStore.filters[filterKey] || []).filter((val: any) => val !== filterValue)
            }
            setDisplayData(displayData.map((obj: any) => {
                if (obj.id === item.id) {
                    return {...obj, isChecked: false}
                }
                return obj
            }))
        }
        

        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: newFilter
        }})
    }

    return (
        <FilterItem name={'Museums'}>
            <View padding="12px 0px 0px 0px">
                <View>
                    {displayData.map((item: any) => {
                        return (
                            <View padding="0px 0px 12px 0px">
                                <View display="inline-flex" alignItems="center" gap="12px">
                                    <Checkbox onChange={(e: any) => onChange(e, item)} checked={item.isChecked}/>
                                    <Text fontSize="16px">{item.first_name} {item.last_name}</Text>
                                </View>
                            </View>
                        )
                    })}
                    {data.length > 5 && <Button noStyle color={`#0938F0`} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? `Show Less`: `Show More`}</Button>}
                </View>
            </View>
        </FilterItem>
    )
}

const GalleryFilter = ({data}: any) => {
    const filterKey = 'owner_id'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [isExpanded, setIsExpanded] = useState(false)

    const [displayData, setDisplayData] = useState(
        data.map((obj: any) => {
            if (filterStore.filters[filterKey]?.includes(obj.id)) {
                return {...obj, isChecked: true}
            }
            return obj
        })
    )

    useEffect(() => {
        if (isExpanded) {
            setDisplayData(data)
        } else {
            setDisplayData(data.slice(0, 5))
        }
        
    }, [isExpanded])

    const onChange = (e: any, item: any) => {
        
        const filterValue = item.id

        let newFilter = [] as any
        if (!item.isChecked) {
            if (!(filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = [...(filterStore.filters[filterKey] || []), filterValue]
                setDisplayData(displayData.map((obj: any) => {
                    if (obj.id === item.id) {
                        return {...obj, isChecked: true}
                    }
                    return obj
                }))
            }
        } else {
            if ((filterStore.filters[filterKey] || []).includes(filterValue)) {
                newFilter = (filterStore.filters[filterKey] || []).filter((val: any) => val !== filterValue)
            }
            setDisplayData(displayData.map((obj: any) => {
                if (obj.id === item.id) {
                    return {...obj, isChecked: false}
                }
                return obj
            }))
        }
        

        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: newFilter
        }})
    }

    return (
        <FilterItem name={'Galleries'}>
            <View padding="12px 0px 0px 0px">
                <View>
                    {displayData.map((item: any) => {
                        return (
                            <View padding="0px 0px 12px 0px">
                                <View display="inline-flex" alignItems="center" gap="12px">
                                    <Checkbox onChange={(e: any) => onChange(e, item)} checked={item.isChecked}/>
                                    <Text fontSize="16px">{item.first_name} {item.last_name}</Text>
                                </View>
                            </View>
                        )
                    })}
                    {data.length > 5 && <Button noStyle color={`#0938F0`} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? `Show Less`: `Show More`}</Button>}
                </View>
            </View>
        </FilterItem>
    )
}

const Filters = () => {
    const account = useAccountStore((state: any) => state.account)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const [api, setApi] = useState<any>({})

    const {data} = useFilters()

    useEffect(() => {
        if (data && data.is_loaded) {
            setApi(data)
        }
    }, [data])

    const onClickClose = () => {
        resetSlideMenu()
    }

    if (account === null) return <div />

    
    if (!api.is_loaded) return <div />

    return (
        <View overflowY="scroll" height="100vh">
            <View position="absolute" right="24px" top="16px"><Button noStyle onClick={onClickClose}><XIcon height={24} width={24}/></Button></View>
            <View padding="24px">
                <View width="fit-content">
                    <Text fontSize="20px" width="fit-content">Filters</Text>
                </View>
                <View padding="24px 0px">
                    <View>
                        <SearchBar />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <ArtistsFilter data={api.data.artists}/>
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <RarityFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <MediumFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <PriceFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <SizeFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <PurchaseOptionFilter />
                    </View>
                </View>
                {/* <hr />
                <View padding="24px 0px">
                    <View>
                        <MaterialFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <ArtistEthnicityFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <ArtworkLocationFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <TimePeriodFilter />
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <ColorFilter />
                    </View>
                </View> */}
                <hr />
                <View padding="24px 0px">
                    <View>
                        <GalleryFilter data={api.data.galleries}/>
                    </View>
                </View>
                <hr />
                <View padding="24px 0px">
                    <View>
                        <MuseumFilter data={api.data.museums}/>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Filters