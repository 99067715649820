import styled from 'styled-components'


interface AllPostsContainerProps {

}


export const StyledAllPostsContainer = styled.div<AllPostsContainerProps>`
`

interface AllPostsProps {

}


export const StyledAllPosts = styled.div<AllPostsProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 4px;
`


