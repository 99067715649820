import styled from "styled-components";

interface NotificationsContainerProps {

}

export const StyledNotificationsContainer = styled.div<NotificationsContainerProps>`
    padding: 12px;
`

interface NotificationsProps {

}

export const StyledNotifications = styled.div<NotificationsProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 4px;

    @media (min-width: 768px) {
        padding: 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface NotificatiosWrapperContainerProps {

}

export const StyledNotificationsWrapper = styled.div<NotificatiosWrapperContainerProps>`
    width: 100%;
    display: flex; 
    flex-direction: column; 
    gap: 24px;
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 112px);

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

    @media (min-width: 768px) {
        height: 90vh;
    }

    @media (min-width: 1024px) {

    }
    
`

interface NotificationContainerProps {

}

export const StyledNotificationContainer = styled.div<NotificationContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    
`