import CountUp from 'react-countup';
import { Text } from "../Text";
import { StyledMetric, StyledMetricButton, StyledMetricContainer } from "./styled"
import { Button } from '../Button';
import {ReactComponent as VisibleIcon} from '../../img/notVisible.svg'


type MetricProps = {
    title?: string;
    value?: any;
    color?: string;
    onClick?: any;
}


const Metric = ({title, value, color, onClick}: MetricProps) => {
    return (
        
            <StyledMetricContainer>
                <StyledMetric>
                    <Text textAlign="center" fontSize="32px" color={color || '#fff'} width='100%'>{title}</Text>
                    <Text textAlign="center" fontSize="60px" fontWeight="600" color={color || '#fff'} width='100%'><CountUp end={value} /></Text>
                    {onClick && <StyledMetricButton><Button onClick={onClick} color={'#fff'} width='100%' padding='8px 20px'>View</Button></StyledMetricButton>}
                </StyledMetric>            
            </StyledMetricContainer>
    )
}

export default Metric