import styled from "styled-components";

interface MainContentContainerProps {

}

export const StyledMainContentContainer = styled.div<MainContentContainerProps>`

`

interface MainContentProps {

}

export const StyledMainContent = styled.div<MainContentProps>`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 24px;
    width: calc(100% - 24px);
`