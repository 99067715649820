import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { Gallery as GalleryNode } from "../../../components/ProductDetail/Gallery"



const Gallery = () => {
    return (
        <DashboardLayout>
            <GalleryNode />
        </DashboardLayout>
    )
}

export default Gallery