import { useEffect, useState } from "react"
import { useActiveFeedStore, useResponsiveStore, useThemeStore } from "../../stores/web.store"
import { Button } from "../Button"
import { Text } from "../Text"
import { StyledFeedNavigation, StyledFeedNavigationContainer } from "./styled"


const FeedNavigation = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const activeFeed = useActiveFeedStore((state: any) => state.activeFeed)
    const setActiveFeed = useActiveFeedStore((state: any) => state.setActiveFeed)
    const width = useResponsiveStore((state: any) => state.width)

    const onClick = (value: string) => {
        if (activeFeed !== value) {
            setActiveFeed(value)
        }
    }
    
    // let oldScrollY = 0;

    // const [direction, setDirection] = useState('up');

    // const controlDirection = (event: any) => {
    //     if(event.target.scrollTop > oldScrollY) {
    //         setDirection('down');
    //     } else {
    //         setDirection('up');
    //     }
    //     oldScrollY = event.target.scrollTop;
    // }

    // useEffect(() => {
    //     const setup = async () => {
    //         const FeedContainer = document.getElementById("FeedContainer") as any;

    //         while(!FeedContainer) {
    //             await new Promise(r => setTimeout(r, 500));
    //         }
    
    //         FeedContainer.addEventListener('scroll', controlDirection);
    //         return () => {
    //             FeedContainer.removeEventListener('scroll', controlDirection);
    //         };
    //     }

    //     if (width <= 768) {
    //         setup()
    //     }
        
        
    // }, [activeFeed, width]);


    return (
        <StyledFeedNavigationContainer isActive={width > 768}>
            <StyledFeedNavigation>
                <Button 
                    onClick={() => onClick('forYou')} 
                    width="100%"
                    padding={width > 768 ? "24px 0px": "0px 0px 24px 0px"}
                    color={activeFeed === 'forYou' ? theme.button.background : theme.text.color}
                    borderBottom={activeFeed === 'forYou' ? `solid 2px ${theme.button.background}` : 'none'}
                    noStyle
                ><Text fontSize="20px" fontWeight="500">For You</Text></Button>
                <Button 
                    onClick={() => onClick('following')} 
                    width="100%"
                    padding={width > 768 ? "24px 0px": "0px 0px 24px 0px"}
                    color={activeFeed === 'following' ? theme.button.background : theme.text.color}
                    borderBottom={activeFeed === 'following' ? `solid 2px ${theme.button.background}` : 'none'}
                    noStyle
                ><Text fontSize="20px" fontWeight="500">Following</Text></Button>
            </StyledFeedNavigation>
        </StyledFeedNavigationContainer>
    )
}

export default FeedNavigation