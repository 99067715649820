import Button from "../Button";

type PostProps = {
    onClick: any;
    isDisabled: boolean;
}

const Post = ({onClick, isDisabled}: PostProps) => {
    return (
        <Button noStyle cursor={isDisabled ? 'not-allowed': ''} fontWeight="600" onClick={onClick} disabled={isDisabled} color={isDisabled ? "grey" : "#0938F0"}>Post</Button>
    )
}

export default Post