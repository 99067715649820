import styled from "styled-components";


interface AboutAccountContainerProps {

}

export const StyledAboutAccountContainer = styled.div<AboutAccountContainerProps>`
    width: 400px;
    background: #fff;
    border-radius: 12px;
    position: relative;

    padding: 20px;

    -webkit-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
`

interface AboutAccountProps {
    
}

export const StyledAboutAccount = styled.div<AboutAccountProps>`
    text-align: center;
`

interface CloseModalContainerProps {

}

export const StyledCloseModalContainer = styled.div<CloseModalContainerProps>`
    position: absolute;
    right: 20px;
    top: 20px;
`