import styled from 'styled-components'


interface FollowedByContainerProps {
    width?: string;
}


export const StyledFollowedByContainer = styled.div<FollowedByContainerProps>`
    width: ${props => props.width || 'auto'};
    padding: 4px 0px;
`

interface FollowedByProps {

}


export const StyledFollowedBy = styled.div<FollowedByProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 16px;
`

interface PhotoContainerProps {

}

export const StyledPhotoContainer = styled.div<PhotoContainerProps>`

`









