import styled from 'styled-components'


interface TopNavigationMenuContainerProps {
    border?: boolean;
}


export const StyledTopNavigationMenuContainer = styled.div<TopNavigationMenuContainerProps>`
    width: calc(100% - 42px);
    margin: 0px 0px 0px 42px;
    
    ${props => props.border && `
        border-bottom: solid 1px #6b6b6b;   
    `}
`


interface NavigationMenuProps {

}


export const StyledNavigationMenu = styled.div<NavigationMenuProps>`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 12px;
    width: 90%;
    margin: auto;
    align-items: center;
    height: 60px;
`


interface MenuItemProps {
    textAlign?: string;
    padding?: string;
    isActive?: boolean;
    theme?: any;
}


export const StyledMenuItem = styled.div<MenuItemProps>`
    padding: ${props => props.padding || '0'};
    text-align: ${props => props.textAlign || 'left'};
    margin: auto;
    font-size: 13px;
    align-items: center;
    height: 60px;
    display: flex;

    ${props => props.isActive && `
        border-bottom: solid 1px ${props.theme.button.background};
    `}
`


