import styled from "styled-components";



interface FeaturedMuseumsProps {

}

export const StyledFeaturedMuseums = styled.div<FeaturedMuseumsProps>`
    width: 100%;
    overflow-x: scroll;
`

interface FeaturedMuseumContainerProps {
    columns: number;
    width: string;
}

export const StyledFeaturedMuseumContainer = styled.div<FeaturedMuseumContainerProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-gap: 0;
    width: ${props => props.width}; 
    
`

interface FeaturedMuseumProps {

}

export const StyledFeaturedMuseum = styled.div<FeaturedMuseumProps>`
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 0;
`