import styled from 'styled-components'


interface IconProps {
    padding: string;
}


export const StyledIcon = styled.div<IconProps>`
    padding: ${props => props.padding || '0'};
`