import styled from 'styled-components'


interface CreateMediaPostContainerProps {

}


export const StyledCreateMediaPostContainer  = styled.div<CreateMediaPostContainerProps>`
   padding: 4px;

    @media (min-width: 768px) {
        padding: 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface CreateMediaPost {

}

export const StyledCreateMediaPost = styled.div<CreateMediaPost>`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 80px;
    }

    @media (min-width: 1024px) {

    }
`

