import { Text } from "../Text"
import { StyledLikedByContainer, StyledLikedBy } from "./styled"
import { toUsername } from "../../utils/app.util"
import { ProfilePicture } from "../ProfilePicture"
import { LikesModal } from "../Modal/LikesModal"
import { useState } from "react"
import { Button } from "../Button"
import { useModalStore, usePostStore } from "../../stores/web.store"
import { View } from "../View"


const LikedByOthers = ({postData, setIsModal}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    
    const onClick = () => {
        setIsModal(true)
        setPost(null)
        setModalKey(null)
        
    }
    return (
        <Button onClick={onClick} noStyle textAlign="left" gap="16px">
            {(postData.likes.length - 1) > 0 ? ' and ' + (postData.likes.length - 1).toString() + (postData.likes.length > 2 ? ' others' : ' other') : ''}
        </Button>
    )
}

const LikedBy = ({postData, noCount}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const [isModal, setIsModal] = useState(false)

    const onClose = () => {
        setIsModal(false)
        setModalKey('post')
        setPost(postData)
    }
   
    if (postData.total_likes === 0) return <View display="none"/>

    return (
        <StyledLikedByContainer>
            <StyledLikedBy>
                <Text 
                    fontSize="14px" 
                >
                    Liked by <ProfilePicture account={postData.owner} username/> <LikedByOthers postData={postData} setIsModal={setIsModal}/>
                </Text>
            </StyledLikedBy>
            {isModal && <LikesModal data={postData.likes} setIsModal={onClose}/>}
        </StyledLikedByContainer>
    )
}

export default LikedBy