import styled from 'styled-components'


interface MuseumsContainerProps {

}

export const StyledMuseumsContainer = styled.div<MuseumsContainerProps>`

`

interface MuseumsProps {

}


export const StyledMuseums = styled.div<MuseumsProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 16px;
`