import { useEffect, useState } from "react"
import { View } from "../../components/View"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { Panel } from "./Panel"
import Setting from "./Setting/Setting"
import { StyledPanelContainer, StyledSettings, StyledSettingsContainer, StyledSettingsContent } from "./styled"
import { Text } from "../../components/Text"
import { Input } from "../../components/Input"
import { Button } from "../../components/Button"
import { Switch } from "../../components/Switch"
import { SearchBar } from "../../components/SearchBar"
import { Divider } from "../../components/Divider"
import { Image } from "../../components/Image"
import { useAccountStore } from "../../stores/web.store"
import { ProfilePicture } from "../../components/ProfilePicture"

import {ReactComponent as PYSettingIcon} from '../../img/PYSetting.svg'
import {ReactComponent as LifeBuoyIcon} from '../../img/lifeBuoy.svg'
import {ReactComponent as ChevronRightIcon} from '../../img/chevronRight.svg'
import { Onboarding } from "../../components/Services/Stripe/Onboarding"
import { toPhoneNumber } from "../../utils/app.util"
import api from "../../services/api.service"


type SectionTitleProps = {
    title: string;
}

const SectionTitle = ({title}: SectionTitleProps) => {
    return (
        <Text fontSize="18px" fontWeight="600">{title}</Text>
    )
}

type AccountSettingsPayloadProps = {
    email?: string;
    phone?: string;
    country_code?: string;
}

const AccountSettings = () => {
    const account = useAccountStore((state: any) => state.account)
    const [payload, setPayload] = useState<AccountSettingsPayloadProps>()

    useEffect(() => {
        setPayload({
            email: account.email,
            phone: account.phone,
            country_code: account.country_code || "1"
        })
    }, [])

    const onChangeEmail = (e: any) => {
        // setPayload({
        //     ...payload,
        //     email: e.target.value
        // })
    }

    const onChangePhone = (e: any) => {
        setPayload({
            ...payload,
            phone: e.target.value
        })
    }

    const onChangeCountryCode = (e: any) => {
        setPayload({
            ...payload,
            country_code: e.target.value
        })
    }

    const onClickSaveChanges = async () => {
        const resp = await api.put(`/accounts/${account.id}`, payload)
        console.log(resp)
        // TODO: Add callback
    }

    return (
        <Setting title={'Account Settings'}>
            <View>
                <View display="flex" flexDirection="column" gap="24px">
                    <View>
                        <View display="flex" flexDirection="column" gap="12px">
                            <View>
                                <SectionTitle title="Email" />
                            </View>
                            <View>
                                <Input 
                                    width="100%" 
                                    placeholder="Enter your email address" 
                                    value={payload?.email}
                                    onChange={onChangeEmail}
                                    noPlaceholderPrefix
                                    disabled
                                />
                            </View>
                        </View>
                    </View>
                    <View>
                        <View display="flex" flexDirection="column" gap="12px">
                            <View>
                                <SectionTitle title="Mobile Number" />
                            </View>
                            <View>
                                <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                                    <View width="48px" position="relative">
                                        <Input 
                                            width="48px" 
                                            placeholder="1" 
                                            onChange={onChangeCountryCode}
                                            value={payload?.country_code}
                                            textIndent="20px"
                                            noPlaceholderPrefix
                                        />
                                        <View position="absolute" left="8px" top="8px">
                                            <Text fontSize="14px">+</Text>
                                        </View>
                                    </View>
                                    <View flexGrow="1">
                                        <Input 
                                            width="100%" 
                                            type="phone"
                                            placeholder="Enter your mobile phone number" 
                                            value={toPhoneNumber(payload?.phone)}
                                            onChange={onChangePhone}
                                            noPlaceholderPrefix
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <Button
                            border={`solid 1px ${'#BFCBFB'}`}
                            background={'#DDE3FD'}
                            color={'#0938F0'}
                            padding="8px 20px"
                            borderRadius="23px"
                            fontSize="16px"
                            width="max-content"
                            onClick={onClickSaveChanges}
                            noStyle
                        >Save Changes</Button>
                    </View>
                    <View>
                        <View display="flex" flexDirection="column" gap="12px">
                            <View>
                                <SectionTitle title="Password" />
                            </View>
                            <View>
                                <Input width="100%" placeholder="Enter your new password" noPlaceholderPrefix/>
                            </View>
                            <View>
                                <Input width="100%" placeholder="Repeat your new password" noPlaceholderPrefix/>
                            </View>
                            
                        </View>
                    </View>
                    <View>
                        <Button
                            border={`solid 1px ${'#BFCBFB'}`}
                            background={'#DDE3FD'}
                            color={'#0938F0'}
                            padding="8px 20px"
                            borderRadius="23px"
                            fontSize="16px"
                            width="max-content"
                            noStyle
                        >Set Password</Button>
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const VerifySettings = () => {
    const [stripeConnection, setStripeConnection] = useState<any>(null)

    useEffect(() => {

        const fetch = async () => {
            const resp = await api.get(`${process.env.REACT_APP_PRIMARY_YELLOW_API}/service/stripe/get-account`)
            if (resp !== null) {
                setStripeConnection(resp)
            }
        }

        if (!stripeConnection) {
            fetch()
        }
        

    }, [])
    return (
        <Setting title={'Verify Account'}>
            <View display="flex" flexDirection="column" gap="24px">
                <View>
                    <SectionTitle title={stripeConnection ? "Connected to Stripe" : "Connect to Stripe"} />
                </View>
                <View>
                    <Text>{stripeConnection ? "You are able to start collecting payments" : "Please connect to Stripe to begin collecting payments."}</Text>
                </View>
                <View>
                    {stripeConnection ? <View /> : <Onboarding stripeConnection={stripeConnection}/>}
                </View>
            </View>
        </Setting>
    )
}

type PrivacySettingsPayloadProps = {
    is_allow_sharing?: boolean;
    is_hide_comments?: boolean;
    is_allow_message_requests?: boolean;

}

const PrivacySettings = () => {
    const account = useAccountStore((state: any) => state.account)
    const [payload, setPayload] = useState<PrivacySettingsPayloadProps>()

    const sendRequest = async (payload: any) => {
        // TODO: Send api request
    }

    const onChangeShareSetting = (e: boolean) => {
        const update = {
            is_allow_sharing: e
        }

        setPayload({...payload, ...update})
        sendRequest(update)
    }

    const onChangeCommentsSetting = (e: boolean) => {
        const update = {
            is_hide_comments: e
        }

        setPayload({...payload, ...update})
        sendRequest(update)
    }

    const onChangeMessageRequestSetting = (e: boolean) => {
        const update = {
            is_allow_message_requests: e
        }

        setPayload({...payload, ...update})
        sendRequest(update)
    }

    return (
        <Setting title={'Account Privacy'}>
            <View>
                <View display="flex" flexDirection="column" gap="24px">
                    <View>
                        <View display="flex" flexDirection="column" gap="12px">
                            <View>
                                <SectionTitle title="What people can share" />
                            </View>
                            <View>
                                <View display="inline-flex" width="100%" alignItems="center">
                                    <View flexGrow="1">
                                        <Text>{'Allow sharing to messages'}</Text>
                                    </View>
                                    <View width="fit-content" textAlign="right">
                                        <Switch 
                                            onChange={onChangeShareSetting} 
                                            isChecked={payload?.is_allow_sharing}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View display="flex" flexDirection="column" gap="24px">
                            <View>
                                <View display="flex" flexDirection="column" gap="12px">
                                    <View>
                                        <SectionTitle title="Hidden words" />
                                    </View>
                                    <View>
                                        <View display="inline-flex" width="100%" alignItems="center">
                                            <View flexGrow="1">
                                                <Text>{'Hide comments'}</Text>
                                            </View>
                                            <View width="fit-content" textAlign="right">
                                                <Switch 
                                                    onChange={onChangeCommentsSetting}
                                                    isChecked={payload?.is_hide_comments}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View>
                                        <Text color="#808080">{"Primary Yellow will automatically move a variety of comments, such as those that are potentially offensive or spam, to a separate section. Anyone can see these comments and you can unhide them anytime."}</Text>
                                    </View>
                                </View>
                            </View>
                            <View>
                                <View display="flex" flexDirection="column" gap="12px">
                                    <View>
                                        <View display="inline-flex" width="100%">
                                            <View flexGrow="1">
                                                <Text>{'Message Requests'}</Text>
                                            </View>
                                            <View width="fit-content" textAlign="right">
                                                <Switch 
                                                    onChange={onChangeMessageRequestSetting}
                                                    isChecked={payload?.is_allow_message_requests}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View>
                                        <Text color="#808080">{"Control how you receive messages from unknown users. Enable message requests to screen messages before they appear in your inbox. If disabled, all messages from unknown users will go directly to your inbox."}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const LanguageSettings = () => {
    return (
        <Setting title={'Language preferences'}>
            <View>
                <View display="flex" flexDirection="column" gap="24px">
                    <View>
                        <View display="flex" flexDirection="column" gap="12px">
                            <View>
                                <SectionTitle title="App language" />
                            </View>
                            <View>
                                <Text color="#808080">{"See buttons, titles, and other texts on Primary Yellow in your preferred language."}</Text>
                            </View>
                            <View>
                                <SearchBar />
                            </View>
                            <View>
                                <View display="inline-flex" width="100%">
                                    <View flexGrow="1">
                                        <Text></Text>
                                    </View>
                                    <View width="fit-content" textAlign="right">
                                        
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const LikeNotificationSettings = () => {
    const [value, setValue] = useState('everyone')
    const account = useAccountStore((state: any) => state.account)

    const sendRequest = async (value: boolean) => {
        // TODO: Send api request
    }

    const onChange = (e: any) => {
        setValue(e.target.value)
        sendRequest(e.target.value)
    }

    return (
        <View display="flex" flexDirection="column" gap="12px">
            <View borderBottom="solid 1px #D0D0D0">
                <View>
                    <SectionTitle title="Likes" />
                </View>
                <View padding="12px 0px 12px 0px">
                    <View 
                        display="flex"
                        flexDirection="column"
                        gap="12px"
                        onChange={onChange}
                    >
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="off" 
                                    name="likes" 
                                    checked={value === 'off'}
                                />
                            </View>
                            <View>
                                <Text>Off</Text>
                            </View>
                        </View>
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="following" 
                                    name="likes" 
                                    checked={value === 'following'}
                                />
                            </View>
                            <View>
                                <Text>From profiles I follow</Text>
                            </View>
                        </View>
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="everyone" 
                                    name="likes" 
                                    checked={value === 'everyone'}
                                />
                            </View>
                            <View>
                                <Text>From everyone</Text>
                            </View>
                        </View>
                        
                    </View>
                </View>
            </View>
        </View>
    )
}

const CommentsNotificationSettings = () => {
    const [value, setValue] = useState('everyone')
    const account = useAccountStore((state: any) => state.account)

    const sendRequest = async (value: boolean) => {
        // TODO: Send api request
    }

    const onChange = (e: any) => {
        setValue(e.target.value)
        sendRequest(e.target.value)
    }

    return (
        <View display="flex" flexDirection="column" gap="12px">
            <View borderBottom="solid 1px #D0D0D0">
                <View>
                    <SectionTitle title="Comments" />
                </View>
                <View padding="12px 0px 12px 0px">
                    <View 
                        display="flex"
                        flexDirection="column"
                        gap="12px"
                        onChange={onChange}
                    >
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="off" 
                                    name="comments" 
                                    checked={value === 'off'}
                                />
                            </View>
                            <View>
                                <Text>Off</Text>
                            </View>
                        </View>
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="following" 
                                    name="comments" 
                                    checked={value === 'following'}
                                />
                            </View>
                            <View>
                                <Text>From profiles I follow</Text>
                            </View>
                        </View>
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="everyone" 
                                    name="comments" 
                                    checked={value === 'everyone'}
                                />
                            </View>
                            <View>
                                <Text>From everyone</Text>
                            </View>
                        </View>
                        
                    </View>
                </View>
            </View>
        </View>
    )
}

const CommentLikesNotificationSettings = () => {
    const [value, setValue] = useState('on')
    const account = useAccountStore((state: any) => state.account)

    const sendRequest = async (value: boolean) => {
        // TODO: Send api request
    }

    const onChange = (e: any) => {
        setValue(e.target.value)
        sendRequest(e.target.value)
    }

    return (
        <View display="flex" flexDirection="column" gap="12px">
            <View borderBottom="solid 1px #D0D0D0">
                <View>
                    <SectionTitle title="Comment Likes" />
                </View>
                <View padding="12px 0px 12px 0px">
                    <View 
                        display="flex"
                        flexDirection="column"
                        gap="12px"
                        onChange={onChange}
                    >
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="off" 
                                    name="commentLikes" 
                                    checked={value === 'off'}
                                />
                            </View>
                            <View>
                                <Text>Off</Text>
                            </View>
                        </View>
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <View>
                                <Input 
                                    type="radio" 
                                    value="on" 
                                    name="commentLikes" 
                                    checked={value === 'on'}
                                />
                            </View>
                            <View>
                                <Text>On</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const PauseAllNotificationSettings = () => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = () => {

    }

    return (
        <View display="flex" flexDirection="column" gap="12px">
            <View>
                <SectionTitle title="Push notifications" />
            </View>
            <View>
                <View display="inline-flex" width="100%" alignItems="center">
                    <View flexGrow="1">
                        <Text>{'Pause all'}</Text>
                    </View>
                    <View width="fit-content" textAlign="right">
                        <Switch onChange={onChange} isChecked={isChecked}/>
                    </View>
                </View>
            </View>
        </View>
    )
}

const NotificationSettings = () => {
    return (
        <Setting title={'Notifications'}>
            <View>
                <View display="flex" flexDirection="column" gap="24px">
                    <View>
                        <PauseAllNotificationSettings />
                    </View>
                    <View>
                       <LikeNotificationSettings />
                    </View>
                    <View>
                        <CommentsNotificationSettings />
                    </View>
                    <View>
                        <CommentLikesNotificationSettings />
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const CreditCards = () => {
    return (
        <View display="flex" flexDirection="column" gap="24px">
            <View>
                <SectionTitle title="Credit cards" />
            </View>
            <View>
                <Text>{"Please add a payment card for a faster checkout experience in future."}</Text>
            </View>
            <View>
                <Button
                    border={`solid 1px ${'#BFCBFB'}`}
                    background={'#DDE3FD'}
                    color={'#0938F0'}
                    padding="8px 20px"
                    borderRadius="23px"
                    fontSize="16px"
                    width="max-content"
                    noStyle
                >Add Credit Card</Button>
            </View>
        </View>
    )
}

const Shipping = () => {
    return (
        <View display="flex" flexDirection="column" gap="24px">
            <View>
                <SectionTitle title="Shipping" />
            </View>
            <View>
                <Text>{"Please add an address for a faster checkout experience in the future."}</Text>
            </View>
            <View>
                <Button
                    border={`solid 1px ${'#BFCBFB'}`}
                    background={'#DDE3FD'}
                    color={'#0938F0'}
                    padding="8px 20px"
                    borderRadius="23px"
                    fontSize="16px"
                    width="max-content"
                    noStyle
                >Add New Address</Button>
            </View>
        </View>
    )
}

const OtherPaymentMethods = () => {
    return (
        <View display="flex" flexDirection="column" gap="24px">
            <View>
                <SectionTitle title="Shipping" />
            </View>
            <View>
                <Text>{"Other payment methods"}</Text>
            </View>
            <View>
                <Button
                    border={`solid 1px ${'#BFCBFB'}`}
                    background={'#DDE3FD'}
                    color={'#0938F0'}
                    padding="8px 20px"
                    borderRadius="23px"
                    fontSize="16px"
                    width="max-content"
                    noStyle
                >Add Payment</Button>
            </View>
        </View>
    )
}

const OrderProduct = () => {
    return (
        <View>
            <Button gap="24px" noStyle>
                <View>
                    <Image 
                        src="https://primary-yellow-development.s3.amazonaws.com/public/media/3c86e731-fda3-4506-abdc-9ab55493bcf7/hero.jpg" 
                        height="96px"
                        width="96px"
                        borderRadius="20px"
                        objectFit="cover"
                    />
                </View>
                <View>
                    <View display="flex" flexDirection="column" gap="12px">
                        <View>
                            <Text>{'accountname'}</Text>
                        </View>
                        <View>
                            <Text>{'Shipping ~ Nov 13, 2023'}</Text>
                        </View>
                        <View>
                            <Text>{'$127.37 (1 item)'}</Text>
                        </View>
                    </View>
                </View>
            </Button>
        </View>
    )
}

const OrderSettings = () => {
    return (
        <Setting title={'Orders'}>
            <View padding="0px 0px 24px 0px">
                <View display="flex" flexDirection="column" gap="24px">
                    <View>
                        <CreditCards />
                    </View>
                    <View>
                        <Shipping />
                    </View>
                    <View>
                        <OtherPaymentMethods />
                    </View>
                </View>
            </View>
            <Divider />
            <View >
                <View padding="24px 0px">
                    <SectionTitle title="Orders" />
                </View>
                <View padding="24px 0px">
                    <View display="inline-flex" alignItems="center" gap="24px" justifyContent="center" width="100%">
                        <View>
                            <Button
                                border={`solid 1px ${'#BFCBFB'}`}
                                background={'#DDE3FD'}
                                color={'#0938F0'}
                                padding="12px 20px"
                                borderRadius="23px"
                                fontSize="16px"
                                width="287px"
                                noStyle
                            >In Progress</Button>
                        </View>
                        <View>
                            <Button
                                border={`solid 1px ${'#BFCBFB'}`}
                                background={'#DDE3FD'}
                                color={'#0938F0'}
                                padding="12px 20px"
                                borderRadius="23px"
                                fontSize="16px"
                                width="287px"
                                noStyle
                            >Past Orders</Button>
                        </View>
                    </View>
                </View>
                <View padding="24px 0px">
                    <View display="flex" flexDirection="column" gap="24px">
                        <OrderProduct />
                        <OrderProduct />
                        <OrderProduct />
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const MutedAccount = () => {
    const account = useAccountStore((state: any) => state.account) 
    return (
        <View>
            <View display="inline-flex" alignItems="center" gap="12px" width="100%">
                <View width="50%">
                    <ProfilePicture account={account} username photo height="48px" width="48px" />
                </View>
                <View width="50%" textAlign="right">
                    <Button
                        border={`solid 1px ${'#BFCBFB'}`}
                        background={'#DDE3FD'}
                        color={'#0938F0'}
                        padding="12px 20px"
                        borderRadius="23px"
                        fontSize="16px"
                        width="287px"
                        noStyle
                    >View Profile</Button>
                </View>
            </View>
        </View>
    )
}

const MutedSettings = () => {
    return (
        <Setting title={'Muted Accounts'}>
            <View display="flex" flexDirection="column" gap="24px">
                <MutedAccount />
                <MutedAccount />
            </View>
        </Setting>
    )
}

const LikeShareSettings = () => {
    const onChange = () => {

    }
    return (
        <Setting title={'Like and share counts'}>
            <View>
                <View display="flex" flexDirection="column" gap="12px">
                    <View display="inline-flex" width="100%" alignItems="center">
                        <View flexGrow="1">
                            <Text>{'Hide like & share count'}</Text>
                        </View>
                        <View width="fit-content" textAlign="right">
                            <Switch onChange={onChange}/>
                        </View>
                    </View>
                    <View>
                        <Text color="#808080">{"On Primary Yellow, the number of likes on posts from other accounts will be hidden .You can  hide the number of likes on your own posts by going to Advanced settings before sharing."}</Text>
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const CloseFriend = () => {
    /*
        pass the name as the id of the close friend and handle change
    */
    const account = useAccountStore((state: any) => state.account)
    const [isChecked, setIsChecked] = useState(true) 
    const onChange = () => {

    }
    return (
        <View>
            <View display="inline-flex" alignItems="center" gap="12px" width="100%">
                <View width="50%">
                    <ProfilePicture account={account} username photo height="48px" width="48px" />
                </View>
                <View width="50%" textAlign="right">
                    <View 
                        display="flex"
                        flexDirection="column"
                        gap="12px"
                        onChange={onChange}
                    >
                        <View>
                            <Input 
                                type="radio" 
                                value="on" 
                                name="closeFriend" 
                                checked={isChecked}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const CloseFriendsSettings = () => {
    return (
        <Setting title={'Close friends'}>
            <View display="flex" flexDirection="column" gap="24px">
                <View>
                    <Text color="#808080">{"On Primary Yellow, the number of likes on posts from other accounts will be hidden .You can  hide the number of likes on your own posts by going to Advanced settings before sharing."}</Text>
                </View>
                <View>
                    <SearchBar />
                </View>
                <View>
                    <View display="flex" flexDirection="column" gap="24px">
                        <CloseFriend />
                        <CloseFriend />
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const SubscriptionsSettings = () => {
    return (
        <Setting title={'Subscriptions'}>
            <View>
                <View padding="0px 0px 24px 0px">
                    <View display="flex" flexDirection="column" gap="12px" textAlign="center" width="100%">
                        <View width="100%">
                            <PYSettingIcon />
                        </View>
                        <View>
                            <Text color="#808080" width="100%" textAlign="center" fontSize="24px">{"No subscriptions"}</Text>
                        </View>
                        <View>
                            <Text color="#808080" width="100%" textAlign="center">{"You don’t have any active subscriptions."}</Text>
                        </View>
                    </View>
                </View>
                <Divider />
                <View padding="24px 0px">
                    <View display="flex" flexDirection="column" gap="24px">
                        <View>
                            <View display="flex" flexDirection="column" gap="12px">
                                <View>
                                    <SectionTitle title="Subscription Price" />
                                </View>
                                <View>
                                    <Text color="#808080">{"You can set the monthly cost for others to view your subscriber only content"}</Text>
                                </View>
                                <View>
                                    <Input width="100%" placeholder="Enter your email address" noPlaceholderPrefix/>
                                </View>
                            </View>
                        </View>
                        <View>
                            <View display="flex" flexDirection="column" gap="12px">
                                <View>
                                    <SectionTitle title="Subscription Services" />
                                </View>
                                <View>
                                    <Input width="100%" placeholder="Enter your email address" noPlaceholderPrefix/>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <Divider />
                <View padding="24px 0px">
                    <View display="flex" flexDirection="column" gap="12px">
                        <View>
                            <SectionTitle title="Settings" />
                        </View>
                        <View>
                            <Button gap="12px" noStyle width="100%">

                                <View>
                                    <LifeBuoyIcon />
                                </View>
                                <View flexGrow="1">
                                    <Text>{'Help & support'}</Text>
                                </View>

                                <View textAlign="right">
                                    <ChevronRightIcon stroke="#000" height={20} width={20} />
                                </View>
                            </Button>
                        </View>
                    </View>
                </View>
            </View>
        </Setting>
    )
}

const BlockedAccount = () => {
    const account = useAccountStore((state: any) => state.account) 
    return (
        <View>
            <View display="inline-flex" alignItems="center" gap="12px" width="100%">
                <View width="50%">
                    <ProfilePicture account={account} username photo height="48px" width="48px" />
                </View>
                <View width="50%" textAlign="right">
                    <Button
                        border={`solid 1px ${'#BFCBFB'}`}
                        background={'#DDE3FD'}
                        color={'#0938F0'}
                        padding="12px 20px"
                        borderRadius="23px"
                        fontSize="16px"
                        width="287px"
                        noStyle
                    >Unblock</Button>
                </View>
            </View>
        </View>
    )
}

const BlockedSettings = () => {
    /*
        if no blocked the you havent blocked message else show blocked accounts
    */
    return (
        <Setting title={'Blocked'}>
            <View padding="0px 0px 48px 0px">
                <Text color="#808080">{"You can block people anytime from their profiles."}</Text>
            </View>
            <View display="flex" flexDirection="column" gap="24px">
                <BlockedAccount />
                <BlockedAccount />
            </View>
            <View display="flex" alignItems="center" height="400px" textAlign="center">
                <Text color="#808080" width="100%">{"You haven’t blocked anyone."}</Text>
            </View>
        </Setting>
    )
}


const Settings = () => {
    const [setting, setSetting] = useState<string>('account')

    return (
        <DashboardLayout>
            <StyledSettingsContainer>
                <StyledSettings>
                    <StyledPanelContainer>
                        <View padding="12px 0px 48px 0px">
                            <Text fontSize="24px" fontWeight="600">Settings</Text>
                        </View>
                        <Panel setSetting={setSetting} setting={setting} />
                    </StyledPanelContainer>
                    <StyledSettingsContent>
                        {setting === 'account' && <AccountSettings />}
                        {setting === 'verify' && <VerifySettings />}
                        {setting === 'privacy' && <PrivacySettings />}
                        {setting === 'language' && <LanguageSettings />}
                        {setting === 'notification' && <NotificationSettings />}
                        {setting === 'orders' && <OrderSettings />}
                        {setting === 'muted' && <MutedSettings />}
                        {setting === 'likeShare' && <LikeShareSettings />}
                        {setting === 'closeFriends' && <CloseFriendsSettings />}
                        {setting === 'subscriptions' && <SubscriptionsSettings />}
                        {setting === 'blocked' && <BlockedSettings />}
                    </StyledSettingsContent>
                </StyledSettings>
            </StyledSettingsContainer>
        </DashboardLayout>
    )
}

export default Settings