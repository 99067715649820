import { QuickFilter } from "../QuickFilter"

type BoardsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const Boards = ({selectedFilter, setSelectedFilter}: BoardsProps) => {
    const onClick = () => {
        setSelectedFilter('boards')
    }

    return (
        <QuickFilter title="Boards" onClick={onClick} isSelected={selectedFilter === 'boards'}/>
    )
}

export default Boards