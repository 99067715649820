import Theme from "./Theme";
import "./styles.css";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { StyledMarkdown, StyledMarkdownContainer } from "./styled";
import { useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from '@lexical/html'; 
import { $createParagraphNode, $getRoot, COMMAND_PRIORITY_HIGH, KEY_ENTER_COMMAND } from "lexical";
import { useResponsiveStore } from "../../stores/web.store";


const Placeholder = ({value}: any) => {
    return <div className="editor-placeholder">{value || 'Write a caption'}</div>;
}


const Markdown = ({
    uid,
    editorStateRef, 
    data, 
    singleLine, 
    onChange, 
    readOnly, 
    placeholder, 
    noTools, 
    allowAttachment,
    border,
    borderTop,
    borderRight,
    borderLeft,
    borderBottom,
    borderRadius,
    emoji,
    handleEnterCommand
}: any) => {
    const width = useResponsiveStore((state: any) => state.width)
    const _onChange = (editorState: any) => editorStateRef.current = editorState
    
    const [editorConfig, setEditorConfig] = useState<any>({
        // The editor theme
        theme: Theme,
        editable: !readOnly,
        editorState: data ? JSON.stringify(data) : null,
        // Handling of errors during update
        onError(error: any) {
          throw error;
        },
        // Any custom nodes go here
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode
        ]
    })


    const _handleEnterCommand = (event: any, editor: any) => {
        if (!handleEnterCommand) return false;
        handleEnterCommand(event, editor)
        editor.update(() => {
            const root = $getRoot();
            root.clear();
            root.append($createParagraphNode());
        });
        return true; 
    }

    const CustomPlugin = () => {
        const [editor] = useLexicalComposerContext();

        useEffect(() => {
            if (editorStateRef) {
                editorStateRef.current = editor;
            }
        }, [editor, editorStateRef]);

        useEffect(() => {
            const removeCommand = editor.registerCommand(
                KEY_ENTER_COMMAND,
                _handleEnterCommand,
                COMMAND_PRIORITY_HIGH
            );

            return () => {
                removeCommand();
            };
        }, [editor]);

        return null;
    };

    return (
        <StyledMarkdownContainer 
            singleLine={singleLine}
            border={border} 
            borderTop={borderTop} 
            borderBottom={borderBottom}
            borderLeft={borderLeft}
            borderRight={borderRight}
            borderRadius={borderRadius}
        >
            <StyledMarkdown singleLine={singleLine}>
                <LexicalComposer initialConfig={editorConfig}>
                    <div className="editor-container">
                        <div className="editor-inner" style={{padding: width <= 768 ? "0px 12px" : "0"}}>
                            {noTools && <ToolbarPlugin noTools singleLine allowAttachment={allowAttachment} emoji={emoji}/>}
                            <RichTextPlugin
                                contentEditable={<ContentEditable className="editor-input" id={uid}/>}
                                placeholder={<Placeholder value={placeholder}/>}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <OnChangePlugin onChange={onChange || _onChange} />
                            <HistoryPlugin />
                            <AutoFocusPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                            <CustomPlugin />
                        </div>
                        {!noTools && <ToolbarPlugin allowAttachment={allowAttachment} noTools={noTools} singleLine={singleLine} emoji={emoji}/>}
                    </div>
                </LexicalComposer>
            </StyledMarkdown>
        </StyledMarkdownContainer>
      );
}

export default Markdown