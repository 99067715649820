import styled from "styled-components";

interface GalleryItemsContainerProps {

}

export const StyledWorksContainer = styled.div<GalleryItemsContainerProps>`

`

interface GalleryItemsProps {

}

export const StyledWorks = styled.div<GalleryItemsProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 16px;
`