import Modal from "../Modal"
import { StyledLoadingModal, StyledLoadingModalContainer } from "./styled"


const LoadingModal = ({children, setModal}: any) => {
    return (
        <Modal
            setModal={setModal} 
            width={'calc(100% - 42px)'} 
            top={'72px'} 
            height={'100vh'} 
            left="42px"
            margin="0"
            background="rgba(0, 0, 0, 0.5)"
        >
            <StyledLoadingModalContainer>
                <StyledLoadingModal>
                    {children}
                </StyledLoadingModal>
            </StyledLoadingModalContainer>
        </Modal>
        
    )
}

export default LoadingModal