import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Input } from "../../../components/Input"
import { Text } from "../../../components/Text"
import { StyledAuctionDatePicker, StyledAuctionDatePickerContainer, StyledAuctionDetails, StyledAuctionDetailsContainer, StyledAuctionInputContainer, StyledAuctionTitleInput } from "./styled"
import { useState } from 'react';
import { usePostPayloadStore } from '../../../stores/web.store';
import { View } from '../../../components/View';
import { DateTimePicker } from '../../../components/DateTimePicker';


const AuctionTitleInput = ({payload, setPayload}: any) => {
    const onChange = (e: any) => {
        setPayload({...payload, title: e.target.value})
    }
    return (
        <StyledAuctionTitleInput>
            <Input 
                placeholder={'Auction title'} 
                fontSize='20px'
                onChange={onChange}
                width='100%'
            />
        </StyledAuctionTitleInput>
    )
}



const AuctionDatePicker = ({payload, setPayload}: any) => {

    return (
        <StyledAuctionDatePickerContainer>
            <DateTimePicker payload={payload} setPayload={setPayload}/>
        </StyledAuctionDatePickerContainer>
    )
}

const AuctionDetails = () => {
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    return (
        <StyledAuctionDetailsContainer>
            <StyledAuctionDetails>
                <StyledAuctionInputContainer>
                    <AuctionTitleInput payload={postPayload} setPayload={setPostPayload}/>
                </StyledAuctionInputContainer>
                <AuctionDatePicker payload={postPayload} setPayload={setPostPayload}/>
            </StyledAuctionDetails>
        </StyledAuctionDetailsContainer>
    )
}

export default AuctionDetails