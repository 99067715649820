import {AddressElement} from '@stripe/react-stripe-js';
import { StyledAddressForm } from './styled';
import { useAccountStore } from '../../../stores/web.store';

const Form = ({payload, setPayload}: any) => {
    const account = useAccountStore((state: any) => state.account)
      
    const onChange = async (event: any) => {
        if (event.complete) {
            const address = event.value.address;
            const addressPayload = {
                street_address: address.line1,
                street_address_two: address.line2,
                city: address.city,
                state: address.state,
                zipcode: address.postal_code,
                country: address.country
            }

            setPayload({...payload, address: {...(payload.address || {}), ...addressPayload}})
        }
    }
    return (
        <StyledAddressForm>
            <AddressElement 
                options={{
                    mode: 'shipping',
                    autocomplete: {
                        mode: "google_maps_api",
                        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
                    },
                    defaultValues: {
                        address: {
                            state: payload.address?.state || 'NY', 
                            country: payload.address?.country || 'US',
                            city: payload.address?.city,
                            line1: payload.address?.street_address,
                            line2: payload.address?.street_address_two,
                            postal_code: payload.address?.zipcode
                        },
                        name: `${account.first_name} ${account.last_name}`
                    },
                }} 
                onChange={onChange} 
            />
        </StyledAddressForm>
    );
};

export default Form;
