import { Button } from "../../../../components/Button"
import { Text } from "../../../../components/Text"
import { StyledCreateShowArtworkContent, StyledCreateShowArtworkContentContainer, StyledUploadArtwork } from "./styled"
import {ReactComponent as UserPlusIcon} from '../../../../img/userPlus.svg'
import { MediaUpload } from "../../../../components/MediaUpload"
import { AboutTheWork } from "../../AboutTheWork"
import { usePostMapPayload } from "../../../../stores/web.store"
import { PostAvailability } from "../../PostAvailability"
import { useEffect } from "react"
import { View } from "../../../../components/View"

const CreateShowArtworkContent = () => {
    const postMapPayload = usePostMapPayload((state: any) => state.postMapPayload)
    const setPostMapPayload = usePostMapPayload((state: any) => state.setPostMapPayload)

    useEffect(() => {
        setPostMapPayload({...postMapPayload, state: 'show'})
    }, [])

    return (
        <StyledCreateShowArtworkContentContainer>
            <StyledCreateShowArtworkContent>
                <View padding="12px 0px">
                    <Text fontSize="20px" fontWeight="500">Choose artworks for your show</Text>
                </View>
                {/* <Button noStyle><UserPlusIcon width={32} height={32}/><Text padding="0px 8px" fontWeight="500">Invite friends to collaborate</Text></Button> */}
            </StyledCreateShowArtworkContent>
            <StyledUploadArtwork>
                <MediaUpload payload={postMapPayload} setPayload={setPostMapPayload} height="300px"/>
                <AboutTheWork payload={postMapPayload} setPayload={setPostMapPayload}/>
                <div style={{padding: '24px 0px'}}>
                    <PostAvailability payload={postMapPayload} setPayload={setPostMapPayload}/>
                </div>
            </StyledUploadArtwork>
        </StyledCreateShowArtworkContentContainer>
    )
}

export default CreateShowArtworkContent