import { ReactNode } from "react";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View"
import { StyledPanel, StyledPanelContainer } from "./styled"
import { Button } from "../../../components/Button";
import {ReactComponent as AccountIcon} from '../../../img/profile.svg'
import {ReactComponent as VerifyIcon} from '../../../img/verify.svg'
import {ReactComponent as LockIcon} from '../../../img/lock.svg'
import {ReactComponent as GlobeIcon} from '../../../img/globe.svg'
import {ReactComponent as NotificationIcon} from '../../../img/notification.svg'
import {ReactComponent as OrdersNotificationIcon} from '../../../img/orders.svg'
import {ReactComponent as MutedIcon} from '../../../img/muted.svg'
import {ReactComponent as LikeShareIcon} from '../../../img/likeShare.svg'
import {ReactComponent as CloseFriendsIcon} from '../../../img/closeFriends.svg'
import {ReactComponent as SubscriptionsIcon} from '../../../img/subscriptions.svg'
import {ReactComponent as BlockedIcon} from '../../../img/blocked.svg'

type PanelProps = {
    setSetting: any;
    setting: string;
}

type PrimaryYellowProps ={
    setSetting: any;
    setting: string;
}

type PurchaseInformationProps ={
    setSetting: any;
    setting: string;
}


type SettingSectionTitleProps = {
    title: string;
    padding?: string;
}

type SettingsButtonProps = {
    icon: ReactNode;
    title: string;
    onClick: any;
    isActive: boolean;
}

const SettingSectionTitle = ({title, padding}: SettingSectionTitleProps) => {
    return (
        <View padding={padding || "24px 0px"}>
            <Text color="#808080" fontSize="18px" fontWeight="600">{title}</Text>
        </View>
    )
}

const SettingsButton = ({icon, title, isActive, onClick}: SettingsButtonProps) => {
    return (
        <Button onClick={onClick} noStyle borderRadius="20px" background={isActive ? '#EDEDED' : 'none'} padding="4px">
            <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                {icon}
                <Text fontSize="18px">{title}</Text>
            </View>
        </Button>
    )
}


const PrimaryYellow = ({setting, setSetting}: PrimaryYellowProps) => {

    const onClickAccountSettings = () => {
        setSetting('account')
    }

    const onClickVerify = () => {
        setSetting('verify')
    }

    const onClickPrivacy = () => {
        setSetting('privacy')
    }

    const onClickLanguage = () => {
        setSetting('language')
    }

    const onClickNotifications = () => {
        setSetting('notification')
    }

    return (
        <View>
            <SettingSectionTitle title={'How you use Primary Yellow'} padding="0px 0px 24px 0px" />
            <View>
                <View display="flex" flexDirection="column" gap="20px">
                    <SettingsButton 
                        icon={<AccountIcon />} 
                        title={'Account Settings'} 
                        onClick={onClickAccountSettings} 
                        isActive={setting === 'account'}
                    />
                    <SettingsButton 
                        icon={<VerifyIcon />} 
                        title={'Verify Account'} 
                        onClick={onClickVerify} 
                        isActive={setting === 'verify'}
                    />
                    <SettingsButton 
                        icon={<LockIcon />} 
                        title={'Account Privacy'} 
                        onClick={onClickPrivacy} 
                        isActive={setting === 'privacy'}
                    />
                    <SettingsButton 
                        icon={<GlobeIcon />} 
                        title={'Language'} 
                        onClick={onClickLanguage} 
                        isActive={setting === 'language'}
                    />
                    <SettingsButton 
                        icon={<NotificationIcon />} 
                        title={'Notifications'} 
                        onClick={onClickNotifications} 
                        isActive={setting === 'notification'}
                    />
                </View>
            </View>
        </View>
    )
}

const PurchaseInformation = ({setting, setSetting}: PurchaseInformationProps) => {

    const onClickOrders = () => {
        setSetting('orders')
    }

    return (
        <View>
            <SettingSectionTitle title={'Purchase Information'} />
            <View>
                <View display="flex" flexDirection="column" gap="20px">
                    <SettingsButton 
                        icon={<OrdersNotificationIcon />} 
                        title={'Orders'} 
                        onClick={onClickOrders} 
                        isActive={setting === 'orders'}
                    />
                </View>
            </View>
        </View>
    )
}

const WhatYouSee = ({setting, setSetting}: PurchaseInformationProps) => {

    const onClickMuted = () => {
        setSetting('muted')
    }

    const onClickLikedShared = () => {
        setSetting('likeShare')
    }

    return (
        <View>
            <SettingSectionTitle title={'What you see'} />
            <View>
                <View display="flex" flexDirection="column" gap="20px">
                    <SettingsButton 
                        icon={<MutedIcon />} 
                        title={'Muted accounts'} 
                        onClick={onClickMuted} 
                        isActive={setting === 'muted'}
                    />
                    <SettingsButton 
                        icon={<LikeShareIcon />} 
                        title={'Like and share counts'} 
                        onClick={onClickLikedShared} 
                        isActive={setting === 'likeShare'}
                    />
                </View>
            </View>
        </View>
    )
}

const WhoCanSee = ({setting, setSetting}: PurchaseInformationProps) => {

    const onClickCloseFriends = () => {
        setSetting('closeFriends')
    }

    const onClickSubscriptions = () => {
        setSetting('subscriptions')
    }

    const onClickBlocked = () => {
        setSetting('blocked')
    }

    return (
        <View>
            <SettingSectionTitle title={'Who can see your content'} />
            <View>
                <View display="flex" flexDirection="column" gap="20px">
                    <SettingsButton 
                        icon={<CloseFriendsIcon />} 
                        title={'Close Friends'} 
                        onClick={onClickCloseFriends} 
                        isActive={setting === 'closeFriends'}
                    />
                    <SettingsButton 
                        icon={<SubscriptionsIcon />} 
                        title={'Subscriptions'} 
                        onClick={onClickSubscriptions} 
                        isActive={setting === 'subscriptions'}
                    />
                    <SettingsButton 
                        icon={<BlockedIcon />} 
                        title={'Blocked'} 
                        onClick={onClickBlocked} 
                        isActive={setting === 'blocked'}
                    />
                </View>
            </View>
        </View>
    )
}


const Panel = ({setting, setSetting}: PanelProps) => {
    
    return (
        <StyledPanelContainer>
            <StyledPanel>
                <PrimaryYellow setSetting={setSetting} setting={setting} />
                <PurchaseInformation setSetting={setSetting} setting={setting} />
                <WhatYouSee setSetting={setSetting} setting={setting} />
                <WhoCanSee setSetting={setSetting} setting={setting} />
            </StyledPanel>
        </StyledPanelContainer>
    )
}

export default Panel