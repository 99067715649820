import styled from 'styled-components'


interface SignInStateContainerProps {

}


export const StyledSignInStateContainer = styled.div<SignInStateContainerProps>`
    padding: 40px 0px 0px 0px;
`

interface SignInStatProps {

}


export const StyledSignInState = styled.div<SignInStatProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - 160px);
`









