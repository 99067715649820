import { Image } from "../Image"
import { StyledMuseumContainer, StyledMuseum } from "./styled"
import { Text } from "../Text";
import { View } from "../View";
import { Follow } from "../Button/Follow";
import { Button } from "../Button";
import { Navigate, useNavigate } from "react-router-dom";
import { usePostStore } from "../../stores/web.store";


type MuseumProps = {
    data?: any;
}


const Museum = ({data}: MuseumProps) => {
    const navigate = useNavigate()
    const setPost = usePostStore((state: any) => state.setPost)

    const onClickGallery = () => {
        setPost(data)
        navigate(`/museums/${data.id}`)
    }

    return (
        <StyledMuseumContainer>
            <StyledMuseum>
                <View padding="4px 0px 0px 0px">
                    <View display="inline-flex" alignItems="center" width="100%">
                        <View width="70%">
                            <Text fontWeight="600" padding="0px 0px 4px 0px">{data.owner.first_name}</Text>
                            <Text color="#6b6b6b" padding="0px 0px 4px 0px">{data.created_date}</Text>
                        </View>
                        <View width="30%" justifyContent="right" display="flex">
                            <Follow />
                        </View>
                    </View>
                </View>
                <Button noStyle onClick={onClickGallery}>
                    <Image src={data.media[0].url} width="100%" height="200px"/>
                </Button>
            </StyledMuseum>
        </StyledMuseumContainer>
    )
}

export default Museum