import styled from 'styled-components'

interface MediaUploadWrapperProps {

}

export const StyledMediaWrapper  = styled.div<MediaUploadWrapperProps>`

`

interface MediaUploadContainerProps {
    height?: string;
}

export const StyledMediaUploadContainer  = styled.div<MediaUploadContainerProps>`
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: solid 1px  #D9D9D9;
    border-radius: 10px;

    @media (min-width: 768px) {
        height: ${props => props.height || '600px'}
    }

    @media (min-width: 1024px) {

    }
`

interface MediaUploadProps {

}

export const StyledMediaUpload  = styled.div<MediaUploadProps>`
    height: 360px;

    @media (min-width: 768px) {
        height: 600px;
    }

    @media (min-width: 1024px) {

    }
`

interface UploadIconContainerProps {

}

export const StyledUploadContainer  = styled.div<UploadIconContainerProps>`

`

interface UploadTitleContainerProps {

}

export const StyledUploadTitleContainer  = styled.div<UploadTitleContainerProps>`
    padding: 12px;
    text-align: center;
`

interface UploadButtonContainerProps {

}

export const StyledUploadButtonContainer  = styled.div<UploadButtonContainerProps>`
    display: inline-flex;
    align-items: center;
    gap: 4px;
`

interface UploadedMediaContainerProps {
    padding?: string;
}

export const StyledUploadedMediaContainer  = styled.div<UploadedMediaContainerProps>`
    padding: ${props => props.padding || '12px 0px'};
    width: 100%;
    overflow-x: scroll;
`

interface UploadedMediaProps {
    gridGap?: string;
    total?: number;
}

export const StyledUploadedMedia  = styled.div<UploadedMediaProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.total || 5}, 1fr);
    grid-gap: ${props => props.gridGap || '16px'};
    width: ${props => ((props.total || 1) * 20)}px;
    margin: 0;
    border-radius: 12px;
`

interface UploadedPreviewContainerProps {
    height?: string;
    width?: string;
}

export const StyledUploadedPreviewContainer  = styled.div<UploadedPreviewContainerProps>`
    background: #D9D9D9;
    position: relative;
    border-radius: 10px;
    height: 48px;
    width: 48px;
    // & img {
    //     height: 48px;
    //     width: 48px;
    // }

    &:hover {
        img {
            filter: brightness(50%);
        }        
    }

    @media (min-width: 768px) {
        height: ${props => props.height || '80px'};
        width: ${props => props.width || '80px'};
        
    
        & img {
            ${props => props.height || '80px'};
            ${props => props.width || '80px'};
        }

    }

    @media (min-width: 1024px) {

    }
`

interface UploadedPreviewProps {

}

export const StyledUploadedPreview = styled.div<UploadedPreviewProps>`
    height: inherit;
    width: inherit;
`

interface RemoveImageContainerProps {

}

export const StyledRemoveImageContainer = styled.div<RemoveImageContainerProps>`
    position: absolute;
    top: 4px;
    right: 4px;
`

