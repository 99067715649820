import { StyledRippleLoader, StyledRippleLoaderContainer } from "./styled"


const RippleLoader = ({zoom}: any) => {
    return (
        <StyledRippleLoaderContainer>
            <StyledRippleLoader className="lds-ripple" zoom={zoom}>
                <div></div><div></div>
            </StyledRippleLoader>
        </StyledRippleLoaderContainer>
    ) 
}

export default RippleLoader