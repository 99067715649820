import styled from 'styled-components'


interface ProfileContainerProps {

}


export const StyledProfileContainer = styled.div<ProfileContainerProps>`
    padding: 24px 12px 12px 12px;

    @media (min-width: 768px) {
        padding: 24px 0px;
    }
    
    @media (min-width: 1600px) {
    }

    
`

interface ProfileProps {
    alignItems?: string;
}


export const StyledProfile = styled.div<ProfileProps>`
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;

    @media (min-width: 768px) {
        display: inline-flex;
        width: 100%;
        flex-wrap: nowrap;
        gap: 48px;
    }
    
    @media (min-width: 1600px) {
    }
`

interface ProfileSectionProps {
    width?: string
}

export const StyledPhotoSection = styled.div<ProfileSectionProps>`
    width: 80px;
    position: relative;
    @media (min-width: 768px) {
        width: 200px;
    }

    @media (min-width: 1600px) {
    }
`



export const StyledBioSection = styled.div<ProfileSectionProps>`
    flex-grow: 1;
    @media (min-width: 768px) {
        width: 100%;
    }

    @media (min-width: 1600px) {
    }
`


export const StyledFeaturedRepresentationSection = styled.div<ProfileSectionProps>`
    flex-grow: 1;
    @media (min-width: 768px) {
        width: 100%;
    }

    @media (min-width: 1600px) {
    }
`

interface BioContainerProps {

}


export const StyledBioContainer = styled.div<BioContainerProps>`
`

interface BioProps {

}


export const StyledBio = styled.div<BioProps>`
`

interface NameContainerProps {

}


export const StyledNameContainer = styled.div<NameContainerProps>`
`


interface BioNameProps {

}


export const StyledBioName = styled.div<BioNameProps>`
    display: inline-flex;
`

interface RoleContainerProps {

}


export const StyledRoleContainer = styled.div<RoleContainerProps>`
`

interface UserBioContainerProps {

}


export const StyledUserBioContainer = styled.div<UserBioContainerProps>`
`

interface BioButtonsContainerProps {

}


export const StyledBioButtonsContainer = styled.div<BioButtonsContainerProps>`
    display: inline-flex;
    gap: 20px;
    padding: 12px 0px;
`

interface FollowedByContainerProps {

}


export const StyledFollowedByContainer = styled.div<FollowedByContainerProps>`
    
`

interface FollowedByProps {

}


export const StyledFollowedBy = styled.div<FollowedByProps>`
    display: inline-flex;
`

interface FollowedByPhotoContainerProps {

}


export const StyledFollowedByPhotoContainer = styled.div<FollowedByPhotoContainerProps>`
    display: inline-flex;
`









