import ZoomVideo from '@zoom/videosdk'

import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'

import { useEffect, useState } from 'react'
import { View } from '../../View'
import { Button } from '../../Button'
import { useAccountStore, useMeetingStore } from '../../../stores/web.store'
import api from '../../../services/api.service'


const SessionContainer = () => {
    const meeting = useMeetingStore((state: any) => state.meeting)
    const setMeeting = useMeetingStore((state: any) => state.setMeeting)
    const account = useAccountStore((state: any) => state.account)
    const [sessionContainer, setSessionContainer] = useState<any>()

    const fetch = async () => {
        const payload = {
            role: account.id === meeting.owner_id ? 0 : 1,
            passcode: meeting.passcode,
            topic: meeting.topic
        }

        const resp = await api.post(`/service/zoom`, payload)
        return {...meeting, token: resp.token, state: "active", passcode: meeting.passcode}
    }



    const onClickStartMeeting = async () => {
        const sessionContainer = document.getElementById('sessionContainer') as any
        const resp = await fetch()
        setMeeting(resp)

        var config = {
            videoSDKJWT: resp.token,
            sessionName: resp.topic,
            sessionPasscode: resp.passcode,
            userName: account.username || `${account.first_name} ${account.last_name}`,
            features: resp.type === 'Audio' ? ['audio'] : ['video', 'audio', 'share', 'chat', 'users', 'settings']
        }

        setSessionContainer(sessionContainer)
        uitoolkit.joinSession(sessionContainer, config)
    }

    const onClickLeave = () => {
        uitoolkit.closeSession(sessionContainer)
    }

    console.log(meeting)
    return (
        <View>
            <View id="sessionContainer" height={meeting.state === 'pending' ? '0' : '100vh'}/>
            {meeting.state === 'pending' && <View>
                <Button onClick={onClickStartMeeting} padding='12px 32px' color='#fff' fontSize='16px'>
                    {account.id === meeting.owner_id ? 'Start Meeting' : 'Join'}
                </Button>
                {/* <Button onClick={onClickLeave}>Leave</Button> */}
            </View>}
        </View>
        
    )
}

const Zoom = () => {
    return (
        <View padding='0px 24px'>
            <SessionContainer />
        </View>
    )
}

export default Zoom