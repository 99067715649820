import styled from "styled-components";


interface AboutTheWorkContainerProps {

}

export const StyledAboutTheWorkContainer = styled.div<AboutTheWorkContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`

interface AboutTheWorkProps {

}

export const StyledAboutTheWork = styled.div<AboutTheWorkProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`