import { ReactComponent as AccountIcon } from '../../img/account.svg';
import { ReactComponent as NotificationIcon } from '../../img/notification.svg';
import { Button } from "../../components/Button"
import { StyledAccountMenu, StyledAccountMenuContainer, StyledMenuItem, StyledTopNav, StyledTopNavContainer, StyledTopNavLeftSection, StyledTopNavMiddleSection, StyledTopNavRightSection, StyledTopNavVerticalAlign, StyledTopNavWrapper } from "./styled"
import { SearchBar } from "../../components/SearchBar"
import { Logo } from "../../components/Logo"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { signout } from "../../services/auth.service"
import { TopNavMenu } from './TopNavMenu';




type TopNavProps = {
    includeNavigation?: boolean;
}


const TopNav = ({includeNavigation}: TopNavProps) => {
    const navigate = useNavigate()
    const [showAccountSettings, setShowAccountSettings] = useState(false)

    const onClickSignout = () => {
        signout()
    }

    const onClickProfile = () => {
        navigate('/dashboard')
    }

    return (
        <StyledTopNavContainer>
            <StyledTopNavWrapper>
                <StyledTopNav>
                    <StyledTopNavLeftSection>
                        <StyledTopNavVerticalAlign>
                            <SearchBar />
                        </StyledTopNavVerticalAlign>
                    </StyledTopNavLeftSection>
                    <StyledTopNavMiddleSection>
                        <StyledTopNavVerticalAlign>
                            <Logo path={'/'}/>
                        </StyledTopNavVerticalAlign>
                    </StyledTopNavMiddleSection>
                    <StyledTopNavRightSection>
                        <StyledTopNavVerticalAlign>
                            {/* <Button noStyle onClick={() => setShowAccountSettings(!showAccountSettings)}>
                                <NotificationIcon height={20} width={20} />
                            </Button> */}
                            <Button noStyle onClick={() => setShowAccountSettings(!showAccountSettings)}>
                                <AccountIcon height={48} width={48} />
                            </Button>
                            
                        </StyledTopNavVerticalAlign>
                        {showAccountSettings && <StyledAccountMenuContainer>
                            <StyledAccountMenu>
                                <StyledMenuItem padding='4px'><Button color={'#000'} noStyle onClick={onClickSignout}>Signout</Button></StyledMenuItem>
                                <StyledMenuItem padding='4px'><Button color={'#000'} noStyle onClick={onClickProfile}>Profile</Button></StyledMenuItem>
                            </StyledAccountMenu>
                        </StyledAccountMenuContainer>}
                    </StyledTopNavRightSection>
                    
                </StyledTopNav>
                {includeNavigation && <TopNavMenu />}
            </StyledTopNavWrapper>
            
        </StyledTopNavContainer>
    )
}

export default TopNav