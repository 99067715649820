import styled from 'styled-components'


interface AuctionsContainerProps {

}


export const StyledAuctionsContainer = styled.div<AuctionsContainerProps>`

`

interface AuctionsProps {

}


export const StyledAuctions = styled.div<AuctionsProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 4px;
`

interface AuctionsFiltersContainerProps {

}


export const StyledAuctionsFiltersContainer = styled.div<AuctionsFiltersContainerProps>`
    padding: 12px 0px 24px 0px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 0;
`

interface SearchAndSortProps {

}


export const StyledSearchAndSort = styled.div<SearchAndSortProps>`
    display: inline-flex;
    width: 100%;
    gap: 24px;
`


