import styled from 'styled-components'


interface PostAvailabilityItemContainerProps {

}


export const StyledPostAvailabilityItemContainer = styled.div<PostAvailabilityItemContainerProps>`
    padding: 8px 0px;
`

interface PostAvailabilityItemProps {

}

export const StyledPostAvailabilityItem = styled.div<PostAvailabilityItemProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 4px;
    padding: 4px 0px;
`

interface ChildContainerProps {
    isExpanded?: boolean;
}

export const StyledChildContainer = styled.div<ChildContainerProps>`
    padding: 4px 0px;
`

interface ChildProps {

}

export const StyledChild = styled.div<ChildProps>`
    padding: 4px 12px 4px 12px;
    border: solid 1px #0938F0;
    border-radius: 4px;
`

interface ChildContentProps {

}

export const StyledChildContent = styled.div<ChildContentProps>`

`





