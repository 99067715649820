import { useState } from "react";
import { Button } from "../../components/Button"
import { Switch } from "../../components/Switch"
import { Text } from "../../components/Text"
import { ReactComponent as ChevronDown } from '../../img/chevronDown.svg';
import { StyledShippingAndFeesContainer, StyledShippingAndFees } from "./styled"
import { ShippingAddress } from "../../components/ShippingAddress";
import { View } from "../../components/View";
import { toCurrency } from "../../utils/app.util";
import {ReactComponent as EditIcon} from '../../img/edit.svg'
import {ReactComponent as XIcon} from '../../img/x.svg'

const ShippingAndFees = ({setPayload, payload}: any) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [shipping, setShipping] = useState<any>({})


    const onClickEditAddress = () => {
        setShipping({...shipping, isHiddenForm: !shipping.isHiddenForm})
    }

    const onClickExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <StyledShippingAndFeesContainer>
            <Button noStyle width="100%" onClick={onClickExpanded} textAlign="left">
                <View  width="80%">
                    <Text fontWeight="500" fontSize="16px">{`Shipping and Fees`}</Text>
                </View>
                <View width="20%" textAlign="right">
                    <ChevronDown />
                </View>
            </Button>
            <StyledShippingAndFees isExpanded={shipping !== null || isExpanded}>
                {isExpanded && shipping.address && <View display="inline-flex" alignItems="center" gap="8px" padding="4px 2px">
                        <Text>{shipping.address.to_address.city}, {shipping.address.to_address.state}</Text>
                        <Button noStyle onClick={onClickEditAddress}>
                            {shipping.isHiddenForm ? <EditIcon height={12} width={12} /> : <XIcon height={12} width={12} />}
                        </Button>
                </View>}
                <View height={isExpanded && !shipping.isHiddenForm ? 'auto' :'0'} overflow="hidden">
                    <ShippingAddress shipping={shipping} setShipping={setShipping}/>
                </View>
                {isExpanded && shipping.isHiddenForm && shipping.address && <View>
                    
                    <View padding="4px 2px">
                        <Text><b>Shipping Estimates</b></Text>
                        <View>
                            {shipping.rates && shipping.rates.map((rate: any) => {
                                return (
                                    <View padding="4px 0px">
                                        <Text fontWeight="600" fontSize="6px">{rate.carrier}</Text>
                                        <View display="inline-flex" alignItems="center" gap="4px">
                                            <Text fontWeight="500" fontSize="11px">{rate.service}:</Text>
                                            <Text fontSize="11px">{toCurrency(rate.rate)}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                    
                </View>}
            </StyledShippingAndFees>
        </StyledShippingAndFeesContainer>
    )
}

export default ShippingAndFees