import { StyledIcon } from "./styled"

type IconProps = {
    children: any;
    padding: string;
}


const Icon = ({padding, children}: IconProps) => {
    return (
        <StyledIcon padding={padding}>
            {children}
        </StyledIcon>
    )
}

export default Icon