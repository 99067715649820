import { usePostStore, useProductDetailPageStore } from "../../../stores/web.store"
import { toDate } from "../../../utils/app.util"
import { ImageGallery } from "../../ImageGallery"
import { Text } from "../../Text"
import { View } from "../../View"


const Featured = ({data}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)

    const onClick = () => {
        setPost(data)
        setPdp('auction')
    }

    if (!data) return <div />

    return (
        <View>
            <View display="inline-flex" alignItems="center" width="100%" gap="48px">
                <View width="50%">
                    <ImageGallery data={data} height={529} setPost={setPost} onClick={onClick} borderRadius={"10px"}/>
                </View>
                <View width="50%">

                   <View>
                        <Text fontSize="32px" fontWeight="500">{data.title}</Text>
                    </View> 
                    <View padding="12px 0px">
                        <Text fontSize="20px">{toDate(data.metadata.start_datetime)}</Text>
                    </View>
                    <View>
                        <Text fontSize="14px" lineHeight="20px">{data.caption}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Featured