import styled from "styled-components"


interface FeedContainerProps {

}

export const StyledFeedContainer = styled.div<FeedContainerProps>`
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
`

interface FeedProps {

}

export const StyledFeed = styled.div<FeedProps>`
    
`