import styled from 'styled-components'


interface CheckoutProps {
    
}

export const StyledCheckout = styled.div<CheckoutProps>`
    display: inline-flex;
    width: 100%;
    gap: 42px;
`

export const StyledProductContainer = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
`

export const StylePriceContainer = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
    border-top: none;
`

export const StyledPurchaseProtected = styled.div`
    padding: 24px;
    border-radius: 10px;
    background: #F1F1F1;
`

export const StyledReviewShopTo = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
`

export const StyledReviewPaymentMethod = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
    border-top: none;
`

export const StyledStepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;
`;

export const StyledBillingShipping = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`

export const StyledStep = styled.div<{ isActive: boolean }>`
  flex: 1;
  text-align: center;
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: ${(props) => (props.isActive ? "bold" : "400")};
  border-bottom: ${(props) => (props.isActive ? "1px solid #000" : "1px solid #e0e0e0")};
`;
