import styled from "styled-components";

interface SlideMenuContainerProps {
    slideMenu?: any;
    isExpanded?: boolean;
    isHalfWidth?: boolean;
    menuKey?: string;
}

export const StyledSlideMenuContainer = styled.div<SlideMenuContainerProps>`
    position: fixed;
    top: 0;
    background: #fff;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 5;
    left: 0;
    height: calc(100vh - 64px);
    width: ${props => props.slideMenu.isActive ? '100%': '0'};

    @media (min-width: 768px) {
        background: rgba(255, 255, 255, 0);
        height: 100vh;
        width: ${props => props.slideMenu.isActive ? '100%': '0'};
        
        border-right: ${props => props.slideMenu.isActive ? 'solid 1px #D7D7D7' : 'none'};
        
        left: ${props => props.isExpanded ? '225px' : '67px'};

        ${props => props.slideMenu.location === 'right' && `
            right: 0;
        `}
    }

    @media (min-width: 1024px) {

    }

`

interface SlideMenuProps {
    slideMenu?: any;
    isHalfWidth?: boolean;
    menuKey?: string;
}

export const StyledSlideMenu = styled.div<SlideMenuProps>`
    width: ${props => props.slideMenu.isActive ? '100%': '0'};
    


    @media (min-width: 768px) {
        background: #fff;
        height: 100vh;
        width: ${props => props.slideMenu.isActive ? '30%': '0'};
        
        // border-right: ${props => props.slideMenu.isActive ? 'solid 1px #D7D7D7' : 'none'};
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;

        -webkit-box-shadow: 4px 0px 5px -6px rgba(0,0,0,0.75);
        -moz-box-shadow: 4px 0px 5px -6px rgba(0,0,0,0.75);
        box-shadow: 4px 0px 5px -6px rgba(0,0,0,0.75);


        ${props => props.isHalfWidth && `
            width: ${props.slideMenu.isActive ? '50%': '0'};
        `}

        ${props => props.menuKey === 'filters' && `
            width: ${props.slideMenu.isActive ? '30%': '0'};
        `}
    }

    @media (min-width: 1024px) {

    }
`