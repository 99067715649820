import { Title } from "../../components/Page/Title"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useAllMyPosts } from "../../hooks/api.hook"
import { useEffect, useState } from "react"
import { useAccountStore, useViewNavigatorStore } from "../../stores/web.store"
import { View } from "../../components/View"
import { ArtPatronFilters } from "../../components/Filters/ArtPatronFilters"
import { Featured } from "../../sections/Featured"
import { ArtPatron } from "../../sections/ArtPatron"


const ArtPatrons = () => {
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const account = useAccountStore((state: any) => state.account) 
    const [api, setApi] = useState<any>({})
    const {data} = useAllMyPosts({query: {owner_id: account?.id}})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApi(data)
        }
        setActiveView('live')
    }, [data])

    if (account === null) return <div />

    
    if (!api.data) return <div />

    return (
        <DashboardLayout>
            <View padding="24px">
                <View>
                    <Title 
                        title={'Art Patrons'} 
                        description={`Browse over ${1000} artists.`}
                    />
                </View>
                <View padding="32px 0px 12px 0px">
                    <ArtPatronFilters />
                </View>
                <View padding="0px 0px 24px 0px">
                    <Featured 
                        data={api.data} 
                        height={`300px`}
                        columns={api.data.length} 
                        width={`${api.data.length / 3 * 100}%`} 
                        noTitle
                    />
                </View>
                <View padding="24px 0px">
                    <View display="flex" gap="32px" flexDirection="column">
                        {api.data.map((artPatron: any) => {
                            return (
                                <ArtPatron data={artPatron}/>
                            )
                        })}
                        
                    </View>
                </View>
            </View>
        </DashboardLayout>
    )
}

export default ArtPatrons