import { usePostPayloadStore, usePostStore } from "../../stores/web.store"
import { Post } from "../Post"
import { Text } from "../Text"
import { View } from "../View"
import { StyledUploadingBar, StyledUploadingBarContainer, StyledUploadingPostContainer, StyledUploadingProgress, StyledUploadingProgressContainer } from "./styled"


const UploadingPost = () => {
    const post = usePostStore((state: any) => state.post)
    return (
        <StyledUploadingPostContainer>
            <StyledUploadingBarContainer>
                <StyledUploadingBar>
                    <View width="48px" height="100%">
                        <Post data={post} width="48px" height="100%" noIcon/>
                    </View>
                    <Text width="fit-content" fontWeight="500">{`Uploading Post`}</Text>
                    <StyledUploadingProgressContainer>
                        <StyledUploadingProgress percentage={50}/>
                    </StyledUploadingProgressContainer>
                </StyledUploadingBar>
            </StyledUploadingBarContainer>
            
        </StyledUploadingPostContainer>
    )
}

export default UploadingPost