import styled from 'styled-components'

interface CameraRollWrapperProps {

}

export const StyledCameraRollWrapper  = styled.div<CameraRollWrapperProps>`

`

interface CameraRollContainerProps {
    padding?: string;
}

export const StyledCameraRollContainer  = styled.div<CameraRollContainerProps>`
    padding: ${props => props.padding || '12px 0px'};
    width: 100%;
    overflow-x: scroll;
`

interface CameraRollProps {
    gridGap?: string;
    total?: number;
}

export const StyledCameraRoll  = styled.div<CameraRollProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.total || 5}, 1fr);
    grid-gap: ${props => props.gridGap || '16px'};
    width: ${props => ((props.total || 1) * 20)}px;
    margin: 0;
    border-radius: 12px;
`

interface PreviewContainerProps {
    height?: string;
    width?: string;
}

export const StyledPreviewContainer  = styled.div<PreviewContainerProps>`
    background: #D9D9D9;
    position: relative;
    border-radius: 10px;
    height: 48px;
    width: 48px;
    // & img {
    //     height: 48px;
    //     width: 48px;
    // }

    &:hover {
        img {
            filter: brightness(50%);
        }        
    }

    @media (min-width: 768px) {
        height: ${props => props.height || '80px'};
        width: ${props => props.width || '80px'};
        
    
        & img {
            ${props => props.height || '80px'};
            ${props => props.width || '80px'};
        }

    }

    @media (min-width: 1024px) {

    }
`

interface PreviewProps {

}

export const StyledPreview = styled.div<PreviewProps>`
    height: inherit;
    width: inherit;
`

