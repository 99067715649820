import { useEffect } from "react"
import { useAccountStore, usePostsStore } from "../../stores/web.store"
import { FeedPost } from "../FeedPost"
import { StyledFeed, StyledFeedContainer } from "./styled"
import { View } from "../View"


const Feed = ({posts}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const setPosts = usePostsStore((state: any) => state.setPosts)
    useEffect(() => {
        setPosts(posts)
    }, [])

    return (
        <StyledFeedContainer id="FeedContainer">
            <StyledFeed>
                {posts.map((obj: any) => {
                    if (account.id !== obj.user_id) {
                        return (
                            <View key={`feed-post-${obj.id}`}>
                                <FeedPost data={obj} type={obj.state}/>
                            </View>
                        )
                    }
                    return <View key={`feed-post-${obj.id}`} display="none"/>
                })}
            </StyledFeed>
        </StyledFeedContainer>
    )
}

export default Feed