import styled from 'styled-components'

interface ShowsContainerProps {

}


export const StyledShowsContainer = styled.div<ShowsContainerProps>`
    padding: 24px 0px 0px 0px;
`

interface ShowsProps {

}


export const StyledShows = styled.div<ShowsProps>`
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 430px;
    
    margin: auto;
`

interface ShowProps {

}

export const StyledShow = styled.div<ShowProps>`
    margin: auto;
    position: relative;

    background: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    



    & .show-details {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }   
    &:hover {
        .show-details {
            max-height: 200px;
        }
    }
`

interface ShowDetailsProps {

}

export const StyledShowDetails = styled.div<ShowDetailsProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    height: fit-content;
    width: 100%;
    background: rgba(0,0,0,.25);
    color: #fff;
    border-radius: 0px 0px 10px 10px;
`


