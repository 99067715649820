import styled from 'styled-components'

interface ButterBarContainerProps {
    background?: string;
    color?: string;
    width?: string;
    left?: string;
}


export const StyledButterBarContainer = styled.div<ButterBarContainerProps>`
    position: fixed;
    top: 0;
    left: ${props => props.left || '0'};
    width: ${props => props.width || '100%'};
    height: 60px;
    color: ${props => props.color || '#000'};
    background: ${props => props.background || '#fff'};
    display: table;
    border-bottom: solid 1px ${props => props.background || '#000'};
    z-index: 9;
`

interface ButterBarProps {

}

export const StyledButterBar = styled.div<ButterBarProps>`
    display: table-cell;
    width: 100%;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    height: 60px;
    position: relative;
`

interface ButterBarCloseProps {

}

export const StyledButterBarClose = styled.div<ButterBarCloseProps>`
    position: absolute;
    right: 12px;
    top: 18px;

`