import { useState } from "react"
import { Plaid } from "../../components/Services/Plaid"
import { DiscoverPeople } from "../../sections/DiscoverPeople"
import { Feed } from "../../sections/Feed"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { RecentActivity } from "../../sections/RecentActivity"
import { StyledDashboard, StyledDashboardContainer, StyledDashboardContent, StyledInfoSection } from "./styled"
import { Onboarding } from "../../components/Services/Stripe/Onboarding"


const Dashboard = () => {
    return (
        <DashboardLayout>
            <StyledDashboardContainer>
                <StyledDashboard>
                    {/* <Plaid /> */}
                    {/* <Onboarding /> */}
                    <StyledDashboardContent>
                        <Feed />
                        <StyledInfoSection>
                            <RecentActivity />
                            <DiscoverPeople />
                        </StyledInfoSection>
                    </StyledDashboardContent>
                </StyledDashboard>
            </StyledDashboardContainer>
        </DashboardLayout>
    )
}

export default Dashboard