import { Title } from "../../components/Page/Title"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useAllMyPosts, useAuctions } from "../../hooks/api.hook"
import { useEffect, useState } from "react"
import { useAccountStore, usePostStore, useProductDetailPageStore, useViewNavigatorStore } from "../../stores/web.store"
import { ViewNavigator } from "../../sections/ViewNavigator"
import { View } from "../../components/View"
import { Auction } from "../../components/Auction"
import api from "../../services/api.service"
import InfiniteScroll from "react-infinite-scroll-component"
import { QuickFilters } from "../../components/Filters/QuickFilters"
import { StyledAuctions, StyledAuctionsContainer } from "./styled"
import { Featured } from "../../components/Auction/Featured"

type AuctionsQuickFiltersProps = {
    posts: any;
    setPosts: any;
    setHasMore?: any;
    setOffset?: any;
}


const AuctionsQuickFilters = ({posts, setPosts, setHasMore, setOffset}: AuctionsQuickFiltersProps) => {
    const [selectedFilter, setSelectedFilter] = useState('all')

    useEffect(() => {
        const fetch = async () => {
            const filter = (selectedFilter === 'all') ? {} : {type: selectedFilter}
            
            
            const resp = await api.post(`/search/auctions`, {query: filter, offset: 0})
            setPosts(resp.data)
            setHasMore(resp.data.length > 0) 
            setOffset(0);
        }

        fetch()

    }, [selectedFilter])

    return (
        <QuickFilters selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} includeAll includeLiveAuctions includeTrendingLots includeEndingSoon includeUpcomingAuctions includePastAuctions />
    )
}

const Auctions = () => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const account = useAccountStore((state: any) => state.account) 
    const [apiResp, setApiResp] = useState<any>({})
    const {data} = useAuctions({query: {}})


    const [auctionPosts, setAuctionPosts] = useState<any>([])

    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
            setAuctionPosts(data.data)
        }
        setActiveView('live')
    }, [data])


    const fetchMoreData = async () => {
        const newOffset = offset + 1
        const resp = await api.post(`/search/auctions`, {query: {}, offset: newOffset})

        setAuctionPosts([...auctionPosts, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
    };

    const onClick = (post: any) => {
        setPost(post)
        setPdp('auction')
    }

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
        }
        setActiveView('live')
    }, [data])

    if (account === null) return <div />

    
    if (!apiResp.data) return <div />

    return (
        <DashboardLayout>
            <View padding="24px">
                <View>
                    <Title 
                        title={'Auctions'} 
                        description={'Buy and collect auctioned art by verified artists on Primary Yellow'}
                    />
                </View>

                <View padding="40px 0px 12px 0px" borderBottom="solid 1px #A7A7A7">
                    <AuctionsQuickFilters posts={auctionPosts} setPosts={setAuctionPosts} setHasMore={setHasMore} setOffset={setOffset}/>
                </View>

                <View padding="40px 0px" borderBottom="solid 1px #A7A7A7">
                    <Featured data={apiResp.data[0]} />
                </View>

                <StyledAuctionsContainer id="Auctions">
                    <InfiniteScroll
                        dataLength={auctionPosts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="Auctions"
                    >
                    <StyledAuctions>
                        {auctionPosts.map((auction: any) => {
                            return (
                                <Auction data={auction} />
                            )
                        })}
                    </StyledAuctions>
                    </InfiniteScroll>
                </StyledAuctionsContainer>
            </View>
        </DashboardLayout>
    )
}

export default Auctions