import { useEffect, useState } from "react"
import { StyledShows, StyledShowsContainer } from "../../../sections/Shows/styled"
import { ShowFilters } from "../../Filters/ShowFilters"
import { Title } from "../../Page/Title"
import { View } from "../../View"
import { useFiltersStore, usePostStore, useProductDetailPageStore, useViewNavigatorStore } from "../../../stores/web.store"
import { useParams } from "react-router-dom"
import api from "../../../services/api.service"
import { Post } from "../../Post"
import { toAuctionState, toDate, toDateDiff } from "../../../utils/app.util"
import { Button } from "../../Button"
import { Text } from "../../Text"
import {ReactComponent as ChevronLeftIcon} from '../../../img/chevronLeft.svg'
import { ImageGallery } from "../../ImageGallery"
import InfiniteScroll from "react-infinite-scroll-component"

const Lot = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const post = usePostStore((state: any) => state.post)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const [timeValue, setTimeValue] = useState<any>()
    const [auctionState, setAuctionState] = useState<any>('')
    const [posts, setPosts] = useState<any>([])
    const {id} = useParams()
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.post('/search/posts', {
                query: {
                    query: filterStore.query, 
                    ...{
                        ...filterStore.filters, 
                        "parent_post.id": post.id,
                        type: "artwork"
                    }
                }
            })
            setPosts(resp.data)
        }

        if (filterStore.loaded)
            fetch()
    }, [filterStore])
    
    useEffect(() => {
        setActiveView('overview')
        const fetch = async () => {
            const resp = await api.get(`/posts/${id}`)
            setPost(resp.data.data)
            setPosts(resp.data.data.posts)
            setTimeValue(toDateDiff(resp.data.data.metadata.end_datetime, true))
        }

        if (!post && id) {
            fetch()
        } else {
            setTimeValue(toDateDiff(post.metadata.end_datetime, true))
            setPosts(post.posts)
        }
            
        
    }, [])

    useEffect(() => {
        toAuctionState(post, setTimeValue, setAuctionState)
    }, [])

    const fetchMoreData = async () => {
        const newOffset = offset + 1
        const resp = await api.post('/search/posts', {
            query: {
                query: filterStore.query, 
                ...{
                    ...filterStore.filters, 
                    "parent_post.id": post.id,
                    type: "artwork"
                },
                offset: newOffset
            }
        })

        setPosts([...posts, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
    };

    const onClickBack = () => {
        setPost(null)
        setPdp(null)
    }

    const onClickPost = (data: any) => {
        setPost(data)
        setPdp('auctionItem')
    }

    const onClickAuction = () => {}


    if (post === null) return <div />


    return (
        <View>
                <View>
                    <Button noStyle onClick={onClickBack}>
                        <ChevronLeftIcon height={16} width={16}/> 
                        <Text fontWeight="500">{`Back`}</Text>
                    </Button>
                </View>
                <View padding="24px 0px">
                    <View display="inline-flex" alignItems="start" width="100%" gap="30px">
                        <View width="50%">
                            <ImageGallery data={post} height={420} setPost={setPost} onClick={onClickAuction} borderRadius={"10px"} />
                        </View>
                        <View width="50%" position="relative" height="420px">
        
                            <View>
                                <Text fontSize="24px" fontWeight="500">{post.title}</Text>
                            </View>
                            <View padding="24px 0px 6px 0px">
                                <View display="inline-flex" alignItems="center" gap="12px">
                                    <Text fontSize="20px">{`${toDate(post.metadata.start_datetime)} to ${toDate(post.metadata.end_datetime)}`}</Text>
                                    {auctionState === 'active' && <Button color="#fff" padding="6px 15px" borderRadius="15px" fontSize="15px">Live</Button>}
                                    {auctionState === 'upcoming' && <Button color="#fff" padding="6px 15px" borderRadius="15px" fontSize="15px">Upcoming</Button>}
                                    {auctionState === 'ended' && <Button color="#fff" padding="6px 15px" borderRadius="15px" fontSize="15px">Ended</Button>}
                                </View>
                            </View>
                            <View>
                                <Text color="#0938F0" fontWeight="500" fontSize="13px">{`Presented by ${post.owner.first_name} ${post.owner.last_name}`}</Text>
                            </View>
            
                        </View>
                    </View>
                </View>
                <View>
                    <ShowFilters setPosts={setPosts} posts={posts} post={post} resetData={post.posts}/>
                </View>
                <View id="Auctions" min-height="400px">
                    <InfiniteScroll
                        dataLength={posts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="Auctions"
                    >
                        <StyledShowsContainer>
                            <StyledShows>
                                {posts.map((auction: any) => {
                                    return (
                                        <Post 
                                            data={{...auction, parent_post: post}} 
                                            type={auction.type} 
                                            setPost={setPost} 
                                            onClick={() => onClickPost({...auction, parent_post: post})} 
                                            noIcon
                                        />
                                    )
                                })}
                            </StyledShows>
                        </StyledShowsContainer>
                    </InfiniteScroll>
                </View>
            </View>
    )
}

export default Lot