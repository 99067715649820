import { useState } from "react";
import { Button } from "../Button"
import { Image } from "../Image";
import { StyledTag, StyledTagContainer, StyledTagImageContainer, StyledTagRemoveContainer, StyledTagTextContainer } from "./styled"


type TagProps = {
    image?: string;
    text?: string;
    actionCallback?: any;
    actionIcon?: any;
    padding?: string;
}


const Tag = ({image, text, actionIcon, actionCallback, padding}: TagProps) => {
    
    return (
        <StyledTagContainer padding={padding}>
            <StyledTag>
                {image && <StyledTagImageContainer>
                    <Image src={image} width='20px' height='20px' borderRadius='20px' border={'solid 1px #000'}/>
                </StyledTagImageContainer>}
                {text && <StyledTagTextContainer>
                    {text}
                </StyledTagTextContainer>}
                {actionCallback && <StyledTagRemoveContainer>
                    <Button noStyle onClick={actionCallback}>x
                        {actionIcon}
                    </Button>
                </StyledTagRemoveContainer>}
            </StyledTag>
        </StyledTagContainer>
    )
}

export default Tag