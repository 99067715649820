import styled from 'styled-components'


interface BoardsProps {

}


export const StyledBoards = styled.div<BoardsProps>`
    column-count: 1; 
    column-gap: 10px;

    @media (min-width: 768px) {
        column-count: 4; 
    }

    @media (min-width: 1024px) {
        column-count: 6; 

    }
`

