import { useEffect, useState } from "react"
import { Post } from "../../components/Post"
import { StyledAllPosts, StyledAllPostsContainer } from "./styled"
import { Modal } from "../../components/Modal"
import { PostDetails } from "../../components/PostDetails"
import { EmptyPosts } from "../../components/EmptyPosts"
import { Button } from "../../components/Button"
import { useAccountStore, useAllMyPostsStore, useModalStore, usePostStore, useProfileStore } from "../../stores/web.store"


const AllPosts = ({data}: any) => {
    const account = useAccountStore((state: any) => state.account) 
    const profile = useProfileStore((state: any) => state.profile) 

    const setPost = usePostStore((state: any) => state.setPost)
    const post = usePostStore((state: any) => state.post)
    // const account = useAccountStore((state: any) => state.account) 
    const allMyPosts = useAllMyPostsStore((state: any) => state.allMyPosts) 
    const setAllMyPosts = useAllMyPostsStore((state: any) => state.setAllMyPosts) 
    const isModal = useModalStore((state: any) => state.isModal)

    useEffect(() => {
        setAllMyPosts(data)
    }, [data])

    if (allMyPosts.length === 0 && account.id === profile.id) return (
        <EmptyPosts type="media" title="No posts yet">
            <Button fontWeight='600' fontSize='20px' noStyle>{'Create your first post'}</Button>
        </EmptyPosts>
    )

    return (
        <StyledAllPostsContainer>
            <StyledAllPosts>
                {allMyPosts.map((obj: any, index: number) => {
                    return (
                        <Post data={{...obj, index}} setPost={setPost} />
                    )
                })}
            </StyledAllPosts>
            {/* {isModal && post &&
                <Modal 
                    setModal={setPost} 
                    borderRadius="0"
                    padding="0"
                >
                <PostDetails data={post} isModal posts={data}/>
            </Modal>} */}
        </StyledAllPostsContainer>
    )
}

export default AllPosts