import styled from 'styled-components'


interface PanelContainerProps {

}

export const StyledPanelContainer = styled.div<PanelContainerProps>`

`

interface PanelProps {

}

export const StyledPanel = styled.div<PanelProps>`

`
