import { View } from "../../View"
import { StyledAccountActionsContainer, StyledAccountActions } from "./styled"
import { Button } from "../../Button"
import { useModalStore } from "../../../stores/web.store"

const AccountActions = ({setIsModal}: any) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    
    return (
        <StyledAccountActionsContainer>
            <StyledAccountActions>
                <View borderBottom="solid 1px #D7D7D7">
                    <Button noStyle padding="20px 0px" fontSize="15px" color="#FE0000" width="100%">Block</Button>
                </View>
                <View borderBottom="solid 1px #D7D7D7">
                    <Button noStyle padding="20px 0px" fontSize="15px" color="#FE0000" width="100%">Restrict</Button>
                </View>
                <View borderBottom="solid 1px #D7D7D7">
                    <Button noStyle padding="20px 0px" fontSize="15px" color="#FE0000" width="100%">Report</Button>
                </View>
                <View borderBottom="solid 1px #D7D7D7">
                    <Button noStyle padding="20px 0px" fontSize="15px" width="100%">Share to</Button>
                </View>
                <View borderBottom="solid 1px #D7D7D7">
                    <Button 
                        noStyle 
                        padding="20px 0px" 
                        fontSize="15px"
                        onClick={() => setModalKey('accountAbout')}
                        width="100%"
                    >About this account</Button>
                </View>
                <View>
                    <Button 
                        padding="16px 0px" 
                        fontSize="16px"
                        noStyle onClick={() => setIsModal(false)}
                        width="100%"
                    >Cancel</Button>
                </View>
            </StyledAccountActions>
        </StyledAccountActionsContainer>
    )
}

export default AccountActions