import styled from 'styled-components'


interface ShowsContainerProps {

}


export const StyledShowsContainer = styled.div<ShowsContainerProps>`
    min-height: 300px;
`

interface ShowsProps {

}


export const StyledShows = styled.div<ShowsProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 4px;
`


