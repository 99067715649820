import { Follow } from "../../../components/Button/Follow"
import { Image } from "../../../components/Image"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { usePostStore } from "../../../stores/web.store"
import { StyledAboutSection, StyledAboutSectionContainer } from "./styled"


const AboutSection = () => {
    const post = usePostStore((state: any) => state.post)
    return (
        <StyledAboutSectionContainer>
            <StyledAboutSection>
                <View>
                    <Image src={post.media[0].url} width="100%" height="400px"/>
                </View>
                <View display="flex" alignItems="start" justifyContent="start">
                    <View>
                        <Text 
                            width="100%" 
                            fontWeight="500" 
                            fontSize="20px"
                        >About</Text>
                        <Text width="100%">
                            {'Lorem ipsum dolor sit amet consectetur. Enim magna luctus morbi eu faucibus. Consectetur elit vitae varius montes purus. Condimentum viverra et odio malesuada. Amet sed congue dictumst tortor eros posuere lacinia.'}
                        </Text>
                        <View>
                            <Follow />
                        </View>
                    </View>
                </View>
            </StyledAboutSection>
        </StyledAboutSectionContainer>
    )
}

export default AboutSection