import { useRef } from "react"
import { PostActions } from "../PostActions"
import { CreateAuctionPostContent } from "./CreateAuctionPostContent"
import { StyledCreateAuctionPost, StyledCreateAuctionPostContainer } from "./styled"
import { CreateAuctionArtworkContent } from "./CreateAuctionArtworkContent"
import { CreateShowTemplateContent } from "../CreateShowPost/CreateShowTemplateContent"
import { useDashboardCreateModeStore, useMyShowsStore, usePostMapPayload, usePostPayloadStore, usePostStore, useResponsiveStore, useSelectedPostStore, useUploadStore } from "../../../stores/web.store"
import { uploadPost, uploadAuction } from "../../../hooks/api.hook"

import { StyledPostTitleContainer } from "../styled"
import { Text } from "../../../components/Text"
import { StyledPreview, StyledPreviewContainer, StyledPreviewSection, StyledPreviewSectionContainer } from "../CreateArtworkPost/styled"
import { Preview } from "../../../components/Auction/Preview"
import { useNavigate } from "react-router-dom"


const CreateAuctionPost = () => {
    const navigate = useNavigate()
    const setIsUploading = useUploadStore((state: any) => state.setIsUploading)
    const setUploadedResp = useUploadStore((state: any) => state.setUploadedResp)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const myShows = useMyShowsStore((state: any) => state.myShows) 
    const setMyShows = useMyShowsStore((state: any) => state.setMyShows)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)
    const postMapPayload = usePostMapPayload((state: any) => state.postMapPayload)
    const setPostMapPayload = usePostMapPayload((state: any) => state.setPostMapPayload)
    const setPost = usePostStore((state: any) => state.setPost)
    const width = useResponsiveStore((state: any) => state.width)
    
    const editorStateRef = useRef();

    const onClickCancel = () => {
        setIsCreateMode(false)
        setSelectedPost(null)
    }

    const onClickSaveDraft = async () => {
        const resp = await uploadPost({
            ...postPayload, 
            metadata: JSON.stringify(postPayload.metadata || {}),
            posts: JSON.stringify((postPayload.posts || []).map((post: any) => {return {...post, status: 'draft'}})),
            status: 'draft',
            type: 'auction'
        })
        setMyShows([resp.data, ...myShows])
        setIsCreateMode(false)
        setSelectedPost(null)
    }

    const onClickSharePost = async () => {
        const {posts, ...showPayload} = postPayload
        setIsUploading(true)
        navigate('/')

        const postData = {
            ...showPayload, 
            metadata: JSON.stringify(postPayload.metadata || {}),
            status: 'active',
            type: 'auction'
        }

        setPost(postData)
        const resp = await uploadAuction(postData)

        for (var post of posts) {
            await uploadPost({
                ...post,
                state: 'auction',
                parent_post_id: resp.data.id,
                metadata: JSON.stringify(post.metadata || {}),
                privacy_settings: postPayload.privacy_settings
            })
        }
        setUploadedResp(resp)
        setMyShows([resp.data, ...myShows])
        setIsCreateMode(false)
        setSelectedPost(null)
        setPostPayload(null)
        setPostMapPayload(null)
        setPost(null)
        setIsUploading(false)
    }

    const onClickAddPostToAuction = async () => {
        setPostPayload({
            ...postPayload, 
            posts: [...postPayload.posts, {
                ...postMapPayload,
                status: 'active',
                state: 'auction',
                type: 'artwork'
            }]
        })
        setPostMapPayload({state: 'auction'})
    }

    return (
        <StyledCreateAuctionPostContainer>
            <StyledPostTitleContainer>
                <Text fontSize={width > 768 ? "24px" : '20px'} fontWeight="500" padding="0px 0px 6px 0px">Auction Details</Text>
                <Text color="#6b6b6b" fontSize="16px">{"Add your auction's logistics and information about your artworks"}</Text>
            </StyledPostTitleContainer>
            
            <StyledCreateAuctionPost>
                <div>
                    <CreateAuctionPostContent editorStateRef={editorStateRef}/>
                    <PostActions 
                        onClickSaveDraft={onClickSaveDraft} 
                        onClickSharePost={onClickSharePost} 
                        onClickCancel={onClickCancel}
                        onClickAddPostToAuction={onClickAddPostToAuction}
                        postType={'auction'}
                    />
                    <CreateAuctionArtworkContent />
                    
                </div>
                <StyledPreviewSectionContainer>
                    <StyledPreviewSection>
                        <StyledPreviewContainer>
                            <StyledPreview>
                                <Preview />
                            </StyledPreview>
                        </StyledPreviewContainer>
                        {/* <StyledPreviewContainer>
                            <StyledPreview>
                                <CreateShowTemplateContent payload={postPayload} setPayload={setPostPayload}/>
                            </StyledPreview>
                        </StyledPreviewContainer> */}
                    </StyledPreviewSection>
                </StyledPreviewSectionContainer>
            </StyledCreateAuctionPost>
            
        </StyledCreateAuctionPostContainer>
    )
}

export default CreateAuctionPost