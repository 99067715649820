import { useState } from "react";
import { ReactComponent as ChevronDown } from '../../img/chevronDown.svg';
import { StyledLocation, StyledLocationContainer } from "./styled"
import { Button } from "../Button";
import { Text } from "../Text";


const Location = ({setPayload, payload}: any) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    return (
        <StyledLocationContainer>
            <Button noStyle width="100%" onClick={() => setIsExpanded(!isExpanded)} textAlign="left">
                <Text fontWeight="500" fontSize="16px" width="80%">Location</Text>
                <Text width="20%" textAlign="right"><ChevronDown /></Text>
            </Button>
            <StyledLocation>

            </StyledLocation>
        </StyledLocationContainer>
    )
}

export default Location