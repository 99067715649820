import { Button } from "../../../Button"


type QuickFilterProps = {
    title: string;
    isSelected?: boolean;
    onClick: any;
}

const QuickFilter = ({title, isSelected, onClick}: QuickFilterProps) => {
    return (
        <Button
            border={`solid 1px ${isSelected ? '#BFCBFB' : '#808080'}`}
            background={isSelected ? '#DDE3FD' : '#FFFFFF'}
            color={isSelected ? '#0938F0' : '#000000'}
            padding="10px 14px"
            borderRadius="23px"
            fontSize="15px"
            lineHeight="15px"
            onClick={onClick}
            isActive={isSelected}
            width="max-content"
            minWidth="80px"
            height="fit-content"
            noStyle
        >{title}</Button>
    )
}

export default QuickFilter