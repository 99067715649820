import { useState } from "react"
import { View } from "../../../View"
import { ShippingAddress } from "../../../ShippingAddress"
import { Text } from "../../../Text"
import { Checkbox } from "../../../Checkbox"
import { Button } from "../../../Button"


const DeliveryMethodRadio = () => {
    return (
        <View display="flex" flexDirection="column" gap="0" width="100%">
            <View border={`solid 1px #e6e6e6`} padding="12px" borderRadius="10px">
                <View display="inline-flex" alignItems="center" width="100%">
                    <Text flexGrow="1" color="#808080">{"Shipping"}</Text>
                    <View width="fit-content">
                        <input type="radio" id="delivery" name="delivery" value="delivery" />
                    </View>
                </View>
                
            </View>
            <View border={`solid 1px #e6e6e6`} padding="12px" borderRadius="10px" borderTop="none">
                <View display="inline-flex" alignItems="center" width="100%">
                    <Text flexGrow="1" color="#808080">{"Arrange for pickup (free)"}</Text>
                    <View width="fit-content">
                        <input type="radio" id="delivery" name="delivery" value="delivery" />
                    </View>
                </View>
            </View>
        </View>
    )
}


const DeliveryMethod = ({checkoutStep, setCheckoutStep}: any) => {

    const onClickSave = () => {
        setCheckoutStep(1)
    }
    return (
        <View>
            <View display="flex" flexDirection="column" gap="24px">
                <View>
                    <View padding="0px 0px 12px 0px">
                        <Text fontSize="20px" fontWeight="500">{'Delivery Method'}</Text>
                    </View>
                    <View>
                        <DeliveryMethodRadio />
                    </View>
                </View>
                <View>
                    <View>
                        <Text fontSize="20px" fontWeight="500">{'Delivery Address'}</Text>
                    </View>
                    <View>
                        <ShippingAddress />
                    </View>
                </View>
                <View>
                    <View display="flex" flexDirection="column" gap="24px">
                        <View>
                            <View display="inline-flex" alignItems="center" gap="4px">
                                <View>
                                    <Checkbox />
                                </View>
                                <View>
                                    <Text color="#808080">{'Save shipping address for later use'}</Text>
                                </View>
                            </View>
                        </View>
                        <View>
                            <Button
                                border={`solid 1px ${'#BFCBFB'}`}
                                background={'#DDE3FD'}
                                color={'#0938F0'}
                                padding="8px 20px"
                                borderRadius="23px"
                                fontSize="16px"
                                width="max-content"
                                onClick={onClickSave}
                                noStyle
                            >{'Save and Continue'}</Button>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default DeliveryMethod