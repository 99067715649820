import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { Card } from "../Card"
import { StyledHeroContainer } from "./styled"


type CardProps = {
    isReverse?: boolean;
}

const Collectors = ({isReverse}: CardProps) => {
    const onClick = () => {

    }
    return (
        <Card
            title={"Let collectors visit your gallery from around the globe"}
            description={"Make it possible for collectors, curators, critics and art patrons to visit your exhibitions as if being there in person, anytime, anywhere."}
            image={"/img/landing/forMuseums/A.svg"}
            onClick={onClick}
            isReverse={isReverse}
        />
    )
}

const ForMuseums = () => {
    return (
        <DashboardLayout>
            <StyledHeroContainer>
                <View display="flex" alignItems="center" height="678px">
                    <View width="100%">
                        <Text padding="0px 0px 0px 64px" fontSize="4vw" color="#fff" fontWeight="500" width="70%">
                            {"Join the leading global marketplace for buying and selling art."}
                        </Text>
                        <View width="100%" padding="24px 0px 0px 64px">
                            <Button 
                                height="48px" 
                                width="160px" 
                                borderRadius="24px" 
                                background="#fff" 
                                border="solid 1px #000"
                                fontSize="20px"
                                fontWeight="500"
                                noStyle
                            >Get Started</Button>
                        </View>
                    </View>
                </View>
            </StyledHeroContainer>
            <View padding="64px 48px">
                <View display="flex" flexDirection="column" gap="120px">
                    <Collectors />
                    <View>
                        <FooterAd image={'/img/landing/forMuseums/LaunchingSoon.svg'} />
                    </View>
                </View>
            </View>
        </DashboardLayout>
    )
}

export default ForMuseums