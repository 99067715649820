import styled from 'styled-components'

interface PriceRangeContainerProps {

}


export const StyledPriceRangeContainer  = styled.div<PriceRangeContainerProps>`
    
`

interface PriceRangeProps {

}


export const StyledPriceRange  = styled.div<PriceRangeProps>`
    padding: 0px 0px 24px 0px;

    & .range-slider {
        height: 3px;
    }
    
    & .range-slider .range-slider__thumb{
        background: #0938F0; 
        height: 8px;
        width: 8px;
    }

    & .range-slider .range-slider__range {
        background: #0938F0; 
    }
`

