import { View } from "../../View"
import { StyledAccountFollowingsContainer, StyledAccountFollowings, StyledCloseModalContainer, StyledAccountFollowingContainer } from "./styled"
import { Button } from "../../Button"
import { useAccountStore, useModalStore, useProfileStore, useThemeStore } from "../../../stores/web.store"
import { SearchBar } from "../../SearchBar"
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { UserInfo } from "../../UserInfo"
import { Text } from "../../Text"
import { useState } from "react"
import { ProfilePicture } from "../../ProfilePicture"
import { Follow } from "../../Button/Follow"

const AccountFollowing = ({following}: any) => {
    const account = useAccountStore((state: any) => state.account)
    
    return (
        <View display="inline-flex" alignItems="center" padding="0px 0px 12px 0px" width="100%">
            <View flexGrow="1" textAlign="left">
                <ProfilePicture account={following} photo username role/>
            </View>
            <View textAlign="right" width="fit-content">
                <Follow data={{...account, follow: following}} />
            </View>
        </View>
    )
}

const AccountFollowings = ({profile}: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const [following, setFollowing] = useState<any>(profile.following)

    const onSearch = (value: string) => {
        const lowercasedValue = value.toLowerCase();
        const hydratedFollowing = profile.following.map((item: any) => {return {...item, full_name: item.first_name + ' ' + item.last_name}});
        const filteredFollowing = hydratedFollowing.filter((item: any) => {
            return Object.values(item).some(val =>
                String(val).toLowerCase().includes(lowercasedValue)
            );
        });
        setFollowing(filteredFollowing);
    }

    return (
        <StyledAccountFollowingsContainer>
            <StyledAccountFollowings>
                <Text width="100%" fontWeight="500">{`Following (${profile.total_following})`}</Text>
                <View padding="20px 0px">
                    <SearchBar setData={setFollowing} data={following} resetData={profile.following} fetch={onSearch}/>
                </View>
                <StyledAccountFollowingContainer>
                    {following.map((following: any) => {
                        return (
                            <AccountFollowing following={following}/>
                        )
                    })}
                </StyledAccountFollowingContainer>
            </StyledAccountFollowings>
            <StyledCloseModalContainer>
                <Button noStyle onClick={() => setIsModal(false)}><XIcon strokeWidth={2} height={24} width={24}/></Button>
            </StyledCloseModalContainer>
        </StyledAccountFollowingsContainer>
    )
}

export default AccountFollowings