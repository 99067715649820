import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { useResponsiveStore } from "../../../stores/web.store"
import { Editorials as EditorialsNode } from "../../../components/Editorials"


const Editorials = () => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <DashboardLayout>
            
            <View padding={width > 768 ? "12px" : "12px"} display="flex" flexDirection="column" gap="48px">
                <View>
                    <View display="flex" flexDirection="column" gap={width > 768 ? "40px" : "0"}>
                        <View>
                            <Text as="h1" width="100%" fontSize={width > 768 ? "36px" : "28px"} fontWeight="500">{"Editorial"}</Text>
                        </View>
                        <View>
                            <EditorialsNode />
                        </View>
                    </View>
                </View>
                <View>
                    <FooterAd image={'/img/landing/about/LaunchingSoon.svg'} />
                </View>
            </View>
           
            
        </DashboardLayout>
    )
}

export default Editorials