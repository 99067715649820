import { ReactComponent as CameraIcon } from '../../img/camera.svg';
import { ReactComponent as ExhibitIcon } from '../../img/exhibitIcon.svg';
import { ReactComponent as AuctionIcon } from '../../img/auction.svg';
import { ReactComponent as ArtworkIcon } from '../../img/artwork.svg';
import { ReactComponent as SubscriberIcon } from '../../img/subscriber.svg';
import { ReactComponent as BookmarkIcon } from '../../img/bookmark.svg';
import { StyledEmptyPosts, StyledEmptyPostsContainer, StyledEmptyPostsIcon, StyledEmptyPostsIconContainer } from "./styled"
import { Text } from '../Text';
import { Button } from '../Button';
import { ReactNode } from 'react';
import { useDashboardCreateModeStore, useSelectedPostStore, useThemeStore } from '../../stores/web.store';


type EmptyPostsProps = {
    type: string;
    title: string;
    children: ReactNode;
    override?: boolean;
}

const EmptyPosts = (props: EmptyPostsProps) => {
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const theme = useThemeStore((state: any) => state.theme)

    const onClick = () => {
        setIsCreateMode(true)
        setSelectedPost(props.type)
    }
    return (
        <StyledEmptyPostsContainer>
            <StyledEmptyPosts>
                {props.override ? props.children : <Button 
                    padding='0' 
                    borderRadius={'32px'} 
                    width='124px' 
                    height='36px' 
                    fontSize='20px'
                    color={theme.button.secondaryColor}
                    onClick={onClick}
                >Post</Button>}
            </StyledEmptyPosts>
        </StyledEmptyPostsContainer>
    )
}

export default EmptyPosts