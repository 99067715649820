import styled from "styled-components";

interface SearchContainerProps {

}

export const StyledSearchContainer = styled.div<SearchContainerProps>`
    // padding: 12px;
`

interface SearchProps {

}

export const StyledSearch = styled.div<SearchProps>`

`

interface SearchResultProps {

}

export const StyledSearchResult = styled.div<SearchResultProps>`
    width: 100%;
    padding: 12px;
    &:hover {
        background-color: #f1f1f1;
    }
`