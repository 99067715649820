import { useState } from "react"
import api from "../../../services/api.service"
import { usePostStore, useThemeStore } from "../../../stores/web.store"
import Button from "../Button"
import { View } from "../../View"
import { Text } from "../../Text"

const BidStatusMap = {
    low_bid: 'Your bid is too low. Please try again',
    active: 'You currently have the winning',
    outbid: 'You have been outbid',
} as any

const PlaceBid = ({setIsModal, amount}: any) => {
    const post = usePostStore((state: any) => state.post)
    const theme = useThemeStore((state: any) => state.theme)
    const [bid, setBid] = useState<any>({})

    const _placeBid = async () => {
        const payload = {
            post_id: post.id,
            amount
        }
        const resp = await api.post('/bids', payload)
        setBid(resp)
        return resp
    }

    const onClick = async () => {
        const resp = await _placeBid()
        setTimeout(() => {
            if (resp.status === 'active') {
                setBid({})
                setIsModal(false) 
            } else {
                setBid({})
            }
        }, 3000);
    }

    return (
        <View>
            <View>
                {bid.status !== 'active' && <Button 
                    fontWeight="500"
                    fontSize="16px"
                    width="fit-content" 
                    height="32px" 
                    borderRadius="32px"
                    color={theme.button.secondaryColor}
                    onClick={onClick}
                    // disabled={auctionState !== 'active'}
                >Place Bid</Button>}
                {bid.status === 'active' && <Button 
                    fontWeight="500"
                    fontSize="16px"
                    width="fit-content" 
                    height="32px" 
                    borderRadius="32px"
                    color={theme.button.secondaryColor}
                    onClick={onClick}
                    // disabled={auctionState !== 'active'}
                >Close</Button>}
            </View>
            <View padding="12px 0px">
                <Text width="100%" textAlign="center">{bid ? BidStatusMap[bid.status] : ''}</Text>
            </View>
        </View>
    )
}

export default PlaceBid