import { QuickFilter } from "../QuickFilter"


type ArtistsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const Artists = ({selectedFilter, setSelectedFilter}: ArtistsProps) => {
    const onClick = () => {
        setSelectedFilter('artist')
    }

    return (
        <QuickFilter title="Artists" onClick={onClick} isSelected={selectedFilter === 'artist'}/>
    )
}

export default Artists