import styled from "styled-components";


export const StyledHeroContainer = styled.div`
    background-image: url("/img/landing/MuseumsMarketplace.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 678px;
    width: 100%;

`