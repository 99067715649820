import styled from "styled-components";

interface ArtworksFiltersContainerProps {

}


export const StyledArtworksFiltersContainer = styled.div<ArtworksFiltersContainerProps>`
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 0;
`

interface SearchAndSortProps {

}


export const StyledSearchAndSort = styled.div<SearchAndSortProps>`
    display: inline-flex;
    width: 100%;
    gap: 8px;
    justify-content: end;
    align-items: center;
`