import styled from 'styled-components'


interface GalleryInfoContainerProps {

}

export const StyledGalleryInfoContainer = styled.div<GalleryInfoContainerProps>`
    display: inline-flex;
    width: 100%;
    gap: 12px;
    align-items: center;
`

interface GalleryInfoProps {

}

export const StyledGalleryInfo = styled.div<GalleryInfoProps>`
    
`