import { useState } from "react"
import { useSlideMenuStore, useThemeStore } from "../../../stores/web.store"
import Button from "../Button"
import api from "../../../services/api.service"


const Message = ({title, data, noStyle, activeColor, inactiveColor, borderRadius}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const setSlideMenu = useSlideMenuStore((state: any) => state.setSlideMenu)
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const [userData, setUserData] = useState(data)

    const [isActive, setIsActive] = useState(false)

    const onClick = () => {
        setIsActive(!isActive)
        if (slideMenu.isActive) {
            resetSlideMenu()
        } else {
            setSlideMenu({...slideMenu, isActive: true, key: 'inbox'})
        }
    }

    return (
        <Button 
            noStyle 
            textAlign="right" 
            width="fit-content" 
            padding="4px 12px" 
            border={noStyle ? 'none' : `solid 1px ${activeColor || theme.button.color}`}
            borderRadius={borderRadius || "12px"}
            background={isActive ? activeColor || theme.button.color : inactiveColor || '#fff'}
            color={isActive ? inactiveColor || '#fff' : activeColor || theme.button.color}
            onClick={onClick}
        >{title || 'Message'}</Button>
    )
}

export default Message