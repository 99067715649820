import { Input } from "../Input"


type CheckboxProps = {
    onChange?: any;
    rounded?: boolean;
    checked?: boolean;
}


const Checkbox = ({onChange, rounded, checked}: CheckboxProps) => {
    return (
        <Input type={'checkbox'} onChange={onChange} rounded={rounded} checked={checked}/>
    )
}

export default Checkbox