import { useState } from "react";
import { Button } from "../../../components/Button"
import { Switch } from "../../../components/Switch"
import { Text } from "../../../components/Text"
import { ReactComponent as ChevronDown } from '../../../img/chevronDown.svg';
import { StyledPrivacySettings, StyledPrivacySettingsContainer, StyledSetAudience, StyledSetAudienceButtons, StyledSwitchContainer } from "./styled"



const SetAudience = ({setPayload, payload}: any) => {
    const [audience, setAudience] = useState<string | null>('public')
    const onClick = (audienceType: string) => {
        setAudience(audienceType)
        setPayload({...payload, privacy_settings: {...payload.privacy_settings, audience: audienceType}})
    }

    return (
        <StyledSetAudience>
            <Text width="max-content">Set Audience</Text>
            <StyledSetAudienceButtons>
                <Button 
                    noStyle={audience !== 'friends'} 
                    padding={"6px 16px"}
                    color={"#000"}
                    fontWeight="600" 
                    borderRadius="12px"
                    background={audience === 'friends' ? '#C7E4FF' : "none"}
                    border={audience === 'friends' ? `solid 1px ${'#C7E4FF'}` : 'none'}
                    onClick={() => onClick('friends')}
                >Close Friends</Button>
                <Button 
                    noStyle={audience !== 'subscribers'} 
                    padding={"6px 16px"}
                    color={"#000"}
                    fontWeight="600" 
                    borderRadius="12px"
                    background={audience === 'subscribers' ? '#C7E4FF' : "none"}
                    border={audience === 'subscribers' ? `solid 1px ${'#C7E4FF'}` : 'none'}
                    onClick={() => onClick('subscribers')}
                >Subscribers</Button>
                <Button 
                    noStyle={audience !== 'public'} 
                    padding={"6px 16px"}
                    color={"#000"}
                    fontWeight="600"
                    borderRadius="12px"
                    background={audience === 'public' ? '#C7E4FF' : "none"}
                    border={audience === 'public' ? `solid 1px ${'#C7E4FF'}` : 'none'}
                    onClick={() => onClick('public')}
                >Public</Button>
            </StyledSetAudienceButtons>
        </StyledSetAudience>
    )
}


const DisableCommenting = ({setPayload, payload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = (e: any) => {
        setIsChecked(e)
        setPayload({...payload, privacy_settings: {...payload.privacy_settings, is_comments_disabled: e}})
    }
    return (
        <StyledSwitchContainer>
            <Text width="80%">{'Disable commenting'}</Text>
            <Text width="20%" textAlign="right">
                <Switch onChange={onChange} isChecked={isChecked}/>
            </Text>
        </StyledSwitchContainer>
    )
}

const HideMetaDataOnPost = ({setPayload, payload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = (e: any) => {
        setIsChecked(e)
        setPayload({...payload, privacy_settings: {...payload.privacy_settings, is_hidden_likes: e}})
    }
    return (
        <StyledSwitchContainer>
            <Text width="80%">{'Hide like and view counts on this post'}</Text>
            <Text width="20%" textAlign="right">
                <Switch onChange={onChange} isChecked={isChecked}/>
            </Text>
        </StyledSwitchContainer>
    )
}

const SchedulePost = ({setPayload, payload}: any) => {
    const [isChecked, setIsChecked] = useState(false)
    const onChange = (e: any) => {
        setIsChecked(e)
        // setPayload({...payload, privacy_settings: {...payload.privacy_settings, is_hidden_likes: e}})
    }
    return (
        <StyledSwitchContainer>
            <Text width="80%">{'Schedule post'}</Text>
            <Text width="20%" textAlign="right">
                <Switch onChange={onChange} isChecked={isChecked}/>
            </Text>
        </StyledSwitchContainer>
    )
}


const PostPrivacySettings = ({setPayload, payload}: any) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true)
    return (
        <StyledPrivacySettingsContainer>
            <Button noStyle width="100%" onClick={() => setIsExpanded(!isExpanded)} textAlign="left">
                <Text fontWeight="500" fontSize="16px" width="80%">Privacy Settings</Text>
                <Text width="20%" textAlign="right"><ChevronDown /></Text>
            </Button>
            <StyledPrivacySettings isExpanded={isExpanded}>
                <SetAudience setPayload={setPayload} payload={payload}/>
                <HideMetaDataOnPost setPayload={setPayload} payload={payload}/>
                <DisableCommenting setPayload={setPayload} payload={payload}/>
                <SchedulePost setPayload={setPayload} payload={payload}/>
            </StyledPrivacySettings>
        </StyledPrivacySettingsContainer>
    )
}

export default PostPrivacySettings