import styled from "styled-components";


interface AlertContainerProps {

}

export const StyledAlertContainer = styled.div<AlertContainerProps>`

`

interface AlertProps {

}

export const StyledAlert = styled.div<AlertProps>`

`