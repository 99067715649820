import { SearchBar } from "../../SearchBar";
import { View } from "../../View"
import { AllFilters } from "../AllFilters";
import { All } from "./All";
import { Artists } from "./Artists";
import { Artwork } from "./Artwork";
import { Auction } from "./Auction";
import { Boards } from "./Boards";
import { Commissions } from "./Commissions";
import { EndingSoon } from "./EndingSoon";
import { Galleries } from "./Galleries";
import { LiveAuctions } from "./LiveAuctions";
import { Media } from "./Media";
import { Messages } from "./Messages";
import { Museums } from "./Museums";
import { OneOnOnes } from "./OneOnOnes";
import { PastAuctions } from "./PastAuctions";
import { PastShows } from "./PastShows";
import { Patrons } from "./Patrons";
import { Show } from "./Show";
import { StyledQuickFilters } from "./styled";
import { TrendingLots } from "./TrendingLots";
import { UpcomingAuctions } from "./UpcomingAuctions";


type QuickFiltersProps = {
    selectedFilter: any;
    setSelectedFilter: any;
    includeAll?: boolean;
    includeMedia?: boolean;
    includeBoards?: boolean;
    includeArtwork?: boolean;
    includeShow?: boolean;
    includeAuction?: boolean;

    includeLiveAuctions?: boolean;
    includeTrendingLots?: boolean;
    includeEndingSoon?: boolean;
    includeUpcomingAuctions?: boolean;
    includePastAuctions?: boolean;

    includeAtrists?: boolean;
    includePatrons?: boolean;
    includeGalleries?: boolean;
    includeMuseums?: boolean;
    includePastShows?: boolean;

    includeMessages?: boolean;
    includeCommissions?: boolean;
    includeOneOnOnes?: boolean;

    includeSearch?: boolean;
    includeAISearch?: boolean;
    includeFilters?: boolean;

    setFilteredData?: any;
    resetData?: any;
    searchRequest?: any;
}


const QuickFilters = (props: QuickFiltersProps) => {
    return (
        <View>
            <StyledQuickFilters>
                {props.includeFilters && <AllFilters iconOnly/>}
                {props.includeAll && <All selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                
                {props.includeMedia && <Media selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeArtwork && <Artwork selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeBoards && <Boards selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeShow && <Show selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeAuction && <Auction selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}

                {props.includeLiveAuctions && <LiveAuctions selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeTrendingLots && <TrendingLots selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeEndingSoon && <EndingSoon selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeUpcomingAuctions && <UpcomingAuctions selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includePastAuctions && <PastAuctions selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}

                {props.includeAtrists && <Artists selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includePatrons && <Patrons selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeGalleries && <Galleries selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeMuseums && <Museums selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includePastShows && <PastShows selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}

                {props.includeMessages && <Messages selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeCommissions && <Commissions selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeOneOnOnes && <OneOnOnes selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>}
                {props.includeSearch || props.includeAISearch && 
                <SearchBar 
                    width="400px" 
                    placeholder="Primary Yellow AI or Search" 
                    isAISearch={props.includeAISearch}
                    setData={props.setFilteredData}
                    resetData={props.resetData}
                    fetch={props.searchRequest}
                />}
            </StyledQuickFilters>
        </View>
    )
}

export default QuickFilters