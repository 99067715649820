import styled from 'styled-components'


interface DashboardContainerProps {

}

export const StyledDashboardContainer = styled.div<DashboardContainerProps>`

`

interface DashboardProps {

}

export const StyledDashboard = styled.div<DashboardProps>`
    padding: 0px 4px;

    @media (min-width: 768px) {
        padding: 0px 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface DashboardContentProps {

}

export const StyledDashboardContent = styled.div<DashboardContentProps>`
    display: block;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        grid-gap: 48px;
    }

    @media (min-width: 1024px) {

    }
`

interface InfoSectionProps {

}

export const StyledInfoSection = styled.div<InfoSectionProps>`
    display: none;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

    @media (min-width: 768px) {
        display: block;
        height: calc(100vh - 48px);
        overflow-y: scroll;
        width: calc(100% - 48px);
        padding: 0;
        padding: 24px 0px;
    }

    @media (min-width: 1024px) {

    }
    

`
