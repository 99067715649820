import { useState } from "react"
import { useThemeStore } from "../../../stores/web.store"
import Button from "../Button"
import {ReactComponent as PlusIcon} from '../../../img/plus.svg'
import api from "../../../services/api.service"


const ProfilePlus = ({title, data, noStyle, activeColor, inactiveColor, borderRadius}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const [userData, setUserData] = useState(data)

    const [isActive, setIsActive] = useState(false)
    const onClick = async () => {
        setIsActive(!isActive)
        
    }

    return (
        <Button 
            noStyle 
            textAlign="right" 
            width="fit-content" 
            padding="4px 5px" 
            border={noStyle ? 'none' : `solid 1px ${activeColor || theme.button.color}`}
            borderRadius={borderRadius || "12px"}
            background={isActive ? activeColor || theme.button.color : inactiveColor || '#fff'}
            color={isActive ? inactiveColor || '#fff' : activeColor || theme.button.color}
            onClick={onClick}
        ><PlusIcon height={12} width={12} stroke={isActive ? inactiveColor || '#fff' : activeColor || theme.button.color}/></Button>
    )
}

export default ProfilePlus