import { QuickFilter } from "../QuickFilter"


type MediaProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const Media = ({selectedFilter, setSelectedFilter}: MediaProps) => {
    const onClick = () => {
        setSelectedFilter('media')
    }

    return (
        <QuickFilter title="Media" onClick={onClick} isSelected={selectedFilter === 'media'}/>
    )
}

export default Media