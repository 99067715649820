import { usePostPayloadStore } from "../../../../stores/web.store"
import { AboutTheWork } from "../../AboutTheWork"
import { PostAvailability } from "../../PostAvailability"
import { PostPrivacySettings } from "../../PostPrivacySettings"
import { StyledCreatePostContent, StyledCreatePostContentContainer } from "./styled"


const CreateArtworkPostContent = () => {
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    return (
        <StyledCreatePostContentContainer>
            <StyledCreatePostContent>
                <PostAvailability payload={postPayload} setPayload={setPostPayload}/>
                <AboutTheWork payload={postPayload} setPayload={setPostPayload}/>
                <div style={{padding: '24px 0px 0px 0px'}}>
                    <PostPrivacySettings payload={postPayload} setPayload={setPostPayload}/>
                </div>
            </StyledCreatePostContent>
        </StyledCreatePostContentContainer>
    )
}

export default CreateArtworkPostContent