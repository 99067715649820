import { ReactNode, useState } from 'react'

import { StyledButton, StyledButtonContent, StyledButtonContentContainer, StyledButtonContentWrapper } from "./styled"
import { useThemeStore } from '../../stores/web.store'


type ButtonProps = {
    children?: ReactNode;
    noEffect?: boolean;
    onClick?: any;
    noStyle?: boolean;
    background?: string;
    border?: string;
    borderBottom?: string;
    display?: string;
    borderRadius?: string;
    padding?: string;
    fontSize?: string;
    fontWeight?: string;
    color?: string;
    width?: string;
    minWidth?: string;
    height?: string;
    lineHeight?: string;
    textAlign?: string;
    flexDirection?: string;
    alignItems?: string;
    contentDisplay?: string;
    disabled?: boolean;
    justifyContent?: string;
    gap?: string;
    cursor?: string;
    hoverEffect?: any
    onMouseEnter?: any;
    onMouseLeave?: any;
    textDecoration?: string;
    isVideo?: boolean;
    isNoEffect?: boolean;
    isActive?: boolean;
    float?: string;
    id?: string;
    className?: string;
}


const Button = (props: ButtonProps) => {
    const theme = useThemeStore((state: any) => state.theme)

    const [isPressed, setIsPressed] = useState(false)

    return (
        <StyledButton 
            className={props.className}
            id={props.id}
            theme={theme}
            float={props.float}
            background={props.background}
            noEffect={props.noEffect}
            border={props.border}
            borderBottom={props.borderBottom}
            borderRadius={props.borderRadius}
            display={props.display}
            padding={props.padding}
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
            width={props.width}
            minWidth={props.minWidth}
            height={props.height}
            justifyContent={props.justifyContent}
            lineHeight={props.lineHeight}
            textAlign={props.textAlign}
            color={props.color}
            noStyle={props.noStyle}
            cursor={props.cursor}
            onClick={props.onClick}
            disabled={props.disabled}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            hoverEffect={props.hoverEffect}
            textDecoration={props.textDecoration}
            onMouseDown={() => setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            isPressed={isPressed}
            isVideo={props.isVideo}
            isNoEffect={props.isNoEffect}
            isActive={props.isActive}
        >
            <StyledButtonContentContainer theme={theme} height={props.height}>
                <StyledButtonContent theme={theme} height={props.height} justifyContent={props.justifyContent}>
                    <StyledButtonContentWrapper 
                        display={props.display} 
                        justifyContent={props.justifyContent} 
                        gap={props.gap} 
                        width={props.width}
                        flexDirection={props.flexDirection}
                        alignItems={props.alignItems}
                    >
                        {props.children}
                    </StyledButtonContentWrapper>
                </StyledButtonContent>
            </StyledButtonContentContainer>
        </StyledButton>
    )
}

export default Button