import styled from 'styled-components'


interface ForYouContainerProps {

}

export const StyledForYouContainer = styled.div<ForYouContainerProps>`

`

interface ForYouProps {

}

export const StyledForYou = styled.div<ForYouProps>`

`


