import styled from 'styled-components'

interface GalleryContainerProps {

}


export const StyledGalleryContainer  = styled.div<GalleryContainerProps>`

`

interface GalleryProps {

}

export const StyledGallery = styled.div<GalleryProps>`

`


