import styled from "styled-components";


interface AccountFollowingsContainerProps {

}

export const StyledAccountFollowingsContainer = styled.div<AccountFollowingsContainerProps>`
    width: 400px;
    background: #fff;
    border-radius: 12px;
    position: relative;

    padding: 20px;

    -webkit-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
`

interface AccountFollowingsProps {
    
}

export const StyledAccountFollowings = styled.div<AccountFollowingsProps>`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0;
`

interface AccountFollowingContainerProps {
    
}

export const StyledAccountFollowingContainer = styled.div<AccountFollowingContainerProps>`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0;
`

interface CloseModalContainerProps {

}

export const StyledCloseModalContainer = styled.div<CloseModalContainerProps>`
    position: absolute;
    right: 20px;
    top: 20px;
`