import { toCurrency } from "../../../../utils/app.util"
import { ImageGallery } from "../../../ImageGallery"
import { Text } from "../../../Text"
import { View } from "../../../View"
import { StyledProductContainer } from "../Checkout/styled"


export const CheckoutProduct = ({post, imageDimension, flexDirection}: any) => {
    return (
        <StyledProductContainer>
            <View display='flex' flexDirection={flexDirection || 'column'} gap='12px'>
                <View>
                    <ImageGallery data={post} width={imageDimension || '40px'} height={imageDimension || '40px'}/>
                </View>
                <View>
                    <View display='flex' flexDirection='column' gap='12px'>
                        <View>
                            <Text>{post.owner.first_name} {post.owner.last_name}</Text>
                        </View>
                        {post.gallery && <View>
                            <Text>{post.gallery.first_name}</Text>
                        </View>}
                        {post.location && <View>
                            <Text>{post.location?.city}</Text>
                        </View>}
                        <View>
                            <Text>{toCurrency(post.metadata.price)}</Text>
                        </View>
                    </View>
                </View>
            </View>
            
        </StyledProductContainer>
    )
}

export default CheckoutProduct