import { useState, useEffect, useRef } from 'react';
import { useResponsiveStore } from "../stores/web.store"

const Desktop = 'desktop'
const Mobile = 'mobile'
const Laptop = 'laptop'

const DesktopFactor = 1
const LaptopFactor = .9
const MobileFactor = .8

const responsiveHeightManager = {
    900: .8,
    700: .7,
    600: .6,
    500: .5,
    400: .4
    
} as any

const _getHeightFactor = (height: number) => {
    const keys = Object.keys(responsiveHeightManager)
    for (var idx in keys) {
        const index = parseInt(idx)
        if (height >= parseInt(keys[index]) && height <= parseInt(keys[index + 1])) {
            return responsiveHeightManager[keys[index]]
        }
    }
    return .2
}

const useResponsify = (value: string) => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const height = useResponsiveStore((state: any) => state.height)

    const heightFactor = _getHeightFactor(height)

    const factor = mode === Mobile ? MobileFactor : mode === Laptop ? LaptopFactor : DesktopFactor
    const valueComps = value.split(' ')
    const responsiveValues = valueComps.map((comp: string) => {
        if (comp.includes('px')) {
            const padInt = parseInt(comp.replace('px', ''))
            return `${padInt * factor * heightFactor}px`
        }
        return comp
        
    })
    return responsiveValues.join(' ')
}


export {Desktop, Mobile, Laptop, DesktopFactor, LaptopFactor, MobileFactor, useResponsify}