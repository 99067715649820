import styled from "styled-components";

interface InboxContainerProps {

}

export const StyledInboxContainer = styled.div<InboxContainerProps>`
    padding: 12px;

    @media (min-width: 768px) {
        padding: 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface InboxProps {

}

export const StyledInbox = styled.div<InboxProps>`

`

