import { useAccountStore } from "../../../../stores/web.store"
import { Button } from "../../../Button"
import { Text } from "../../../Text"
import { View } from "../../../View"
import { StyledProductContainer, StyledReviewPaymentMethod, StyledReviewShopTo } from "../Checkout/styled"
import { CheckoutProduct } from "../CheckoutProduct"

const ReviewShipTo = ({setCheckoutStep}: any) => {
    const account = useAccountStore((state: any) => state.account)
    return (
        <StyledReviewShopTo>
            <View display="flex" flexDirection="column" gap="12px">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%">
                        <Text flexGrow="1">{'Ship to'}</Text>
                        <View width="fit-content" textAlign="right">
                            <Button 
                                noStyle
                                onClick={() => setCheckoutStep(0)}
                            >{'Change'}</Button>
                        </View>
                    </View>
                </View>
                <View>
                    <View display="flex" flexDirection="column" width="100%" gap="8px">
                        <Text color="#808080">{'Customer name'}</Text>
                        <Text color="#808080">{'Address 1'} {'Address 2'}</Text>
                        <Text color="#808080">{'City'}, {'State'}, {'Zipcode'}</Text>
                        <Text color="#808080">{'Phone Number'}</Text>
                    </View>
                </View>
            </View>
        </StyledReviewShopTo>
    )
}

const ReviewPaymentMethod = ({setCheckoutStep}: any) => {
    return (
        <StyledReviewPaymentMethod>
            <View display="flex" flexDirection="column" gap="12px">
                <View>
                    <View display="inline-flex" alignItems="center" width="100%">
                        <Text flexGrow="1">{'Payment method'}</Text>
                        <View width="fit-content" textAlign="right">
                            <Button 
                                noStyle
                                onClick={() => setCheckoutStep(1)}
                            >{'Change'}</Button>
                        </View>
                    </View>
                </View>
            </View>
        </StyledReviewPaymentMethod>
    )
}

const OrderReview = ({post, checkoutStep, setCheckoutStep}: any) => {

    const onClickSubmit = () => {
        setCheckoutStep(3)
    }
    return (
        <View>
            <View display="flex" flexDirection="column" gap="24px">
                <View>
                    <Text fontSize="20px" fontWeight="500">{'Order Review'}</Text>
                </View>
                <View>
                    <View display="flex" flexDirection="column" gap="0">
                        <ReviewShipTo setCheckoutStep={setCheckoutStep}/>
                        <ReviewPaymentMethod setCheckoutStep={setCheckoutStep}/>
                    </View>
                </View>
                <View>
                    <CheckoutProduct post={post} imageDimension={'120px'} flexDirection="column-reverse"/>
                </View>
                <View>
                    <Button
                        border={`solid 1px ${'#BFCBFB'}`}
                        background={'#DDE3FD'}
                        color={'#0938F0'}
                        padding="8px 20px"
                        borderRadius="23px"
                        fontSize="16px"
                        width="max-content"
                        onClick={onClickSubmit}
                        noStyle
                    >{'Submit'}</Button>
                </View>
                <View>
                    <Text color="#808080">{`By clicking Submit, I agree to Primary Yellow’s `}<a href="" style={{color: "#808080"}}>{"General Terms and Conditions of Sale."}</a></Text>
                </View>
            </View>
        </View>
    )
}

export default OrderReview