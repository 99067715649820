import { useNavigate } from "react-router-dom"
import { useAccountStore } from "../../../../stores/web.store"
import { Button } from "../../../Button"
import { Text } from "../../../Text"
import { View } from "../../../View"
import { StyledProductContainer, StyledReviewPaymentMethod, StyledReviewShopTo } from "../Checkout/styled"
import { CheckoutProduct } from "../CheckoutProduct"
import { StyledCompleteHeader, StyledCompleteProducts, StyledCompleteTotal } from "./styled"


const OrderComplete = ({post, checkoutStep, setCheckoutStep}: any) => {
    
    return (
        <View>
            <View>
                <View display="flex" flexDirection="column" gap="0">
                    <StyledCompleteHeader>
                        <View display="inline-flex" alignItems="center" width="100%">
                            <Text width="25%">{'Product'}</Text>
                            <Text width="25%">{'Price'}</Text>
                            <Text width="25%">{'Quantity'}</Text>
                            <Text width="25%">{'Total'}</Text>
                        </View>

                    </StyledCompleteHeader>
                    <StyledCompleteProducts>
                        <View display="inline-flex" alignItems="center" width="100%">
                            <View width="25%">
                                <Button noStyle>{'Product'}</Button>
                            </View>
                            <Text width="25%">{'$2,150.00'}</Text>
                            <Text width="25%">{1}</Text>
                            <Text width="25%">{'$2,150.00'}</Text>
                        </View>

                    </StyledCompleteProducts>
                    <StyledCompleteTotal>
                        <View display="inline-flex" alignItems="center" width="100%">
                            <View width="25%">
                                <View display="flex" flexDirection="column" gap="24px">
                                    <View>
                                        <View display="flex" flexDirection="column" gap="12px">
                                            <Text>{'Subtotal'}</Text>
                                            <Text>{'Shipping'}</Text>
                                            <Text>{'Tax'}</Text>
                                            
                                        </View>
                                    </View>
                                    <View>
                                        <Text>{'Total'}</Text>
                                    </View>
                                </View>
                            </View>
                            <Text width="25%"></Text>
                            <Text width="25%"></Text>
                            <View width="25%">
                                <View display="flex" flexDirection="column" gap="24px">
                                    <View>
                                        <View display="flex" flexDirection="column" gap="12px">
                                            <Text>{'$2,150.00'}</Text>
                                            <Text>{0}</Text>
                                            <Text>{'$215.00'}</Text>
                                            
                                        </View>
                                    </View>
                                    <View>
                                        <Text>{'$2,365.00'}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </StyledCompleteTotal>
                </View>
            </View>
        </View>
    )
}

export default OrderComplete