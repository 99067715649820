import styled from 'styled-components'


interface MetricContainerProps {

}


export const StyledMetricContainer = styled.div<MetricContainerProps>`
    width: 100%;
    position: relative;
`

interface MetricProps {

}


export const StyledMetric = styled.div<MetricProps>`
    background: #12232E;
    padding: 24px;
    border-radius: 4px;
`

interface MetricButtonProps {

}


export const StyledMetricButton = styled.div<MetricButtonProps>`
    position: absolute;
    bottom: 12px;
    left: 12px;
`



