import { Title } from "../../components/Page/Title"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useAllMyPosts } from "../../hooks/api.hook"
import { useEffect, useState } from "react"
import { useAccountStore, useViewNavigatorStore } from "../../stores/web.store"
import { View } from "../../components/View"
import { StyledMuseums, StyledMuseumsContainer } from "./styled"
import { Museum } from "../../components/Museum"
import { FeaturedMuseums } from "../../sections/Featured/FeaturedMuseums"
import { MuseumFilters } from "../../components/Filters/MuseumFilters"


const Museums = () => {
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const account = useAccountStore((state: any) => state.account) 
    const [api, setApi] = useState<any>({})
    const {data} = useAllMyPosts({query: {owner_id: account?.id}})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApi(data)
        }
        setActiveView('live')
    }, [data])

    if (account === null) return <div />

    
    if (!api.data) return <div />

    return (
        <DashboardLayout>
            <View padding="24px">
                <View>
                    <Title 
                        title={'Museums'} 
                        description={`Browse over ${1000} museums.`}
                    />
                </View>
                <View padding="32px 0px 12px 0px">
                    <MuseumFilters />
                </View>
                <View padding="0px 0px 24px 0px">
                    <FeaturedMuseums data={api.data} />
                </View>
                <StyledMuseumsContainer>
                    <StyledMuseums>
                        {api.data.map((museum: any) => {
                            return (
                                <Museum data={museum} />
                            )
                        })}
                    </StyledMuseums>
                </StyledMuseumsContainer>
            </View>
        </DashboardLayout>
    )
}

export default Museums