import { useNavigate } from "react-router-dom"
import { useProductDetailPageStore, useThemeStore } from "../../../stores/web.store"
import Button from "../Button"

const Purchase = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const navigate = useNavigate()

    const onClick = async () => {
        navigate('/checkout')
    }

    return (
        <Button 
            width="90%" 
            height="48px" 
            borderRadius="48px" 
            fontWeight="500"
            fontSize="16px"
            onClick={onClick}
            color={theme.button.secondaryColor}
        >Purchase</Button>
    )
}

export default Purchase