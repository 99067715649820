import styled from 'styled-components'


interface TableContainerProps {

}

export const StyledTableContainer = styled.div<TableContainerProps>`

`

interface SearchContainerProps {

}

export const StyledSearchContainer = styled.div<SearchContainerProps>`
    padding: 0px 0px 24px 0px;
    width: 50%;
`
