import { SignIn } from "../../../../../components/SignIn"
import { StyledState, StyledStateContainer } from "../styled"
import { StyledSignInStateContainer, StyledSignInState } from "./styled"


const SignInState = () => {
    return (
        <StyledSignInStateContainer>
            <StyledSignInState>
                <StyledStateContainer>
                    <StyledState>
                        <SignIn />
                    </StyledState>
                </StyledStateContainer>
            </StyledSignInState>
        </StyledSignInStateContainer>
    )
}

export default SignInState