import { Button } from "../../../../components/Button"
import { Text } from "../../../../components/Text"
import { StyledCreateAuctionArtworkContent, StyledCreateAuctionArtworkContentContainer } from "./styled"
import {ReactComponent as UserPlusIcon} from '../../../../img/userPlus.svg'
import { MediaUpload } from "../../../../components/MediaUpload"
import { AboutTheWork } from "../../AboutTheWork"
import { usePostMapPayload, useResponsiveStore } from "../../../../stores/web.store"
import { PostAvailability } from "../../PostAvailability"
import { useEffect } from "react"
import { View } from "../../../../components/View"

const CreateAuctionArtworkContent = () => {
    const postMapPayload = usePostMapPayload((state: any) => state.postMapPayload)
    const setPostMapPayload = usePostMapPayload((state: any) => state.setPostMapPayload)
    const width = useResponsiveStore((state: any) => state.width)

    useEffect(() => {
        setPostMapPayload({...postMapPayload, state: 'auction'})
    }, [])

    return (
        <StyledCreateAuctionArtworkContentContainer>
            <StyledCreateAuctionArtworkContent>
                <View display="flex" flexDirection="column" gap="4px" padding="0px 0px 12px 0px">
                    <Text fontSize={'20px'} fontWeight="500" padding="12px 0px 0px 0px">Choose artworks for your auction</Text>
                    <Text>{'Upload artworks from your computer or from your Primary Yellow account'}</Text>
                </View>
                {/* <Button noStyle><UserPlusIcon width={32} height={32}/><Text padding="0px 8px" fontWeight="500">Invite friends to collaborate</Text></Button> */}
            </StyledCreateAuctionArtworkContent>
            <View>
                <MediaUpload payload={postMapPayload} setPayload={setPostMapPayload}/>
                <AboutTheWork payload={postMapPayload} setPayload={setPostMapPayload}/>
                <View padding="24px 0px">
                    <PostAvailability payload={postMapPayload} setPayload={setPostMapPayload}/>
                </View>
            </View>
        </StyledCreateAuctionArtworkContentContainer>
    )
}

export default CreateAuctionArtworkContent