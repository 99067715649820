import { QuickFilter } from "../QuickFilter"

type PastAuctionsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const PastAuctions = ({selectedFilter, setSelectedFilter}: PastAuctionsProps) => {
    const onClick = () => {
        setSelectedFilter('past_auctions')
    }

    return (
        <QuickFilter title="Past Auctions" onClick={onClick} isSelected={selectedFilter === 'past_auctions'}/>
    )
}

export default PastAuctions