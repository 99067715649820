import styled from 'styled-components'


interface HeaderContainerProps {

}


export const StyledHeaderContainer = styled.div<HeaderContainerProps>`
    padding: 60px 60px 0px 60px;
`

interface HeaderProps {

}


export const StyledHeader = styled.div<HeaderProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
`

interface LogoContainerProps {

}


export const StyledLogoContainer = styled.div<LogoContainerProps>`
    width: 70%;
`

interface LinksContainerProps {

}


export const StyledLinksContainer = styled.div<LinksContainerProps>`
    width: 30%;
`

interface LinksProps {

}


export const StyledLinks = styled.div<LinksProps>`
    display: inline-flex;
    width: 100%;
    gap: 24px;
    justify-content: right;
`







