import { ReactNode } from "react";
import { StyledButtons } from "./styled";


type ButtonsProps = {
    children: ReactNode;
    flexDirection?: string;
    gap?: string;
}


const Buttons = ({children, flexDirection, gap}: ButtonsProps) => {
    return (
        <StyledButtons flexDirection={flexDirection} gap={gap}>
            {children}
        </StyledButtons>
    )
}

export default Buttons