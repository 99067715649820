import styled from 'styled-components'


interface FeaturedRepresentationContainerProps {

}


export const StyledFeaturedRepresentationContainer = styled.div<FeaturedRepresentationContainerProps>`
    border-bottom: solid 1px #000;
    padding: 12px 0px 0px 0px;
`

interface FeaturedRepresentationProps {

}


export const StyledFeaturedRepresentation = styled.div<FeaturedRepresentationProps>`
`









