import { StyledAttachment, StyledAttachmentContainer } from "./styled"
import {ReactComponent as AttachmentIcon} from '../../../img/paperclip.svg'
import { Text } from "../../Text";
import Button from "../Button";
import api from "../../../services/api.service";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { View } from "../../View";

type AttachmentProps = {
    data?: any;
}


const Attachment = ({data}: AttachmentProps) => {
    const [postData, setPostData] = useState(data)
    const [isActive, setIsActive] = useState(false)
    const onClick = async () => {
        setIsActive(!isActive)
        // if (postData.saved?.id && !!isSaved) {
        //     api.delete(`/saved/${postData.saved?.id}`)
        // } else {
        //     const resp = await api.post('/saved', {
        //         source: postData.type,
        //         source_id: postData.id
        //     })

        //     setPostData({...postData, saved: resp.data})
        // }
        
    }

    const onDrop = useCallback((acceptedFiles: any) => {
        const mediaData = [acceptedFiles.map((file: any) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {}
            reader.readAsArrayBuffer(file)
            return {url: URL.createObjectURL(file), file: file}
        })]
    }, [])
    
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <StyledAttachmentContainer>
            <StyledAttachment {...getRootProps()}> 
                <View {...getInputProps()}>
                    <AttachmentIcon height={14} width={14}/>
                </View>
            </StyledAttachment>
        </StyledAttachmentContainer>
    )
}

export default Attachment