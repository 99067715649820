import styled from 'styled-components'


interface SignupCodesTableProps {

}

export const StyledSignupCodesTable = styled.div<SignupCodesTableProps>`
    
`

interface CodeGeneratorProps {

}

export const StyledCodeGenerator = styled.div<CodeGeneratorProps>`
    padding: 0px 0px 24px 0px;
    text-align: center;
    width: 100%;
    position: relative;
    width: 500px;
    margin: auto;
`

interface GenerateCodeInputProps {

}

export const StyledGenerateCodeInput = styled.input<GenerateCodeInputProps>`
    padding: 12px;
    font-size: 16px;
    width: 500px;
    background: rgba(39, 61, 79, 1);
    border: rgba(39, 61, 79, 1);
    border-radius: 8px;
    color: #fff;
`

interface GenerateCodeButtonProps {

}

export const StyledGenerateCodeButton = styled.button<GenerateCodeButtonProps>`
    cursor: pointer;
    background: none;
    border: none;
    position: absolute;
    right: -20px;
    top: 50px;
`

interface CopyButtonCellProps {
    isClicked?: boolean
}

export const StyledCopyButtonCell = styled.div<CopyButtonCellProps>`
    display: inline-flex;
    width: 100%;
    ${props => props.isClicked && `
        color: #9fbbd0;
    `}
`

interface CopyButtonContainerProps {

}

export const StyledCopyButtonContainer = styled.div<CopyButtonContainerProps>`
    text-align: right;
    width: 100%;
    padding: 6px 4px;
`

interface CopyButtonProps {

}

export const StyledCopyButton = styled.button<CopyButtonProps>`
    cursor: pointer;
    background: none;
    border: none;
    text-align: right;
`

interface StatusCellProps {
    status?: string;
}

export const StyledStatusCell = styled.div<StatusCellProps>`
    padding: 8px 0px
`

interface PreDeleteButtonCellProps {
    isClicked?: boolean
}

export const StyledPreDeleteButtonCell = styled.div<PreDeleteButtonCellProps>`
    display: inline-flex;
    width: 100%;
    ${props => props.isClicked && `
        color: #9fbbd0;
    `}
`

interface PreDeleteButttonContainerProps {

}

export const StyledPreDeleteButtonContainer = styled.div<PreDeleteButttonContainerProps>`
    text-align: right;
    width: 100%;
    padding: 6px 4px;
`

interface PreDeleteButtonProps {

}

export const StyledPreDeleteButton = styled.button<PreDeleteButtonProps>`
    cursor: pointer;
    background: none;
    border: none;
    text-align: right;
`

interface DeleteModalProps {
}

export const StyledDeleteModal = styled.div<DeleteModalProps>`

`

interface DeleteContainerProps {

}

export const StyledDeleteContainer = styled.div<DeleteContainerProps>`
    text-align: center;
    width: 100%;
    padding: 6px 4px;

    & button {
        margin: 0px 4px;
    }
`



