import { useEffect, useRef, useState } from "react"
import { Button } from "../Button"
import { Input } from "../Input"
import { Markdown } from "../MarkDown"
import { Text } from "../Text"
import { View } from "../View"
import { ShippingAddress } from "../ShippingAddress"
import { $getRoot, createEditor, LexicalEditor } from "lexical"
import { $generateHtmlFromNodes } from '@lexical/html';
import { toCurrency } from "../../utils/app.util"
import DOMPurify from 'dompurify';

const Message = ({payload}: any) => {
    const [serializedContent, setSerializedContent] = useState<string>('');
    const editorRef = useRef<LexicalEditor | null>(null);

    useEffect(() => {
        if (!editorRef.current) {
            editorRef.current = createEditor();
        }
        const editorState = editorRef.current.parseEditorState(JSON.parse(payload.message.lexical_message));
        editorRef.current.setEditorState(editorState);
        editorRef.current.update(() => {
            if (editorRef.current) {
                const htmlContent = $generateHtmlFromNodes(editorRef.current, null);
                const sanitizedContent = DOMPurify.sanitize(htmlContent);
                setSerializedContent(sanitizedContent);
            }
        });
    }, [payload]);

    return (
        <View>
            <Text><div dangerouslySetInnerHTML={{ __html: serializedContent }} /></Text>
        </View>
    )
}

const MakeOffer = ({setStep, setPayload, payload, isReview}: any) => {
    const editorStateRef = useRef<any>();

    const onClickMakeOffer = () => {
        console.log(payload)
    }

    const onChangeEditorState = (editorState: any) => {

        editorStateRef.current = editorState
        editorState.read(() => {
            const msg = $getRoot().getTextContent();
            setPayload({
                ...payload,
                message: {
                    message: msg,
                    lexical_message: JSON.stringify(editorStateRef.current),
                },
            });
        });

    }

    const onChangeAmount = (e: any) => { 
        setPayload({...payload, amount: e.target.value})
    }

    return (
        <View display="flex" flexDirection="column" gap="12px" width="100%">
            <View>
                <View display="flex" flexDirection="column" gap="12px" width="100%">
                    <View>
                        <Text fontSize="18px" fontWeight="600">{isReview ? 'Review offer details' : 'Make an offer'}</Text>
                    </View>
                    <View>
                        <Text fontSize="20px" fontStyle="500">{toCurrency(payload.amount)}</Text> 
                    </View>
                    <View>
                        <Input type="number" width="100%" placeholder="$" noPlaceholderPrefix onChange={onChangeAmount}/>
                    </View>
                </View>
            </View>
            <View>
                <View display="flex" flexDirection="column" gap="12px" width="100%">
                    {!isReview && <View>
                        <Text fontSize="14px" fontWeight="600">{'Send a message'}</Text>
                    </View>}
                    <View>
                        {isReview ? <Message payload={payload}/> : <Markdown 
                            onChange={onChangeEditorState}
                            allowAttachment 
                            editorStateRef={editorStateRef}
                            noTools={isReview}
                        />}
                    </View>
                </View>
            </View>
            {!isReview && <View>
                <Text>{"Send a direct message to the artist regarding your request. Clearly outline your specifications, and any questions you may have."}</Text>
            </View>}
            <View>
                {isReview ? <Button
                    border={`solid 1px ${'#BFCBFB'}`}
                    background={'#DDE3FD'}
                    color={'#0938F0'}
                    padding="8px 20px"
                    borderRadius="23px"
                    fontSize="16px"
                    width="100%"
                    onClick={onClickMakeOffer}
                    noStyle
                >Make an Offer</Button> : 
                <Button
                    border={`solid 1px ${'#BFCBFB'}`}
                    background={'#DDE3FD'}
                    color={'#0938F0'}
                    padding="8px 20px"
                    borderRadius="23px"
                    fontSize="16px"
                    width="100%"
                    onClick={() => setStep(3)}
                    noStyle
                >Next Step</Button>}
            </View>
        </View>
    )
}

const Delivery = ({setStep, setPayload, payload}: any) => {
    return (
        <View display="flex" flexDirection="column" gap="0" width="100%">
            <View>
                <Text fontSize="18px" fontWeight="600">{'Delivery'}</Text>
            </View>
            <View>
                <ShippingAddress setShipping={setPayload} shipping={payload}/>
            </View>
            <View>
                <View width="100%">
                    <Button
                        border={`solid 1px ${'#BFCBFB'}`}
                        background={'#DDE3FD'}
                        color={'#0938F0'}
                        padding="8px 20px"
                        borderRadius="23px"
                        fontSize="16px"
                        width="100%"
                        onClick={() => setStep(3)}
                        noStyle
                    >Review Offer</Button>
                </View>
            </View>
        </View>
    )
}



const Commission = () => {
    const [step, setStep] = useState(1)
    const [payload, setPayload] = useState({})
    return (
        <View>
            <MakeOffer setStep={setStep} setPayload={setPayload} payload={payload} isReview={step === 3}/>
            {/* {step === 2 && <Delivery setStep={setStep} setPayload={setPayload} payload={payload}/>} */}
        </View>
    )
}

export default Commission