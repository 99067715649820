import { useEffect, useRef, useState } from "react"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useExplorePostsStore, useModalStore, usePostStore, useProductDetailPageStore, useResponsiveStore } from "../../stores/web.store"
import { StyledExplorePosts, StyledExplorePostsContainer, StyledQuickFiltersContainer } from "./styled"
import { Post } from "../../components/Post"
import InfiniteScroll from "react-infinite-scroll-component";
import api from "../../services/api.service"
import { View } from "../../components/View"
import { QuickFilters } from "../../components/Filters/QuickFilters"
import { Boards } from "../../components/Boards"
import { PageLoader } from "../../components/Loader/PageLoader"
import { set } from "lodash"
import Skeleton from "react-loading-skeleton"
import {ReactComponent as AILoading} from '../../img/aiLoader.svg'


type ExploreQuickFiltersProps = {
    posts: any;
    setPosts: any;
    setOffset: any;
    setHasMore: any;
    selectedFilter: string;
    setSelectedFilter: any;
    boards?: any;
    fetchMoreData?: any;
    setIsLoading?: any;
}


const ExploreQuickFilters = ({setIsLoading, fetchMoreData, selectedFilter, setSelectedFilter, setOffset, setHasMore, posts, boards, setPosts}: ExploreQuickFiltersProps) => {
    const filteredData = useExplorePostsStore((state: any) => state.filteredData) 
    const setFilteredData = useExplorePostsStore((state: any) => state.setFilteredData) 
    
    const [isInit, setIsInit] = useState(false)

    useEffect(() => {
        const fetch = async () => {
            setIsInit(true)
            const filter = (selectedFilter === 'all') ? {} : {type: selectedFilter}
            
            const resp = await api.post(`/search/explore`, {query: filter, offset: 0})
            setPosts(resp.data)
            setFilteredData(resp.data)
            setHasMore(resp.data.length > 0) 
            setOffset(0);
        }
        if (selectedFilter) fetch()

    }, [selectedFilter])

    useEffect(() => {
        if (isInit) {
            fetchMoreData()
            setIsInit(false)
        }
    }, [posts])

    const searchRequest = async (value: any) => {
        const filter = (selectedFilter === 'all') ? {} : {type: selectedFilter}
        const resp = await api.post(`/search/explore`, {query: {...filter, query: value}, offset: 0})
        setPosts(resp.data)
        setFilteredData(resp.data)
        setHasMore(resp.data.length > 0) 
        setOffset(0);
    }

    return (
        <QuickFilters searchRequest={searchRequest} resetData={selectedFilter === 'boards' ? boards : posts} setFilteredData={setFilteredData} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} includeAll includeMedia includeArtwork includeAuction includeShow includeBoards includeAISearch includeFilters />
    )
}

const UserBoards = ({apiResp, setApiResp}: any) => {
    const setFilteredData = useExplorePostsStore((state: any) => state.setFilteredData) 
    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get('/boards')
            setApiResp(resp)
            setFilteredData(resp.data)
        }

        fetch()
    }, [])

    if (!apiResp.is_loaded) return <PageLoader />

    return (
        <Boards api={apiResp}/>
    )
}

const Explore = () => {
    const ref = useRef<any>(null)
    const explorePosts = useExplorePostsStore((state: any) => state.explorePosts) 
    const setExplorePosts = useExplorePostsStore((state: any) => state.setExplorePosts) 

    const filteredData = useExplorePostsStore((state: any) => state.filteredData) 
    const setFilteredData = useExplorePostsStore((state: any) => state.setFilteredData) 

    const [selectedFilter, setSelectedFilter] = useState<any>(null)

    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setPost = usePostStore((state: any) => state.setPost)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const width = useResponsiveStore((state: any) => state.width)

    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const [boardsApiResp, setBoardsApiResp] = useState<any>({})
    const [height, setHeight] = useState(0);

    const [isLoading, setIsLoading] = useState(false)

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => { 
        setSelectedFilter('all')
        if (ref.current) { 
            setHeight(ref.current.offsetHeight); 
        } 
    }, []);

    useEffect(() => { 
        const handleScroll = (event: WheelEvent) => { 
            const scrollContainer = scrollContainerRef.current; 
            if (scrollContainer && isLoading) { 
                const { scrollTop, scrollHeight, clientHeight } = scrollContainer; 
                const isAtBottom = scrollTop + clientHeight >= scrollHeight;
                if (event.deltaY < 0) { return; }
                if (isAtBottom) { event.preventDefault(); } 
                // scrollContainer.scrollTop = scrollTop; 
            } 
        }; 

        const scrollContainer = scrollContainerRef.current; 
        if (scrollContainer) { 
            scrollContainer.addEventListener('wheel', handleScroll, { passive: false }); 
        } 
        return () => { if (scrollContainer) { scrollContainer.removeEventListener('wheel', handleScroll); } }; 
    }, [isLoading]);


    const fetchMoreData = async () => {
        setIsLoading(true)
        const newOffset = offset + 1 
        const resp = await api.post(`/search/explore`, {query: {}, offset: explorePosts.length})
        setIsLoading(false)
        setExplorePosts([...explorePosts, ...resp.data])
        setFilteredData([...filteredData, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
        
        
    };

    const onClick = (obj: any) => {
        if (obj.type === 'artwork') {
            setPdp(obj.type)
        } else {
            setModalKey('post')
        }
        
        setPost(obj)
    }


    return (
        <DashboardLayout>
            <View display="flex" flexDirection="column" gap={width > 768 ? "32px" : "4px"} padding={width > 768 ? "12px 0px 12px 12px" : "4px"} overflow="hidden">
                <div ref={ref}>
                    <StyledQuickFiltersContainer>
                        <ExploreQuickFilters setIsLoading={setIsLoading} fetchMoreData={fetchMoreData} boards={boardsApiResp.data} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} setOffset={setOffset} setHasMore={setHasMore} posts={explorePosts} setPosts={setExplorePosts}/>
                    </StyledQuickFiltersContainer>
                </div>
                {selectedFilter === 'boards' ? <UserBoards apiResp={boardsApiResp} setApiResp={setBoardsApiResp}/> :
                <StyledExplorePostsContainer height={`calc(100vh - ${height}px - 64px)`} id="Explore" ref={scrollContainerRef}> 
                    <InfiniteScroll
                        dataLength={explorePosts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="Explore"
                        scrollThreshold={0.9}
                    >
                        <StyledExplorePosts>
                            {filteredData.map((obj: any) => {
                                return (
                                    <Post data={obj} setPost={setPost} onClick={() => onClick(obj)} height={width > 768 ? 'auto' : '120px'} />
                                )
                            })}
                        </StyledExplorePosts>
                    </InfiniteScroll>
                </StyledExplorePostsContainer>}
                {isLoading && <View position="fixed" bottom="0" left="64px" width="calc(100% - 64px)" textAlign="center"><AILoading height={18} width={18} /></View>}
            </View>
        </DashboardLayout>
    )
}

export default Explore