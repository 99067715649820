import styled from 'styled-components'


interface DiscoverPeopleContainerProps {

}

export const StyledDiscoverPeopleContainer = styled.div<DiscoverPeopleContainerProps>`

`

interface DiscoverPeopleProps {

}

export const StyledDiscoverPeople = styled.div<DiscoverPeopleProps>`
`


