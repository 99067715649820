import styled from 'styled-components'


interface CriticallyAcclaimedContainerProps {

}


export const StyledCriticallyAcclaimedContainer = styled.div<CriticallyAcclaimedContainerProps>`
    padding: 12px 0px
`

interface CriticallyAcclaimedProps {

}


export const StyledCriticallyAcclaimed = styled.div<CriticallyAcclaimedProps>`
    display: inline-flex;
    width: 100%;
    padding: 4px 0px;
`

interface CriticallyAcclaimedContentContainerProps {
    isExpanded?: boolean;
}

export const StyledCriticallyAcclaimedContentContainer = styled.div<CriticallyAcclaimedContentContainerProps>`
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    ${props => props.isExpanded && `
        height: fit-content;
        max-height: 200px;
    `}
`

interface CriticallyAcclaimedContentProps {

}

export const StyledCriticallyAcclaimedContent = styled.div<CriticallyAcclaimedContentProps>`

`









