import styled from "styled-components";


interface MessageContainerProps {

}

export const StyledMessgeContainer = styled.div<MessageContainerProps>`

`

interface MessageProps {

}

export const StyledMessge = styled.div<MessageProps>`

`

interface ContactContainerProps {

}

export const StyledContactContainer = styled.div<ContactContainerProps>`
    border-bottom: solid 1px #D7D7D7;
    height: 64px;
`

interface ContactProps {

}

export const StyledContact = styled.div<ContactProps>`
    display: inline-flex;
    width: calc(100% - 12px);
    align-items: center;
    height: 64px;
    gap: 8px;

    @media (min-width: 768px) {
        width: calc(100% - 20px);
    }

    @media (min-width: 1024px) {

    }
`

interface ThreadContainerProps {
    isSender: boolean;
}

export const StyledThreadContainer = styled.div<ThreadContainerProps>`
    margin: 12px;
    justify-content: ${props => props.isSender ? 'end' : 'start'};
    width: calc(100% - 20px);
    display: inline-flex;
    align-items: end;
    gap: 12px;

    ${props => props.isSender && `
        flex-direction: row-reverse;    
    `};
    
`

interface ThreadProps {
    isTyping?: boolean;
    isButton?: boolean;
    isSender?: boolean;
}

export const StyledThread = styled.div<ThreadProps>`
    width: max-content;
    background: ${props => props.isSender ? '#0938F0' : '#EBEBEB'};
    color: ${props => props.isSender ? '#fff' : '#000'};
    border-radius: 10px;
    padding: 12px;

    ${props => props.isButton && `
        padding: 0;
        background: #fff;
    `};

    ${props => props.isTyping && `
        padding: 10px;
        background: #fff;
    `};
    
`

interface TypingProps {

}

export const StyledTyping = styled.div<TypingProps>`
    position: relative;
    width: 40px;
    height: 10px;
    

    
`

export const StyledTypingSpan = styled.span<TypingProps>`
    content: '';
    animation: blink 1.5s infinite;
    animation-fill-mode: both;
    height: 10px;
    width: 10px;
    background: #0938F0;
    position: absolute;
    left:0;
    top:0;
    border-radius: 50%;
    
    &:nth-child(2) {
        animation-delay: .2s;
        margin-left: calc(10px * 1.5);
    }
    
    &:nth-child(3) {
        animation-delay: .4s;
        margin-left: calc(10px * 3);
    }

    @keyframes blink {
        0% {
            opacity: .1;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: .1;
        }
    }
    
`
