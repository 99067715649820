import styled from 'styled-components'


interface ContentContainerProps {

}


export const StyledContentContainer = styled.div<ContentContainerProps>`
    text-align: center;
`

interface ContentProps {

}


export const StyledContent = styled.div<ContentProps>`

`









