import styled from "styled-components";


interface LikeContainerProps {

}

export const StyledLikeContainer = styled.div<LikeContainerProps>`

`

interface LikeProps {

}

export const StyledLike = styled.div<LikeProps>`

`