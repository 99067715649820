import { ReactNode, useEffect, useRef, useState } from "react"
import { StyledBackNavigator, StyledExitButton, StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer, StyledNextNavigator } from "./styled"
import { useComponentVisible } from "../../../utils/app.util"
import { useModalStore, usePostStore, useProductDetailPageStore, useResponsiveStore } from "../../../stores/web.store";
import { View } from "../../View";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'
import {ReactComponent as LeftIcon} from '../../../img/leftNavigator.svg'
import {ReactComponent as RightIcon} from '../../../img/rightNavigator.svg'


type PostModallProps = {
    children: ReactNode;
    setModal: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
    modalDimensions?: any;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const pdp = useProductDetailPageStore((state: any) => state.pdp)

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false)
            setIsModal(false)
            setModalKey('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const BackNavigator = ({posts, data}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const onClick = (e: any) => {
        if (data.index === 0) {
            setPost({...posts[posts.length - 1], index: posts.length - 1})
        } else {
            setPost({...posts[data.index - 1], index: data.index - 1})
        }
    }
    return (
        <StyledBackNavigator>
            <Button noStyle onClick={onClick}>
                <LeftIcon height={32} width={32}/>
            </Button>
        </StyledBackNavigator>
    )
}

const NextNavigator = ({posts, data}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const onClick = () => {
        if (data.index === posts.length - 1) {
            setPost({...posts[0], index: 0})
        } else {
            setPost({...posts[data.index + 1], index: data.index + 1})
        }
    }
    return (
        <StyledNextNavigator>
            <Button noStyle onClick={onClick}>
                <RightIcon height={32} width={32}/>
            </Button>
        </StyledNextNavigator>
    )
}

const PostModal = ({setModal, modalDimensions, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius, children}: PostModallProps) => {
    // const { ref } = useComponentVisible(setModal);
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const respWidth = useResponsiveStore((state: any) => state.width)
    const wrapperRef = useRef(null);

    // TEMP: Comment out for now
    // useOutsideAlerter(wrapperRef, setModal);

    const onClick = () => {
        setIsModal(false)
        setModalKey('')
    }

    return (
        <StyledModalContainer position={position} top={top} left={left}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer modalDimensions={modalDimensions}>
                    
                    <StyledModalContent 
                        height={height} 
                        background={background} 
                        padding={padding} 
                        borderRadius={borderRadius} 
                        ref={wrapperRef}
                    >
                        {children}
                    </StyledModalContent>
                </StyledModalContentContainer>
            </StyledModal>
            <StyledExitButton>
                <Button onClick={onClick} noStyle>
                    {respWidth > 768 ? <XIcon height={28} width={28} stroke="#fff" strokeWidth={4} /> : "Back"}
                </Button>
            </StyledExitButton>
            {/* <BackNavigator />
            <NextNavigator /> */}
        </StyledModalContainer>
    )
}

export default PostModal