import styled from 'styled-components'


interface ActionsContainerProps {

}


export const StyledActionsContainer = styled.div<ActionsContainerProps>`
    padding: 48px 0px;
`

interface ActionsProps {

}


export const StyledActions = styled.div<ActionsProps>`

`

interface CreateAccountContainerProps {

}


export const StyledCreateAccountContainer = styled.div<CreateAccountContainerProps>`
    padding: 20px 0px;
`

interface SignInContainerProps {

}


export const StyledSignInContainer = styled.div<SignInContainerProps>`

`










