import styled from 'styled-components'


interface LikesContainerProps {
    width?: string;
}


export const StyledLikesContainer = styled.div<LikesContainerProps>`
    width: ${props => props.width || 'auto'};
    padding: 4px 0px;
`

interface LikesProps {

}


export const StyledLikes = styled.div<LikesProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 16px;
`

interface PhotoContainerProps {

}

export const StyledPhotoContainer = styled.div<PhotoContainerProps>`

`










