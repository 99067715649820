import styled from 'styled-components'


interface PrivacySettingsContainerProps {

}


export const StyledPrivacySettingsContainer  = styled.div<PrivacySettingsContainerProps>`
    overflow: hidden;
`

interface PrivacySettingsProps {
    isExpanded?: boolean;
}

export const StyledPrivacySettings = styled.div<PrivacySettingsProps>`
    height: 0;
    transition: all 0.5s ease-out;
    ${props => props.isExpanded && `
        height: fit-content;
    `}
`

interface SetAudienceProps {

}

export const StyledSetAudience = styled.div<SetAudienceProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding: 24px 0px;
`

interface SetAudienceButtonsProps {

}

export const StyledSetAudienceButtons = styled.div<SetAudienceButtonsProps>`
    display: inline-flex;
    gap: 6px;
    justify-content: right;
    flex-grow: 1;
    
`

interface SwitchContainerProps {

}

export const StyledSwitchContainer = styled.div<SwitchContainerProps>`
    width: 100%;
    display: inline-flex;
    padding: 12px 0px;
`




