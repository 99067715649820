import { StyledImpressions, StyledImpressionsContainer } from "./styled"
import {ReactComponent as ImpressionsIcon} from '../../../img/impressions.svg'
import { Text } from "../../Text";
import Button from "../Button";

type ImpressionsProps = {
    data?: any;
}


const Impressions = ({data}: ImpressionsProps) => {
    return (
        <StyledImpressionsContainer>
            <StyledImpressions>
                <Button onClick={() => {}} noStyle cursor="unset" isNoEffect>
                    <ImpressionsIcon />
                    <Text>{data.total_views}</Text>
                </Button>
            </StyledImpressions>
        </StyledImpressionsContainer>
    )
}

export default Impressions