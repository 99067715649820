import styled from "styled-components";


interface AttachmentContainerProps {

}

export const StyledAttachmentContainer = styled.div<AttachmentContainerProps>`

`

interface AttachmentProps {

}

export const StyledAttachment = styled.div<AttachmentProps>`
    cursor: pointer;

`