import styled from 'styled-components'


interface AboutTheWorkContainerProps {

}


export const StyledAboutTheWorkContainer  = styled.div<AboutTheWorkContainerProps>`

`

interface AboutTheWorkProps {

}

export const StyledAboutTheWork = styled.div<AboutTheWorkProps>`

`

interface InputContainerProps {

}

export const StyledInputContainer = styled.div<InputContainerProps>`
    padding: 8px 0px;
`

