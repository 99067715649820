import { Signup } from "../../../../../components/Signup"
import { StyledState, StyledStateContainer } from "../styled"
import { StyledCreateAccountContainer, StyledCreateAccount } from "./styled"


const CreateAccountState = () => {
    return (
        <StyledCreateAccountContainer>
            <StyledCreateAccount>
                <StyledStateContainer>
                    <StyledState>
                        <Signup />
                    </StyledState>
                </StyledStateContainer>
            </StyledCreateAccount>
        </StyledCreateAccountContainer>
    )
}

export default CreateAccountState