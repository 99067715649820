import styled from 'styled-components'


interface PostAvailabilityContainerProps {

}


export const StyledPostAvailabilityContainer  = styled.div<PostAvailabilityContainerProps>`
    
`

interface PostAvailabilityProps {

}

export const StyledPostAvailability = styled.div<PostAvailabilityProps>`
    padding: 12px 0px;
`

