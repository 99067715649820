import ReactSelect from 'react-select'
import { StyledSelectContainer } from './styled';
import { useThemeStore } from '../../stores/web.store';


type SelectProps = {
    options?: any;
    onChange?: any
    placeholder?: string;
    width?: string;
    color?: string;
    background?:string;
    cursor?: string;
    border?: string;
    borderRadius?: string;
    padding?: string;
    fontSize?: string;
    outline?: string;
    textIndent?: string;
    boxShadow?: string;
    isHiddenIndicator?: boolean;
    isSearchable?: boolean;
    value?: any;
    isMulti?: boolean;
    controlShouldRenderValue?: boolean
}


const Select = ({
        options, 
        onChange, 
        value, 
        boxShadow, 
        placeholder,
        width, 
        background, 
        cursor, 
        padding, 
        textIndent, 
        border, 
        borderRadius, 
        outline, 
        color, 
        fontSize, 
        isSearchable, 
        isHiddenIndicator,
        isMulti,
        controlShouldRenderValue
    }: SelectProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    const components = {
        IndicatorSeparator: () => null
    } as any

    if (isHiddenIndicator) {
        components["DropdownIndicator"] = () => null
    }
    return (
        <StyledSelectContainer
            width={width}
            padding={padding}
            theme={theme}
            borderRadius={borderRadius}
            border={border}
            fontSize={fontSize}
            background={background}
            color={color}
            outline={outline}
            boxShadow={boxShadow}
            textIndent={textIndent}
            cursor={cursor}
        >
            <ReactSelect 
                classNamePrefix="select"
                options={options} 
                onChange={onChange}
                placeholder={placeholder}
                isSearchable={isSearchable}
                components={components}
                value={value}
                isMulti={isMulti}
                controlShouldRenderValue={controlShouldRenderValue}
                
            />
        </StyledSelectContainer>
        
    )
}

export default Select
