import { useThemeStore } from "../../../stores/web.store"
import Button from "../Button"

const Offer = ({setIsModal, isModal}: any) => {
    const theme = useThemeStore((state: any) => state.theme)

    const onClick = () => {
        setIsModal(!isModal)
    }

    return (
        <Button 
            width="90%" 
            height="48px" 
            borderRadius="48px" 
            border={`solid 1px #000`}
            fontSize="16px"
            fontWeight="500"
            noStyle
            onClick={onClick}
            // disabled={auctionState !== 'active'}
        >Make Offer</Button>
    )
}

export default Offer