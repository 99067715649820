import { QuickFilter } from "../QuickFilter"


type Props = {
    selectedFilter: any;
    setSelectedFilter: any;
}


const Messages = ({selectedFilter, setSelectedFilter}: Props) => {
    const onClick = () => {
        setSelectedFilter('direct_message')
    }

    return (
        <QuickFilter title="Messages" onClick={onClick} isSelected={selectedFilter === 'direct_message'}/>
    )
}

export default Messages