import { useState } from "react";
import { Button } from "../../../components/Button";
import PhotoReel from "../../../components/PhotoReel/PhotoReel"
import { Text } from "../../../components/Text";
import { View } from "../../../components/View"
import { useEffectOnce } from "../../../utils/app.util";
import api from "../../../services/api.service";


const Artist = ({post}: any) => {
    const [respApi, setRespApi] = useState<any>({loaded: false})
    useEffectOnce(() => {
        const fetch = async () => {
            const resp = await api.get(`/account/${post.owner_id}/posts`)
            setRespApi(resp)
        }
        fetch()
    })

    const onClickViewAll = () => {
        
    }

    return (
        <View>
            <View padding="0px 0px 20px 0px">
                <View display="inline-flex" alignItems="center" width="100%">
                    <View width="90%">
                        <Text 
                            fontSize="20px" 
                            fontWeight="500" 
                            padding="0px 0px 4px 0px"
                        >{`Other works by ${post.owner.first_name} ${post.owner.last_name}`}</Text>
                    </View>
                    <View width="10%" justifyContent="end">
                        <Button 
                            noStyle 
                            width="100%"
                            justifyContent="end"
                            fontWeight="500"
                            onClick={onClickViewAll}
                        >{`View All`}</Button>
                    </View>
                </View>
            </View>
            <View>
                <PhotoReel api={respApi} />
            </View>
        </View>
    )
}

export default Artist