import { Tag } from "../../../components/Tag"
import { Text } from "../../../components/Text"
import { useAccountStore } from "../../../stores/web.store"
import { StyledFeaturedRepresentation, StyledFeaturedRepresentationContainer } from "./styled"


const FeaturedRepresentation = () => {
    const account = useAccountStore((state: any) => state.account)
    const data = [
        {
            id: 1, 
            name: 'Thaddeaus Ropac',
            photo: '/img/hero.jpg'
        }
    ]

    return (
            <StyledFeaturedRepresentationContainer>
                <StyledFeaturedRepresentation>
                    <Text fontWeight="600" padding="0px 0px 12px 0px">Featured Representation</Text>
                    {account.featured_representation && data.map((obj: any) => {
                        return (
                            <Tag key={obj.id} text={obj.name} image={obj.photo} />
                        )
                    })}
                </StyledFeaturedRepresentation>
            </StyledFeaturedRepresentationContainer>
    )
}

export default FeaturedRepresentation