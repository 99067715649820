import { SignupCodesTable } from "../../components/Table/SignupCodesTable"
import { Text } from "../../components/Text"
import { StyledDashboard, StyledDashboardContainer, StyledMetrics, StyledMetricsContainer, StyledTotalContainer, StyledViewToggle, StyledViewToggleContainer } from "./styled"
import { Layout } from "../../sections/Layout"
import { AccountAuditTable } from "../../components/Table/AccountAuditTable"
import { Button } from "../../components/Button"
import { useEffect, useState } from "react"
import { useAccountAudit } from "../../hooks/api.hook"
import { StyledMetricContainer } from "../../components/Metric/styled"
import { Metric } from "../../components/Metric"
import { ButterBar } from "../../components/ButterBar"
import { useButterBarStore } from "../../stores/web.store"



const ViewToggle = ({view, setView}: any) => {

    const onClickViewToggle = (value: string) => {
        setView(value)
    }

    return (
        <StyledViewToggleContainer>
            <StyledViewToggle>
                <Button 
                    onClick={() => onClickViewToggle('dashboard')}
                    disabled={view === 'dashboard'}
                    padding={'4px 12px'}
                >Dashboard</Button>
                <Button 
                    onClick={() => onClickViewToggle('codes')}
                    disabled={view === 'codes'}
                    padding={'4px 12px'}
                >Signup Codes</Button>
                <Button 
                    onClick={() => onClickViewToggle('audit')}
                    disabled={view === 'audit'}
                    padding={'4px 12px'}
                >Account Audit</Button>
            </StyledViewToggle>
        </StyledViewToggleContainer>
    )
}

const AccountMetrics = () => {
    const { data } = useAccountAudit()
    const [metricData, setMetricData] = useState<any>(null)

    useEffect(() => {
        if (data && data.is_loaded) {
            setMetricData(data.data)

        }
    }, [data])

    const [activeMetric, setActiveMetric] = useState<any>(null)
    

    if (metricData === null) return <div/>
    console.log(metricData)

    const active = {value: 0, title: 'Active'}
    const pending = {value: 0, title: 'Pending'}
    const error = {value: 0, title: 'Error'}
    const verified = {value: 0, title: 'Verified'}
    const unverified = {value: 0, title: 'Unverified'}
    
    const totalArtists = {value: 0, title: 'Artists'}

    const totalGalleries = {value: 0, title: 'Galleries'}

    const totalMuseums = {value: 0, title: 'Museums'}

    const totalArtPatrons = {value: 0, title: 'Art Patrons'}
    const verifiedArtPatrons = {value: 0, title: 'Art Patrons'}
    const unverifiedArtPatrons = {value: 0, title: 'Art Patrons'}

    const verifiedCollector = {value: 0, title: 'Collector'}
    const verifiedArtAdvisor = {value: 0, title: 'Art Advisor'}
    const verifiedArtDealer = {value: 0, title: 'Art Dealer'}
    const verifiedArtWriter = {value: 0, title: 'Art Writer'}
    const verifiedCurator = {value: 0, title: 'Curator'}
    const verifiedInteriorDesigner = {value: 0, title: 'Interior Designer'}


    // const unverifiedArtist = {value: 0, title: 'Artist'}
    // const unverifiedGallery = {value: 0, title: 'Gallery'}
    // const unverifiedMuseum = {value: 0, title: 'Museum'}

    const unverifiedCollector = {value: 0, title: 'Collector'}
    const unverifiedArtAdvisor = {value: 0, title: 'Art Advisor'}
    const unverifiedArtDealer = {value: 0, title: 'Art Dealer'}
    const unverifiedArtWriter = {value: 0, title: 'Art Writer'}
    const unverifiedCurator = {value: 0, title: 'Curator'}
    const unverifiedInteriorDesigner = {value: 0, title: 'Interior Designer'}

    let totalUsers = 0
    let verifiedArtists = 0
    let verifiedGallery = 0
    let verifiedMuseum = 0
    let unverifiedArtists = 0
    let unverifiedGallery = 0
    let unverifiedMuseum = 0

    metricData.forEach((account: any) => {
        if (account.status === 'active' && account.role !== 'admin') {
            active.value += 1
        }

        if (account.status === 'pending' && account.role !== 'admin') {
            pending.value += 1
        }

        if (account.status === 'error' && account.role !== 'admin') {
            error.value += 1
        }
        if (account.is_verified) {
            if (account.role === 'collector') {
                verifiedCollector.value += 1
                totalArtPatrons.value += 1
                verifiedArtPatrons.value += 1 
                totalUsers += 1
            }
            if (account.role === 'artist') {
                verifiedArtists += 1
                totalArtists.value += 1
                totalUsers += 1
            }
            if (account.role === 'gallery') {
                verifiedGallery += 1
                totalGalleries.value += 1
                totalUsers += 1
            }
            if (account.role === 'museum') {
                verifiedMuseum += 1
                totalMuseums.value += 1
                totalUsers += 1
            }
            if (account.role === 'art_advisor') {
                verifiedArtAdvisor.value += 1
                totalArtPatrons.value += 1
                verifiedArtPatrons.value += 1 
                totalUsers += 1
            }
            if (account.role === 'art_dealer') {
                verifiedArtDealer.value += 1
                totalArtPatrons.value += 1
                verifiedArtPatrons.value += 1 
                totalUsers += 1
            }
            if (account.role === 'art_writer') {
                verifiedArtWriter.value += 1
                totalArtPatrons.value += 1
                verifiedArtPatrons.value += 1 
                totalUsers += 1
            }
            if (account.role === 'curator') {
                verifiedCurator.value += 1
                totalArtPatrons.value += 1
                verifiedArtPatrons.value += 1 
                totalUsers += 1
            }
            if (account.role === 'interior_designer') {
                verifiedInteriorDesigner.value += 1
                totalArtPatrons.value += 1
                verifiedArtPatrons.value += 1 
                totalUsers += 1
            }

            if (account.role !== 'admin')
                verified.value += 1
        } else {
            if (account.role === 'collector') {
                unverifiedCollector.value += 1
                totalArtPatrons.value += 1
                unverifiedArtPatrons.value += 1
                totalUsers += 1
            }
            if (account.role === 'artist') {
                unverifiedArtists += 1
                totalArtists.value += 1
                totalUsers += 1
            }
            if (account.role === 'gallery') {
                unverifiedGallery += 1
                totalGalleries.value += 1
                totalUsers += 1
            }
            if (account.role === 'museum') {
                unverifiedMuseum += 1
                totalMuseums.value += 1
                totalUsers += 1
            }
            if (account.role === 'art_advisor') {
                unverifiedArtAdvisor.value += 1
                totalArtPatrons.value += 1
                unverifiedArtPatrons.value += 1
                totalUsers += 1
            }
            if (account.role === 'art_dealer') {
                unverifiedArtDealer.value += 1
                totalArtPatrons.value += 1
                unverifiedArtPatrons.value += 1
                totalUsers += 1
            }
            if (account.role === 'art_writer') {
                unverifiedArtWriter.value += 1
                totalArtPatrons.value += 1
                unverifiedArtPatrons.value += 1
                totalUsers += 1
            }
            if (account.role === 'curator') {
                unverifiedCurator.value += 1
                totalArtPatrons.value += 1
                unverifiedArtPatrons.value += 1
                totalUsers += 1
            }
            if (account.role === 'interior_designer') {
                unverifiedInteriorDesigner.value += 1
                totalArtPatrons.value += 1
                unverifiedArtPatrons.value += 1
                totalUsers += 1
            }

            if (account.role !== 'admin')
                unverified.value += 1
        }
    })

    const totalMetrics =[
        totalArtists,
        totalArtPatrons,
        totalGalleries,
        totalMuseums,
    ]

    const metrics = [
        verified,
        unverified,
        active,
        pending,
        error
    ]

    const verifiedMetrics = [
        verifiedArtPatrons,
        verifiedCollector,
        verifiedArtAdvisor,
        verifiedArtDealer,
        verifiedArtWriter,
        verifiedCurator,
        verifiedInteriorDesigner
    ]

    const unverifiedMetrics = [
        unverifiedArtPatrons,
        unverifiedCollector,
        unverifiedArtAdvisor,
        unverifiedArtDealer,
        unverifiedArtWriter,
        unverifiedCurator,
        unverifiedInteriorDesigner
    ]
    

    return (
        <div>
            <div style={{width: '50%', margin: 'auto', padding: '48px 0px'}}>
                {/* <Text fontSize="32px" color="#fff" textAlign="center" padding="24px 0px" width='100%'>Totals</Text> */}
                <Metric title={'Total Users'} value={totalUsers} />
            </div>
            <div>
                <Text fontSize="32px" color="#fff" textAlign="center" padding="24px 0px" width='100%'>Totals</Text>
                <StyledTotalContainer>
                    {totalMetrics.map((metric: any) => {
                        return <Metric title={metric.title} color={activeMetric === metric.title ? '#2187FF' : '#fff'} value={metric.value} onClick={() => setActiveMetric(metric.title)}/>
                    })}
                </StyledTotalContainer>
            </div>
            
            <div>
                {activeMetric && <Text fontSize="32px" color="#fff" textAlign="center" padding="24px 0px" width="100%">{activeMetric}</Text>}
                <StyledMetricsContainer>
                    {activeMetric === 'Artists' && <StyledMetricContainer>
                        <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Verified</Text>
                        <StyledMetrics>
                            <Metric title={activeMetric} value={verifiedArtists} />
                        </StyledMetrics>
                    </StyledMetricContainer>}

                    {activeMetric === 'Galleries' && <StyledMetricContainer>
                        <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Verified</Text>
                        <StyledMetrics>
                            <Metric title={activeMetric} value={verifiedGallery} />
                        </StyledMetrics>
                    </StyledMetricContainer>}
                    {activeMetric === 'Museums' && <StyledMetricContainer>
                        <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Verified</Text>
                        <StyledMetrics>
                            <Metric title={activeMetric} value={verifiedMuseum} />
                        </StyledMetrics>
                    </StyledMetricContainer>}
                    {activeMetric === 'Art Patrons' && <StyledMetricContainer>
                        <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Verified</Text>
                        <StyledMetrics>
                            {verifiedMetrics.map((metric: any) => {
                                return <Metric title={metric.title} value={metric.value} />
                            })}
                        </StyledMetrics>
                    </StyledMetricContainer>}
                    
                    <StyledMetricContainer>
                        {activeMetric === 'Artists' && <StyledMetricContainer>
                            <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Unverified</Text>
                            <StyledMetrics>
                                <Metric title={activeMetric} value={unverifiedArtists} />
                            </StyledMetrics>
                        </StyledMetricContainer>}

                        {activeMetric === 'Galleries' && <StyledMetricContainer>
                            <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Unverified</Text>
                            <StyledMetrics>
                                <Metric title={activeMetric} value={unverifiedGallery} />
                            </StyledMetrics>
                        </StyledMetricContainer>}
                        {activeMetric === 'Museums' && <StyledMetricContainer>
                            <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Unverified</Text>
                            <StyledMetrics>
                                <Metric title={activeMetric} value={unverifiedMuseum} />
                            </StyledMetrics>
                        </StyledMetricContainer>}
                        {activeMetric === 'Art Patrons' && <StyledMetricContainer>
                            <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px" width="100%">Unverified</Text>
                            <StyledMetrics>
                                {unverifiedMetrics.map((metric: any) => {
                                    return <Metric title={metric.title} value={metric.value} />
                                })}
                            </StyledMetrics>
                        </StyledMetricContainer>}
                    </StyledMetricContainer>
                </StyledMetricsContainer>
            </div>
            {/* <StyledTotalContainer >
                {metrics.map((metric: any) => {
                    return <Metric title={metric.title} value={metric.value} />
                })}
            </StyledTotalContainer> */}
        </div>
    )
}


const AdminDashboard = () => {
    const [view, setView] = useState('dashboard')
    const isButterBar = useButterBarStore((state: any) => state.isButterBar)
    return (
        <Layout>
            <StyledDashboardContainer>
                <StyledDashboard>
                    <ViewToggle view={view} setView={setView} />

                    {view === 'dashboard' && <AccountMetrics />}
                    {view === 'codes' && <SignupCodesTable />}
                    {view === 'audit' && <AccountAuditTable />}
                </StyledDashboard>
                {isButterBar && <ButterBar>{isButterBar}</ButterBar>}
            </StyledDashboardContainer>   
        </Layout>
    )
}

export default AdminDashboard