import styled from 'styled-components'


interface SavedContainerProps {

}


export const StyledSavedContainer = styled.div<SavedContainerProps>`
    position: relative;
`

interface SavedProps {

}


export const StyledSaved = styled.div<SavedProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 4px;
`


