import styled from "styled-components";


export const StyledCareersContainer = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (min-width: 768px) {
        padding: 48px;
    }
    
    @media (min-width: 1600px) {
    }
`

export const StyledHeroContainer = styled.div`

    background-image: url("/img/landing/CareersHero.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 264px;
    width: 100%;

    @media (min-width: 768px) {
        height: 405px;
    }
    
    @media (min-width: 1600px) {
    }

`

export const StyledCards = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 48px 32px;
    }
    
    @media (min-width: 1600px) {
    }
`

export const StyledCard = styled.div`
    -webkit-box-shadow: 0px 0px 10px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px -8px rgba(0,0,0,0.75);
    padding: 24px;
    border-radius: 10px;
`