import { useState } from "react"
import { usePostStore, useThemeStore } from "../../../stores/web.store"
import Button from "../Button"
import api from "../../../services/api.service"

const MakeOffer = ({setIsModal, amount, offer, setOffer}: any) => {
    const post = usePostStore((state: any) => state.post)
    const theme = useThemeStore((state: any) => state.theme)


    const _placeBid = async () => {
        if (offer.id) {
            const payload = {
                amount
            }

            const resp = await api.post(`/offers/${offer?.id}/store/${offer.store?.id}`, payload)
            setOffer(resp)
            return resp
            
        } else {
            const payload = {
                post_id: post?.id,
                amount
            }
            const resp = await api.post('/offers', payload)
            setOffer(resp)
            return resp
        }
        
        
    }

    const onClick = async () => {
        await _placeBid()
        setTimeout(() => {
            setOffer({})
            setIsModal(false)
        }, 3000);
    }

    return (
        <Button 
            fontWeight="500"
            fontSize="16px"
            width="fit-content" 
            height="32px" 
            borderRadius="32px"
            color={theme.button.secondaryColor}
            onClick={onClick}
            // disabled={auctionState !== 'active'}
        >{offer.id ? 'Update Offer' : 'Make Offer'}</Button>
    )
}

export default MakeOffer