import styled from 'styled-components'


interface FilteredItemContainerProps {
    isExpanded?: boolean;
}


export const StyledFilterItemContainer  = styled.div<FilteredItemContainerProps>`
    overflow: hidden;
    
    ${props => props.isExpanded && `
        & svg {
            transform: rotate(180deg);
        }
    `}
`

interface FilteredItemProps {
    isExpanded?: boolean;
}

export const StyledFilterItem = styled.div<FilteredItemProps>`
    height: 0;
    transition: all 0.5s ease-out;
    ${props => props.isExpanded && `
        height: fit-content;
    `}

    & .range-slider {
        margin: 24px auto 0;
    }
`



