import { QuickFilter } from "../QuickFilter"


type AllProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const All = ({selectedFilter, setSelectedFilter}: AllProps) => {
    const onClick = () => {
        setSelectedFilter('all')
    }

    return (
        <QuickFilter title="All" onClick={onClick} isSelected={selectedFilter === 'all'}/>
    )
}

export default All