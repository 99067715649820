import styled from "styled-components";

interface ViewingRoomProps {
}


export const StyledViewingRoom = styled.div<ViewingRoomProps>`
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;

    &:hover {
        background: rgba(0, 0, 0, 0.25);

        .viewing-room-text {
            gap: 8px;
        }
    }
`

interface ViewingRoomTextProps {

}

export const StyledViewingRoomText = styled.div<ViewingRoomTextProps>`
    display: inline-flex;
    align-items: center;
    gap: 4px;
`
