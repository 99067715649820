import styled from 'styled-components'


interface CreateShowPostContentContainerProps {

}


export const StyledCreateShowPostContentContainer  = styled.div<CreateShowPostContentContainerProps>`

`

interface CreateShowPostContentProps {

}

export const StyledCreateShowPostContent = styled.div<CreateShowPostContentProps>`
`