import { useQuery } from 'react-query'
import ApiService from '../services/api.service'
import api from '../services/api.service'


export const useSignupCodes = () => {
    const endpoint = '/codes'
    const { isLoading, isError, data, refetch } = useQuery(`signup-codes`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useAccountAudit = () => {
    const endpoint = '/account-audit'
    const { isLoading, isError, data, refetch } = useQuery(`account-audit`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}


export const useAccount = () => {
    const endpoint = '/account'
    const { isLoading, isError, data, refetch } = useQuery(`account`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useFooter = () => {
    const endpoint = '/web/footer'
    const { isLoading, isError, data, refetch } = useQuery(`web-footer`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useExplorePosts = () => {
    const endpoint = '/search/explore'
    const { isLoading, isError, data, refetch } = useQuery(`search`, () => ApiService.post(endpoint, {query: {}}))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useFollowingPosts = () => {
    const endpoint = '/search/following'
    const { isLoading, isError, data, refetch } = useQuery(`search-following`, () => ApiService.post(endpoint, {query: {}}))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useForYouPosts = () => {
    const endpoint = '/search/for-you'
    const { isLoading, isError, data, refetch } = useQuery(`search-for-you`, () => ApiService.post(endpoint, {query: {}}))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useRecentActivity = () => {
    const endpoint = '/recent/activity'
    const { isLoading, isError, data, refetch } = useQuery(`recent-activity`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useRecentNotifications = () => {
    const endpoint = '/recent/notifications'
    const { isLoading, isError, data, refetch } = useQuery(`recent-notifications`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useMyRecentSearches = () => {
    const endpoint = '/searches/recent'
    const { isLoading, isError, data, refetch } = useQuery(`recent-searches`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useAllMyPosts = (payload: any) => {
    const endpoint = '/search/my-posts'
    const { isLoading, isError, data, refetch } = useQuery(`search-my-posts`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useAllPosts = (payload: any) => {
    const endpoint = '/search'
    const { isLoading, isError, data, refetch } = useQuery(`search`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useShows = (payload: any) => {
    const endpoint = '/search/shows'
    const { isLoading, isError, data, refetch } = useQuery(`search-shows`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useAuctions = (payload: any) => {
    const endpoint = '/search/auctions'
    const { isLoading, isError, data, refetch } = useQuery(`search-auctions`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useFeaturedShows = (payload: any) => {
    const endpoint = '/search/featured-shows'
    const { isLoading, isError, data, refetch } = useQuery(`search-featured-shows`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useMyArtworks = (payload: any) => {
    const endpoint = '/me/search/artworks'
    const { isLoading, isError, data, refetch } = useQuery(`search-my-artworks`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useArtworks = (payload: any) => {
    const endpoint = '/search/artworks'
    const { isLoading, isError, data, refetch } = useQuery(`search-artworks`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useMyShows = (payload: any) => {
    const endpoint = '/me/earch/shows'
    const { isLoading, isError, data, refetch } = useQuery(`search-my-shows`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useMyAuctions = (payload: any) => {
    const endpoint = '/me/search/auctions'
    const { isLoading, isError, data, refetch } = useQuery(`search-my-auctions`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useMySubscriberPosts = (payload: any) => {
    const endpoint = '/search'
    payload.query.type = 'auction'
    const { isLoading, isError, data, refetch } = useQuery(`search`, () => ApiService.post(endpoint, payload))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useFilters = () => {
    const endpoint = '/filters'
    const { isLoading, isError, data, refetch } = useQuery(`filters`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useAllThreads = () => {
    const endpoint = '/threads'
    const { isLoading, isError, data, refetch } = useQuery(`direct-message-threads`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}


export const useDirectMessageThreads = () => {
    const endpoint = '/threads/direct-messages'
    const { isLoading, isError, data, refetch } = useQuery(`direct-message-threads`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const uploadProfilePicture = async (payload: any) => {
    const formData = new FormData();
    formData.append('media', payload.media.file)

    const resp = await api.post(`/profile/upload-photo`, formData, {headers: {
        'Content-Type': 'multipart/form-data'
    }})
    return resp
}

export const uploadPost = async (payload: any) => {
    const formData = new FormData();
    const serielizedPayload = Object.assign({}, {...payload, privacy_settings: JSON.stringify(payload.privacy_settings)})
    for (var key in serielizedPayload) {
        if (key === 'media') {
            for (var file of serielizedPayload.media) {
                formData.append('media', file.file)
            }
            
        } else if (key === 'posts') {
            for (var post of serielizedPayload.posts) {
                const postFormData = new FormData();
                for (var postFile of post.media) {
                    postFormData.append('media', postFile.file)
                }
                post.media = postFormData
                console.log(post)
            }
            formData.append(key, serielizedPayload.posts)
        } else {
            formData.append(key, serielizedPayload[key])
        }
       
    }

    const resp = await api.post('/posts', formData, {headers: {
        'Content-Type': 'multipart/form-data'
    }})
    return resp
}

export const uploadAuction = async (payload: any) => {
    const formData = new FormData();
    const serielizedPayload = Object.assign({}, {...payload, privacy_settings: JSON.stringify(payload.privacy_settings)})
    for (var key in serielizedPayload) {
        if (key === 'media') {
            for (var file of serielizedPayload.media) {
                formData.append('media', file.file)
            }
            
        } else if (key === 'posts') {
            for (var post of serielizedPayload.posts) {
                const postFormData = new FormData();
                for (var postFile of post.media) {
                    postFormData.append('media', postFile.file)
                }
                post.media = postFormData
            }
            formData.append(key, serielizedPayload.posts)
        } else {
            formData.append(key, serielizedPayload[key])
        }
       
    }

    const resp = await api.post('/auctions', formData, {headers: {
        'Content-Type': 'multipart/form-data'
    }})
    return resp
}

export const uploadShow = async (payload: any) => {
    const formData = new FormData();
    const serielizedPayload = Object.assign({}, {...payload, privacy_settings: JSON.stringify(payload.privacy_settings)})
    for (var key in serielizedPayload) {
        if (key === 'media') {
            for (var file of serielizedPayload.media) {
                formData.append('media', file.file)
            }
            
        } else if (key === 'posts') {
            for (var post of serielizedPayload.posts) {
                const postFormData = new FormData();
                for (var postFile of post.media) {
                    postFormData.append('media', postFile.file)
                }
                post.media = postFormData
            }
            formData.append(key, serielizedPayload.posts)
        } else {
            formData.append(key, serielizedPayload[key])
        }
       
    }

    const resp = await api.post('/shows', formData, {headers: {
        'Content-Type': 'multipart/form-data'
    }})
    return resp
}


export const uploadPublicMedia = async (payload: any) => {
    const resp = await api.post('/media/upload/public', payload, {headers: {
        'Content-Type': 'multipart/form-data'
    }})
    return resp
}

export const uploadPrivateMedia = async (payload: any) => {
    const resp = await api.post('/media/upload/private', payload, {headers: {
        'Content-Type': 'multipart/form-data'
    }})
    return resp
}



