import { Input } from "../../../components/Input"
import { Select } from "../../../components/Select"
import { Text } from "../../../components/Text"
import { usePostPayloadStore } from "../../../stores/web.store"
import { StyledAboutTheWork, StyledAboutTheWorkContainer, StyledInputContainer } from "./styled"


const ArtworkTitle = ({payload, setPayload}: any) => {
    const onBlur = (e: any) => {
        setPayload({...payload, title: e})
    }

    return (
        <StyledInputContainer>
            <Input 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Artwork title"
                width="100%"
                onBlur={onBlur}
            />
        </StyledInputContainer>
    )
}

const Medium = ({payload, setPayload}: any) => {
    const options = [
        {label: 'Painting', value: 'painting'},
        {label: 'Photography', value: 'photography'},
        {label: 'Sculpture', value: 'sculpture'},
        {label: 'Prints', value: 'prints'},
        {label: 'Work on Paper', value: 'work_on_paper'},
        {label: 'NFT', value: 'nft'},
        {label: 'Design', value: 'design'},
        {label: 'Drawing', value: 'drawing'},
        {label: 'Installation', value: 'installzation'},
        {label: 'Film/Video', value: 'video'},
        {label: 'Jewelry', value: 'jewelry'},
        {label: 'Performance Art', value: 'performance_art'},
        {label: 'Reproduction', value: 'reproduction'},
    ]

    const onChange = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), medium: e.value}})
    }

    return (
        <StyledInputContainer>
            <Select 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Medium"
                width="100%"
                padding="0"
                options={options}
                onChange={onChange}
            />
        </StyledInputContainer>
    )
}

const Dimensions = ({payload, setPayload}: any) => {
    return (
        <StyledInputContainer>
            <Input 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Dimensions"
                width="100%"
            />
        </StyledInputContainer>
    )
}

const Gallery = ({payload, setPayload}: any) => {
    return (
        <StyledInputContainer>
            <Input 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Gallery"
                width="100%"
            />
        </StyledInputContainer>
    )
}

const Rarity = ({payload, setPayload}: any) => {
    const options = [
        {label: 'Unique', value: 'unique'},
        {label: 'Limited Edition', value: 'limited_edition'},
        {label: 'Open Edition', value: 'open_edition'},
        {label: 'Unknown Edition', value: 'unknown_edition'}
    ]

    const onChange = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), rarity: e.value}})
    }

    return (
        <StyledInputContainer>
             <Select 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Rarity"
                width="100%"
                padding="0"
                options={options}
                onChange={onChange}
            />
        </StyledInputContainer>
    )
}

const SignatureSelect = ({payload, setPayload}: any) => {
    const options = [
        {label: 'Yes', value: true},
        {label: 'No', value: false},
    ]

    const onChange = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), has_signature: e.value}})
    }

    return (
        <StyledInputContainer>
            <Select 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Does this include a signature?"
                width="100%"
                padding="0"
                options={options}
                onChange={onChange}
            />
        </StyledInputContainer>
    )
}

const CertificateOfAutenticitySelect = ({payload, setPayload}: any) => {
    const options = [
        {label: 'Yes', value: true},
        {label: 'No', value: false},
    ]

    const onChange = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), has_coa: e.value}})
    }

    return (
        <StyledInputContainer>
            <Select 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Does this include a certificate of authenticity?"
                width="100%"
                padding="0"
                options={options}
                onChange={onChange}
            />
        </StyledInputContainer>
    )
}

const IncludeFrameSelect = ({payload, setPayload}: any) => {
    const options = [
        {label: 'Yes', value: true},
        {label: 'No', value: false}
    ]

    const onChange = (e: any) => {
        setPayload({...payload, metadata: {...(payload.metadata || {}), has_frame: e.value}})
    }

    return (
        <StyledInputContainer>
            <Select 
                border={`solid 1px ${'#B0B0B0'}`} 
                placeholder="Does this include a frame?"
                width="100%"
                padding="0"
                options={options}
                onChange={onChange}
            />
        </StyledInputContainer>
    )
}


const AboutTheWork = ({payload, setPayload}: any) => {
    return (
        <StyledAboutTheWorkContainer>
            <Text fontWeight="500" fontSize="16px" padding="8px 0px">About the work</Text>
            <StyledAboutTheWork>
                <ArtworkTitle payload={payload} setPayload={setPayload}/>
                <Medium payload={payload} setPayload={setPayload}/>
                <Dimensions payload={payload} setPayload={setPayload}/>
                <Gallery payload={payload} setPayload={setPayload}/>
                <Rarity payload={payload} setPayload={setPayload}/>
                <SignatureSelect payload={payload} setPayload={setPayload}/>
                <CertificateOfAutenticitySelect payload={payload} setPayload={setPayload}/>
                <IncludeFrameSelect payload={payload} setPayload={setPayload}/>
            </StyledAboutTheWork>
        </StyledAboutTheWorkContainer>
    )
}

export default AboutTheWork