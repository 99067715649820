import styled from "styled-components";


interface ButtonsProps {
    flexDirection?: string;
    gap?: string;
}

export const StyledButtons = styled.div<ButtonsProps>`
    display: flex;
    flex-direction: ${props => props.flexDirection || 'row'};
    gap: ${props => props.gap || '12px'};
`