import styled from "styled-components";


interface ViewInRoomContainerProps {

}

export const StyledViewInRoomContainer = styled.div<ViewInRoomContainerProps>`

`

interface ViewInRoomProps {

}

export const StyledViewInRoom = styled.div<ViewInRoomProps>`

`