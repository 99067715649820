import { View } from "../View"
import { AuctionCompleteNotification, CommentNotification, FollowedNotification, LikedNotification, MessageNotification, PurchasedNotification } from "../Notifications/Notifications"



export const RecentActivityPost = ({data}: any) => {
    return (
       <View padding="4px 0px">
            {data.source === 'follow' && <FollowedNotification data={data} />}
            {data.source === 'like' && <LikedNotification data={data} />}
            {data.source === 'purchase' && <PurchasedNotification data={data} />}
            {data.source === 'auction_ended' && <AuctionCompleteNotification data={data} />}
            {data.source === 'message' && <MessageNotification data={data} />}
            {data.source === 'comment' && <CommentNotification data={data} />}
       </View> 
    )
}

export default RecentActivityPost