import { Button } from "../Button"
import { Text } from "../Text"
import { ProfilePicture } from "../ProfilePicture"
import { StyledUserInfo, StyledUserInfoContainer } from "./styled"




const UserInfo = ({account, includeLocation}: any) => {
    return (
        <StyledUserInfoContainer>
            <ProfilePicture height="32px" width="32px" textAlign="left" account={account} photo/>
            <StyledUserInfo>
                <ProfilePicture account={account} username/>
                {includeLocation && <Button noStyle>Add a Location</Button>}
            </StyledUserInfo>
        </StyledUserInfoContainer>
    )
}

export default UserInfo