
import { SearchBar } from "../SearchBar"
import { Text } from "../Text"
import { View } from "../View"
import { StyledInbox, StyledInboxContainer } from "./styled"
import {ReactComponent as EditIcon} from '../../img/edit.svg'
import { useAccountStore, useThreadsStore, useThreadStore, useViewNavigatorStore } from "../../stores/web.store"
import { Button } from "../Button"
import { useEffect, useState } from "react"
import { SlideMenu } from "./SlideMenu"
import { useAllThreads } from "../../hooks/api.hook"
import { Preview } from "../Thread"
import { QuickFilters } from "../Filters/QuickFilters"
import { Divider } from "../Divider"
import api from "../../services/api.service"
import { toDate } from "../../utils/app.util"
import { ProfilePicture } from "../ProfilePicture"


type InboxQuickFiltersProps = {
    apiResp: any;
}




const InboxQuickFilters = ({apiResp}: InboxQuickFiltersProps) => {
    const setThreads = useThreadsStore((state: any) => state.setThreads)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const activeView = useViewNavigatorStore((state: any) => state.activeView)
    

    useEffect(() => {
        if (activeView === 'all') {
            setThreads(apiResp.data)
        } else {
            setThreads(apiResp.data.filter((t: any) => t.type === activeView))
        }
        
    }, [activeView, apiResp])

    return (
        <QuickFilters 
            selectedFilter={activeView} 
            setSelectedFilter={setActiveView} 
            includeAll
            includeMessages
            includeCommissions
            includeOneOnOnes
        />
    )
}

const Threads = ({onClickThread}: any) => {
    const threads = useThreadsStore((state: any) => state.threads)

    return (
        <View>
            <View overflowY="scroll" height="70vh">
                {threads.map((t: any) => {
                    return (
                        <Preview data={t} onClick={onClickThread} key={`thread-${t.id}`}/>
                    )
                })}
            </View>
        </View>
    )
}

const SearchResults = ({searchResults, setSearchResults, setQuery, threads, onClickThread}: any) => {
    const onClickClear = () => {
        setSearchResults([])
    }

    return (
        <View display="flex" flexDirection="column" gap="24px">
            <View>
                <View display="inline-flex" alignItems="center" width="100%">
                    <View width="50%">
                        <Text fontSize="18px" fontWeight="600">Results</Text>
                    </View>
                    <View width="50%" textAlign="right">
                        <Button noStyle onClick={onClickClear}>Clear</Button>
                    </View>
                </View>
            </View>
            <View display="flex" flexDirection="column" gap="24px">
                {searchResults.map((result: any) => {
                    let thread = threads.filter((t: any) => t.id === result.thread_id)
                    if (thread.length > 0) {
                        thread = thread[0]
                        return (
                            <Button noStyle textAlign="left" width="100%" onClick={() => onClickThread(thread)}>
                                <View width="100%" textAlign="left">
                                    <View padding="0px 0px 4px 0px">
                                        <View  display="inline-flex" alignItems="center" gap="4px" width="100%" overflowX="scroll">
                                            {thread.recipients?.map((rec: any) => {
                                                return (
                                                    <View>
                                                        <ProfilePicture account={rec} key={`search-result-${rec.id}`} photo username/>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    </View>
                                    <View padding="4px 0px 0px 0px">
                                        <Text padding="0px 0px 4px 0px" fontSize="14px" fontWeight="bold">{result.message}</Text>
                                        <Text fontSize="9px">{toDate(result.created_date)}</Text>
                                    </View>
                                </View>
                            </Button>
                        )
                    }
                    return <View display="none" />
                    
                })}
            </View>
        </View>
    )
}

const Inbox = () => {
    const account = useAccountStore((state: any) => state.account)
    const setThread = useThreadStore((state: any) => state.setThread)
    const thread = useThreadStore((state: any) => state.thread)
    
    const [isExpandedThread, setIsExpandedThread] = useState<boolean>(false)

    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)

    const [isNewMessage, setIsNewMessage] = useState(false)

    const [apiResp, setApiResp] = useState<any>({})
    
    const {data} = useAllThreads()

    const [searchResults, setSearchResults] = useState<any>([])
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
        }
    }, [data])


    //

    useEffect(() => {
        setActiveView('all')
        if (data && data.is_loaded) {
            setApiResp(data)
        }
    }, [data])


    const readMessages = async (thread_id: string) => {
        const resp = await api.post(`/threads/${thread_id}/read`, {})
        // setApiResp({...apiResp, data: apiResp.data.map((t: any) => {
        //     if (t.id === thread_id) {
        //         return {...t, messages: resp.data}
        //     }
        //     return t
        // })
        // })
    }
    const searchMessages = async (value: string) => {
        const resp = await api.post('/search/messages', {query: {query: value}})
        setSearchResults(resp.data)
    }

    const onClickNewThread = () => {
        setThread({isNew: true, recipients: [], sender: account})
        // setIsExpandedThread(true)
        setIsNewMessage(!isNewMessage)
        // setIsExpanded(true)
    }

    const onClickThread = async (data: any) => {
        if (isExpandedThread && data?.id && data?.id === thread?.id) {
            setThread(null)
            setIsExpandedThread(false)
        } else {
            setThread(data)
            setIsExpandedThread(true)
            readMessages(data.id)
        }
        
    }

    if (account === null) return <div />

    if (!apiResp.is_loaded) return <div />

    
    return (
        <StyledInboxContainer>
            <StyledInbox>
                <View display="inline-flex" alignItems="center" width="100%">
                    <Text fontSize="24px" padding="0px 0px 0px 4px" fontWeight="500" width="fit-content">Inbox</Text>
                    <View flexGrow="1" textAlign="right">
                        <View 
                            display="inline-flex" 
                            alignItems="center" 
                            width="100%" 
                            justifyContent="end"
                            gap="12px"
                        >
                            <View>
                                <Button onClick={onClickNewThread} noStyle>
                                    <EditIcon height={20} width={20}/>
                                </Button>
                            </View>
                        </View>
                    </View>
                </View>
                <View padding="24px 0px 0px 0px">
                    <SearchBar borderRadius="10px" background="#EDEDED" color="#777777" fetch={searchMessages} setData={setSearchResults} data={searchResults} resetData={[]} setQuery={setQuery}/>
                </View>
                <View padding="24px 0px">
                    <InboxQuickFilters apiResp={apiResp}/>
                </View>
                <Divider margin="0"/>
                <View padding="24px 6px">
                    {searchResults.length > 0 ? <SearchResults setSearchResults={setSearchResults} searchResults={searchResults} setQuery={setQuery} threads={apiResp.data} onClickThread={onClickThread}/> : <Threads onClickThread={onClickThread}  />}
                </View>
            </StyledInbox>
            <SlideMenu 
                isActive={isExpandedThread} 
                setIsActive={setIsExpandedThread} 
                isNewMessage={isNewMessage} 
                setIsNewMessage={setIsNewMessage}
            />
            
        </StyledInboxContainer>
    )
}

export default Inbox