import styled from 'styled-components'


interface ActionsContainerProps {
    isExpanded?: boolean;
}

export const StyledActionsContainer = styled.div<ActionsContainerProps>`
    padding: 0px 4px;
`

interface ActionsProps {

}

export const StyledActions = styled.div<ActionsProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding: 24px 0px 0px 0px;
`

interface ActionsLeftContainerProps {

}

export const StyledActionsLeftContainer = styled.div<ActionsLeftContainerProps>`
    display: inline-flex;
    width: 70%;
    align-items: center;
    justify-content: left;
    gap: 12px;
`

interface ActionsRightContainerProps {

}

export const StyledActionsRightContainer = styled.div<ActionsRightContainerProps>`
    display: inline-flex;
    width: 30%;
    align-items: center;
    justify-content: right;
    gap: 12px;
`