import { useEffect, useState } from "react"
import { useFiltersStore } from "../../../stores/web.store"
import { Select } from "../../Select"
import { StyledMedium, StyledMediumContainer } from "./styled"


const Medium = () => {
    const filterKey = 'metadata.medium'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [value, setValue] = useState<any>();

    const options = [
        {value: null, label: 'None'},
        {value: 'painting', label: 'Painting'},
        {value: 'photography', label: 'Photography'},
        {value: 'sculpture', label: 'Sculpture'},
        {value: 'prints', label: 'Prints'},
        {value: 'work_on_paper', label: 'Work on Paper'},
        {value: 'nft', label: 'NFT'},
        {value: 'design', label: 'Design'},
        {value: 'drawing', label: 'Drawing'},
        {value: 'installation', label: 'Installation'},
        {value: 'film_video', label: 'Film/Video'},
        {value: 'jewelry', label: 'Jewelry'},
        {value: 'performance_art', label: 'Performance Art'},
        {value: 'reproduction', label: 'Reproduction'},
    ]

    const _setValue = (v: any) => {
        if (v === null) {
            setValue(null)
        } else {
            setValue(options.find((option: any) => (option.value === v || v?.includes(option.value))))
        }
        
    }

    // useEffect(() => { 
    //     _setValue(filterStore.filters[filterKey])
    // }, [])

    useEffect(() => { 
        _setValue(filterStore.filters[filterKey])
    }, [filterStore])
    

    const onChange = (e: any) => {
        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: e.value
        }})
        
    }

    return (
        <StyledMediumContainer>
            <StyledMedium>
                <Select 
                    placeholder="Medium" 
                    options={options}
                    borderRadius="24px"
                    padding="0"
                    onChange={onChange}
                    width="160px"
                    value={value}
                />
            </StyledMedium>
        </StyledMediumContainer>
    )
}

export default Medium