import { SearchBar } from "../../SearchBar"
import { Sort } from "../../Sort"
import Filters from "../Filters"
import { StyledArtPatronFiltersContainer, StyledSearchAndSort } from "./styled"



const ArtPatronFilters = () => {
    return (
        <StyledArtPatronFiltersContainer>
            <Filters rarity priceRange medium allFilters/>
            <StyledSearchAndSort>
                <SearchBar />
                <Sort />
            </StyledSearchAndSort>
        </StyledArtPatronFiltersContainer>
    )
}

export default ArtPatronFilters