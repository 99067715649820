
import { Text } from "../../../components/Text"
import { ReactComponent as VerifiedIcon } from '../../../img/verified.svg';
import { ReactComponent as InstagramIcon } from '../../../img/instagram.svg';
import { ReactComponent as SettingsDotsIcon } from '../../../img/horizontalSettingsDots.svg';
import { ReactComponent as ChevronDown } from '../../../img/chevronDown.svg';
import { StyledBio, StyledBioButtonsContainer, StyledBioContainer, StyledBioName, StyledFollowing, StyledNameContainer, StyledProfileFollow, StyledRoleContainer, StyledUserBioContainer } from "./styled"
import { Button } from "../../../components/Button";
import { useAccountStore, useDashboardCreateModeStore, useModalStore } from "../../../stores/web.store";
import { Modal } from "../../../components/Modal";
import { useEffect, useRef, useState } from "react";
import { EditBio } from "../../../components/Profile/EditBio";
import { AboutAccount } from "../../../components/Profile/AboutAccount";
import { AccountActions } from "../../../components/Profile/AccountActions";
import { Follow } from "../../../components/Button/Follow";
import { View } from "../../../components/View";
import EditProfile from "../../../components/Profile/EditProfile/EditProfile";
import { QRCode } from 'react-qrcode-logo';
import { Commission } from "../../../components/Button/Commission";
import { Subscribe } from "../../../components/Button/Subscribe";
import { Message } from "../../../components/Button/Message";
import { FollowedBy } from "../../../components/FollowedBy";
import { ProfilePlus } from "../../../components/Button/ProfilePlus";
import { EditProfileModal } from "../../../components/Modal/EditProfileModal";
import { useNavigate } from "react-router-dom";
import { toDisplayValue } from "../../../utils/app.util";


const AboutAccountModal = () => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    return (
        <Modal 
                setModal={setIsModal} 
                width={'100%'} 
                height={'100vh'}
                background="rgba(0, 0, 0, .5)"
            >
            <AboutAccount />
        </Modal>
    )
}

const AccountActionsModal = () => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    return (
        <Modal 
            setModal={setIsModal} 
            width={'100%'} 
            height={'100vh'}
            background="rgba(0, 0, 0, .4)"
        >
            <AccountActions setIsModal={setIsModal}/>
        </Modal>
    )
}

const QRCodeModal = ({profile}: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const qrRef = useRef<HTMLDivElement>(null);

    const onClickDownload = () => {
        console.log(qrRef)
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'qr-code.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    return (
        <Modal 
            setModal={setIsModal} 
            width={'100%'} 
            height={'100vh'}
            background="rgba(0, 0, 0, .4)"
        >
            <View>
                <View display="flex" flexDirection="column" gap="20px">
                    <div ref={qrRef}>
                        <View background="#fff" padding="12px" borderRadius="20px">
                            <View  display="flex" flexDirection="column" gap="4px">
                                <QRCode 
                                    value={`https://beta.primaryyellow.com/${profile.username || profile.id}`}
                                    qrStyle="squares" 
                                    // eyeRadius={12} 
                                    // quietZone={4} 
                                    // eyeColor={'#FFDC39'}
                                    // bgColor={'#FFDC39'}
                                />
                                <View>
                                    <Text
                                        textAlign="center" 
                                        width="100%"
                                        fontSize="14px"
                                        fontWeight="500"
                                    >{profile.username || profile.first_name}</Text>
                                </View>
                            </View>
                        </View>
                    </div>
                    <View textAlign="center">
                        <Button
                            border={`solid 1px ${'#BFCBFB'}`}
                            background={'#DDE3FD'}
                            color={'#0938F0'}
                            padding="8px 20px"
                            borderRadius="23px"
                            fontSize="16px"
                            width="max-content"
                            onClick={onClickDownload}
                            noStyle
                        >Download QR code</Button>
                    </View>
                </View>
            </View>
        </Modal>
    )
}


const PrivateView = ({profile}: any) => {
    const isModal = useModalStore((state: any) => state.isModal)
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const modalKey = useModalStore((state: any) => state.modalKey)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    
    const onClickEditProfile = () => {
        setModalKey('accountActions')
        setIsModal(true)
    }
    
    const onClickQRCode = () => {
        setModalKey('qrCode')
        setIsModal(true)
    }
    return (
        <StyledBioButtonsContainer>
            <Button 
                fontWeight={'500'} 
                padding="8px 20px" 
                borderRadius="20px" 
                background={'#fff'} 
                border={'solid 1px #808080'}
                onClick={onClickEditProfile}
                noStyle
            >Edit Profile</Button>
            <Button 
                fontWeight={'500'} 
                background={'#fff'} 
                padding="8px 20px" 
                border={'solid 1px #808080'} 
                borderRadius="20px"
                onClick={onClickQRCode}
                noStyle
            >QR Code</Button>

            {isModal && modalKey === 'accountActions' && 
                <EditProfileModal setModal={setIsModal}>
                    <EditProfile profile={profile} setIsParentModal={setIsModal}/>
                </EditProfileModal>
            }
            {isModal && modalKey === 'qrCode' && <QRCodeModal profile={profile}/>}
        </StyledBioButtonsContainer>
    )
}

const PublicView = ({profile}: any) => {
    const account = useAccountStore((state: any) => state.account)
    return (
        <StyledBioButtonsContainer>
            <Follow data={profile} activeColor="#0938F0" borderRadius={"24px"}/>
            <Subscribe data={profile} activeColor="#0938F0" borderRadius={"24px"}/>
            <Commission data={profile} activeColor="#0938F0" borderRadius={"24px"}/>
            <Message data={profile} activeColor="#0938F0" borderRadius={"24px"}/>
            <ProfilePlus data={profile} activeColor="#0938F0" borderRadius={"12px"}/>
        </StyledBioButtonsContainer>
    )
}

const PublicSettings = ({profile}: any) => {
    const isModal = useModalStore((state: any) => state.isModal)
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const modalKey = useModalStore((state: any) => state.modalKey)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    
    const onClick = () => {
        setModalKey('accountActions')
        setIsModal(true)
    }
    return (
        <View>
            <Button noStyle onClick={onClick}><SettingsDotsIcon width={'24px'} height={'24px'}/></Button>
            {isModal && modalKey === 'accountActions' && <AccountActionsModal />}
        </View>
    )
}

const PrivateSettings = () => {
    const navigate = useNavigate()
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)

    const onClick = () => {
        setIsCreateMode(false)
        navigate('/settings')
    }
    return (
        <Button noStyle><SettingsDotsIcon width={'24px'} height={'24px'} onClick={onClick}/></Button>
    )
}

const AccountFollowSection = ({profile}: any) => {
    const setIsModal = useModalStore((state: any) => state.setIsModal)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    const onClickAccountFollowers = () => {
        setModalKey('accountFollowers')
        setIsModal(true)
    }

    const onClickAccountFollowing = () => {
        setModalKey('accountFollowing')
        setIsModal(true)
    }


    return (
        <StyledProfileFollow>
            <View>
                <View textAlign="left">
                    <StyledFollowing>
                        <Button onClick={onClickAccountFollowers} noStyle>{profile.followers.length} Followers</Button>
                        <Button onClick={onClickAccountFollowing} noStyle>{profile.following.length} Following</Button>
                    </StyledFollowing>
                </View>
            </View>
            <View>
                <View textAlign="left">
                    <StyledFollowing>
                        <FollowedBy profile={profile}/>
                    </StyledFollowing>
                </View>
            </View>
        </StyledProfileFollow>
    )
}



const Bio = ({profile}: any) => {
    const account = useAccountStore((state: any) => state.account)
   
    return (
        <StyledBioContainer>
            <StyledBio>
                <StyledNameContainer>
                    <StyledBioName>
                        <Text fontSize="28px" fontWeight="500">{profile.first_name} {profile.last_name}</Text>
                        {profile.is_verified && <Button noStyle><VerifiedIcon width={'20px'} height={'20px'}/></Button>}
                        {profile.profile && profile.profile.instagram ? <Button noStyle><InstagramIcon width={'20px'} height={'20px'}/></Button> : <div/>}
                    </StyledBioName>
                    {account.id === profile.id ? <PrivateSettings /> : <PublicSettings profile={profile}/>}
                </StyledNameContainer>
                <StyledRoleContainer>
                    <Text color={'#666666'} fontWeight="600" textTransform="capitalize">{toDisplayValue(profile.role)}</Text>
                </StyledRoleContainer>
                <View>
                    <Text color={'#666666'} fontWeight="600" textTransform="capitalize">{profile.location || 'United States'}</Text>
                </View>
                <AccountFollowSection profile={profile} />
                {account.id === profile.id ? <PrivateView profile={profile}/> : <PublicView profile={profile}/>}
            </StyledBio>
        </StyledBioContainer>
    )
}

export default Bio