import styled from 'styled-components'


interface MainDashboardContainerProps {
    isCreateMode: boolean;
}


export const StyledMainDashboardContainer = styled.div<MainDashboardContainerProps>`
    overflow: hidden;
    height: auto;
    transition: all .5s ease-in-out;
    padding: 4px;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {

    }

    ${props => props.isCreateMode && `
        height: 0px;
    `}

    
`

interface CreateDashboardContainerProps {
    isCreateMode: boolean;
}


export const StyledCreateDashboardContainer = styled.div<CreateDashboardContainerProps>`
    overflow: hidden;
    height: 0;
    transition: all .5s ease-in-out;
    
    ${props => props.isCreateMode && `
        height: auto;
    `}
`

interface DashboardContentProps {
    
}


export const StyledDashboardContent = styled.div<DashboardContentProps>`
padding: 12px 0px;

    @media (min-width: 768px) {
        padding: 20px 0px;
    }

    @media (min-width: 1024px) {

    }
`