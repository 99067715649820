import { useNavigate, useParams } from "react-router-dom"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { useResponsiveStore } from "../../../stores/web.store"
import { useEffect, useState } from "react"
import api from "../../../services/api.service"
import { QAEditorial } from "../../../components/Editorial/QAEditorial"
import { Text } from "../../../components/Text"
import Skeleton from "react-loading-skeleton"
import { toDate } from "../../../utils/app.util"
import { Image } from "../../../components/Image"
import { Editorials } from "../../../components/Editorials"
import { Button } from "../../../components/Button"
import {ReactComponent as ChevronLeft} from '../../../img/chevronLeft.svg'

const Editorial = () => {
    const navigate = useNavigate()
    const {slug} = useParams()
    const width = useResponsiveStore((state: any) => state.width)

    const [article, setArticle] = useState<any>(null)

    useEffect(() => {   
        const fetch = async () => {
            const resp = await api.get(`/editorial/articles/${slug}`)
            setArticle(resp.data)
        }
        fetch()
    }, [])
    
    useEffect(() => {   
        const fetch = async () => {
            const resp = await api.get(`/editorial/articles/${slug}`)
            setArticle(resp.data)
        }
        fetch()
    }, [slug])



    return (
        <DashboardLayout>
            
            <View padding={width > 768 ? "24px" : "12px"}>
                <View padding="0px 0px 24px 0px">
                    <Button noStyle onClick={() => navigate('/editorial')} fontWeight="500">
                        <ChevronLeft height={13} width={13}/>
                        Back
                    </Button>
                </View>
                <View>
                    {article && <View display="inline-flex" gap={width > 768 ? "120px" : "24px"} width="100%" flexDirection={width > 768 ? "row" : "column"}>
                        <View width={width > 768 ? "60%" : "100"}>
                            <View display="flex" flexDirection="column" gap={width > 768 ? "48px" : "12px"}>
                                <View>
                                    <View display="flex" flexDirection="column" gap="12px">
                                        <View>
                                            <View>
                                                {article ? <Text as="h1" fontSize={width > 768 ? "2rem" : "1.5rem"} margin="0">{article.title}</Text> : <Skeleton />}
                                            </View>
                                            <View>
                                                {article ? <Text fontSize="20px" color="grey">{article.author}</Text> : <Skeleton />}
                                            </View>
                                        </View>
                                        <View>
                                            {article ? <Text>{toDate(article.published_date)}</Text> : <Skeleton />}
                                        </View>
                                        <View>
                                            {article ? <Image src={`${process.env.REACT_APP_PRIMARY_YELLOW_API}${article.hero_image}`} width="100%" maxHeight={"500px"}/> : <Skeleton count={10} />}
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View>
                                <Text fontSize="1rem" lineHeight="2rem">{article.description}</Text>
                            </View>
                            <View padding="24px 0px 0px 0px">
                                <QAEditorial editorial={article} />
                            </View>
                            <View padding="24px 0px">
                                {article ? <Text fontSize="20px" color="grey">{article.author}</Text> : <Skeleton />}
                            </View>
                        </View>
                        <View width={width > 768 ? "40%" : "100"}>
                            <View>
                                <Text fontSize="24px" fontWeight="400">Related Articles</Text>
                            </View>
                            <View>
                                <Editorials article={article}/>
                            </View>
                        </View>
                    </View>}
                </View>
            </View>
           
            
        </DashboardLayout>
    )
}

export default Editorial