import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyledSearchContainer, StyledTableContainer } from './styled';
import { SearchBar } from '../SearchBar';


type SearchProps = {
    gridRef?: any;
    data?: any
    placeholder?: string;
}

const Search = ({gridRef, data, placeholder}: SearchProps) => {
    const onSearchChange = useCallback(() => {
        if (gridRef) {
            gridRef.current!.api.setGridOption(
                'quickFilterText',
                (document.getElementById('search-bar') as HTMLInputElement).value
              );
        }
        
      }, []);

    return (
        <StyledSearchContainer>
            <SearchBar placeholder={placeholder || 'Search...'} onInput={onSearchChange} id="search-bar"/>
        </StyledSearchContainer>
    )
}

const Table = ({data, columns, searchPlaceholder, hasSearchBar, onCellValueChanged}: any) => {
    const gridRef = useRef<AgGridReact<any>>(null);
    const [rowData, setRowData] = useState<any>(null);
    const [colDefs, setColDefs] = useState<any>(null);

    useEffect(() => {
        setRowData(data)
        setColDefs(columns)
        console.log(columns)
        // console.log(gridRef.current?.api)
        // if (gridRef.current) {
        //     console.log('hrwe')
        //     gridRef.current.api.setGridOption('rowData', data);
        // }
    }, [data])
    

    if (rowData === null && !gridRef.current) return <div />

    

    const gridOptions = {
        // autoSizeStrategy: {
        //     type: 'fitGridWidth',
        //     defaultMinWidth: 200
        // },
        // enableCellTextSelection: true
        suppressCellFocus: true,
        applyColumnDefOrder: true,
        pagination: true, 
        paginationAutoPageSize: false,
        paginationPageSize: 20,
        suppressRowTransform: true

    } as any

    return (
        <div>
            {hasSearchBar && <Search gridRef={gridRef} data={rowData} placeholder={searchPlaceholder}/>}
            <StyledTableContainer>
                <div className="ag-theme-quartz-auto-dark" style={{ height: '60vh'}}>
                    <AgGridReact rowData={rowData} columnDefs={colDefs} gridOptions={gridOptions} ref={gridRef} onCellValueChanged={onCellValueChanged}/>
                </div>
            </StyledTableContainer>
        </div>
    )
}

export default Table