import { Title } from "../../components/Page/Title"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useAllMyPosts } from "../../hooks/api.hook"
import { useEffect, useState } from "react"
import { useAccountStore, usePostStore, useViewNavigatorStore } from "../../stores/web.store"
import { View } from "../../components/View"
import { FeaturedGalleries } from "../../sections/Featured/FeaturedGalleries"
import { Gallery } from "../../components/Gallery"
import { StyledGalleries, StyledGalleriesContainer } from "./styled"
import { GalleryFilters } from "../../components/Filters/GalleryFilters"
import { useNavigate } from "react-router-dom"


const Galleries = () => {
    const account = useAccountStore((state: any) => state.account) 
    const [api, setApi] = useState<any>({})
    const {data} = useAllMyPosts({query: {owner_id: account?.id}})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApi(data)
        }
    }, [data])

    if (account === null) return <div />

    
    if (!api.data) return <div />

    return (
        <DashboardLayout>
            <View padding="24px">
                <View>
                    <Title 
                        title={'Galleries'} 
                        description={`Browse over ${1000} galleries.`}
                    />
                </View>
                <View padding="32px 0px 12px 0px">
                    <GalleryFilters />
                </View>
                <View padding="0px 0px 24px 0px">
                    <FeaturedGalleries data={api.data} />
                </View>
                <StyledGalleriesContainer>
                    <StyledGalleries>
                        {api.data.map((gallery: any) => {
                            return (
                                <Gallery data={gallery}/>
                            )
                        })}
                    </StyledGalleries>
                </StyledGalleriesContainer>
            </View>
        </DashboardLayout>
    )
}

export default Galleries