import { QuickFilter } from "../QuickFilter"

type LiveAuctionsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const LiveAuctions = ({selectedFilter, setSelectedFilter}: LiveAuctionsProps) => {
    const onClick = () => {
        setSelectedFilter('live_auctions')
    }

    return (
        <QuickFilter title="Live Auctions" onClick={onClick} isSelected={selectedFilter === 'live_auctions'}/>
    )
}

export default LiveAuctions