import { useState } from "react"
import { StyledContentContainer, StyledSlideMenu, StyledSlideMenuContainer, StyledSlideMenuTrigger } from "./styled"
import { View } from "../View"
import { Text } from "../Text"
import { Button } from "../Button"
import {ReactComponent as ChevronDownIcon} from '../../img/chevronDown.svg'



const SlideMenu = ({children}: any) => {
    const [isActive, setIsActive] = useState(false)

    const onClick = () => {
        setIsActive(!isActive)
    }

    return (
        <StyledSlideMenuContainer isActive={isActive}>
            <StyledSlideMenu>
                <StyledContentContainer isActive={isActive}> 
                    {children}
                </StyledContentContainer>
                <StyledSlideMenuTrigger>
                    <View 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="center" 
                        justifyContent="center"
                        width="fit-content"
                        
                        
                    >
                        <Button noStyle width="100%" onClick={onClick} isNoEffect>
                            <View 
                                transform="rotate(270deg)" 
                                width="100vh" 
                                justifyContent="center" 
                                display="inline-flex" 
                                alignItems="center" 
                                gap="8px"
                                height="66px"
                            >
                                <View transform={isActive ? "rotate(180deg)" : "rotate(0deg)"}>
                                    <ChevronDownIcon />
                                </View>
                                <Text fontSize="20px">{isActive ? 'Hide Details' : 'Show Details'}</Text>
                            </View>
                        </Button>
                    </View>
                </StyledSlideMenuTrigger>
            </StyledSlideMenu>
        </StyledSlideMenuContainer>
    )
}

export default SlideMenu