import { useEffect, useState } from "react"
import { StyledShows, StyledShowsContainer } from "../../../sections/Shows/styled"
import { ShowFilters } from "../../Filters/ShowFilters"
import { Title } from "../../Page/Title"
import { View } from "../../View"
import { useFiltersStore, usePostStore, useProductDetailPageStore, useViewNavigatorStore } from "../../../stores/web.store"
import { useParams } from "react-router-dom"
import api from "../../../services/api.service"
import { Post } from "../../Post"
import { toDate, toStateByDate } from "../../../utils/app.util"
import { Button } from "../../Button"
import { Text } from "../../Text"
import {ReactComponent as ChevronLeftIcon} from '../../../img/chevronLeft.svg'
import { ImageGallery } from "../../ImageGallery"
import InfiniteScroll from "react-infinite-scroll-component"
import { CameraRoll } from "../../CameraRoll"
import { ViewingRoom } from "../../Overlays/ViewingRoom"

const Show = () => {
    const filterStore = useFiltersStore((state: any) => state.filterStore)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const post = usePostStore((state: any) => state.post)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const {id} = useParams()
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const [posts, setPosts] = useState<any>([])

    const [timeValue, setTimeValue] = useState<any>('')
    const [showState, setShowState] = useState<any>(post.state)

    useEffect(() => {
        toStateByDate(post, setTimeValue, setShowState)
    }, [])

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.post('/search/posts', {
                query: {
                    query: filterStore.query, 
                    ...{
                        ...filterStore.filters, 
                        "parent_post.id": post.id,
                        type: "artwork"
                    }
                }
            })
            setPosts(resp.data)
        }

        if (filterStore.loaded)
            fetch()
    }, [filterStore])
    
    useEffect(() => {
        setActiveView('overview')
        const fetch = async () => {
            const resp = await api.get(`/posts/${id}`)
            setPost(resp.data.data)
            setPosts(resp.data.data.posts)
        }

        if (!post && id) {
            fetch()
        } else {
            setPosts(post.posts)
        }
            
        
    }, [])

    const fetchMoreData = async () => {
        const newOffset = offset + 1
        const resp = await api.post('/search/posts', {
            query: {
                query: filterStore.query, 
                ...{
                    ...filterStore.filters, 
                    "parent_post.id": post.id,
                    type: "artwork"
                },
                offset: newOffset
            }
        })
        setPosts(resp.data)

        setPosts([...posts, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
    };

    const onClickBack = () => {
        setPost(null)
        setPdp(null)
    }

    const onClickShow = () => {}
    
    

    if (post === null) return <div />


    return (
        <View>
            <View>
                <Button noStyle onClick={onClickBack}>
                    <ChevronLeftIcon height={16} width={16}/> 
                    <Text fontWeight="500">{`Back`}</Text>
                </Button>
            </View>
            <View padding="24px 0px">
                <View display="inline-flex" alignItems="start" width="100%" gap="30px">
                    <View width="70%" position="relative">
                        <ImageGallery data={post} height={600} setPost={setPost} onClick={onClickShow} borderRadius={"10px"} />
                        <ViewingRoom post={post}/>
                    </View>
                    <View width="30%" position="relative" height="420px">
    
                        <View display="flex" flexDirection="column" gap="12px">
                            <View display="inline-flex" alignItems="center" gap="12px">
                                <Text fontSize="24px" fontWeight="500">{post.title}</Text>
                                <Button color="#fff" padding="4px 12px" borderRadius="15px" width="max-content">
                                    {showState === 'ended' && <Text textAlign="center" width="100%" fontSize="13px">{`Show Ended`}</Text>}
                                    {showState === 'upcoming' && <Text textAlign="center" width="100%" fontSize="13px">{timeValue}</Text>}
                                    {showState === 'active' && <Text textAlign="center" width="100%" fontSize="13px">{`Show Live`}</Text>}
                                </Button>
                            </View>
                            <View>
                                <View display="flex" flexDirection="column" gap="4px">
                                    {['ended', 'active'].includes(showState) && <Text width="100%" fontSize="15px">Ended {toDate(post.metadata.end_datetime)}</Text>}
                                    {['upcoming'].includes(showState) && <Text width="100%" fontSize="15px">Live {toDate(post.metadata.start_datetime)}</Text>}
                                </View>
                            </View>
                            <View>
                                <Text color="#0938F0" fontWeight="500" fontSize="13px">{`Presented by ${post.owner.first_name} ${post.owner.last_name}`}</Text>
                            </View>
                        </View>
                        <View>
                            <CameraRoll data={posts} />
                        </View>
        
                    </View>
                </View>
            </View>
            
            <View>
                <ShowFilters setPosts={setPosts} posts={posts} post={post} resetData={post.posts}/>
            </View>
            <View id="Shows" min-height="400px">
                <InfiniteScroll
                    dataLength={posts.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<div />}
                    scrollableTarget="Shows"
                >
                    <StyledShowsContainer>
                        <StyledShows>
                            {posts.map((show: any) => {
                                return (
                                    <Post data={{...show, parent_post: post}} type={show.type} setPost={setPost} noIcon/>
                                )
                            })}
                        </StyledShows>
                    </StyledShowsContainer>
                </InfiniteScroll>
            </View>
        </View>
    )
}

export default Show