import { Button } from "../../../../../../components/Button"
import { Text } from "../../../../../../components/Text"
import { useLandingPageStateStore } from "../../../../../../stores/web.store"
import { StyledActions, StyledActionsContainer, StyledCreateAccountContainer, StyledSignInContainer } from "./styled"


const Actions = () => {
    const setActiveState = useLandingPageStateStore((state: any) => state.setActiveState)

    return (
        <StyledActionsContainer>
            <StyledActions>
                <StyledCreateAccountContainer>
                    <Button
                        background="#0938F0"
                        padding="12px 32px"
                        fontSize="18px"
                        borderRadius="24px"
                        color="#fff"
                        noStyle
                        onClick={() => setActiveState('createAccount')}
                    >{'Create an account'}</Button>
                </StyledCreateAccountContainer>
                <StyledSignInContainer>
                    <Text 
                        textAlign="center" 
                        color="#fff" 
                        padding="12px 0px"
                        fontSize="16px"
                    >{'Already have an account?'}</Text>
                    <Button noStyle onClick={() => setActiveState('signIn')}>
                        <Text 
                            textDecoration="underline" 
                            color="#fff" 
                            fontSize="16px"
                            fontWeight="500"
                        >{'Sign in'}</Text>
                    </Button>
                </StyledSignInContainer>
            </StyledActions>
        </StyledActionsContainer>
    )
}

export default Actions