import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import api from "../../../services/api.service"
import { useAccountStore, usePostStore, useProductDetailPageStore, useThemeStore, useViewNavigatorStore } from "../../../stores/web.store"
import { View } from "../../../components/View"
import { Button } from "../../../components/Button"
import {ReactComponent as ChevronLeftIcon} from '../../../img/chevronLeft.svg'
import { Text } from "../../../components/Text"
import { StyledWorks, StyledWorksContainer } from "./styled"
import { Post } from "../../../components/Post"
import { GalleryFilters } from "../../../components/Filters/GalleryFilters"
import { Title } from "../../../components/Page/Title"
import { ViewNavigator } from "../../../sections/ViewNavigator"
import { AboutSection } from "../../../sections/Gallery/AboutSection"
import { ShowSection } from "../../../sections/Gallery/ShowSection"
import { useAllMyPosts } from "../../../hooks/api.hook"

const VIEW_NAVIGATION_DATA = [
    {
        id: 'overview',
        title: 'Overview',
        image: ''
    },
    {
        id: 'works',
        title: 'Works',
        image: ''
    },
    {
        id: 'events',
        title: 'Events',
        image: ''
    },
    {
        id: 'press',
        title: 'Press',
        image: ''
    },
    {
        id: 'contact',
        title: 'Contact',
        image: ''
    },
]

const Overview = () => {
    return (
        <View>
            <View>
                <AboutSection />
            </View>
            <View padding="48px 0px 0px 0px">
                <ShowSection />
            </View>
        </View>
    )
}

const Works = () => {
    const account = useAccountStore((state: any) => state.account) 
    const [api, setApi] = useState<any>({})
    const {data} = useAllMyPosts({query: {owner_id: account?.id}})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApi(data)
        }
    }, [data])

    if (account === null) return <div />

    
    if (!api.data) return <div />
    return (
        <View>
            <StyledWorksContainer>
                <StyledWorks>
                    {api.data.map((work: any) => {
                        return (
                            <Post data={work}/>
                        )
                    })}
                </StyledWorks>
            </StyledWorksContainer>
        </View>
    )
}

const Gallery = () => {
    const navigate = useNavigate()
    const activeView = useViewNavigatorStore((state: any) => state.activeView)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const theme = useThemeStore((state: any) => state.theme)
    const post = usePostStore((state: any) => state.post)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const {id} = useParams()
    
    useEffect(() => {
        setActiveView('overview')
        const fetch = async () => {
            const resp = await api.get(`/posts/${id}`)
            setPost(resp.data.data)
        }

        if (!post && id)
            fetch()
        
    }, [])

    const onClickBack = () => {
        setPost(null)
        setPdp(null)
    }

    if (post === null) return <div />

    return (
        <View>
            <View>
                <Button noStyle onClick={onClickBack}>
                    <ChevronLeftIcon height={16} width={16}/> 
                    <Text fontWeight="500">{`Back to Galleries`}</Text>
                </Button>
            </View>
            <View padding="24px 0px">
                <Title 
                    title={'Gallery Name'} 
                    description={'City/Location'}
                />
            </View>
            <View>
                <GalleryFilters />
            </View>
            <View>
                <ViewNavigator data={VIEW_NAVIGATION_DATA}/>
            </View>
            <View padding="24px 0px">
                {activeView === 'overview' && <Overview />}
                {activeView === 'works' && <Works />}
            </View>
        </View>
    )
}

export default Gallery