import { useEffect } from "react"
import { EmptyPosts } from "../../components/EmptyPosts"
import { Post } from "../../components/Post"
import { Text } from "../../components/Text"
import { LI, UL } from "../../styled"
import { useAccountStore, useMyArtworksStore, usePostStore, useProfileStore } from "../../stores/web.store"
import { StyledAllPosts, StyledAllPostsContainer } from "../AllPosts/styled"


const Artworks = ({data, isResultsPage}: any) => {
    const account = useAccountStore((state: any) => state.account) 
    const profile = useProfileStore((state: any) => state.profile)

    const myArtworks = useMyArtworksStore((state: any) => state.myArtworks) 
    const setMyArtworks = useMyArtworksStore((state: any) => state.setMyArtworks) 
    const setPost = usePostStore((state: any) => state.setPost) 

    useEffect(() => {
        setMyArtworks(data.filter((dataPost: any) => dataPost.type === 'artwork'))
    }, [data])
    

    if (!isResultsPage && myArtworks.length === 0 && account.id === profile.id) return (
        <EmptyPosts type="artwork" title="No artworks yet">
            <Text width="100%" textAlign="center" fontWeight="600" fontSize="16px">{'List an artwork to sell now, make offer, inquire, or contact gallery.'}</Text>
            <UL color="#6b6b6b" fontSize="16px">
                <LI>{'Create a show or curate and invite other users on the platform to collaborate on a group show.'}</LI>
                <LI>{'Produce the show independently or direct users to your representative gallery.'}</LI>
                <LI>{'Catalog past and forthcoming shows.'}</LI>
                <LI>{'Share the link to the show across platforms.'}</LI>
            </UL>
            <Text 
                textAlign="center" 
                width="100%"
                color="#6b6b6b"
                fontWeight="600"
                fontSize="16px"
            >{'AR/VR capabilities will be available in the summer with the Apple Vision Pro.'}</Text>
        </EmptyPosts>
    )


    return (
        <StyledAllPostsContainer>
            <StyledAllPosts>
                {myArtworks.map((obj: any) => {
                    return (
                        <Post data={obj} setPost={setPost} type={'artwork'} noIcon/>
                    )
                })}
            </StyledAllPosts>
        </StyledAllPostsContainer>
    )
}

export default Artworks