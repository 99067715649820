import styled from 'styled-components'


interface LikedByContainerProps {
    width?: string;
}


export const StyledLikedByContainer = styled.div<LikedByContainerProps>`
    width: ${props => props.width || 'auto'};
    padding: 4px 0px;
`

interface LikedByProps {

}


export const StyledLikedBy = styled.div<LikedByProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 16px;
`

interface PhotoContainerProps {

}

export const StyledPhotoContainer = styled.div<PhotoContainerProps>`

`









