import { useEffect, useRef } from "react";
import api from "../services/api.service";

export const useViewed = (data: any) => {
    const effectRan = useRef(false);

    useEffect(() => {
        if (effectRan.current) return;
        api.post('/analytics/v', data)
        effectRan.current = true;
    }, [data]);
};