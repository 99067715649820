import styled from 'styled-components'


interface AuctionDetailsContainerProps {

}

export const StyledAuctionDetailsContainer = styled.div<AuctionDetailsContainerProps>`

`

interface AuctionDetailsProps {

}

export const StyledAuctionDetails = styled.div<AuctionDetailsProps>`

`

interface AuctionInfoContainerProps {

}

export const StyledAuctionInfoContainer = styled.div<AuctionInfoContainerProps>`

`

interface AuctionInputContainerProps {

}

export const StyledAuctionInputContainer = styled.div<AuctionInputContainerProps>`

`

interface AuctionTitleInputProps {

}

export const StyledAuctionTitleInput = styled.div<AuctionTitleInputProps>`
    padding: 24px 0px 0px 0px;
`

interface AuctionDatePickerContainerProps {

}

export const StyledAuctionDatePickerContainer = styled.div<AuctionDatePickerContainerProps>`
    padding: 24px 0px;
`

interface AuctionDatePickerProps {

}

export const StyledAuctionDatePicker = styled.div<AuctionDatePickerProps>`
    & .react-datetimerange-picker__wrapper {
        display: inline-flex;
        width: 100%;
        align-items: center;
        gap: 0;
        font-family: Roboto;
        font-size: 20px;
        text-indent: 12px;
        border-radius: 10px;
    }

    & .react-datetimerange-picker__range-divider {
        padding: 0px 6px;
    }

    & .class="react-datetimerange-picker__inputGroup__input:placeholder {
        color: #6b6b6b;
    }
`