import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {Form} from './Form';
import { Button } from '../Button';
import { View } from '../View';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);


const Address = ({payload, setPayload, setIsModal}: any) => {
  
  const handleUseDeviceLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPayload((prevAddress: any) => ({
            ...prevAddress,
            latitude,
            longitude,
          }));
          fetchAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const fetchAddressFromCoordinates = async (latitude: number, longitude: number) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const addressComponents = data.results[0].address_components;
      const street = addressComponents.find((component: any) =>
        component.types.includes('route')
      )?.long_name;
      const city = addressComponents.find((component: any) =>
        component.types.includes('locality')
      )?.long_name;
      const state = addressComponents.find((component: any) =>
        component.types.includes('administrative_area_level_1')
      )?.short_name;
      const zipCode = addressComponents.find((component: any) =>
        component.types.includes('postal_code')
      )?.long_name;
      const country = addressComponents.find((component: any) =>
        component.types.includes('country')
      )?.long_name;

      setPayload((prevAddress: any) => ({
        ...prevAddress,
        street: street || '',
        city: city || '',
        state: state || '',
        zipCode: zipCode || '',
        country: country || '',
      }));
    }
  };
  const options = {
    // Fully customizable with appearance API.
    appearance: {

      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Roboto, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
        // See all possible variables below
      },
      rules: {
        '.Input': {
          boxShadow: "none",
          fontSize: "13px",
          padding: "8px"
        }
      }
    }
  };

  return (
    <View>
      <View>
        <Elements stripe={stripe} options={options}>
          {/* <Button onClick={handleUseDeviceLocation}>Use Device Location</Button> */}
          <Form payload={payload} setPayload={setPayload}/>
        </Elements>
      </View>
      <View>
          <Button
              border="solid 1px #BFCBFB"
              background="#DDE3FD"
              color="#0838F0"
              padding="12px"
              width="100%"
              borderRadius="20px"
              fontWeight="600"
              fontSize="16px"
              onClick={() => setIsModal(false)}
          >Done</Button>
      </View>
    </View>
    
  );
};

export default Address