import styled from 'styled-components'


interface VideoProps {
    height?: string;
    width?: string;
    border?: string;
    objectFit?: string;
    borderRadius?: string;
    zoom?: number;
}


export const StyledVideo = styled.video<VideoProps>`
    height: ${props => props.height || 'auto'};
    width: ${props => props.width || 'auto'};
    border: ${props => props.border || 'none'};
    border-radius: ${props => props.borderRadius || 'unset'};
    display: block;
    zoom: ${props => props.zoom || 1};
    // aspect-ratio: 1;
    object-fit: ${props => props.objectFit || 'cover'};
`