// https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=embedded
import { useStripeConnect } from "./stripe.hook";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import { View } from "../../../View"
import { useEffect, useState } from "react";
import { Button } from "../../../Button";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import { PageLoader } from "../../../Loader/PageLoader";
import api from "../../../../services/api.service";


const Onboarding = ({stripeConnection}: any) => {
    const [accountCreatePending, setAccountCreatePending] = useState(false);
    const [onboardingExited, setOnboardingExited] = useState(false);
    const [error, setError] = useState(false);
    const [connectedAccountId, setConnectedAccountId] = useState();
    const stripeConnectInstance = useStripeConnect(connectedAccountId);
    

    return (
        <View>
            {!stripeConnection && !accountCreatePending && !connectedAccountId && (
            <Button
                border={`solid 1px ${'#BFCBFB'}`}
                background={'#DDE3FD'}
                color={'#0938F0'}
                padding="8px 20px"
                borderRadius="23px"
                fontSize="16px"
                noStyle
                onClick={async () => {
                    setAccountCreatePending(true);
                    setError(false);

                    const createResp = await api.get(`${process.env.REACT_APP_PRIMARY_YELLOW_API}/service/stripe/create-account`)
                    
                    console.log(createResp)

                    // const payload = {
                    //     source: 'stripe',
                    //     config: createResp
                    // }
                    // const resp = await api.post(`/connections`, payload)
                    // console.log(resp)

                    // setAccountCreatePending(false);
                    // const { account, error } = createResp;

                    // if (account) {
                    //     setConnectedAccountId(account);
                    // }

                    // if (error) {
                    //     setError(true);
                    // }
                    
                    // fetch(, {
                    //     method: "POST",
                    // })
                    // .then((response) => response.json())
                    // .then(async (json) => {
                    //     console.log(json)

                    //     const payload = {
                    //         source: 'plaid',
                    //         config: json
                    //     }
                    //     const resp = await api.post(`/connections`, payload)
                    //     console.log(resp)

                    //     setAccountCreatePending(false);
                    //     const { account, error } = json;

                    //     if (account) {
                    //         setConnectedAccountId(account);
                    //     }

                    //     if (error) {
                    //         setError(true);
                    //     }
                    // });
                }}
                >
                Connect
                </Button>
            )}
            {stripeConnectInstance && (
                <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                    <ConnectAccountOnboarding
                    onExit={() => setOnboardingExited(true)}
                    />
                </ConnectComponentsProvider>
            )}
            {accountCreatePending && <PageLoader />}
            {/* {error && <p className="error">Something went wrong!</p>} */}
            {/* {(connectedAccountId || accountCreatePending || onboardingExited) && (
            <div className="dev-callout">
                {connectedAccountId && <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
                {accountCreatePending && <p>Creating a connected account...</p>}
                {onboardingExited && <p>Thank You for Connecting!</p>}
            </div>
            )} */}
        </View>
    )
}

export default Onboarding