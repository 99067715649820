import { FeaturedItem } from "../FeaturedItem"
import { StyledFeaturedGroup, StyledFeaturedGroupContainer } from "./styled"


type FeaturedGroupProps = {
    data?: any
    columns: number;
    width?: string;
    height: string;
    onClick?: any;
}

const FeaturedGroup = ({data, columns, height, width, onClick}: FeaturedGroupProps) => {
    
    return (
        <StyledFeaturedGroupContainer>
            <StyledFeaturedGroup columns={columns} width={width}>
                {data.map((obj: any) => {
                    return (
                        <FeaturedItem key={`featured-item-${obj.id}`} data={obj} height={height} onClick={onClick}/>
                    )
                })}
            </StyledFeaturedGroup>
        </StyledFeaturedGroupContainer>
    )
}

export default FeaturedGroup