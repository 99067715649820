import { SignupComplete } from "../../../../../components/Signup/SignupComplete"
import { StyledState, StyledStateContainer } from "../styled"
import { StyledSignupCompleteState, StyledSignupCompleteStateContainer } from "./styled"


const SignupCompleteState = () => {
    return (
        <StyledSignupCompleteStateContainer>
            <StyledSignupCompleteState>
                <StyledStateContainer>
                    <StyledState>
                        <SignupComplete />
                    </StyledState>
                </StyledStateContainer>
            </StyledSignupCompleteState>
        </StyledSignupCompleteStateContainer>
    )
}

export default SignupCompleteState