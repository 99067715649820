import { ReactNode } from "react"
import { StyledContent, StyledContentContainer } from "./styled"


type ContentProps = {
    children: ReactNode
}


const Content = ({children}: ContentProps) => {
    return (
        <StyledContentContainer>
            <StyledContent>
                {children}
            </StyledContent>
        </StyledContentContainer>
    )
}

export default Content