
import { useEffect, useState } from "react"
import { Title } from "../../components/Page/Title"
import { View } from "../../components/View"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { useAccountStore } from "../../stores/web.store"
import { useAllMyPosts } from "../../hooks/api.hook"
import { StyledArtists, StyledArtistsContainer } from "./styled"
import { Artist } from "../../components/Artist"
import { ArtistFilters } from "../../components/Filters/ArtistFilters"

const Artists = () => {
    const account = useAccountStore((state: any) => state.account) 
    const [api, setApi] = useState<any>({})

    const {data} = useAllMyPosts({query: {owner_id: account?.id}})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApi(data)
        }
    }, [data])

    if (account === null) return <div />

    
    if (!api.is_loaded) return <div />

    return (
        <DashboardLayout>
            <View padding="24px">
                <View>
                    <Title 
                        title={'Artists'} 
                        description={`Browse over ${1000} artists.`}
                    />
                </View>
                <View padding="32px 0px 12px 0px">
                    <ArtistFilters />
                </View>
                <StyledArtistsContainer>
                    <StyledArtists>
                        {api.data.map((artist: any) => {
                            return (
                                <Artist data={artist} />
                            )
                        })}
                    </StyledArtists>
                </StyledArtistsContainer>
            </View>
        </DashboardLayout>
    )
}

export default Artists