import styled from 'styled-components'


interface GalleriesContainerProps {

}

export const StyledGalleriesContainer = styled.div<GalleriesContainerProps>`

`

interface GalleriesProps {

}


export const StyledGalleries = styled.div<GalleriesProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 16px;
`