import { $getRoot } from "lexical"
import { Markdown } from "../../../../components/MarkDown"
import { Text } from "../../../../components/Text"
import { usePostPayloadStore } from "../../../../stores/web.store"
import { AuctionDetails } from "../../AuctionDetails"
import { StyledCreateShowPostContent, StyledCreateShowPostContentContainer } from "./styled"
import { View } from "../../../../components/View"


const CreateAuctionPostContent = ({editorStateRef}: any) => {
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)

    const onChangeEditorState = (editorState: any) => {
        editorStateRef.current = editorState
        setPostPayload({...postPayload, 
            caption: editorState.read(() => $getRoot().getTextContent()),
            lexical_caption: JSON.stringify(editorStateRef.current)
        })

    }
    return (
        <StyledCreateShowPostContentContainer>
            <StyledCreateShowPostContent>
                <AuctionDetails />
                <div>
                    <View padding="0px 0px 12px 0px">
                        <Text fontWeight="500" fontSize="20px">Press release</Text>
                    </View>
                    <Markdown editorStateRef={editorStateRef} data={null} onChange={onChangeEditorState} emoji={{padding: "0px 8px"}}/>
                </div>
            </StyledCreateShowPostContent>
        </StyledCreateShowPostContentContainer>
    )
}

export default CreateAuctionPostContent