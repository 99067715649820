import api from "../../../services/api.service"
import { useFiltersStore } from "../../../stores/web.store"
import { SearchBar } from "../../SearchBar"
import { Sort } from "../../Sort"
import Filters from "../Filters"
import { StyledShowFiltersContainer, StyledSearchAndSort } from "./styled"



const ShowFilters = ({post, posts, setPosts, resetData}: any) => {
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 

    const fetch = (value: string) => {

        setFilterStore({...filterStore, query: value, loaded: true})
    }

    return (
        <StyledShowFiltersContainer>
            <Filters rarity priceRange medium allFilters/>
            <StyledSearchAndSort>
                <SearchBar setData={setPosts} fetch={fetch} resetData={resetData} />
                <Sort />
            </StyledSearchAndSort>
        </StyledShowFiltersContainer>
    )
}

export default ShowFilters