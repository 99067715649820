import styled from "styled-components";


export const StyledHeroContainer = styled.div`
    background-image: url("/img/landing/FutureOfCollecting.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 264px;
    width: 100%;

    @media (min-width: 768px) {
        height: 405px;
    }
    
    @media (min-width: 1600px) {
    }

`

export const StyledCards = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 48px 32px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 1600px) {
    }
`