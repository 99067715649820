import styled from 'styled-components'

interface CommentContainerProps {

}


export const StyledCommentContainer  = styled.div<CommentContainerProps>`
`

interface CommentProps {

}

export const StyledComment = styled.div<CommentProps>`

`

interface PhotoNameContainerProps {

}

export const StyledPhotoNameContainer = styled.div<PhotoNameContainerProps>`
    display: inline-flex;
    width: 100%;
    gap: 12px;
    align-items: center;
`

interface DateLikesContainerProps {

}

export const StyledDateLikesContainer = styled.div<DateLikesContainerProps>`
    display: inline-flex;
    width: 100%;
    gap: 12px;
    align-items: center;
`

interface LikesContainerProps {

}

export const StyledLikesContainer = styled.div<LikesContainerProps>`
    display: inline-flex;
    width: 100%;
    gap: 4px;
    align-items: center;
`

