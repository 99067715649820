import { Text } from "../../Text";
import { View } from "../../View";
import {ReactComponent as ArtworkIcon} from '../../../img/artworkPost.svg'
import {ReactComponent as ChevronRightIcon} from '../../../img/chevronRight.svg'
import { Button } from "../../Button";
import { StyledViewingRoom, StyledViewingRoomText } from "./styled";
import { usePostStore, useProductDetailPageStore } from "../../../stores/web.store";


const ViewingRoom = ({post}: any) => {
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setPost = usePostStore((state: any) => state.setPost)

    const onClick = () => {
        setPost(post)
        setPdp('viewingRoom')
    }

    return (
        <StyledViewingRoom>
            <Button 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                width="100%"
                height="100%"
                gap="12px"
                onClick={onClick}
                noStyle
            >
                <View>
                    <View>
                        <ArtworkIcon stroke="#fff" width={96} height={96} strokeWidth={.5}/>
                    </View>
                    <StyledViewingRoomText className="viewing-room-text">
                        <Text fontSize="15px" color="#fff">Enter Viewing Room</Text>
                        <ChevronRightIcon height={15} width={15} stroke="#fff" strokeWidth={4}/>
                    </StyledViewingRoomText>
                </View>
                
            </Button>
        </StyledViewingRoom>
    )
}

export default ViewingRoom;