import { StyledButtonContainer, StyledMessageContainer, StyledSlideMenu, StyledSlideMenuContainer } from "./styled"
import { useAccountStore, useDashboardMenuExpandedStore, useMessageStore, useResponsiveStore, useThreadsStore, useThreadStore, useViewNavigatorStore } from "../../../stores/web.store";
import { View } from "../../View";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { Message } from "../../Message";
import { Markdown } from "../../MarkDown";
import { useEffect, useRef, useState } from "react";
import { $createParagraphNode, $getRoot } from "lexical";
import {ReactComponent as SendIcon} from '../../../img/send.svg'
import {ReactComponent as YourMessagesIcon} from '../../../img/yourMessage.svg'
import { CLEAR_HISTORY_COMMAND } from "lexical";
import api from "../../../services/api.service";
import { Text } from "../../Text";
import { MessageModal } from "../../Modal/MessageModal";


const YourMessages = ({setIsNewMessage}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const setThread = useThreadStore((state: any) => state.setThread)
    const onClickNewThread = () => {
        // setThread({isNew: true})
        // setIsActive(true)
        setIsNewMessage(true)
        setThread({isNew: true, recipients: [], sender: account})
    }
    return (
        <View display="flex" alignItems="center"  height="100vh" justifyContent="center">
            <View>
                <View display="flex" flexDirection="column" gap="24px">
                    <View>
                        <YourMessagesIcon />
                    </View>
                    <View>
                        <View display="flex" flexDirection="column" gap="16px" width="100%" textAlign="center">
                            <Text width="100%" textAlign="center" fontSize="16px" fontWeight="500">{'Your messages'}</Text>
                            <Text width="100%" textAlign="center" color="#909090">{'Send a message to start a chat'}</Text>
                            <View width="100%" textAlign="center">
                                <Button 
                                    color="#fff" 
                                    padding="6px 12px" 
                                    borderRadius="16px" 
                                    width="max-content"
                                    onClick={onClickNewThread}
                                >{'Send message'}</Button>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

type SlideMenuProps = {
    isActive?: boolean;
    setIsActive?: any;
    isNewMessage?: boolean;
    setIsNewMessage?: any;
}

const SlideMenu = ({isActive, setIsActive, isNewMessage, setIsNewMessage}: SlideMenuProps) => {
    const setThreads = useThreadsStore((state: any) => state.setThreads)
    const threads = useThreadsStore((state: any) => state.threads)
    const account = useAccountStore((state: any) => state.account)
    const setMessage = useMessageStore((state: any) => state.setMessage)
    const message = useMessageStore((state: any) => state.message)
    const setThread = useThreadStore((state: any) => state.setThread)
    const thread = useThreadStore((state: any) => state.thread)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)
    const setIsExpanded = useDashboardMenuExpandedStore((state: any) => state.setIsExpanded)
    const width = useResponsiveStore((state: any) => state.width)
    const [isTyping, setIsTyping] = useState(false)
    const [threadMessages, setThreadMessages] = useState<any>(thread?.messages || [])
    const activeView = useViewNavigatorStore((state: any) => state.activeView)
    const [localMessage, setLocalMessage] = useState<any>();

    const [websckt, setWebsckt] = useState<any>();

    const initWebSocket = () => {
        const url = `${process.env.REACT_APP_PRIMARY_YELLOW_WEBSOCKET}/ws/threads/${thread?.id}?uid=${account.id}`;
        const ws = new WebSocket(url);

        

        setWebsckt(ws);
        return ws
    }

    useEffect(() => {
        setIsExpanded(width > 768)
    }, [width])

    useEffect(() => {
        // const fetch = async () => {
        //     await initWebSocket()
        // }

        if (thread?.id) {
            if (!websckt) {
                initWebSocket()
            } else {
                websckt.onopen = (event: any) => {
                    // ws.send("Connect");
                };
        
                // recieve message every start page
                websckt.onmessage = (e: any) => {
                    const msg = JSON.parse(e.data);
                    setThreadMessages([...threadMessages, msg]);
                    setThreads(threads.map((t: any) => {
                        if (t.id === thread.id) {
                            return {
                                ...thread,
                                messages: [...threadMessages, msg]
                            }
                        }
                        return t
                    }))
                }; 
            }
            
        }
        
    }, [threadMessages]);

    const _sendMessage = (ws: any) => {
        ws.send(JSON.stringify(message));
        // recieve message every send message
        ws.onmessage = (e: any) => {
            const msg = JSON.parse(e.data);
            setThreadMessages([...threadMessages.filter((msg: any) => !msg.isTyping), msg]);

            setThreads(threads.map((t: any) => {
                if (t.id === thread.id) {
                    return {
                        ...thread,
                        messages: [...threadMessages.filter((msg: any) => !msg.isTyping), msg]
                    }
                }
                return t
            }))
            setIsTyping(false)
        };
    }
    
    const sendMessage = () => {

        if (!websckt) {
            const ws = initWebSocket()
            _sendMessage(ws)
        } else {
            websckt.onopen = (event: any) => {
                // ws.send("Connect");
            };
    
            // recieve message every start page
            websckt.onmessage = (e: any) => {
                const msg = JSON.parse(e.data);
                setThreadMessages([...threadMessages, msg]);
            };
            _sendMessage(websckt)
        }
        
        
    };

    const editorStateRef = useRef<any>();

    useEffect(() => {
        setThreadMessages(thread?.messages || [])
    }, [thread])

    const onChangeEditorState = (editorState: any) => {
        editorStateRef.current = editorState
        const msg = editorState.read(() => $getRoot().getTextContent())
        setIsTyping(msg.length > 0)
        setMessage({
            message: msg,
            lexical_message: JSON.stringify(editorStateRef.current)

        })

    }

    const onClickSend = (editorState: any) => {
        sendMessage()
        setIsTyping(false)

        if (editorStateRef.current) {
            editorStateRef.current.update(() => {
                const root = $getRoot();
                root.clear();
                root.append($createParagraphNode());
            });
        }
    }

    const handleEnterCommand = async (event: any, editor: any) => {
        event.preventDefault();
        // const serializedContent = $generateHtmlFromNodes(editor, null);

        sendMessage()
        return true
    };

    return (
        <StyledSlideMenuContainer isActive={true} isExpanded={isExpanded} isExtender>
            <StyledSlideMenu isActive={isActive}>
                {isActive && !thread?.isNew && 
                    <Message 
                        isTyping={isTyping} 
                        setIsNewMessage={setIsNewMessage} 
                        setThreadMessages={setThreadMessages} 
                        threadMessages={threadMessages}
                        setIsActive={setIsActive}
                />}
                {!thread && <YourMessages setIsNewMessage={setIsNewMessage} />}
            </StyledSlideMenu>
            {isActive && !thread?.isNew && <View>
                <StyledMessageContainer>
                    <View>
                        <View>
                            <Markdown
                                editorStateRef={editorStateRef} 
                                data={null} 
                                singleLine 
                                onChange={onChangeEditorState} 
                                placeholder={'Add a message'} 
                                onClickSend={onClickSend}
                                handleEnterCommand={handleEnterCommand}
                                editable
                                noTools
                            />
                        </View>
                        
                    </View>
                    <View width="fit-content" position="absolute" right="12px" bottom="12px">
                        <Button noStyle onClick={onClickSend}>
                            <SendIcon height={20} width={20}/>
                        </Button>
                    </View>
                </StyledMessageContainer>
            </View>}
            {isNewMessage && <MessageModal setModal={setIsNewMessage} setIsActive={setIsActive} />}
        </StyledSlideMenuContainer>
    )
}

export default SlideMenu