import { $getRoot } from "lexical"
import { Markdown } from "../../../../components/MarkDown"
import { Text } from "../../../../components/Text"
import { usePostPayloadStore, useResponsiveStore } from "../../../../stores/web.store"
import { ShowDetails } from "../../ShowDetails"
import { StyledCreateShowPostContent, StyledCreateShowPostContentContainer } from "./styled"
import { View } from "../../../../components/View"


const CreateShowPostContent = ({editorStateRef}: any) => {
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const width = useResponsiveStore((state: any) => state.width)

    const onChangeEditorState = (editorState: any) => {
        editorStateRef.current = editorState
        setPostPayload({...postPayload, 
            caption: editorState.read(() => $getRoot().getTextContent()),
            lexical_caption: JSON.stringify(editorStateRef.current)
        })

    }
    return (
        <StyledCreateShowPostContentContainer>
            <StyledCreateShowPostContent>
                <ShowDetails />
                <div>
                    <View padding="0px 0px 12px 0px">
                        <Text fontWeight="500" fontSize={"20px"}>Press release</Text>
                    </View>
                    <Markdown 
                        editorStateRef={editorStateRef} 
                        data={null} 
                        onChange={onChangeEditorState} 
                        placeholder={"Write details about your show"}
                        emoji={{padding: "0px 8px"}}
                        allowAttachment
                    />
                </div>
            </StyledCreateShowPostContent>
        </StyledCreateShowPostContentContainer>
    )
}

export default CreateShowPostContent