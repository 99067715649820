import styled from "styled-components";


interface AddressFormProps {

}

export const StyledAddressForm = styled.form<AddressFormProps>`
    padding: 12px 0px;

`