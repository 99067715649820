import styled from "styled-components";


interface CommentContainerProps {

}

export const StyledCommentContainer = styled.div<CommentContainerProps>`
    position: relative;
`

interface CommentProps {

}

export const StyledComment= styled.div<CommentProps>`

`