import { useEffect, useState } from "react";
import { StyledVideo } from "./styled"


type VideoProps = {
    src: string;
    display?: string;
    height?: string;
    width?: string;
    borderRadius?: string;
    objectFit?: string;
    border?: string;
    zoom?: number;
}

const Video = ({src, height, width, border, objectFit, borderRadius, zoom}: VideoProps) => {
    const [isActive, setIsActive] = useState<boolean>(false)
    const [videoSrc, setVideoSrc] = useState<any>(src)

    useEffect(() => {
        if (isActive) {
            setVideoSrc(src + '#play')
        } else {
            setVideoSrc(src)
        }
            
    }, [isActive])

    const onMouseEnter = () => {
        setIsActive(true)
    }

    const onMouseLeave = () => {
        setIsActive(false)
    }
    

    return (
        <StyledVideo 
            src={videoSrc} 
            height={height} 
            width={width}
            border={border}
            objectFit={objectFit}
            borderRadius={borderRadius}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            controls={isActive}
            autoPlay={isActive}
            loop={isActive}
            zoom={zoom}
            muted
        />
    )
}

export default Video