import { Text } from "../../Text";
import { View } from "../../View"

type TitleProps = {
    title: string;
    description?: string;
}


const Title = ({title, description}: TitleProps) => {
    return (
        <View>
            <Text fontSize="32px" fontWeight="600" padding={description ? "0px 0px 4px 0px" : "0"}>{title}</Text>
            {description && <Text fontSize="13px">{description}</Text>}
        </View>
    )
}

export default Title