import { useEffect, useRef } from "react"
import { StyledSlideMenu, StyledSlideMenuContainer } from "./styled"
import { useDashboardMenuExpandedStore, useSlideMenuStore } from "../../stores/web.store";
import { Inbox } from "../../components/Inbox";
import { Search } from "../../components/Search";
import { Notifications } from "../../components/Notifications";
import { Filters } from "../Filters";


const useOutsideAlerter = (ref: any) => {
    const closeMenu = useSlideMenuStore((state: any) => state.closeMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            // if (slideMenu.key === 'search' && event.target.id === 'MenuSearchButton') return
            // closeMenu()            
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}


const SlideMenu = () => {
    const wrapperRef = useRef(null);
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)

    useOutsideAlerter(wrapperRef);
    
    return (
        <StyledSlideMenuContainer slideMenu={slideMenu} isExpanded={isExpanded} menuKey={slideMenu.key}>
            <StyledSlideMenu slideMenu={slideMenu} ref={wrapperRef}>
                {slideMenu.key === 'inbox' && <Inbox />}
                {slideMenu.key === 'search' && <Search />}
                {slideMenu.key === 'notifications' && <Notifications />}
                {slideMenu.key === 'filters' && <Filters />}
            </StyledSlideMenu>
        </StyledSlideMenuContainer>
    )
}

export default SlideMenu