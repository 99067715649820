import {$generateHtmlFromNodes} from '@lexical/html';
import { useAccountStore, usePostMapPayload, usePostPayloadStore, useThemeStore } from "../../../stores/web.store"
import { Button } from "../../Button"
import { ImageGallery } from "../../ImageGallery"
import { Text } from "../../Text"
import { UserInfo } from "../../UserInfo"
import { StyledGuestLogContainer, StyledLeftSectionContainer, StyledPreview, StyledPreviewContainer, StyledRightSection, StyledRightSectionContainer } from "./styled"
import { Markdown } from '../../MarkDown';
import { useRef } from 'react';
import { FollowedBy } from '../../FollowedBy';
import { UploadedPreview } from '../../MediaUpload/MediaUpload';
import { StyledUploadedMedia, StyledUploadedMediaContainer } from '../../MediaUpload/styled';

/*
location = Column(JSONB)
    price = Column(Float)
    reserve_price = Column(Float)
    reserve_price_currency = Column(String(16))
    currency = Column(String(16))
    shipping_fee = Column(Float)
    medium = Column(String(256))
    rarity = Column(String(256))
    has_signature = Column(Boolean)
    has_coa = Column(Boolean)
    has_frame = Column(Boolean)

    dimensions = Column(JSONB)
    can_make_offer = Column(Boolean)
    can_inquire = Column(Boolean)
    gallery_id = Column(UUID)
    template = Column(String(64))

    is_for_sale = Column(Boolean)
    is_sold = Column(Boolean)
    is_permanent_collection = Column(Boolean)

    start_datetime = Column(DateTime, nullable=False)
    end_datetime = Column(DateTime, nullable=False)
*/


const Preview = () => {
    const theme = useThemeStore((state: any) => state.theme)
    
    const account = useAccountStore((state: any) => state.account)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    const postMapPayload = usePostMapPayload((state: any) => state.postMapPayload)
    const setPostMapPayload = usePostMapPayload((state: any) => state.setPostMapPayload)

    const setMedia = () => {
        
    }

    return (
        <StyledPreviewContainer>
            <Text fontWeight="500">Preview</Text>
            <StyledPreview>
                <StyledLeftSectionContainer>
                    <ImageGallery data={postPayload} setPost={setPostPayload}/>
                    <div style={{padding: '12px 0px'}}>
                        <Text fontSize="13px" fontWeight="500" padding="12px 0px 4px 0px">{postPayload.title}</Text>
                        {postPayload.metadata?.start_datetime && 
                            <Text fontSize="11px" color={'grey'}>
                                {postPayload.metadata?.start_datetime.toLocaleDateString()} - {postPayload.metadata?.end_datetime.toLocaleDateString()}
                            </Text>
                        }
                    </div>
                </StyledLeftSectionContainer>
                <StyledRightSectionContainer>
                    <StyledRightSection>
                        <div style={{padding: '0px 0px 4px 0px'}}>
                            <Text fontSize='11px' fontWeight='500' padding='0px 0px 4px 0px'>Detail Images</Text>
                            <StyledUploadedMediaContainer padding='0'>
                                <StyledUploadedMedia gridGap="4px" total={(postPayload.posts  || []).length}>
                                    {(postPayload.posts || []).map((post: any, idx: number) => {
                                        const file = post.media[0]
                                        return (
                                            <UploadedPreview 
                                                key={`media-details-${idx}`} 
                                                media={post.media} 
                                                file={file} 
                                                setMedia={null}
                                                height="40px"
                                                width="40px"
                                            />
                                        )
                                    })}
                                </StyledUploadedMedia>
                            </StyledUploadedMediaContainer>
                        </div>
                        <div style={{padding: '4px 0px'}}>
                            <Text fontSize='11px' fontWeight='500' padding='0px 0px 4px 0px'>Artists</Text>
                            <UserInfo account={account}/> 
                        </div>
                        <div style={{padding: '4px 0px'}}>
                            <Text fontSize="13px" fontWeight="500" padding="0px 0px 4px 0px">{postPayload.title}</Text>
                            {postPayload.metadata?.start_datetime && 
                                <Text fontSize="11px" color={'grey'}>
                                    {postPayload.metadata?.start_datetime.toLocaleDateString()} - {postPayload.metadata?.end_datetime.toLocaleDateString()}
                                </Text>
                            }
                        </div>
                        <div style={{padding: '4px 0px'}}>
                            <Text fontSize='11px' fontWeight='500' padding='0px 0px 4px 0px'>Press Release</Text>
                            <Text>{postPayload.caption}</Text>
                        </div>
                        {/* <div style={{padding: '4px 0px'}}>
                            <StyledGuestLogContainer>
                                <Text 
                                    fontSize='11px' 
                                    fontWeight='500'
                                    flexGrow='1'
                                >Guest Log</Text>
                                <Button 
                                    fontSize='11px'
                                    fontWeight='500'
                                    noStyle
                                >Open Guest Log</Button>
                            </StyledGuestLogContainer>
                            <FollowedBy />
                        </div> */}
                        <div style={{padding: '4px 0px'}}>
                            <Text fontSize='11px' fontWeight='500' padding='0px 0px 4px 0px'>
                                VR and Apple Vision Pro Compatibile
                            </Text>
                            <Text>Coming Summer 2024</Text>
                        </div>
                    </StyledRightSection>
                </StyledRightSectionContainer>
            </StyledPreview>
        </StyledPreviewContainer>
    )
}

export default Preview