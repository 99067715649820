import { Text } from "../Text"
import { ProfilePicture } from "../ProfilePicture"
import { StyledDiscoverPeoplePostContainer } from "./styled"
import { View } from "../View"


const DiscoverPeoplePost = ({data}: any) => {
    return (
        <StyledDiscoverPeoplePostContainer>
            <View width="100%">
            <ProfilePicture 
                height="32px" 
                width="32px" 
                textAlign="left" 
                account={data} 
                photo 
                username 
                role/>
            </View>
                {data.followers.length > 0 && 
                    <View width="100%">
                        <View display="inline-flex" width="100%" gap="12px">
                            <View width="34px"></View>
                            <Text fontSize="11px">Followed by <ProfilePicture account={data} username fontSize="11px"/> {data.total_followers > 1 ? `and ${data.total_followers - 1} other${data.total_followers - 1 > 1 ? 's' : ''}` : ''}</Text>
                        </View>
                    </View>}
            
        </StyledDiscoverPeoplePostContainer>
    )
}

export default DiscoverPeoplePost