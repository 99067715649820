import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Text } from "../../../components/Text";
import { StyledMenuItem, StyledNavigationMenu, StyledTopNavigationMenuContainer } from "./styled"
import { useState } from "react";
import { useThemeStore } from "../../../stores/web.store";


type MenuItemProps = {
    title: string;
    textAlign?: string;
    path: string;
}


const MenuItem = ({title, path, textAlign}: MenuItemProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    const navigate = useNavigate()
    const location = useLocation()

    const [isActive, setIsActive] = useState<boolean>(
        location.pathname === path
    )

    console.log(theme)

    return (
        <StyledMenuItem textAlign={textAlign} isActive={isActive} theme={theme}>
            <Button onClick={() => navigate(path)} noStyle><Text fontSize={'18px'}>{title}</Text></Button>
        </StyledMenuItem>
    )
}

type TopNavMenuProps = {
    border?: boolean;
}


const TopNavMenu = ({border}: TopNavMenuProps) => {
    return (
        <StyledTopNavigationMenuContainer border={border}>
            <StyledNavigationMenu>
                <MenuItem title={'Explore'} path={'/explore'}/>
                <MenuItem title={'Artworks'} path={'/artworks'}/>
                <MenuItem title={'Auctions'} path={'/auctions'}/>
                <MenuItem title={'Shows'} path={'/shows'}/>
                <MenuItem title={'Artists'} path={'/artists'}/>
                <MenuItem title={'Art Patrons'} path={'/art-patrons'}/>
                <MenuItem title={'Galleries'} path={'/galleries'}/>
                <MenuItem title={'Museums'} path={'/museums'}/>
            </StyledNavigationMenu>
        </StyledTopNavigationMenuContainer>
    )
}

export default TopNavMenu