import styled from 'styled-components'


interface DiscoverPeoplePostContainerProps {

}

export const StyledDiscoverPeoplePostContainer = styled.div<DiscoverPeoplePostContainerProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
    align-items: center;
    padding: 8px 0px;
    width: 100%;
`
