import styled from 'styled-components'


interface FollowingContainerProps {

}

export const StyledFollowingContainer = styled.div<FollowingContainerProps>`

`

interface FollowingProps {

}

export const StyledFollowing = styled.div<FollowingProps>`
    
`


