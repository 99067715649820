import styled from 'styled-components'


interface ViewNavigatorContainerProps {

}

export const StyledViewNavigatorContainer = styled.div<ViewNavigatorContainerProps>`
    border-bottom: solid 1px #D7D7D7;
`

interface ViewNavigatorProps {
    columns?: number;
    gridGap?: number;
    width?: string;
}

export const StyledViewNavigator = styled.div<ViewNavigatorProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.columns || 3}, 1fr);
    grid-gap: ${props => props.gridGap || 0};
    width: ${props => props.width || '100%'};
`




