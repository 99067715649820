import { Text } from "../../Text"
import { RippleLoader } from "../Loaders/RippleLoader"
import { StyledPageLoader, StyledPageLoaderContainer } from "./styled"
import {ReactComponent as AILoader} from '../../../img/aiLoader.svg'



const PageLoader = () => {

    return (
        <StyledPageLoaderContainer>
            <Text fontSize="24px" padding="12px 0px" width="100%" textAlign="center" aiify>Primary Yellow</Text>
            <StyledPageLoader>
                <AILoader height={48} width={48}/>
                {/* <RippleLoader zoom={'1'}/> */}
                {/* <SpinnerLoader /> */}
            </StyledPageLoader>
        </StyledPageLoaderContainer>
    )
}

export default PageLoader