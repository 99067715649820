// https://docs.stripe.com/payments/ach-debit/accept-a-payment
// https://docs.stripe.com/checkout/embedded/quickstart?lang=python&client=react
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { View } from '../../../View';
import { CheckoutForm } from '../CheckoutForm';
import { PaymentStatus } from '../PaymentStatus';
import { useEffect, useState } from 'react';
import { StyledStep, StyledStepperContainer, StyledCheckout, StyledProductContainer, StylePriceContainer, StyledPurchaseProtected, StyledBillingShipping } from './styled';
import api from '../../../../services/api.service';
import { usePostStore, useProductDetailPageStore } from '../../../../stores/web.store';
import { Post } from '../../../Post';
import { ShippingAddress } from '../../../ShippingAddress';
import { DeliveryMethod } from '../DeliveryMethod';
import {ReactComponent as ChevromRight} from '../../../../img/chevronRight.svg'
import {ReactComponent as RoundedCheckIcon} from '../../../../img/roundedCheck.svg'
import {ReactComponent as LogoIcon} from '../../../../img/logo.svg'
import { Button } from '../../../Button';
import { Text } from '../../../Text';
import { toCurrency } from '../../../../utils/app.util';
import { ImageGallery } from '../../../ImageGallery';
import { OrderReview } from '../OrderReview';
import { CheckoutProduct } from '../CheckoutProduct';
import { OrderComplete } from '../OrderComplete';
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import { RippleLoader } from '../../../Loader/Loaders/RippleLoader';


const CheckoutNavigation = ({activeStep, setActiveStep}: any) => {
    return (
        <StyledStepperContainer>
            <StyledStep isActive={activeStep === 0}>
                <Button 
                    noStyle 
                    width='100%' 
                    padding='0px 12px'
                    onClick={() => setActiveStep(0)}
                >
                    <View flexGrow='1' textAlign='left'>
                        <Text 
                            fontSize='16px'
                            color={activeStep === 0 ? "#000" : "#c0c0c0"}
                        >Shipping</Text>
                    </View>
                    <View textAlign='right' width='fit-content'>
                        <ChevromRight stroke='#CBCBCB' height={16} width={16}/>
                    </View>
                </Button>
            </StyledStep>
            <StyledStep isActive={activeStep === 1}>
                <Button 
                    noStyle 
                    width='100%' 
                    padding='0px 12px'
                    onClick={() => setActiveStep(1)}
                >
                    <View flexGrow='1' textAlign='left'>
                        <Text 
                            fontSize='16px'
                            color={activeStep === 1 ? "#000" : "#c0c0c0"}
                        >Payment</Text>
                    </View>
                    <View textAlign='right' width='fit-content'>
                        <ChevromRight stroke='#CBCBCB' height={16} width={16}/>
                    </View>
                </Button>
            </StyledStep>
            <StyledStep isActive={activeStep === 2}>
                <Button 
                    noStyle 
                    width='100%' 
                    padding='0px 12px'
                    onClick={() => setActiveStep(2)}
                >
                    <View flexGrow='1' textAlign='left'>
                        <Text 
                            fontSize='16px'
                            color={activeStep === 2 ? "#000" : "#c0c0c0"}
                        >Review</Text>
                    </View>
                    <View textAlign='right' width='fit-content'>
                        <ChevromRight stroke='#CBCBCB' height={16} width={16}/>
                    </View>
                </Button>
            </StyledStep>
        </StyledStepperContainer>
    )
}



const CheckoutPrice = ({post}: any) => {
    return (
        <StylePriceContainer>
            <View display='inline-flex' alignItems='center' width='100%' padding='0px 0px 12px 0px'>
                <Text width='fit-content'>{'Price'}</Text>
                <Text flexGrow='1' textAlign='right'>{post.metadata.price}</Text>
            </View>
            <View display='inline-flex' alignItems='center' width='100%' padding='0px 0px 0px 0px'>
                <Text width='fit-content'>{'Shipping'}</Text>
                <Text flexGrow='1' textAlign='right'>{post.metadata.price}</Text>
            </View>
            <View display='inline-flex' alignItems='center' width='100%' padding='0px 0px 12px 0px'>
                <Text width='fit-content'>{'Tax*'}</Text>
                <Text flexGrow='1' textAlign='right'>{post.metadata.price}</Text>
            </View>
            <View display='inline-flex' alignItems='center' width='100%' padding='0px 0px 12px 0px'>
                <Text fontWeight='500' width='fit-content'>{'Total'}</Text>
                <Text fontWeight='500' flexGrow='1' textAlign='right'>{'Waiting for final cost'}</Text>
            </View>
            <View>
                <Text>{'*Additional duties and taxes may apply at import'}</Text>
            </View>
        </StylePriceContainer>
    )
}

const PurchaseProtected = () => {
    return (
        <StyledPurchaseProtected>
            <View display='inline-flex' alignItems='start' gap='8px'>
                <View>
                    <RoundedCheckIcon height={13} width={13} />
                </View>
                <View>
                    <Text fontWeight='500'>{'Your purchase is protected.'}</Text>
                    <Text color='#808080' fontSize='13px' padding='4px 0px 0px 0px'>{"Learn more about Primary Yellow’s buyer protection."}</Text>
                </View>
            </View>
        </StyledPurchaseProtected>
    )
}

const CompleteBilling = () => {
    return (
        <View display="flex" flexDirection="column" gap="12px">
            <View>
                <Text fontSize="20px" fontWeight="500">{'Billing Address'}</Text>
            </View>
            <View>
                <Text>Payment Status: {'Paid'}</Text>
            </View>
            <View>
                <View display="flex" flexDirection="column" width="100%" gap="8px">
                    <Text color="#808080">{'Customer name'}</Text>
                    <Text color="#808080">{'Address 1'} {'Address 2'}</Text>
                    <Text color="#808080">{'City'}, {'State'}, {'Zipcode'}</Text>
                </View>
            </View>
        </View>
    )
}

const CompleteShipping = () => {
    return (
        <View display="flex" flexDirection="column" gap="12px">
            <View>
                <Text fontSize="20px" fontWeight="500">{'Shipping Address'}</Text>
            </View>
            <View>
                <Text>Fullfillment Status: {'Unfulfilled'}</Text>
            </View>
            <View>
                <View display="flex" flexDirection="column" width="100%" gap="8px">
                    <Text color="#808080">{'Customer name'}</Text>
                    <Text color="#808080">{'Address 1'} {'Address 2'}</Text>
                    <Text color="#808080">{'City'}, {'State'}, {'Zipcode'}</Text>
                </View>
            </View>
        </View>
    )
}


const Checkout = () => {
    const navigate = useNavigate()
    const post = usePostStore((state: any) => state.post)
    const [stripePromise, setstripePromise] = useState<Promise<Stripe | null> | null>(null);
    const [options, setOptions] = useState<any>(null)
    const [activeStep, setActiveStep] = useState(0)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const [isLoading, setIsLoading] = useState(false)
    

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)
            const resp = await api.post(`/service/stripe/payment-intent`, {
                quantity: 1, 
                post_id: post.id,
            })

            if (resp !== null) {
                setOptions({clientSecret: resp.client_secret})
                setstripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!))
            } else {
                setPdp(null)
            }
            setIsLoading(false)
        }

        if (!post?.id) {
            navigate('/')
            return
        }

        fetch();
    }, [post?.id, setPdp]);

    const onClickHome = () => {
        navigate('/')
    }

    return (
        <View background='#fff' padding='24px' height='100vh'>
            <View padding='0px 0px 24px 0px'>
                <Button noStyle onClick={() => navigate('/')}>
                    <LogoIcon height={32} width={32}/>
                </Button>
            </View>
            {activeStep <= 2 && <View>
                <View>
                    <CheckoutNavigation activeStep={activeStep} setActiveStep={setActiveStep} />
                </View>
                <View padding='24px 0px'>
                    {stripePromise && options && <Elements stripe={stripePromise} options={options}>  
                        <StyledCheckout>
                            <View width='60%'>
                                {activeStep === 0 && <DeliveryMethod checkoutStep={activeStep} setCheckoutStep={setActiveStep} />}
                                {activeStep === 1 && <CheckoutForm post={post} checkoutStep={activeStep} setCheckoutStep={setActiveStep}/>}
                                {activeStep === 2 && <OrderReview post={post} checkoutStep={activeStep} setCheckoutStep={setActiveStep}/>}
                                {/* <PaymentStatus /> */}
                            </View>
                            <View width='40%'>
                                <CheckoutProduct post={post} />
                                <CheckoutPrice post={post}/>
                                <View padding='12px 0px'>
                                    <PurchaseProtected />
                                </View>
                            </View>
                        </StyledCheckout>
                    </Elements>}
                </View>
            </View>}
            {activeStep === 3 && <View display='flex' flexDirection='column' gap='36px'>
                <View>
                    <Button onClick={onClickHome} noStyle>Return to home</Button>
                </View>
                <View>
                    <View display="flex" flexDirection="column" gap="12px">
                        <Text color='#808080'>Confirmation {'#1231241241'}</Text>
                        <Text fontSize='20px' fontWeight='500'>Thank you, {'Customer Name'}!</Text>
                    </View>
                </View>
                <StyledCheckout>
                    <View width='60%'>
                        <OrderComplete post={post} checkoutStep={activeStep} setCheckoutStep={setActiveStep}/>
                    </View>
                    <View width='40%'>
                        <StyledBillingShipping>
                            <CompleteBilling />
                            <CompleteShipping />
                        </StyledBillingShipping>
                    </View>
                </StyledCheckout>
            </View>}
            {isLoading && <RippleLoader />}
        </View>
       
        // <StyledCheckout>
            
            /* <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider> */
        // </StyledCheckout>
    )
}

export default Checkout