import {  useEffect, useRef, useState } from "react"
import { StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"
import { toUTC, useComponentVisible } from "../../../utils/app.util"
import { Text } from "../../Text";
import { View } from "../../View";
import { Button } from "../../Button";
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { Input } from "../../Input";
import api from "../../../services/api.service";
import { Divider } from "../../Divider";
import { useAccountStore } from "../../../stores/web.store";
import {ReactComponent as EditIcon} from '../../../img/edit.svg'


type MeetingModalProps = {
    setModal: any;
    thread: any;
    payload: any;
    setPayload: any;
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: string;
    left?: string;
    margin?: any;
    padding?: string;
    background?: string;
    borderRadius?: string;
}

const useOutsideAlerter = (ref: any, setModal: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(null)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const MeetingTypeNavigator = ({payload, setPayload}: any) => {
    return (
        <View>
            <View display="inline-flex" alignItems="center" width="100%"  padding="0px 0px 12px 0px">
                <View width="100%">
                    <Button 
                        noStyle 
                        fontSize="16px"
                        fontWeight="500"
                        color={payload.type === 'Video' ? '#0938F0' : '#000'}
                        onClick={() => setPayload({...payload, type: 'Video'})}
                    >{'Video'}</Button>
                </View>
                <View width="100%">
                    <Button 
                        noStyle 
                        fontSize="16px"
                        fontWeight="500"
                        color={payload.type === 'Audio' ? '#0938F0' : '#000'}
                        onClick={() => setPayload({...payload, type: 'Audio'})}
                    >{'Audio'}</Button>
                </View>
            </View>
            <Divider />
        </View>
    )
}

const MeetingScheduler = ({payload, setPayload, thread}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const [isSchedule, setIsSchedule] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setPayload({...payload, topic: `${account.first_name}/${thread.recipients.map((recipient: any) => recipient.first_name).join('/')} ${payload.type} Call`})
    }, [payload.type])

    const onClickMeetNow = async () => {
        const resp = await api.post('/meetings', {
            ...payload,
            date: toUTC(new Date()),
            participants: thread.recipients.map((rec: any) => rec.id),
            source_id: thread.id,
            source: 'thread'
        })

        console.log(resp)
    }

    const onClickSchedule = () => {
        console.log(payload)
    }

    const onChangeSchedule = (e: any) => {
        const datetime = toUTC(new Date(e.target.value))
        setPayload({...payload, date: datetime})
    }

    const onChangeNameOfMeeting = (e: any) => {
        setPayload({...payload, topic: e.target.value})
    }

    const onChangePasscode = (e: any) => {
        setPayload({...payload, passcode: e.target.value})
    }

    return (
        <View>
            <View display="flex" flexDirection="column" width="100%" gap="12px">
                <View>
                    <View display="flex" flexDirection="column" width="100%" gap="24px">
                        <View>
                            <View display="flex" flexDirection="column" width="100%" gap="12px">
                                <View width="fit-content" position="relative" margin="auto">
                                    <Text width="100%" fontSize="20px" fontWeight="500" textTransform="title">{payload.topic}</Text>
                                    <View position="absolute" right="-24px" top="4px">
                                        <Button noStyle onClick={() => setIsEdit(!isEdit)}>{isEdit ? <XIcon height={14} width={14}/> : <EditIcon height={14} width={14}/>}</Button>
                                    </View>
                                </View>
                                {isEdit && <View>
                                    <Input placeholder="Name of Meeting" width="70%" onChange={onChangeNameOfMeeting} value={payload.topic}/>
                                </View>}
                            </View>
                        </View>
                        
                    </View>
                </View>
                <View>
                    <Input placeholder="Meeting Passcode" width="70%" onChange={onChangePasscode} value={payload.passcode}/>
                </View>
                {!isSchedule && <View width="70%" margin="auto">
                    <Button
                        border={`solid 1px ${'#BFCBFB'}`}
                        background={'#DDE3FD'}
                        color={'#0938F0'}
                        padding="8px 20px"
                        borderRadius="23px"
                        fontSize="16px"
                        width="100%"
                        onClick={onClickMeetNow}
                        noStyle
                    >Meet Now</Button>
                </View>}
                <View>
                    <View display="flex" flexDirection="column" width="100%" gap="12px">
                        {!isSchedule && <View>
                            <Button 
                                fontSize="13px" 
                                textAlign="center" 
                                width="100%" 
                                noStyle 
                                color="#0938F0"
                                onClick={() => setIsSchedule(true)}
                            >{'Schedule Meeting'}</Button>
                        </View>}
                        {isSchedule && <View>
                            <Input type="datetime-local" width="70%" onChange={onChangeSchedule}/>
                        </View>}
                        {isSchedule && <View width="70%" margin="auto">
                            <Button
                                border={`solid 1px ${'#BFCBFB'}`}
                                background={'#DDE3FD'}
                                color={'#0938F0'}
                                padding="8px 20px"
                                borderRadius="23px"
                                fontSize="16px"
                                width="100%"
                                onClick={onClickSchedule}
                                noStyle
                            >Schedule</Button>
                        </View>}
                        {isSchedule && <View>
                            <Button 
                                fontSize="13px" 
                                textAlign="center" 
                                width="100%" 
                                noStyle 
                                color="#0938F0"
                                onClick={() => setIsSchedule(false)}
                            >{'Meet Now'}</Button>
                        </View>}
                    </View>
                </View>
            </View>
        </View>
    )
}

const Disclaimer = () => {
    return (
        <View>
            <Text width="100%" textAlign="center" fontSize="11px">Disclaimer: Meeting links are valid for <b>48 hours</b> from start date.</Text>
        </View>
    )
}

const MeetingModal = ({setModal, payload, thread, setPayload, position, height, width, modalHeight, top, left, margin, background, padding, borderRadius}: MeetingModalProps) => {
    const { ref } = useComponentVisible(setModal);
    const wrapperRef = useRef(null);

    const onClickClose = () => {
        setModal(false)
    }

    useOutsideAlerter(wrapperRef, setModal);
    return (
        <StyledModalContainer position={position} top={top} left={left}  ref={ref}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer>
                    <StyledModalContent height={height} background={background} padding={padding} borderRadius={borderRadius} ref={wrapperRef}>
                        <View display="flex" flexDirection="column" gap="24px" width="100%" textAlign="center" padding="24px 0px">
                            <View>
                                <MeetingTypeNavigator setPayload={setPayload} payload={payload} />
                            </View>   
                            <View>
                                <MeetingScheduler setPayload={setPayload} payload={payload} thread={thread}/>
                            </View>
                            <View>
                                <Disclaimer />
                            </View>
                        </View>
                        
                    </StyledModalContent>
                    <View position="absolute" top="12px" right="12px">
                        <Button noStyle onClick={onClickClose}><XIcon height={24} width={24}/></Button>
                    </View>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default MeetingModal