import { Post } from '../Post'
import {StyledFeedPostContainer, StyledFeedPost, StyledMobileComments, StyledMobileCommentsContainer, StyledMobileCommentHeader} from './styled'
import { useRef, useState } from 'react'
import { useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { View } from '../View'
import { Button } from '../Button'
import { Text } from '../Text'
import { LikeButton } from '../Button/Like'
import { Impressions } from '../Button/Impressions'
import { toDate, toDateDiff } from '../../utils/app.util'
import { SaveButton } from '../Button/Save'
import { ShareButton } from '../Button/Share'
import { useLexicalContextStore, useModalStore, usePostStore, useResponsiveStore } from '../../stores/web.store'
import { ProfilePicture } from '../ProfilePicture'
import { Comment } from '../Button/Comment'
import { PostCaption } from '../PostCaption'
import { Markdown } from '../MarkDown'
import { $getRoot } from 'lexical'
import api from '../../services/api.service'
import { Comments } from '../Comments'
import { Likes } from '../Likes';


const FeedPost = ({data}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const post = usePostStore((state: any) => state.post)
    const [isExpanded, setIsExpanded] = useState(false)
    const [comment, setComment] = useState<any>()
    const [postData, setPostData] = useState<any>(data)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const [websckt, setWebsckt] = useState<any>();
    const lexicalContext = useLexicalContextStore((state: any) => state.lexicalContext)
    const width = useResponsiveStore((state: any) => state.width)
    const [isMobileComments, setIsMobileComments] = useState(false)

    const editorStateRef = useRef<any>();
    

    // TODO: Review Message websocket for proper implementation
    // useEffect(() => {
    //     if (!websckt) {
    //         const url = "ws://localhost:8080/ws/comments";
    //         const ws = new WebSocket(url);

    //         ws.onopen = (event) => {
    //             // ws.send("Connect");
    //         };

    //         ws.onmessage = (e) => {
    //             const msg = JSON.parse(e.data);
    //             setPost({...post, comments: [...post.comments, msg]});
    //         };

    //         setWebsckt(ws);

    //     }
        
    // }, []);

    const _updateComments = (comment: any) => {
        setPostData({
            ...postData,
            comments: [
                ...postData.comments,
                comment
            ]
        })

        setPost({
            ...postData,
            comments: [
                ...postData.comments,
                comment
            ]
        })
    }

    const onChangeEditorState = (editorState: any) => {
        editorStateRef.current = editorState
        const msg = editorState.read(() => $getRoot().getTextContent())
        setComment({
            comment: msg,
            lexical_comment: JSON.stringify(editorStateRef.current)
        })

    }

    const handleEnterCommand = async (event: any, editor: any) => {
        event.preventDefault();
        // const serializedContent = $generateHtmlFromNodes(editor, null);

        const resp = await api.post('/comments', {
            ...comment,
            source: postData.type,
            source_id: postData.id

        })
        _updateComments(resp.data)
        return true
    };

    // const sendMessage = () => {
    //     websckt.send(JSON.stringify(comment));
    //     // recieve message every send message
    //     websckt.onmessage = (e: any) => {
    //         const msg = JSON.parse(e.data);
    //         setPost({...post, comments: [...post.comments, msg]});
    //     };
    // };

    const onClickSend = async (editorState: any) => {
        
    }

    const onClick = () => {
        if (width <= 768) {
            setIsMobileComments(true)
        } else {
            setModalKey('post')
            setPost(postData)
        }
        
    }

    const onClickPost = () => {
        if (width <= 768) {
            setIsMobileComments(true)
        } else {
            setModalKey('post')
            setPost(postData)
        }
    }

    const [{ y }, set] = useSpring(() => ({
        y: 0,
        config: { tension: 200, friction: 26 }
      }));
    
    const bind = useDrag(
        ({ down, movement: [, my], velocity, direction: [, dy], cancel }) => {
            if (my > 0 && !down && velocity > 0.5) {
                setTimeout(() => setIsMobileComments(false), 300);
            } else {
            set({ y: down ? my : 0 });
            }
            if (my < -50 && dy < 0) cancel();
        },
        { axis: 'y', bounds: { top: 0 }, rubberband: true }
    );

    return (
        <StyledFeedPostContainer>
            <StyledFeedPost>
                <View padding='0px 0px 12px 0px'>
                    <View display='inline-flex' alignItems='center' gap='4px' width='100%'>
                        <ProfilePicture account={postData.owner} username photo fontSize='16px'/>
                        <View width='fit-content'>
                            <Text color='#808080' fontSize='16px'>&#8226;</Text>
                        </View> 
                        <Text color='#808080' fontSize='16px'>{toDateDiff(postData.created_date, true, 'd')}</Text>
                    </View>
                </View> 
                <Post data={data} height="641px" type={postData.type} setPost={setPost} borderRadius='10px' onClick={onClick}>
                    <View padding="12px 0px">
                        <View display="inline-flex" alignItems="center" width="100%">
                            <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                                <LikeButton data={postData}/>
                                <Comment data={postData} uid={`feedPost-${postData.id}`}/>
                                <ShareButton data={postData} noTitle noAdjust/>
                                
                            </View>
                            <View display="inline-flex" alignItems="center" width="100%" justifyContent="right" gap="12px">
                                <Impressions data={postData} />
                                <SaveButton data={postData} noTitle/>
                            </View>
                        </View>
                        <View>
                            <Likes postData={postData}/>
                        </View>
                    </View>
                </Post>
                <View>
                    <PostCaption data={postData}/>
                </View>
                
                
                {/* <View height={isExpanded ? '160px' : 'auto'} overflowY='scroll'>
                    <View display='flex' flexDirection='column' gap="12px">
                        {data.comments.map((comment: any, idx: number) => {
                            // if (!isExpanded && idx === 0) {
                            //     return (
                            //         <PreviewComment data={comment}/>
                            //     )
                            // }
                            if (isExpanded) {
                                return (
                                    <PreviewComment data={comment}/>
                                )
                            } 
                            
                        })}
                        
                    </View>
                    
                </View> */}
                {postData.comments.length > 0 && <View padding='12px 0px 0px 0px'>
                    <View>
                        <Button color={'#848484'} noStyle onClick={onClickPost}>{isExpanded ? `Hide comments` : `View all ${postData.comments.length} comments`}</Button>
                    </View>
                </View>}

                {width > 768 && <View>
                    <Markdown
                        uid={`feedPost-${postData.id}`}
                        editorStateRef={editorStateRef} 
                        data={null} 
                        singleLine 
                        onChange={onChangeEditorState} 
                        placeholder={'Write a comment'} 
                        onClickSend={onClickSend}
                        borderTop="none"
                        borderRight="none"
                        borderLeft="none"
                        borderBottom='solid 1px #D7D7D7'
                        borderRadius="none"
                        handleEnterCommand={handleEnterCommand}
                        editable
                        noTools
                    />
                </View>}
            </StyledFeedPost>
            <StyledMobileCommentsContainer isActive={isMobileComments}>
                <StyledMobileComments
                    
                    isActive={isMobileComments}
                >
                    <View>
                        <StyledMobileCommentHeader {...bind()}>
                            <View padding='12px'>
                                <View width='100%' textAlign='center' display='flex' justifyContent='center'>
                                    <View width='40px' height='4px' background='#FFDC39' borderRadius='6px'/>
                                </View>
                            </View>
                            <View>
                                <Text width='100%' textAlign='center' fontSize='16px' fontWeight='500'>Comments</Text>
                            </View>
                        </StyledMobileCommentHeader>
                        <View padding='0px 12px 12px 12px'>
                            <Comments post={postData}/>
                        </View>
                        <View position='absolute' bottom='0' width='100%'>
                            <Markdown
                                uid={`feedPost-${postData.id}`}
                                editorStateRef={editorStateRef} 
                                data={null} 
                                singleLine 
                                onChange={onChangeEditorState} 
                                placeholder={'Write a comment'} 
                                onClickSend={onClickSend}
                                borderTop="none"
                                borderRight="none"
                                borderLeft="none"
                                borderRadius="none"
                                handleEnterCommand={handleEnterCommand}
                                editable
                                noTools
                            />
                        </View>
                    </View>
                </StyledMobileComments>
            </StyledMobileCommentsContainer>
        </StyledFeedPostContainer>
        
    )
}

export default FeedPost