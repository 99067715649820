import { useEffect, useRef, useState } from "react"
import { usePostStore } from "../../stores/web.store"
import { Post } from "../Post"
import { View } from "../View"
import { StyledBoard } from "./styled"
import { Text } from "../Text"
import { ProfilePicture } from "../ProfilePicture"


const Board = ({data, board, setBoard, setFilteredData, height}: any) => {
    const setPost = usePostStore((state: any) => state.setPost)
    const [textHeight, setTextHeight] = useState(0); 
    const ref = useRef<HTMLDivElement>(null);

    const onClick = () => {
        setFilteredData(data.posts)
        setBoard(data)
    }

    useEffect(() => { 
        if (ref.current) {
            setTextHeight(ref.current.offsetHeight); 
        } 
    }, []);

    return (
        <StyledBoard height={height + textHeight}>
            <View display="flex" flexDirection="column" gap="0">
                <Post
                    data={data} 
                    setPost={board ? setPost : setBoard}
                    type={data.type}
                    isThumbnail={!board} 
                    onClick={board ? null : onClick}
                    height={height}
                    borderRadius="10px"
                />
                <div ref={ref}>
                    <View padding="12px 12px 0px 4px">
                        <Text isReadMore>{data.name || data.caption}</Text>
                    </View>
                    <View padding="4px 12px 12px 1px">
                        <ProfilePicture account={data.owner} photo username />
                    </View>
                </div>
            </View>
        </StyledBoard>
    )
}

export default Board