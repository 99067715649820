import styled from 'styled-components'


interface SortContainerProps {

}


export const StyledSortContainer  = styled.div<SortContainerProps>`
    min-width: 120px;
    width: auto;
`

interface SortProps {
    left?: number;
}


export const StyledSort  = styled.div<SortProps>`
    display: inline-flex;
    align-items: center;

    & .select__menu {
        left: ${props => props.left}px;
    }

    & .select__control {
        :focus {
            border: none;
        }
        :active {
            border: none;
            
        }
        box-shadow: none;
    }

    & .select__indicator svg {
        display: none;
    }


`