import styled from 'styled-components'

interface PhotoOverlapContainerProps {
    width?: string;
}


export const StyledPhotoOverlapContainer  = styled.div<PhotoOverlapContainerProps>`
    width: ${props => props.width || 'auto'}; 
    height: 24px;  
`

interface PhotoOverlapProps {

}

export const StyledPhotoOverlap = styled.div<PhotoOverlapProps>`
    position: relative;
`

interface PhotoOverlapItemProps {
    total: number;
    index: number;
    offset?: number;
}

export const StyledPhotoOverlapItem = styled.div<PhotoOverlapItemProps>`
    position: absolute;
    width: 16px;

    ${props => `
        left: ${(props.offset || 14) * props.index}px;
    `}

`
