import styled from 'styled-components'

interface CommentsContainerProps {

}


export const StyledCommentsContainer  = styled.div<CommentsContainerProps>`

`

interface CommentsProps {
    height?: string;
}

export const StyledComments = styled.div<CommentsProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 12px 0px 24px 0px;
    height: ${props => props.height || '48vh'};
    overflow-y: scroll;
`
