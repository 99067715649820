import styled from 'styled-components'




export const StyledCompleteHeader = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
`

export const StyledCompleteProducts = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
    border-top: none;
`

export const StyledCompleteTotal = styled.div`
    border: solid 1px #e6e6e6;
    padding: 24px;
    border-radius: 10px;
    border-top: none;
`



