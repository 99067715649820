import { useState } from "react"
import { useAccountStore, useThemeStore } from "../../../stores/web.store"
import Button from "../Button"
import api from "../../../services/api.service"
import { View } from "../../View"


const Follow = ({title, data, noStyle, activeColor, inactiveColor, borderRadius, isFollowing}: any) => {
    const account = useAccountStore((state: any) => state.account)
    const setAccount = useAccountStore((state: any) => state.setAccount)
    const theme = useThemeStore((state: any) => state.theme)
    const [userData, setUserData] = useState(data)
    const [followAccount, setFollowAccount] = useState(account.following?.find((f: any) => f?.id === userData?.id))
    const [isFollowed, setIsFollowed] = useState(followAccount?.follow?.status === 'active')

    const onClick = async () => {
        setIsFollowed(!isFollowed)
        if (followAccount?.follow?.id) {
            const updatedStatus = !isFollowed ? 'active': 'inactive'
            api.put(`/follows/${followAccount?.follow?.id}`, {
                status: updatedStatus,
            })
            setAccount({...account, following: account.following.map((f: any) => {
                if (f.id === followAccount.id) {
                    return {...f, follow: {...f.follow, status: updatedStatus}}
                }
                return f
            })})
            setUserData({...userData, follow: {...userData.follow, status: updatedStatus}})
        } else {
            const resp = await api.post('/follows', {
                source_id: userData.id
            })
            setFollowAccount(resp)
            setAccount({...account, following: [...account.following, resp]})
            setUserData({...userData, follow: resp.data, total_followers: userData.total_followers + 1})
        }
        
    }

    if (account.id === userData.id) return <View display="none" />

    console.log('noStyle,', noStyle)
    return (
        <Button 
            noStyle={noStyle} 
            textAlign="right" 
            width="fit-content" 
            padding={noStyle ? '0px' : "4px 12px"} 
            border={noStyle ? 'none' : `solid 1px ${activeColor || theme.button.color}`}
            borderRadius={borderRadius || "12px"}
            background={isFollowed ? activeColor || theme.button.color : inactiveColor || '#fff'}
            color={isFollowed ? inactiveColor || '#fff' : activeColor || theme.button.color}
            onClick={onClick}
        >{isFollowed ? 'Unfollow' : (title || 'Follow')}</Button>
    )
}

export default Follow