import { ReactNode } from "react";
import { StyledHangArtPiece, StyledHangArtPieceContainer } from "./styled"
import { useResponsiveStore } from "../../stores/web.store";


type HangArtPieceProps = {
    top?: string;
    children: ReactNode;
}


const HangArtPiece = ({children, top}: HangArtPieceProps) => {
    const width = useResponsiveStore((state: any) => state.width)
    const height = useResponsiveStore((state: any) => state.height)

    return (
        <StyledHangArtPieceContainer top={top}>
            <StyledHangArtPiece width={width} height={height}>
                {children}
            </StyledHangArtPiece>
        </StyledHangArtPieceContainer>
    )
}

export default HangArtPiece