import { View } from "../../View"
import { SearchBar } from "../../SearchBar"
import { StyledLikesModalContainer, StyledLikesModal, StyledCloseModalContainer, StyledLikeModalContainer } from "./styled"
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { ProfilePicture } from "../../ProfilePicture"
import { Follow } from "../../Button/Follow"
import { Text } from "../../Text"
import { Button } from "../../Button"
import Modal from "../Modal"


const LikesModal = ({data, setIsModal}: any) => {
    console.log(data)
    
    return (
        <Modal
            setModal={setIsModal} 
            width={'100%'} 
            height={'100vh'}
            background="rgba(0, 0, 0, .5)"
        >
            <StyledLikesModalContainer>
                <StyledLikesModal>
                    <View borderBottom="solid 1px grey" padding="0px 0px 12px 0px">
                        <Text width="100%" fontWeight="500">{`Likes (${data.length})`}</Text>
                    </View>
                    <View padding="20px 0px">
                        {/* <SearchBar 
                            setData={setFollowers} 
                            data={followers} 
                            resetData={profile.followers}
                            fetch={onSearch}
                            
                        /> */}
                    </View>
                    <StyledLikeModalContainer>
                        {data.map((acct: any) => {
                            return (
                                <View>
                                    <View display="inline-flex" alignItems="center" width="100%">
                                        <View width="70%" textAlign="left">
                                            <ProfilePicture account={acct.owner} photo username role/>
                                        </View>
                                        <View textAlign="right" flexGrow="1">
                                            <Follow data={acct.owner}/>
                                        </View>
                                    </View>
                                </View>
                            )
                        })}
                    </StyledLikeModalContainer>
                </StyledLikesModal>
                <StyledCloseModalContainer>
                    <Button noStyle onClick={() => setIsModal(false)}><XIcon strokeWidth={2} height={24} width={24}/></Button>
                </StyledCloseModalContainer>
            </StyledLikesModalContainer>              
        </Modal>
    )
}

export default LikesModal