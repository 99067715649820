import styled from "styled-components";


interface ViewingRoomContainerProps {
    
}

export const StyledViewingRoomContainer = styled.div<ViewingRoomContainerProps>`
`

interface ViewingRoomProps {
    
}

export const StyledViewingRoom = styled.div<ViewingRoomProps>`
    position: relative;
`

interface BackNavigatorProps {
    isHover?: boolean;
}

export const StyledBackNavigator = styled.div<BackNavigatorProps>`
    opacity: .7;

    &:hover {
        opacity: 1;
    }

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`

interface NextNavigatorProps {
    isHover?: boolean;
}

export const StyledNextNavigator = styled.div<NextNavigatorProps>`
    opacity: .7;

    &:hover {
        opacity: 1;
    }
    

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`