

interface GlobalThemeProps {
    fontFamily?: string;
}

interface TextThemeProps {
    color?: string;
    secondaryColor?: string;
}

interface ButtonThemeProps {
    background?: string;
    padding?: string;
    border?: string;
    borderRadius?: string;
    fontSize?: string;
    color?: string;
    secondaryColor?: string;
    width?: string;
    height?: string;
    fontWeight?: string;
    lineHeight?: string;
    textAlign?: string;
    display?: string;
}

interface InputThemeProps {
    background?: string;
    padding?: string;
    border?: string;
    borderRadius?: string;
    fontSize?: string;
    color?: string;
    width?: string;
}

interface BaseThemeProps {
    global?: GlobalThemeProps
    button?: ButtonThemeProps
    text?: TextThemeProps
    input?: InputThemeProps
}


export const LightTheme = (props?: BaseThemeProps) => {
    return {
        global: {
            fontFamily: props?.global?.fontFamily || 'Roboto',
        },
        button: {
            background: props?.button?.background || '#0938F0',
            padding: props?.button?.padding || '0px 16px',
            border: props?.button?.border || 'solid 1px #2187FF',
            borderRadius: props?.button?.borderRadius || '10px',
            fontSize: props?.button?.fontSize || '13px',
            lineHeight: props?.button?.lineHeight || props?.button?.fontSize || '13px',
            fontWeight: props?.button?.fontWeight || '400',
            textAlign: props?.button?.textAlign || 'center',
            color: props?.button?.color || '#000',
            width: props?.button?.width || 'auto',
            height: props?.button?.height || 'auto',
            display: props?.button?.display || 'inline-block',
            secondaryColor: props?.button?.secondaryColor || '#fff',
        },
        text: {
            color: props?.text?.color || 'inherit',
            secondaryColor: props?.text?.secondaryColor || '#808080'
        },
        input: {
            background: props?.input?.background || '#fff',
            padding: props?.input?.padding || '8px 0px',
            border: props?.input?.border || 'solid 1px #D7D7D7',
            borderRadius: props?.input?.borderRadius || '4px',
            fontSize: props?.input?.fontSize || '13px',
            color: props?.input?.color || '#000',
            width: props?.input?.width || 'auto'
        }
    }
}

