import { useState } from "react";
import { View } from "../View"
import { Input } from "../Input";
import { Text } from "../Text";

const StartDatePicker = ({payload, setPayload}: any) => {
    const [value, setValue] = useState<any>(new Date());
    const onChange = (e: any) => {
        const value = e.target.value
        setPayload({...payload, metadata: {...(payload.metadata || {}), start_date: value}})
    }
    return (
        <Input 
            type="date" 
            width='100%' 
            textIndent='4px' 
            onChange={onChange}
        />
    )
}

const StartTimePicker = ({payload, setPayload}: any) => {
    const [value, setValue] = useState<any>(new Date());
    const onChange = (e: any) => {
        const value = e.target.value
        setPayload({...payload, metadata: {...(payload.metadata || {}), start_time: value}})
    }
    return (
        <Input 
            type="time" 
            width='100%' 
            textIndent='4px' 
            onChange={onChange}
        />
    )
}

const EndDatePicker = ({payload, setPayload}: any) => {
    const [value, setValue] = useState<any>(new Date());
    const onChange = (e: any) => {
        const value = e.target.value
        setPayload({...payload, metadata: {...(payload.metadata || {}), end_date: value}})
    }
    return (
        <Input 
            type="date" 
            width='100%' 
            textIndent='4px'
            onChange={onChange}
        />
    )
}

const EndTimePicker = ({payload, setPayload}: any) => {
    const [value, setValue] = useState<any>(new Date());
    const onChange = (e: any) => {
        const value = e.target.value
        setPayload({...payload, metadata: {...(payload.metadata || {}), end_time: value}})
    }
    return (
        <Input 
            type="time" 
            width='100%' 
            textIndent='4px'
            onChange={onChange}
        />
    )
}

type DateTimePicker = {
    paylaod: any;
    setPayload: any;
    startTitle?: string;
    endTitle?: string;
    noTitle?: boolean;
}

const DateTimePicker = ({payload, setPayload, startTitle, endTitle, noTitle}: any) => {
    return (
        <View>
            <View display='inline-flex' width='100%' gap='12px'>
                <View width='50%'>
                    <View display='flex' flexDirection='column' gap='4px'>
                        {!noTitle && <Text>{startTitle || 'Start Date & Time'}</Text>}
                        <StartDatePicker payload={payload} setPayload={setPayload}/>
                        <StartTimePicker payload={payload} setPayload={setPayload}/>
                    </View>
                </View>
                <View width='50%'>
                    <View display='flex' flexDirection='column' gap='4px'>
                        {!noTitle && <Text>{endTitle || 'End Date & Time'}</Text>}
                        <EndDatePicker payload={payload} setPayload={setPayload}/>
                        <EndTimePicker payload={payload} setPayload={setPayload}/>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default DateTimePicker