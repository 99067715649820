import { useState } from "react";
import { ReactComponent as ChevronDown } from '../../../img/chevronDown.svg';
import { ReactComponent as ChevronUp } from '../../../img/ch.svg';
import { StyledFilterItem, StyledFilterItemContainer } from "./styled"
import { Button } from "../../../components/Button";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";


const FilterItem = ({name, children}: any) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true)
    return (
        <StyledFilterItemContainer isExpanded={isExpanded}>
            <Button noStyle width="100%" onClick={() => setIsExpanded(!isExpanded)} textAlign="left">
                <View width="80%">
                    <Text fontWeight="500" fontSize="16px">{name}</Text>
                </View>
                <View width="20%" textAlign="right">
                    <Text width="100%"><ChevronDown /></Text>
                </View>
            </Button>
            <StyledFilterItem isExpanded={isExpanded}>
                {children}
            </StyledFilterItem>
        </StyledFilterItemContainer>
    )
}

export default FilterItem