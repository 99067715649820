import { useEffect } from "react"
import { EmptyPosts } from "../../components/EmptyPosts"
import { Post } from "../../components/Post"
import { Text } from "../../components/Text"
import { LI, UL } from "../../styled"
import { StyledSubscribers, StyledSubscribersContainer } from "./styled"
import { useMySubscribersStore } from "../../stores/web.store"
import { StyledAllPosts, StyledAllPostsContainer } from "../AllPosts/styled"
import { View } from "../../components/View"
import {ReactComponent as PYSettingIcon} from '../../img/PYSetting.svg'


const Subscribers = ({data}: any) => {
    const mySubscribers = useMySubscribersStore((state: any) => state.mySubscribers) 
    const setMySubscribers = useMySubscribersStore((state: any) => state.setMySubscribers) 

    useEffect(() => {
        setMySubscribers(data.filter((dataPost: any) => dataPost.privacy_settings && dataPost.privacy_settings.audience === 'subscribers'))
    }, [data])

    if (mySubscribers.length  === 0) return (
        <EmptyPosts type="subscribers" title="No subscriber posts yet" override>
            <View padding="0px 0px 24px 0px">
                <View display="flex" flexDirection="column" gap="12px" textAlign="center" width="100%">
                    <View width="100%">
                        <PYSettingIcon />
                    </View>
                    <View>
                        <Text color="#808080" width="100%" textAlign="center" fontSize="24px">{"No subscribers"}</Text>
                    </View>
                    <View>
                        <Text color="#808080" width="100%" textAlign="center">{"You don’t have any active subscribers."}</Text>
                    </View>
                </View>
            </View>
        </EmptyPosts>
    )
    
    return (
        <StyledAllPostsContainer>
            <StyledAllPosts>
                {mySubscribers.map((obj: any) => {
                    return (
                        <Post data={obj} />
                    )
                })}
            </StyledAllPosts>
        </StyledAllPostsContainer>
    )
}

export default Subscribers