import styled from "styled-components";


export const StyledHeroContainer = styled.div`
    background-image: url("/img/landing/ArtistsShareArtwork.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    width: 100%;

    @media (min-width: 768px) {
        height: 678px;
    }
    
    @media (min-width: 1600px) {
    }

`