import styled from 'styled-components'


interface CreatePostContentContainerProps {

}


export const StyledCreatePostContentContainer  = styled.div<CreatePostContentContainerProps>`

`

interface CreatePostContentProps {

}

export const StyledCreatePostContent = styled.div<CreatePostContentProps>`

`

interface UserInfoContainerProps {

}

export const StyledUserInfoContainer = styled.div<UserInfoContainerProps>`
    display: inline-flex;
    width: 100%;
    gap: 12px;
`

interface UserInfoProps {

}

export const StyledUserInfo = styled.div<UserInfoProps>`
    
`


