import styled from 'styled-components'


interface CreateArtworkPostContainerProps {

}


export const StyledCreateArtworkPostContainer  = styled.div<CreateArtworkPostContainerProps>`
    padding: 4px;

    @media (min-width: 768px) {
        padding: 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface CreateArtworkPost {

}

export const StyledCreateArtworkPost = styled.div<CreateArtworkPost>`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;

    @media (min-width: 768px) {
        grid-template-columns: 30% 70%;
        grid-gap: 20px;
    }

    @media (min-width: 1024px) {

    }
`
interface PreviewSectionContainerProps {

}

export const StyledPreviewSectionContainer = styled.div<PreviewSectionContainerProps>`
    background: #F1F2F6;
    height: 100%;
    width: 100%;
    postion: relative;
    
    
`

interface PreviewSectionProps {

}

export const StyledPreviewSection = styled.div<PreviewSectionProps>`
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    
    
`

interface PreviewContainerProps {

}

export const StyledPreviewContainer = styled.div<PreviewContainerProps>`
    background: #fff;
    width: 100%;
    border-radius: 8px;

    -webkit-box-shadow: 1px 3px 5px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 3px 5px -4px rgba(0,0,0,0.75);
    box-shadow: 1px 3px 5px -4px rgba(0,0,0,0.75);
`

interface PreviewProps {

}

export const StyledPreview = styled.div<PreviewProps>`
    
`
