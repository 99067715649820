import axios, { AxiosRequestConfig } from 'axios'
import { auth } from './firebase.service'

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_PRIMARY_YELLOW_API,
    headers: {
        'Content-type': 'application/json',
    },
})

axiosClient.interceptors.request.use(
    async function(config) {
        if (auth.currentUser) {
            const accesToken = await auth.currentUser.getIdToken(false)
            config.headers.Authorization = `Bearer ${accesToken}`
        } else {
            config.cancelToken = undefined
        }
        return config
    },
    function(error) {
        console.log(error)
        return error
    }
)

interface RetryConfig extends AxiosRequestConfig {
    retry: number;
    retryDelay: number;
  }

const axiosConfig: RetryConfig = {
    retry: 1,
    retryDelay: 0,
  };


const api = {
    get: async (endpoint: string) => {
        const resp = await axiosClient.get(endpoint, axiosConfig)
        return resp.data
    },
    post: async (endpoint: string, payload: any, config?: any) => {
        const resp = await axiosClient.post(endpoint, payload, config)
        return resp.data
    },
    put: async (endpoint: string, payload: any, config?: any) => {
        const resp = await axiosClient.put(endpoint, payload, config)
        return resp.data
    },
    delete: async (endpoint: string) => {
        const resp = await axiosClient.delete(endpoint)
        return resp.data
    }
}

export default api