import { Button } from "../../../../components/Button";
import { Text } from "../../../../components/Text";
import {ReactComponent as PlusIcon} from "../../../../img/plus.svg"
import {ReactComponent as MinusIcon} from "../../../../img/minus.svg"
import { StyledChild, StyledChildContainer, StyledChildContent, StyledPostAvailabilityItem, StyledPostAvailabilityItemContainer } from "./styled";
import { useState } from "react";


type PostAvailabilityItemProps = {
    title: string;
    children?: any;
    label?: string;
}

const PostAvailabilityItem = ({title, label, children}: PostAvailabilityItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const onClick = () => {
        setIsExpanded(!isExpanded)
    }
    
    return (
        <StyledPostAvailabilityItemContainer>
            <Button onClick={onClick} noStyle>
                <StyledPostAvailabilityItem>
                    {isExpanded ? <MinusIcon width={20} height={20}/> : <PlusIcon width={20} height={20}/>}
                    <Text color={isExpanded ? "#6b6b6b" : "#0938F0"} fontWeight="500">{title}</Text>
                </StyledPostAvailabilityItem>
            </Button>
            {isExpanded && <StyledChildContainer>
                <StyledChild>
                    <Text fontSize="10px" color="#0938F0" fontWeight="500">{label || title}</Text>
                    <StyledChildContent>
                        {children}
                    </StyledChildContent>
                </StyledChild>
            </StyledChildContainer>}
        </StyledPostAvailabilityItemContainer>
    )
}

export default PostAvailabilityItem