import styled from "styled-components";

const tileHover = `
  transform: scale(1.05);
  transition: transform 0.3s ease;
`;

interface BoardProps {
    height: number;
}

export const StyledBoard = styled.div<BoardProps>`
    break-inside: avoid; margin-bottom: 10px;

    &:hover {
        ${tileHover}
    }
`