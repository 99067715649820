import { MediaUpload } from "../../../components/MediaUpload"
import { uploadPost } from "../../../hooks/api.hook"
import { useDashboardCreateModeStore, useMyArtworksStore, usePostPayloadStore, usePostStore, useResponsiveStore, useSelectedPostStore, useUploadStore } from "../../../stores/web.store"
import { PostActions } from "../PostActions"
import { CreateArtworkPostContent } from "./CreateArtworkPostContent"
import { StyledCreateArtworkPost, StyledCreateArtworkPostContainer, StyledPreview, StyledPreviewContainer, StyledPreviewSection, StyledPreviewSectionContainer } from "./styled"
import { Text } from "../../../components/Text"
import { StyledPostTitleContainer } from "../styled"
import { Preview } from "../../../components/Artwork/Preview"
import { View } from "../../../components/View"
import { useNavigate } from "react-router-dom"


const CreateArtworkPost = () => {
    const navigate = useNavigate()
    const setIsUploading = useUploadStore((state: any) => state.setIsUploading)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    
    const myArtworks = useMyArtworksStore((state: any) => state.myArtworks) 
    const setMyArtworks = useMyArtworksStore((state: any) => state.setMyArtworks)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)
    const width = useResponsiveStore((state: any) => state.width)

    const setPost = usePostStore((state: any) => state.setPost)

    const onClickCancel = () => {
        setIsCreateMode(false)
        setSelectedPost(null)
    }

    const onClickSaveDraft = async () => {
        setIsUploading(true)

        const postData = {
            ...postPayload, 
            metadata: JSON.stringify(postPayload.metadata || {}),
            status: 'draft',
            type: 'artwork'
        }

        const resp = await uploadPost(postData)
        setIsUploading(false)
        setMyArtworks([resp.data, ...myArtworks])
        setIsCreateMode(false)
        setSelectedPost(null)
    }

    const onClickSharePost = async () => {
        setIsUploading(true)
        navigate('/')

        const postData = {
            ...postPayload, 
            metadata: JSON.stringify(postPayload.metadata || {}),
            status: 'active',
            type: 'artwork'
        }
        setPost(postData)

        const resp = await uploadPost(postData)
        
        setMyArtworks([resp.data, ...myArtworks])
        setIsCreateMode(false)
        setSelectedPost(null)
        setPost(null)
        setIsUploading(false)
    }
    return (
        <StyledCreateArtworkPostContainer>
            <StyledPostTitleContainer>
                <Text fontSize={width > 768 ? "24px" : '20px'}>Post Details</Text>
            </StyledPostTitleContainer>
            <StyledCreateArtworkPost>
                <View display="flex" flexDirection="column" gap="12px">
                    <PostActions onClickSaveDraft={onClickSaveDraft} onClickSharePost={onClickSharePost} onClickCancel={onClickCancel}/>
                    <MediaUpload setPayload={setPostPayload} payload={postPayload} height="300px"/>
                    <CreateArtworkPostContent />
                </View>
                <StyledPreviewSectionContainer>
                    <StyledPreviewSection>
                        <StyledPreviewContainer>
                            <StyledPreview>
                                <Preview />
                            </StyledPreview>
                        </StyledPreviewContainer>
                    </StyledPreviewSection>
                </StyledPreviewSectionContainer>
            </StyledCreateArtworkPost>
        </StyledCreateArtworkPostContainer>
    )
}

export default CreateArtworkPost