import { FooterAd } from "./FooterAd"
import { FooterBottom } from "./FooterBottom"
import { FooterContent } from "./FooterContent"
import { StyledFooter, StyledFooterContainer } from "./styled"


const Footer = ({width, margin, isExpanded}: any) => {
    return (
        <StyledFooterContainer width={width} margin={margin} isExpanded={isExpanded}>
            <StyledFooter>
                {/* <FooterAd /> */}
                <FooterContent />
                <FooterBottom />
            </StyledFooter>
        </StyledFooterContainer>
    )
}

export default Footer