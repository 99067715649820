import styled from 'styled-components'


interface FooterContainerProps {
    width?: string;
    margin?: string;
    isExpanded?: boolean;
}


export const StyledFooterContainer = styled.div<FooterContainerProps>`
    border-top: solid 1px #D7D7D7;

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`

interface FooterProps {

}


export const StyledFooter = styled.div<FooterProps>`

`