import { QuickFilter } from "../QuickFilter"

type ArtworkProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const Artwork = ({selectedFilter, setSelectedFilter}: ArtworkProps) => {
    const onClick = () => {
        setSelectedFilter('artwork')
    }

    return (
        <QuickFilter title="Artwork" onClick={onClick} isSelected={selectedFilter === 'artwork'}/>
    )
}

export default Artwork