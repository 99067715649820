import {useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import { ReactComponent as UploadMedia } from '../../img/uploadMedia.svg';
import { StyledMediaUploadContainer, StyledMediaWrapper, StyledRemoveImageContainer, StyledUploadButtonContainer, StyledUploadContainer, StyledUploadTitleContainer, StyledUploadedMedia, StyledUploadedMediaContainer, StyledUploadedPreview, StyledUploadedPreviewContainer } from './styled'
import { Text } from '../Text';
import { Button } from '../Button';
import { Image } from '../Image';
import {ReactComponent as CloseIcon} from '../../img/close.svg'
import { useThemeStore } from '../../stores/web.store';
import { Video } from '../Video';

export const UploadedPreview = ({file, media, setMedia, height, width}: any) => {
    const onClick = () => {
        setMedia(media.filter((mf: any) => mf.file.name !== file.file.name))
    }

    return (
        <StyledUploadedPreviewContainer height={height} width={width}>
            <StyledUploadedPreview>
                {file.file?.type.includes('video') ? 
                    <Video src={file.url} width={"100%"} height={"inherit"} objectFit={'cover'} borderRadius='12px' border='solid 1px #7C736B'/> : 
                    <Image src={file.url} width={"100%"} height={"inherit"} objectFit={'cover'} borderRadius='12px' border='solid 1px #7C736B'/>
                }
            </StyledUploadedPreview>
            {/* {setMedia && <StyledRemoveImageContainer>
                <Button noStyle>
                    <CloseIcon fill="#fff" width={20} height={20} onClick={onClick}/>
                </Button>
            </StyledRemoveImageContainer>} */}
        </StyledUploadedPreviewContainer>
    )
}


const MediaUpload = ({payload, setPayload, height}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const [media, setMedia] = useState<any>([])

    useEffect(() => {
        setPayload({...payload, media: media})
    }, [media])

    useEffect(() => {
        if (payload.media === undefined) {
            setMedia([])
        }
    }, [payload])

    const onDrop = useCallback((acceptedFiles: any) => {
        const mediaData = [...media, ...acceptedFiles.map((file: any) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {}
            reader.readAsArrayBuffer(file)
            return {url: URL.createObjectURL(file), file: file}
        })]
        setMedia(mediaData)
        setPayload({...payload, media: mediaData.map((md: any) => {return md.file})})
    }, [media, payload])
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <StyledMediaWrapper >
            <StyledMediaUploadContainer {...getRootProps()} height={height}>
                <StyledUploadContainer>
                    <UploadMedia height={120} width={120} />
                </StyledUploadContainer>
                <StyledUploadTitleContainer>
                    <input {...getInputProps()} />
                
                    {
                    isDragActive ?
                        <Text>Drop the files here ...</Text> :
                        <Text fontSize='16px' fontWeight='500'>Drag and Drop Media (max 12mb)</Text>
                    }
                    <StyledUploadButtonContainer>
                        <Text width='100%' textAlign='center'>or</Text>
                        <Button noStyle color={theme.button.background} padding='0' fontWeight='500'>Browse</Button>
                    </StyledUploadButtonContainer>
                </StyledUploadTitleContainer>
            </StyledMediaUploadContainer>
            <StyledUploadedMediaContainer>
                <StyledUploadedMedia total={media.length}>
                    {media.map((file: any, idx: number) => {
                        return (
                            <UploadedPreview key={`media-${idx}`} media={media} file={file} setMedia={setMedia}/>
                        )
                    })}
                </StyledUploadedMedia>
                <div />
            </StyledUploadedMediaContainer>
        </StyledMediaWrapper>
    )
}

export default MediaUpload