import { useState } from "react";
import { StyledSlider, StyledSpan, StyledSwitch, StyledSwitchContainer } from "./styled"
import Switch from "react-switch";


type SwitchProps = {
    onChange?: any;
    isChecked?: boolean;
    afterTitle?: string;
    beforeTitle?: string;
}

const PYSwitch = (props: SwitchProps) => {
   
    return (
        <StyledSwitchContainer>
            {props.beforeTitle && <StyledSpan isChecked={props.isChecked === false}>{props.beforeTitle}</StyledSpan>}
            <Switch 
                onChange={props.onChange}
                checked={props.isChecked || false}
                checkedIcon={false}
                uncheckedIcon={false}
                offColor={'#DEDEDE'}
                onColor={'#d1d1d1'}
                onHandleColor={'#2187FF'}
                offHandleColor={'#fffff'}
                height={20}
                width={40}
            />
            {props.afterTitle && <StyledSpan isChecked={props.isChecked === true}>{props.afterTitle}</StyledSpan>}
        </StyledSwitchContainer>
    )
}

export default PYSwitch