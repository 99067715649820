import styled from 'styled-components'


interface SettingContainerProps {

}

export const StyledSettingContainer = styled.div<SettingContainerProps>`

`

interface SettingProps {

}

export const StyledSetting = styled.div<SettingProps>`

`
