import { useEffect, useState } from "react"
import { Filters } from "../../components/Filters"
import { Post } from "../../components/Post"
import { SearchBar } from "../../components/SearchBar"
import { Sort } from "../../components/Sort"
import { Text } from "../../components/Text"
import { GroupAuction } from "./GroupAuction"
import { StyledAuctions, StyledAuctionsContainer, StyledAuctionsFiltersContainer, StyledSearchAndSort } from "./styled"
import { useAccountStore, useMyAuctionsStore, usePostStore, useProfileStore } from "../../stores/web.store"
import { useMyAuctions } from "../../hooks/api.hook"
import { EmptyPosts } from "../../components/EmptyPosts"
import { LI, UL } from "../../styled"
import { StyledAllPosts, StyledAllPostsContainer } from "../AllPosts/styled"


const AuctionFilters = () => {
    return (
        <StyledAuctionsFiltersContainer>
            <Filters rarity priceRange medium allFilters/>
            <StyledSearchAndSort>
                <SearchBar />
                <Sort />
            </StyledSearchAndSort>
        </StyledAuctionsFiltersContainer>
    )
}


const Auctions = ({data}: any) => {
    // const [post, setPost] = useState(null)
    const setPost = usePostStore((state: any) => state.setPost)
    const account = useAccountStore((state: any) => state.account) 
    const profile = useProfileStore((state: any) => state.profile) 

    const myAuctions = useMyAuctionsStore((state: any) => state.myAuctions) 
    const setMyAuctions = useMyAuctionsStore((state: any) => state.setMyAuctions) 

    useEffect(() => {
        setMyAuctions(data.filter((dataPost: any) => dataPost.type === 'auction'))
    }, [data])

    if (myAuctions.length === 0 && account.id === profile.id) return (
        <EmptyPosts type="auction" title="No auctions yet">
            <Text width="100%" textAlign="center" fontWeight="600" fontSize="16px">{'Create your first auction post'}</Text>
        </EmptyPosts>
    )

    return (
        <StyledAllPostsContainer>
            <StyledAllPosts>
                {myAuctions.map((obj: any) => {
                    return (
                        <Post data={obj} noIcon setPost={setPost}/>
                    )
                })}
            </StyledAllPosts>
        </StyledAllPostsContainer>
    )
}

export default Auctions