import { useThemeStore } from "../../../stores/web.store"
import Button from "../Button"

const Bid = ({setIsModal, isModal, auctionState}: any) => {
    const theme = useThemeStore((state: any) => state.theme)

    const onClick = () => {
        setIsModal(!isModal)
    }

    return (
        <Button 
            width="100%" 
            height="48px" 
            borderRadius="48px" 
            fontWeight="500"
            fontSize="16px"
            color={theme.button.secondaryColor}
            onClick={onClick}
            disabled={auctionState !== 'active'}
        >Place Bid</Button>
    )
}

export default Bid