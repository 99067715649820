import styled from 'styled-components'


interface SettingsContainerProps {

}

export const StyledSettingsContainer = styled.div<SettingsContainerProps>`

`

interface SettingsProps {

}

export const StyledSettings = styled.div<SettingsProps>`
    display: inline-flex;
    width: 100%;
    height: 100%;
`

interface PanelContainerProps {

}

export const StyledPanelContainer = styled.div<PanelContainerProps>`
    border-right: solid 1px #D7D7D7;
    width: 30%;
    padding: 24px;
    height: 100vh;
    overflow-y: scroll;
`

interface SettingsContentProps {

}

export const StyledSettingsContent = styled.div<SettingsContentProps>`
    width: 70%;
    padding: 24px 48px;
    height: 100vh;
    overflow-y: scroll;
`

