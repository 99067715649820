import { useState } from "react";
import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { ReactComponent as ChevronDownIcon } from '../../../img/chevronDown.svg';
import { StyledHighAuctionRecord, StyledHighAuctionRecordContainer, StyledHighAuctionRecordContentContainer } from "./styled"


const HighAuctionRecord = () => {
    const [isExpanded, setIsExpanded] = useState(false)
    const data = {
        high: 0 
    }

    return (
        <StyledHighAuctionRecordContainer>
            <StyledHighAuctionRecord>
                <Button onClick={() => setIsExpanded(!isExpanded)} noStyle width="100%">
                    <Text fontWeight="500" width="90%" fontSize="13px" lineHeight="20px" textAlign="left">{`High Auction Record (${data.high})`}</Text>
                    <Text textAlign="right" width="100%"><ChevronDownIcon height={'20px'} width={'20px'}/></Text>
                </Button>
            </StyledHighAuctionRecord>
            <StyledHighAuctionRecordContentContainer isExpanded={isExpanded}>
                <StyledHighAuctionRecord>
                    Coming soon
                </StyledHighAuctionRecord>
            </StyledHighAuctionRecordContentContainer>
        </StyledHighAuctionRecordContainer>
    )
}

export default HighAuctionRecord