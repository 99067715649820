import styled from 'styled-components'


interface ShippingAndFeesContainerProps {

}


export const StyledShippingAndFeesContainer  = styled.div<ShippingAndFeesContainerProps>`
    overflow: hidden;
`

interface ShippingAndFeesProps {
    isExpanded?: boolean;
}

export const StyledShippingAndFees = styled.div<ShippingAndFeesProps>`
    height: 0;
    transition: all 0.5s ease-out;
    ${props => props.isExpanded && `
        height: fit-content;
    `}
`





