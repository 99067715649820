import { $getRoot } from "lexical"
import { Markdown } from "../../../../components/MarkDown"
import { useAccountStore, useAllMyPostsStore, useDashboardCreateModeStore, usePostPayloadStore, usePostStore, useSelectedPostStore, useUploadStore } from "../../../../stores/web.store"
import { PostPrivacySettings } from "../../PostPrivacySettings"
import { UserInfo } from "../../../../components/UserInfo"
import { StyledCreatePostContent, StyledCreatePostContentContainer } from "./styled"
import { Location } from "../../../../components/Location"
import { View } from "../../../../components/View"
import { Post } from "../../../../components/Button/Post"
import { useState } from "react"
import { uploadPost } from "../../../../hooks/api.hook"
import { useNavigate } from "react-router-dom"


const CreateMediaPostContent = ({editorStateRef}: any) => {
    const navigate = useNavigate()
    const setIsUploading = useUploadStore((state: any) => state.setIsUploading)
    const account = useAccountStore((state: any) => state.account)
    const postPayload = usePostPayloadStore((state: any) => state.postPayload)
    const setPostPayload = usePostPayloadStore((state: any) => state.setPostPayload)

    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const allMyPosts = useAllMyPostsStore((state: any) => state.allMyPosts) 
    const setAllMyPosts = useAllMyPostsStore((state: any) => state.setAllMyPosts)
    const setPost = usePostStore((state: any) => state.setPost)

    const onClickSharePost = async () => {
        setIsUploading(true)
        navigate('/')
        const postData = {
            ...postPayload, 
            metadata: JSON.stringify(postPayload.metadata || {}),
            status: 'active',
            type: 'media'
        }

        setPost(postData)

        const resp = await uploadPost(postData)
        setAllMyPosts([resp.data, ...allMyPosts])
        setIsCreateMode(false)
        setSelectedPost(null)
        setPost(null)
        setIsUploading(false)
    }


    const onChangeEditorState = (editorState: any) => {
        editorStateRef.current = editorState
        setPostPayload({...postPayload, 
            caption: editorState.read(() => $getRoot().getTextContent()),
            lexical_caption: JSON.stringify(editorStateRef.current)
        })

    }

    return (
        <StyledCreatePostContentContainer>
            <StyledCreatePostContent>
                <View display="inline-flex" alignItems="center" width="100%" padding="0px 0px 6px 0px">
                    <View width="50%">
                        <UserInfo account={account}/>
                    </View>
                    <View width="50%" textAlign="right">
                        <Post onClick={onClickSharePost} isDisabled={false}/>
                    </View>
                </View>
                <div style={{borderBottom: 'solid 1px lightgrey', padding: "0px 0px 20px 0px"}}>
                    <Markdown editorStateRef={editorStateRef} data={null} onChange={onChangeEditorState} emoji={{padding: '0px 8px'}}/>
                </div>
                <div style={{padding: '12px 0px', borderBottom: 'solid 1px lightgrey'}}>
                    <Location payload={postPayload} setPayload={setPostPayload}/>
                </div>
                <div style={{padding: '12px 0px', borderBottom: 'solid 1px lightgrey'}}>
                    <PostPrivacySettings payload={postPayload} setPayload={setPostPayload}/>
                </div>
            </StyledCreatePostContent>
        </StyledCreatePostContentContainer>
    )
}

export default CreateMediaPostContent