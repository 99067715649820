import styled from 'styled-components'


interface SearchBarContainerProps {
    isFocus?: boolean;
    width?: string;
}


export const StyledSearchBarContainer = styled.div<SearchBarContainerProps>`
    position: relative;
    width: ${props => props.width || '100%'} !important;
    transition: all .25s ease-out;
    width: 100%;
`

interface SearchBarProps {
    textIndent?: string;
    borderRadius?: string;
    background?: string;
    color?: string;
    border?: string;
    width?: string;
}


export const StyledSearchBar = styled.input<SearchBarProps>`
    width: inherit;
    padding: 8px 4px;
    background: ${props => props.background || '#fff'};
    color: ${props => props.color || '#000'};
    border: ${props => props.border || "solid 1px #808080"};
    font-size: 16px;
    border-radius: ${props => props.borderRadius || '24px'};
    text-indent: ${props => props.textIndent || '40px'};
    &::placeholder {
        color: #808080;
        font-size: 16px;
    }
`

interface SearchBarIconProps {

}


export const StyledSearchBarIcon = styled.div<SearchBarIconProps>`
    position: absolute;
    top: 6px;
    left: 12px;
`

interface AILensIconProps {

}


export const StyledAILensIcon = styled.div<AILensIconProps>`
    position: absolute;
    top: 8px;
    right: 8px;
`

interface SearchBarExitIconProps {

}


export const StyledSearchBarExitIcon = styled.div<SearchBarExitIconProps>`
    position: absolute;
    top: 8px;
    right: 8px;
`

