import styled from 'styled-components'




export const StyledInputContainer = styled.div`
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    padding: 10px;
`


