import styled from "styled-components";

interface QuickFiltersProps {
    width?: string;
}


export const StyledQuickFilters = styled.div<QuickFiltersProps>`
    display: inline-flex;
    gap: 10px;
    width: ${props => props.width || 'auto'};
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0px 12px 0px 0px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar { 
        display: none;
    }
`