import { useEffect, useState } from "react"
import { EmptyPosts } from "../../components/EmptyPosts"
import { Post } from "../../components/Post"
import { Text } from "../../components/Text"
import { LI, OL, UL } from "../../styled"
import { CurrentShow } from "./CurrentShow"
import { StyledShowsContainer, StyledShows } from "./styled"
import { useAccountStore, useMyShowsStore, usePostStore, useProductDetailPageStore, useProfileStore } from "../../stores/web.store"
import { useMyShows } from "../../hooks/api.hook"
import { StyledAllPosts, StyledAllPostsContainer } from "../AllPosts/styled"


const Shows = ({data}: any) => {
    // const [post, setPost] = useState(null)
    const account = useAccountStore((state: any) => state.account) 
    const profile = useProfileStore((state: any) => state.profile)

    const myShows = useMyShowsStore((state: any) => state.myShows) 
    const setMyShows = useMyShowsStore((state: any) => state.setMyShows) 

    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)

    const onClick = (obj: any) => {
        setPost(obj)
        setPdp('show')
    }

    useEffect(() => {
        setMyShows(data.filter((dataPost: any) => dataPost.type === 'show'))
    }, [data])

    if (myShows.length === 0 && account.id === profile.id) return (
        <EmptyPosts type="show" title="No shows yet">
            <UL fontSize="16px">
                <LI padding="0px 0px 12px 0px">{'Create a show or curate and invite other users on the platform to collaborate on a group show.'}</LI>
                <LI>{'Produce the show independently or direct users to your representative gallery.'}</LI>
                <LI>{'Catalog past and forthcoming shows.'}</LI>
                <LI>{'Share the link to the show across platforms.'}</LI>
            </UL>
            <Text 
                textAlign="center" 
                width="100%"
                color="#6b6b6b"
                fontWeight="600"
                fontSize="16px"
            >{'AR/VR capabilities will be available in the summer with the Apple Vision Pro.'}</Text>
        </EmptyPosts>
    )

    return (
        <StyledAllPostsContainer>
            <StyledAllPosts>
                {myShows.map((obj: any) => {
                    return (
                        <Post data={obj} onClick={() => onClick(obj)} setPost={setPost} noIcon/>
                    )
                })}
            </StyledAllPosts>
        </StyledAllPostsContainer>
    )
}

export default Shows