import { useEffect, useState } from "react";
import { View } from "../View";
import Skeleton from "react-loading-skeleton";
import api from "../../services/api.service";
import { Text } from "../Text";
import { Button } from "../Button";
import { toDate } from "../../utils/app.util";
import { useNavigate } from "react-router-dom";
import { Image } from "../Image";
import { useResponsiveStore } from "../../stores/web.store";

const EditorialPreview = ({editorial, activeArticle}: any) => {
    const navigate = useNavigate()
    const width = useResponsiveStore((state: any) => state.width)
    const onClick = () => {
        navigate(`/editorial/articles/${editorial.slug}`)
    }
    return (
        <Button noStyle textAlign="left" padding={activeArticle ? "0" : "12px"} width="100%" onClick={onClick}>
            <View 
                display="flex" 
                width="100%" 
                flexDirection={activeArticle ? "row-reverse" : width > 768 ? "row" : "column-reverse"} 
                gap={activeArticle ? "24px" :  width > 768 ? "60px" : "24px"}
            >
                <View width={activeArticle ? "unset" : width > 768 ? "60%" : "100%"} flexGrow={activeArticle ? "1" : "unset"}>
                    <View display="flex" flexDirection="column" gap="4px" width="100%">
                        <Text fontSize={activeArticle ? "11px" : ".75rem"} color="grey" fontWeight="500">
                            {toDate(editorial.published_date)}
                        </Text>
                        <Text as="h1" fontSize={activeArticle ? "24px" : "2rem"} fontWeight="400" margin="0">
                            {editorial.title}
                        </Text>
                        <Text fontSize={activeArticle ? "14px" : "1.25rem"} color="grey">{editorial.author}</Text>
                    </View>
                </View>
                <View width={activeArticle ? "90px" : width > 768 ? "40%" : "100%"}>
                    <Image src={`${process.env.REACT_APP_PRIMARY_YELLOW_API}${editorial.hero_image}`} width={activeArticle ? "90px" : "100%"} height={activeArticle ? "90px" : "400px"} objectFit={activeArticle ? "cover" : "cover"} />
                </View>

            </View>
        
        </Button>
    )
}

const Editorials = ({article}: any) => {
    const [articles, setArticles] = useState<any>(null)
    const SKELETON_EDITORIALS = Array.from({length: 5}, (_, i) => i)

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get('/editorial/articles')
            setArticles(resp.data)
        }
        fetch()
    }, [])

    if (!articles) {
        return (
            <View display="flex" flexDirection="column" gap="24px" width="100%">
                {SKELETON_EDITORIALS.map((_, i) => (
                    <View key={i} width="100%" height="200px">
                        <Skeleton />
                    </View>
                ))}
            </View>
        )
    }

    return (
        <View padding={article ? "24px 0px" : "0"}>
            <View display="flex" flexDirection="column" gap="48px" width="100%">
                {articles.filter((art: any) => art.id !== article?.id).map((editorial: any) => (
                    <EditorialPreview key={`editorial-${editorial.id}`} editorial={editorial} activeArticle={article}/>
                ))}
            </View>
        </View>
    );
}

export default Editorials