import styled from 'styled-components'

interface FeaturedGroupContainerProps {

}


export const StyledFeaturedGroupContainer = styled.div<FeaturedGroupContainerProps>`
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    };
    &-ms-overflow-style: none;
    &scrollbar-width: none;
`

interface FeaturedGroupProps {
    columns: number;
    width?: string;
}


export const StyledFeaturedGroup = styled.div<FeaturedGroupProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    width: ${props => props.columns * 1}00%;
`