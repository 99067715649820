import styled from "styled-components";


interface SaveContainerProps {

}

export const StyledSaveContainer = styled.div<SaveContainerProps>`

`

interface SaveProps {

}

export const StyledSave= styled.div<SaveProps>`

`