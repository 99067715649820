import styled from 'styled-components'


interface TitleContainerProps {

}


export const StyledTitleContainer = styled.div<TitleContainerProps>`
    color: #fff;
    width: 90%;
    margin: 0px auto;

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
  
    }
`

interface TitleProps {

}


export const StyledTitle = styled.h1<TitleProps>`
    font-size: 36px;
    margin: 0;
    color: #fff;

    @media (min-width: 768px) {
        font-size: 48px;
    }

    @media (min-width: 1024px) {
        font-size: 60px;
    }

    @media (min-width: 1220px) {
        font-size: 72px;
    }
`

interface DescriptionContainerProps {

}


export const StyledDescriptionContainer = styled.div<DescriptionContainerProps>`
 
`

interface DescriptionProps {

}


export const StyledDescription = styled.h2<DescriptionProps>`
    margin: 12px 0px;
    font-size: 16px;

    @media (min-width: 768px) {
        font-size: 24px;
    }

    @media (min-width: 1024px) {
    }
`








