import { useAccountStore } from "../../stores/web.store";
import { toDateDiff } from "../../utils/app.util";
import { Button } from "../Button";
import { ProfilePicture } from "../ProfilePicture";
import { Text } from "../Text";
import { View } from "../View"
import { StyledMultiUsers, StyledPreviewText } from "./styled";

type PreviewProps = {
    data: any;
    onClick: any;
}

type ThreadAccountsProps = {
    data: any;
    onClick?: any;
}

const ThreadAccounts = ({data, onClick}: ThreadAccountsProps) => {
    const account = useAccountStore((state: any) => state.account)

    const recipients = data.recipients.filter((acct: any) => acct.id !== account.id)

    return (
        <View>
            <View display="inline-flex" alignItems="center" width="100%" gap="4px">
                <View>
                    <ProfilePicture account={recipients[0]} photo username onClick={() => onClick(data)}/>
                </View>
                {recipients.length > 1 && <StyledMultiUsers>
                    <Text fontSize="11px">+ {recipients.length - 1} others</Text>
                    {/* <View position="absolute" left="0" background="#fff">
                        <View padding="4px">
                            {recipients.slice(1).map((acct: any) => {
                               return (
                                    <ProfilePicture account={acct} photo username onClick={() => {}}/>
                               ) 
                            })}
                        </View>
                    </View> */}
                </StyledMultiUsers>}
            </View>
        </View>
    )
}

const PreviewMessage = ({data, onClick}: any) => {
    const TYPE_MAP ={
        direct_message: '',
        commission: '[Commission]',
        one_on_one: '[1:1]'
    } as any

    return (
        <View display="inline-flex" alignItems="center" gap="4px">
            <Text width="min-content" color="#0938F0" fontWeight="500" fontSize="13px">{TYPE_MAP[data.type]} </Text>
            <StyledPreviewText>{data.messages[data.messages.length - 1].message}</StyledPreviewText>
        </View>
    )
}

const Preview = ({data, onClick}: PreviewProps) => {
    const account = useAccountStore((state: any) => state.account)
    const unreadMessages = data.messages.filter((msg: any) => msg.state === 'unread' && msg.sender_id !== account.id)
    return (
        <View padding="6px 0px" id={data.id}>
            <Button noStyle onClick={() => onClick(data)} textAlign="left" width="100%" justifyContent="left">
                <View display="flex" flexDirection="column" gap="6px" width="100%">
                    <View>
                        <View display="inline-flex" alignItems="center" width="100%">
                            <View flexGrow="1">
                                <ThreadAccounts data={data} onClick={onClick}/>
                            </View>
                            <View textAlign="right" width="40px">
                                <Text width="100%">{toDateDiff(data.created_date)}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View display="inline-flex" alignItems="center" width="100%">
                            <View flexGrow="1">
                                {data.messages.length > 0 ? <PreviewMessage data={data} onClick={onClick}/> : <Text fontStyle="italic">Start a Conversation</Text>}
                            </View>
                            <View textAlign="right" width="40px">
                                {unreadMessages.length > 0 && <View width="100%">
                                    <View 
                                        width="20px" 
                                        height="20px" 
                                        background="#0938F0" 
                                        borderRadius="50%"
                                        color="#fff"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        float="right"
                                    >
                                        <Text fontSize="11px">{unreadMessages.length}</Text>
                                    </View>
                                </View>}
                            </View>
                        </View>
                        
                    </View>
                </View>
            </Button>

        </View>
    )
}

export default Preview