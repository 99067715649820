import {CardCvcElement, CardExpiryElement, CardNumberElement, PaymentElement, PaymentRequestButtonElement, useElements, useStripe} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { View } from '../../../View';
import { Button } from '../../../Button';
import { Checkbox } from '../../../Checkbox';
import { Text } from '../../../Text';
import { StyledInputContainer } from './styled';


const BillingAddress = () => {
    return (
        <View>
            <View>
                <View display="inline-flex" alignItems="center" gap="4px">
                    <View>
                        <Checkbox />
                    </View>
                    <View>
                        <Text color="#808080">{'Billing and shipping addresses are the same.'}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

const SaveCreditCard = () => {
    return (
        <View>
            <View>
                <View display="inline-flex" alignItems="center" gap="4px">
                    <View>
                        <Checkbox />
                    </View>
                    <View>
                        <Text color="#808080">{'Save credit card for later use.'}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}


const CheckoutForm = ({post, checkoutStep, setCheckoutStep}: any) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentRequest, setPaymentRequest] = useState<any | null>(null);

    const [errorMessage, setErrorMessage] = useState<any>(null);

    // useEffect(() => {
    //     if (stripe) {
    //       const pr = stripe.paymentRequest({
    //         country: "US",
    //         currency: "usd",
    //         total: {
    //           label: "Total",
    //           amount: post.metadata.price,  // Payment amount in cents
    //         },
    //         requestPayerName: true,
    //         requestPayerEmail: true,
    //       });
    
    //       // Check if Apple Pay or Google Pay is available
    //       pr.canMakePayment().then((result) => {
    //         if (result) {
    //           setPaymentRequest(pr);
    //         }
    //       });
    //     }
    //   }, [stripe]);

    const onClickSaveContinue = () => {
        setCheckoutStep(2)
    }

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000/checkout',
            },
        });


        if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
        } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    const appearance = {
        theme: 'flat',
        variables: {
          colorPrimary: '#0570de',
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorDanger: '#df1b41',
        },
        rules: {
          '.Label': {
            color: '#30313d',
          },
          '.InputContainer': {
            border: '1px solid #BFCBFB',
            borderRadius: '4px',
            padding: '10px',
            },
        },
      };
      
      const options: any = {
        appearance,
        showIcon: true
      };

    return (
        <form onSubmit={handleSubmit}>
            
            {/* <PaymentElement options={options}/> */}
            <View display='flex' flexDirection='column' gap='12px'>
                <View>
                    <StyledInputContainer>
                        <CardNumberElement options={options} />
                    </StyledInputContainer>
                </View>
                <View>
                    <View display='inline-flex' alignItems='center' width='100%' gap='8px'>
                        <View width='50%'>
                            <StyledInputContainer>
                                <CardExpiryElement options={options} />
                            </StyledInputContainer>
                        </View>
                        <View width='50%'>
                            <StyledInputContainer>
                                <CardCvcElement options={options} />
                            </StyledInputContainer>
                        </View>
                    </View>
                </View>
            </View>
            <View padding='24px 0px'>
                <View display='flex' flexDirection='column' gap='8px'>
                    <BillingAddress />
                    <SaveCreditCard />
                </View>
            </View>
            <View>
                <View>
                    <Button
                        border={`solid 1px ${'#BFCBFB'}`}
                        background={'#DDE3FD'}
                        color={'#0938F0'}
                        padding="8px 20px"
                        borderRadius="23px"
                        fontSize="16px"
                        width="max-content"
                        onClick={onClickSaveContinue}
                        noStyle
                    >{'Save and Continue'}</Button>
                </View>
            </View>
            {/* {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />} */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    );
};

export default CheckoutForm;
