import { useEffect, useState } from "react"
import { useFiltersStore } from "../../../stores/web.store"
import { Select } from "../../Select"
import { StyledRarity, StyledRarityContainer } from "./styled"


const Rarity = () => {
    const filterKey = 'metadata.rarity'
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [value, setValue] = useState<any>();

    const options = [
        {
            value: null,
            label: 'None'
        },
        {
            value: 'unique',
            label: 'Unique'
        },
        {
            value: 'limited_edition',
            label: 'Limited Edition'
        },
        {
            value: 'open_edition',
            label: 'Open Edition'
        },
        {
            value: 'unknown_edition',
            label: 'Unkown Edition'
        }

    ]

    const _setValue = (v: any) => {
        if (v === null) {
            setValue(null)
        } else {
            setValue(options.find((option: any) => (option.value === v || v?.includes(option.value))))
        }
        
    }

    useEffect(() => { 
        _setValue(filterStore.filters[filterKey])
    }, [filterStore])

    const onChange = (e: any) => {
        
        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: e.value
        }})
        _setValue(e.value)
    }
    return (
        <StyledRarityContainer>
            <StyledRarity>
                <Select 
                    placeholder="Rarity" 
                    options={options}
                    borderRadius="24px"
                    padding="0"
                    width="120px"
                    onChange={onChange}
                    value={value}
                />
            </StyledRarity>
        </StyledRarityContainer>
    )
}

export default Rarity