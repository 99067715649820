import { useAccountStore, useMeetingStore, useProductDetailPageStore } from "../../stores/web.store"
import { Button } from "../Button"
import { View } from "../View"
import {ReactComponent as ChevronLeftIcon} from '../../img/chevronLeft.svg'
import { Text } from "../Text"
import { StyledMainContent, StyledMainContentContainer } from "../ProductDetail/Artwork/styled"
import { useEffect, useState } from "react"
import { Zoom } from "../Services/Zoom"
import { Input } from "../Input"
import api from "../../services/api.service"


const Meeting = () => {
    const account = useAccountStore((state: any) => state.account)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setMeeting = useMeetingStore((state: any) => state.setMeeting)
    const meeting = useMeetingStore((state: any) => state.meeting)

    const onClickBack = () => {
        setMeeting(null)
        setPdp(null)
    }

    const onChangePasscode = (e: any) => {
        setMeeting({...meeting, passcode: e.target.value})
    }

    return (
        <View padding="24px">
            <View>
                <Button noStyle onClick={onClickBack}>
                    <ChevronLeftIcon height={16} width={16}/> 
                    <Text fontWeight="500">{`Back`}</Text>
                </Button>
            </View>
            <View>
                <View>
                    <View 
                        justifyContent='center' 
                        width='100%' 
                        display='flex' 
                        gap='24px' 
                        height='90vh' 
                        alignItems="center"
                        flexDirection="column"
                    >
                        <View>
                            <Text fontWeight="500" fontSize="32px">{meeting.topic}</Text>
                        </View>
                        {meeting?.state === 'pending' && account.id === meeting.owner_id && <View>
                            <Input 
                                fontSize="16px"
                                placeholder="Meeting Passcode" 
                                width="100%" 
                                onChange={() => {}} 
                            />
                        </View>}
                        <View>
                            <Zoom />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Meeting