import { useResponsiveStore, useSignupCompleteStore } from "../../../stores/web.store"
import { Bold, StyledInlineContainer } from "../../../styled"
import { Desktop, Laptop } from "../../../utils/responsive.util"
import { Text } from "../../Text"
import { StyledSignupComplete, StyledSignupCompleteContainer, StyledThankYou, StyledWelcome } from "./styled"


const ThankYou = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    return (
        <StyledThankYou>
            <Text 
                fontSize={[Desktop, Laptop].includes(mode) ? '20px' : '16px'} 
                fontWeight={'400'}
                color="#fff"
            >
                Thank you for signing up. We’ll keep you in the loop when our private beta is ready to launch and you can start exploring.
            </Text>
        </StyledThankYou>
    )
}

const Welcome = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupComplete = useSignupCompleteStore((state: any) => state.signupComplete)


    return (
        <StyledWelcome>
            <Text 
                fontSize={[Desktop, Laptop].includes(mode) ? '32px' : '24px'} 
                fontWeight={'400'}
                textAlign={'left'}
                color="#fff"
            >
                Welcome to Primary Yellow, {<Bold>{signupComplete.first_name}</Bold>}.
            </Text>
        </StyledWelcome>
    )
}


const SignupComplete = () => {
    return (
        <StyledSignupCompleteContainer>
            <StyledSignupComplete>
                <Welcome />
                <ThankYou />
            </StyledSignupComplete>
        </StyledSignupCompleteContainer>
    )
}

export default SignupComplete