import Button from "../Button"


type DraftProps = {
    onClick: any;
    isDisabled: boolean;
}

const Draft = ({onClick, isDisabled}: DraftProps) => {
    return (
        <Button noStyle cursor={isDisabled ? 'not-allowed': ''} fontWeight="600" onClick={onClick} disabled={isDisabled} color={isDisabled ? "grey" : "inherit"}>Draft</Button>
    )
}

export default Draft