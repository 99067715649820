import styled from 'styled-components'

interface MuseumContainerProps {

}


export const StyledMuseumContainer  = styled.div<MuseumContainerProps>`

`

interface MuseumProps {

}

export const StyledMuseum = styled.div<MuseumProps>`

`


