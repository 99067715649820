import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { Auction as AuctionNode } from "../../../components/ProductDetail/Auction"


const Auction = () => {
    return (
        <DashboardLayout>
            <AuctionNode />
        </DashboardLayout>
    )
}

export default Auction