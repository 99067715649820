import styled from 'styled-components'


interface CreateShowArtworkContentContainerProps {

}


export const StyledCreateShowArtworkContentContainer  = styled.div<CreateShowArtworkContentContainerProps>`
`

interface CreateShowArtworkContentProps {

}

export const StyledCreateShowArtworkContent = styled.div<CreateShowArtworkContentProps>`
`

interface UploadArtworkProps {

}

export const StyledUploadArtwork = styled.div<UploadArtworkProps>`
`