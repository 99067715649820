import { QuickFilter } from "../QuickFilter"

type UpcomingAuctionsProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const UpcomingAuctions = ({selectedFilter, setSelectedFilter}: UpcomingAuctionsProps) => {
    const onClick = () => {
        setSelectedFilter('upcoming_auctions')
    }

    return (
        <QuickFilter title="Upcoming Auctions" onClick={onClick} isSelected={selectedFilter === 'upcoming_auctions'}/>
    )
}

export default UpcomingAuctions