import styled from 'styled-components'

interface CaptionContainerProps {

}


export const StyledCaptionContainer  = styled.div<CaptionContainerProps>`
`

interface CaptionProps {

}

export const StyledCaption = styled.div<CaptionProps>`
    display: inline-block;
    width: 100%;
`


