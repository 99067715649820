import styled from "styled-components";


interface ImpressionsContainerProps {

}

export const StyledImpressionsContainer = styled.div<ImpressionsContainerProps>`

`

interface ImpressionsProps {

}

export const StyledImpressions = styled.div<ImpressionsProps>`

`