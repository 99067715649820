import { useEffect, useState } from "react"
import { Feed } from "../../components/Feed"
import { StyledFollowing, StyledFollowingContainer } from "./styled"
import { useFollowingPostsStore } from "../../stores/web.store"
import { useFollowingPosts } from "../../hooks/api.hook"
import api from "../../services/api.service"
import InfiniteScroll from "react-infinite-scroll-component"


const Following = () => {
    const followingPosts = useFollowingPostsStore((state: any) => state.followingPosts) 
    const setFollowingPosts = useFollowingPostsStore((state: any) => state.setFollowingPosts) 

    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const {data} = useFollowingPosts()

    useEffect(() => {
        if (data && data.is_loaded) {
            setFollowingPosts(data.data)
        }
    }, [data])

    const fetchMoreData = async () => {
        const newOffset = offset + 1
        const resp = await api.post(`/search/following`, {query: {}, offset: followingPosts.length})

        setFollowingPosts([...followingPosts, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
    };
    
    if (data && !data.is_loaded) return <div />
    return (
        <StyledFollowingContainer>
            <StyledFollowing>
                <InfiniteScroll
                        dataLength={followingPosts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="FeedContainer"
                    >
                    <Feed posts={followingPosts} />
                </InfiniteScroll>
            </StyledFollowing>
        </StyledFollowingContainer>
    )
}

export default Following