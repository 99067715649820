import { QuickFilter } from "../QuickFilter"

type EndingSoonProps = {
    selectedFilter: any;
    setSelectedFilter: any;
}

const EndingSoon = ({selectedFilter, setSelectedFilter}: EndingSoonProps) => {
    const onClick = () => {
        setSelectedFilter('ending_soon')
    }

    return (
        <QuickFilter title="Ending Soon" onClick={onClick} isSelected={selectedFilter === 'ending_soon'}/>
    )
}

export default EndingSoon