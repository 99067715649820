import styled from 'styled-components'


interface LocationContainerProps {
    isExpanded?: boolean;
}


export const StyledLocationContainer  = styled.div<LocationContainerProps>`
    overflow: hidden;

    ${props => props.isExpanded && `
        & svg {
            transform: rotate(180deg);
        }
    `}
`

interface LocationProps {
    isExpanded?: boolean;
}

export const StyledLocation = styled.div<LocationProps>`
    height: 0;
    transition: all 0.5s ease-out;
    ${props => props.isExpanded && `
        height: fit-content;
    `}
`

interface SetAudienceProps {

}

export const StyledSetAudience = styled.div<SetAudienceProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding: 24px 0px;
`

interface SetAudienceButtonsProps {

}

export const StyledSetAudienceButtons = styled.div<SetAudienceButtonsProps>`
    width: 100%;
    display: inline-flex;
    gap: 12px;
    justify-content: right;
    
`

interface SwitchContainerProps {

}

export const StyledSwitchContainer = styled.div<SwitchContainerProps>`
    width: 100%;
    display: inline-flex;
    padding: 12px 0px;
`




