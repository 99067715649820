import { useState } from "react";
import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { ReactComponent as ChevronDownIcon } from '../../../img/chevronDown.svg';
import { StyledCriticallyAcclaimedContainer, StyledCriticallyAcclaimed, StyledCriticallyAcclaimedContentContainer, StyledCriticallyAcclaimedContent } from "./styled"


const CriticallyAcclaimed = () => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <StyledCriticallyAcclaimedContainer>
            <StyledCriticallyAcclaimed>
                <Button onClick={() => setIsExpanded(!isExpanded)} noStyle width="100%">
                    <Text fontWeight="500" width="90%" fontSize="13px" lineHeight={'20px'} textAlign="left">Critically Acclaimed</Text>
                    <Text textAlign="right" width="100%"><ChevronDownIcon height={'20px'} width={'20px'}/></Text>
                </Button>
            </StyledCriticallyAcclaimed>
            <StyledCriticallyAcclaimedContentContainer isExpanded={isExpanded}>
                <StyledCriticallyAcclaimedContent>
                    Coming soon
                </StyledCriticallyAcclaimedContent>
            </StyledCriticallyAcclaimedContentContainer>
        </StyledCriticallyAcclaimedContainer>
    )
}

export default CriticallyAcclaimed