import { Text } from '../../../../components/Text';
import { ReactComponent as Logo } from '../../../../img/logo.svg';
import { ReactComponent as Intagram } from '../../../../img/instagram.svg';
import { ReactComponent as TwitterX } from '../../../../img/twitterX.svg';
import { ReactComponent as TikTok } from '../../../../img/tikTok.svg';
import { ReactComponent as Pinterest } from '../../../../img/pinterest.svg';
import { StyledFooterBottom, StyledFooterBottomContainer, StyledFooterBottomLeft, StyledFooterBottomLeftContainer, StyledFooterBottomRight, StyledFooterBottomRightContainer } from "./styled"
import { Button } from '../../../../components/Button';
import { useAccessStore } from '../../../../stores/web.store';


const FooterBottom = () => {
    const access = useAccessStore((state: any) => state.access)
    const onClickSocial = (link: string) => {
        window.open(link, "_blank")
    }
    return (
        <StyledFooterBottomContainer access={access}>
            <StyledFooterBottom>
                <StyledFooterBottomLeftContainer>
                    <StyledFooterBottomLeft>
                        <Logo />
                        <Text color='#888888' textAlign='center'>{'© 2024 Primary Yellow'}</Text>
                        <Button color='#888888' noStyle>{'Terms of Use'}</Button>
                        <Button color='#888888' noStyle>{'Privacy Policy'}</Button>
                        <Button color='#888888' noStyle>{'Buyer Guarantee'}</Button>
                    </StyledFooterBottomLeft>
                </StyledFooterBottomLeftContainer>
                <StyledFooterBottomRightContainer>
                    <StyledFooterBottomRight>
                        <Button noStyle onClick={() => onClickSocial('https://www.instagram.com/primaryyellowapp/')}><Intagram /></Button>
                        <Button noStyle><TwitterX onClick={() => onClickSocial('https://twitter.com/primaryyellowx')}/></Button>
                        <Button noStyle><TikTok onClick={() => onClickSocial('http://tiktok.com/@primaryyellow')}/></Button>
                        <Button noStyle><Pinterest onClick={() => onClickSocial('http://pinterest.com/primaryyellowapp')}/></Button>
                    </StyledFooterBottomRight>
                </StyledFooterBottomRightContainer>
            </StyledFooterBottom>
        </StyledFooterBottomContainer>
    )
}

export default FooterBottom