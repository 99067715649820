import Button from "../Button"


type CancelProps = {
    onClick: any;
}

const Cancel = ({onClick}: CancelProps) => {
    return (
        <Button noStyle fontWeight="600" onClick={onClick}>Cancel</Button>
    )
}

export default Cancel