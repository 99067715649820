import { Image } from "../Image"
import { StyledArtistContainer, StyledArtist } from "./styled"
import { Text } from "../Text";
import { View } from "../View";
import { Follow } from "../Button/Follow";


type ArtistProps = {
    data?: any;
}


const Artist = ({data}: ArtistProps) => {
    return (
        <StyledArtistContainer>
            <StyledArtist>
                <Image src={data.media[0].url} width="100%" height="200px"/>
                <View padding="4px 0px 0px 0px">
                    <View display="inline-flex" alignItems="center" width="100%">
                        <View width="70%">
                            <Text fontWeight="600" padding="0px 0px 4px 0px">{data.owner.first_name}</Text>
                            <Text color="#6b6b6b" padding="0px 0px 4px 0px">{data.created_date}</Text>
                        </View>
                        <View width="30%" justifyContent="right" display="flex">
                            <Follow />
                        </View>
                    </View>
                </View>
            </StyledArtist>
        </StyledArtistContainer>
    )
}

export default Artist