import { Select } from "../Select"
import { ReactComponent as SortIcon } from '../../img/sort.svg';
import { StyledSort, StyledSortContainer } from "./styled"
import { Text } from "../Text";
import { useEffect, useRef, useState } from "react";


const Sort = () => {

    const sortRef = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState(0);

    useEffect(() => {
        if (sortRef.current) {
            const width = sortRef.current.offsetWidth;
            setLeft(-width);
        }
    }, []);

    const OPTIONS = [
        {
            label: "Recently Updated",
            value: "modified_date:desc"
        },
        {
            label: "Recently Added",
            value: "created_date:desc"
        },
        {
            label: "Artwork Year (Descending)",
            value: "date:desc"
        },
        {
            label: "Artwork Year (Ascending)",
            value: "date:asc"
        },
    ]

    const [options, setOptions] = useState(OPTIONS)
    const [value, setValue] = useState(OPTIONS[0])

    const onChange = (e: any) => {
        setValue(e)
    }
    
    return (
        <StyledSortContainer ref={sortRef}>
            <StyledSort left={left} >
                <SortIcon height={20} width={20}/>
                <Text fontSize="16px">Sort:</Text>
                <Select 
                    value={value} 
                    textIndent="4px"
                    options={options}
                    onChange={onChange}
                    border="none"
                    fontSize="16px"
                    padding="0"
                    cursor="pointer"
                    isSearchable={false}
                    width="320px"
                />
            </StyledSort>
        </StyledSortContainer>
    )
}

export default Sort