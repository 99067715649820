import styled from 'styled-components'


interface ModalContainerProps {
    position?: string;
    top?: string;
    left?: string;
}


export const StyledModalContainer = styled.div<ModalContainerProps>`
    background: ${'rgba(0, 0, 0, .4)'};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5;
`


interface ModalProps {
    width?: any;
    height?: any;
    margin?: any;
}


export const StyledModal = styled.div<ModalProps>`
    width: ${props => props.width || '100%'};
    display: flex;
    flex-direction: column;
    height: ${props => props.height || '100vh'};
    margin: ${props => props.margin || 'auto'};
    align-items: center;
    

    @media (min-width: 768px) {
        width: ${props => props.width || '50%'};
        margin: ${props => props.margin || 'auto'};
        
    }
    
    @media (min-width: 1600px) {
    }
`

interface ModalContentContainerProps {

}


export const StyledModalContentContainer = styled.div<ModalContentContainerProps>`
    height: 240px;
    width: 80%;
    margin: auto;
    position: relative;
    
    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`

interface ModalContentProps {
    height?: string;
    background?: string;
    padding?: string;
    borderRadius?: string;
}


export const StyledModalContent = styled.div<ModalContentProps>`
    border-radius: ${props => props.borderRadius || '0px'};
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    width: 100%;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        border-radius: ${props => props.borderRadius || '8px'};
        padding: ${props => props.padding || '12px'};
        height: ${props => props.height || '240px'};
    }

    @media (min-width: 1024px) {
    }
`

