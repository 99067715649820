import { Button } from "../../components/Button"
import { Follow } from "../../components/Button/Follow"
import { Image } from "../../components/Image"
import PhotoReel from "../../components/PhotoReel/PhotoReel"
import { Text } from "../../components/Text"
import { View } from "../../components/View"
import { StyledArtPatron, StyledArtPatronContainer } from "./styled"


const ArtPatron = ({data}: any) => {
    return (
        <StyledArtPatronContainer>
            <StyledArtPatron>
                <View width="10%">
                    Image
                </View>
                <View width="30%">
                    <View display="flex" flexDirection="column">
                        <View>
                            <Text>{`Daniel Kennedy`} Collection</Text>
                            <Text>Location</Text>
                            <Text>{`Collector`} {`167 Artworks`}</Text>
                        </View>
                        <View>
                            <View display="inline-flex" alignItems="center" width="100%">
                                <View width="fit-content">
                                    <Follow />
                                </View>
                                <Text flexGrow="1" textAlign="right">{`2498`} Followers</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View width="60%">
                    <PhotoReel data={data} />
                </View>
            </StyledArtPatron>
        </StyledArtPatronContainer>
    )
}

export default ArtPatron