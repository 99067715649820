import styled from "styled-components";

interface ArtPatronContainerProps {

}

export const StyledArtPatronContainer = styled.div<ArtPatronContainerProps>`

`

interface ArtPatronProps {

}

export const StyledArtPatron = styled.div<ArtPatronProps>`
    display: inline-flex;
    align-items: center;
    width: 100%;
    gap: 12px;
`