import styled from 'styled-components'

interface PreviewContainerProps {
    ref?: any
}

export const StyledPreviewContainer  = styled.div<PreviewContainerProps>`
    padding: 12px;
    position: fixed;
    height: fit-content;
    width: calc(70% - 136px);
    background: #fff;
    z-index: 3;
    border-radius: 10px;
`

interface PreviewProps {

}

export const StyledPreview = styled.div<PreviewProps>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    width: 100%;
    height: fit-content;
    padding: 12px;
`

interface LeftSectionContainerProps {

}

export const StyledLeftSectionContainer = styled.div<LeftSectionContainerProps>`

`

interface RightSectionContainerProps {

}

export const StyledRightSectionContainer = styled.div<RightSectionContainerProps>`

`

interface RightSectionProps {

}

export const StyledRightSection = styled.div<RightSectionProps>`
    
`
