import styled from 'styled-components'


interface CreatePostContentContainerProps {

}


export const StyledCreatePostContentContainer  = styled.div<CreatePostContentContainerProps>`

`

interface CreatePostContentProps {

}

export const StyledCreatePostContent = styled.div<CreatePostContentProps>`

`


