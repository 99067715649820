import styled from 'styled-components'


interface CreateAuctionArtworkContentContainerProps {

}


export const StyledCreateAuctionArtworkContentContainer  = styled.div<CreateAuctionArtworkContentContainerProps>`
`

interface CreateAuctionArtworkContentProps {

}

export const StyledCreateAuctionArtworkContent = styled.div<CreateAuctionArtworkContentProps>`
`
