import { useLandingPageStateStore } from "../../../stores/web.store"
import { Content } from "./Content"
import { Header } from "./Header"
import { CreateAccountState } from "./States/CreateAccountState"
import { InitialState } from "./States/InitialState"
import { SignInState } from "./States/SignInState"
import { SignupCompleteState } from "./States/SignupCompleteState"
import { StyledMainContentContainer } from "./styled"

const MainContent = () => {
    const activeState = useLandingPageStateStore((state: any) => state.activeState)
    return (
        <StyledMainContentContainer>
            <Header />
            <Content>
                {activeState === 'initial' && <InitialState />}
                {activeState === 'signIn' && <SignInState />}
                {activeState === 'createAccount' && <CreateAccountState />}
                {activeState === 'signupComplete' && <SignupCompleteState />}
            </Content>
        </StyledMainContentContainer>
    )
}

export default MainContent