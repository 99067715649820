//https://nucleoapp.com/social-media-icons
import { Text } from "../../Text"
import { StyledShare, StyledShareContainer } from "./styled"
import {ReactComponent as PinterestIcon} from '../../../img/pinterest.svg'
import {ReactComponent as InstagramIcon} from '../../../img/instagram.svg'
import {ReactComponent as WhatsAppIcon} from '../../../img/whatsapp.svg'
import {ReactComponent as TikTokIcon} from '../../../img/tikTok.svg'
import {ReactComponent as FacebookIcon} from '../../../img/facebook.svg'
import {ReactComponent as MessageIcon} from '../../../img/message.svg'
import {ReactComponent as LinkIcon} from '../../../img/link.svg'
import { View } from "../../View"
import { Button } from "../../Button"
import { useEffect, useRef, useState } from "react"


const Share = ({post, noAdjust}: any) => {
    const postUrl = window.location.protocol + '//' + window.location.host + '/posts/' + post.id

    const popoutRef = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState(0);

    useEffect(() => {
        if (popoutRef.current) {
            const width = popoutRef.current.offsetWidth;
            setLeft(noAdjust ? 0 : -width);
        }
    }, []);

    const onClickCopy = () => {
        navigator.clipboard.writeText(postUrl)
    }

    const shareToFacebook = () => {
        const url = encodeURIComponent(postUrl);
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        window.open(facebookShareUrl, '_blank', 'noopener,noreferrer');
    };

    const shareToPinterest = () => {
        const url = encodeURIComponent(postUrl);
        const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${url}`;
        window.open(pinterestShareUrl, '_blank', 'noopener,noreferrer');
    };

    const shareToWhatsApp = () => {
        const url = encodeURIComponent(postUrl);
        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${url}`;
        window.open(whatsappShareUrl, '_blank', 'noopener,noreferrer');
    };

    const shareToMessenger = () => {
        const url = encodeURIComponent(postUrl);
        const messengerShareUrl = `fb-messenger://share?link=${url}`;
        window.open(messengerShareUrl, '_blank', 'noopener,noreferrer');
    };

    const shareToInstagram = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                url: postUrl
            }).catch(console.error);
        } else {
            alert('Sharing to Instagram is typically done via mobile apps.');
        }
    };

    const shareToTikTok = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                url: postUrl
            }).catch(console.error);
        } else {
            alert('Sharing to TikTok is typically done via mobile apps.');
        }
    };

    return (
        <StyledShareContainer left={left} ref={popoutRef}>
            <StyledShare>
                <View padding="24px 0px 0px 0px">
                    <View display="inline-flex" alignItems="center" width="100%" gap="24px" justifyContent="center">
                        <Button noStyle borderRadius="50%" onClick={shareToPinterest}><PinterestIcon height={32} width={32}/></Button>
                        <Button noStyle borderRadius="50%" onClick={shareToInstagram}><InstagramIcon height={32} width={32}/></Button>
                        <Button noStyle borderRadius="50%" onClick={shareToWhatsApp}><WhatsAppIcon height={32} width={32}/></Button>
                        <Button noStyle borderRadius="50%" onClick={shareToTikTok}><TikTokIcon height={32} width={32}/></Button>
                        <Button noStyle borderRadius="50%" onClick={shareToFacebook}><FacebookIcon height={32} width={32}/></Button>
                        <Button noStyle borderRadius="50%" onClick={shareToMessenger}><MessageIcon height={32} width={32}/></Button>
                        <Button noStyle fontWeight="500" onClick={onClickCopy}><LinkIcon height={32} width={32}/></Button>
                    </View>
                </View>

            </StyledShare>
        </StyledShareContainer>
    )
}

export default Share