import { Button } from "../../../../components/Button"
import { Image } from "../../../../components/Image"
import { Text } from "../../../../components/Text"
import { View } from "../../../../components/View"
import { useResponsiveStore } from "../../../../stores/web.store"


type FooterAdProps = {
    image: string;
}

const FooterAd = ({image}: FooterAdProps) => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <View background="#F4F5F7" borderRadius="10px" height={width > 768 ? "320px" : "fit-content"}>
            <View display="inline-flex" alignItems="center" width="100%" gap={width > 768 ? "0px" : "12px"} flexDirection="row">
                <View width="50%">
                    <View padding={width > 768 ? "0px 48px" : "24px"}>
                        <View>
                            <Text fontSize={width > 768 ? "36px" : "24px"} fontWeight="500">{`Launching soon on iOS and visionOS`}</Text>
                        </View>
                        <View padding="12px 0px 0px 0px">
                            <Button 
                                noStyle 
                                border="solid 1px #000" 
                                padding="12px 24px" 
                                borderRadius="24px" 
                                fontSize="20px"
                                background="#fff"
                            >{`Discover`}</Button>
                        </View>
                    </View>
                </View>
                <View textAlign="right" width="50%">
                    <Image 
                        src={image || "/img/visionAd.png"} 
                        height={width > 768 ? "320px" : "auto"}
                        width="100%" 
                        objectFit={width > 768 ? "contain" : "cover"}
                        isNoEffect
                    />
                </View>
            </View>
        </View>
    )
}

export default FooterAd