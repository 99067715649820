import styled from 'styled-components'


interface PageLoaderContainerProps {
}

export const StyledPageLoaderContainer  = styled.div<PageLoaderContainerProps>`
    height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
`

interface PageLoaderProps {

}

export const StyledPageLoader  = styled.div<PageLoaderProps>`
  
  
`



