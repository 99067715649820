import styled from 'styled-components'

interface HangArtPieceContainerProps {
    top?: string;
}

// implement absolute positioning incas want to hang it somewhere not just in middle
export const StyledHangArtPieceContainer  = styled.div<HangArtPieceContainerProps>`
    position: absolute;
    top: ${props => props.top || '20%'};
    text-align: center;
    width: 100%;
`

interface HangArtPieceProps {
    width: number;
    height: number;
}

export const StyledHangArtPiece = styled.div<HangArtPieceProps>`
    width: ${props => `${props.width * .5}px`};
    margin: auto;
    scale: .5;

    
`
