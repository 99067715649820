import styled from 'styled-components'

interface FiltersContainerProps {

}


export const StyledFiltersContainer  = styled.div<FiltersContainerProps>`
    
`

interface FiltersProps {

}


export const StyledFilters  = styled.div<FiltersProps>`
    display: inline-flex;
    width: 100%;
    gap: 8px;
`

